import InputSelect from '../../../InputSelect';
import Label from '../../../Label';
import { ColumnDefinitionList } from '../../types';
import styles from './index.module.css';

interface CascadeInputSelectProps <T, K extends keyof T> {
	controlColumn: ColumnDefinitionList<T,K>;
	onChange: (value: string) => void;
}


const CascadeInputSelect = <T, K extends keyof T>({controlColumn, onChange}: CascadeInputSelectProps<T,K>) => {
	const onChangeHandler = (v: string) => {
		onChange(v);
	};
	return (
		<div className={styles.container}>
			<Label htmlFor={`input-${String(controlColumn.key)}`} strong>{controlColumn.name}</Label>
			<InputSelect id={`input-${String(controlColumn.key)}`}
				valueOptions={controlColumn.columnListValues}
				className={styles.input}
				onChange={onChangeHandler}
			/>
		</div>
	);
};

export default CascadeInputSelect;
