/*
*  Handles API requests to back-end
*/
import {API_BASE_URL, FETCH_RETRIES, FETCH_MIN_DELAY, FETCH_TIMEOUT, FETCH_NOTIFY} from '../configuration';
import { declaratieListUnprocessed, declaratieVerantwoordingListUnprocessed, usersMe, usersNotification, usersNotificationChanged } from '../requests';
import clearSelection from './clearSelection';

/*
*  Interface for Fetch options
*/
/** @deprecated */
interface Options {
	method: RequestInit['method']
	body?: RequestInit['body']
	headers?: Record<string, string>
	redirect: RequestInit['redirect']
	cache: RequestInit['cache']
}
/** @deprecated */
function makeid(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}


/** @deprecated */
const fetchRetryFetch = (url: string, options: RequestInit | undefined, attempt: number): Promise<Response | 'timeout' | 'offline'> => {
	return new Promise((resolve, reject) => {
		if (!window.navigator.onLine) {
			resolve('offline');
			return;
		}

		const controller = new AbortController();
		const id = setTimeout(() => {
			resolve('timeout');
			controller.abort();
		}, FETCH_TIMEOUT * (attempt + 1));

		fetch(url, {
			...options,
			signal: controller.signal,
		}).then((response) => {
			clearTimeout(id);
			if (response.headers.get('content-type') !== 'application/json' && !response.headers.get('content-type')?.startsWith('application/json;')) {
				throw new Error('Unable to decode response as json! Got ' + response.headers.get('content-type'));
			}
			resolve(response);
		}).catch((error) => {
			console.error('Request error for ' + url, error);
			if (!window.navigator.onLine) {
				resolve('offline');
				return;
			}
			reject(error);
		});
	});
};

/*
*  Automatic retry in case of failure
*/
/** @deprecated */
const fetchRetry = async (url: string, options: RequestInit | undefined, retries: number): Promise<Response> => {
	let lastError: Error | null = null;
	for (let i = 0; i < retries; i++) {
		const delayPromise = new Promise((resolve) => window.setTimeout(resolve, Math.min(FETCH_MIN_DELAY * i, 1000)));
		try {
			const res = await fetchRetryFetch(url, options, i);
			if (res === 'timeout') {
				const error = new Error('Timeout while talking to the backend');
				console.warn('Caught failed request: ', error);
				if (!lastError) {
					lastError = error;
				}
			} else if (res === 'offline') {
				i--;
			} else {
				return res;
			}
		} catch (error) {
			console.warn('Caught failed request: ', error);
			lastError = error instanceof Error ? error : new Error(`${error}`);
		}
		await delayPromise;
	}
	throw lastError ?? new Error('Unknown error while talking to ' + url);
};

/**
 *  Performs API request to back-end with automatic retry in case of failure
 */
/** @deprecated */
const apiRequest = async (method: Options['method'], endpoint: string, body?: any, headers?: Options['headers']) => {
	// clear text selection in front-end on every API call
	// should be moved elsewhere in the future
	clearSelection();

	const requestId = makeid(32);

	const eventData = {
		requestId,
		endpoint,
		method,
	};

	let hasAnnouncedTimer: number | null = window.setTimeout(() => {
		hasAnnouncedTimer = null;
		window.dispatchEvent(new CustomEvent('aanbieder-url-loading', {
			detail: eventData,
		}));
	}, FETCH_NOTIFY);

	try {

		const options: Options = {
			method: method,
			headers: body ? {'Content-Type': 'application/json', 'X-conflict-token': requestId} : {},
			redirect: 'follow',
			cache: 'default',
		};
		if (body) options.body = JSON.stringify(body);
		if (headers) options.headers = {...options.headers, ...headers};
		try {
			const response = await fetchRetry(API_BASE_URL + endpoint, options, FETCH_RETRIES);
			return await response.json();
		} catch (e) {
			return {error: true, response: `${e}`};
		}
	} finally {
		if (hasAnnouncedTimer === null) {
			window.dispatchEvent(new CustomEvent('aanbieder-url-loading-done', {
				detail: eventData,
			}));
		} else {
			window.clearTimeout(hasAnnouncedTimer);
		}
	}
};
/** @deprecated */
const makeHeader = (token: string) => {
	return {
		Authorization: 'Bearer ' + token,
	};
};

type GenericError = {error: true, response: string, status: number};

function convertModernApIToLegacy<I, O>(modern: { execute(input: I): Promise<O> }): (input: I) => Promise<O | GenericError> {
	return async (input) => {
		try {
			return await modern.execute(input);
		} catch (e) {
			return {error: true, response: `${e}`, status: 0};
		}
	};
}
/*
*  Individual API calls with hardcoded URL paths
*/

// GET

/** @deprecated */
const getVowInProgress = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocreqs/providers/' + provider + '/clients/' + client + '/status', null, headers);
};

/** @deprecated */
const getVotInProgress = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/changereqs/providers/' + provider + '/clients/' + client + '/status', null, headers);
};

/** @deprecated */
const getAllocationList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/allocations', null, headers);
};

/** @deprecated */
const getAllocationRequestList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/allocreqs', null, headers);
};

/** @deprecated */
const getChangeRequestList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/changereqs', null, headers);
};

/** @deprecated */
const getInvoiceRules = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/invoicerules', null, headers);
};

/** @deprecated */
const getInvoiceRulesForBatch = (provider: number, batch: string, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/batch/' + batch, null, headers);
};

/** @deprecated */
const getRulesForInvoice = (provider: number, invoice: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/invoice/' + invoice, null, headers);
};

/** @deprecated */
const getInvoice = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/invoices', null, headers);
};

/** @deprecated */
const getInvoiceUnprocessed = convertModernApIToLegacy(declaratieListUnprocessed);

/** @deprecated */
const setInvoiceRuleCredit = (provider: number, invoice: number, invoicerule: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/invoice/' + invoice + '/invoicerule/' + invoicerule, null, headers);
};

/** @deprecated */
const checkDuplicateProduct = (provider: number, client: number, product: string | null, categorie_code: string | null, begindatum: string, einddatum: string, allocreq: number | null, wet: string, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocreqs/providers/' + provider + '/clients/' + client + '/conflict-check', {
		product,
		categorie_code,
		begindatum,
		einddatum,
		allocreq,
		wet,
	}, headers);
};
/** @deprecated */
const getVowList = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/changereqs/providers/' + provider + '/clients/' + client, null, headers);
};

/** @deprecated */
const getCheckToewijzing = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/changereqs/providers/' + provider + '/clients/' + client + '/check', null, headers);
};

/** @deprecated */
const getTrajectFinanciering = (provider: number, client: number, alloc: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/traject', null, headers);
};

/** @deprecated */
const getStartStopList = (provider: number, client: number, alloc: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/startstops', null, headers);
};

/** @deprecated */
const getToewijzing = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client, null, headers);
};

/** @deprecated */
const getActieveToewijzing = (provider: number, client: number, changereq: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/changereqs/providers/' + provider + '/clients/' + client + '/changereq/' + changereq, null, headers);
};

/** @deprecated */
const getToewijzingAfterUpdate = (provider: number, client: number, alloc: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc, null, headers);
};

const getToewijzingBerichtForExport = (provider: number, client: number, alloc: number, headers?: Options['headers']): Promise<
GenericError |
{
	status: 200,
	response: {
		bericht: string,
		json: string,
		verwerkt_ts: string,
		gemeente_naam: string,
	} | null
}
> => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/get-bericht', null, headers);
};

/** @deprecated */
const getVerantwoording = (provider: number, client: number, alloc: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/statements', null, headers);
};

/** @deprecated */
const getVotList = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/allocreqs/providers/' + provider + '/clients/' + client, null, headers);
};

/** @deprecated */
const getGemeente = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/municipalities', null, headers);
};

/** @deprecated */
const getClientList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/clients/providers/' + provider, null, headers);
};

/** @deprecated */
const findProvider = (clientId: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/clients/find-provider/' + clientId, null, headers);
};

/** @deprecated */
const getBsnUnique = (provider: number, client: number, bsn: string, headers?: Options['headers']) => {
	return apiRequest('GET', '/clients/providers/' + provider + '/clients/' + client + '/bsn/' + bsn + '/unique', null, headers);
};

/** @deprecated */
const getValueList = (key: string, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/valuelists/' + key, null, headers);
};

/** @deprecated */
const getCategorieList = (headers?: Options['headers']) => {
	return apiRequest('GET', '/users/categories', null, headers);
};

/** @deprecated */
const getContract = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/contracts', null, headers);
};

/** @deprecated */
const getContractRates = (provider: number, id: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/rates/id/' + id, null, headers);
};

/** @deprecated */
const getXMLData = (provider: number, id: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/xmldata/id/' + id, null, headers);
};

/** @deprecated */
const getPeriod = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/periods', null, headers);
};

/** @deprecated */
const getUser = convertModernApIToLegacy(usersMe);

/** @deprecated */
const getAanbieder = (user: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/users/' + user + '/providers', null, headers);
};

/** @deprecated */
const getNotification  = convertModernApIToLegacy(usersNotification);

/** @deprecated */
const getChangedNotification  = convertModernApIToLegacy(usersNotificationChanged);

/** @deprecated */
const getStatement = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider, null, headers);
};

/** @deprecated */
const getStatementsUnprocessed = convertModernApIToLegacy(declaratieVerantwoordingListUnprocessed);

/** @deprecated */
const getStatementDateRange = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/invoices/providers/' + provider + '/daterange', null, headers);
};

/** @deprecated */
const getMessageList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/lists/providers/' + provider + '/list/messages', null, headers);
};

/** @deprecated */
const appManageApiCall = (path: string, headers?: Options['headers']) => {
	return apiRequest('GET', path, null, headers);
};
/**@deprecated */
const getSamenwerkingsList = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/contracts/samenwerkinsgverband/' + provider, null, headers);
};
/**@deprecateds */
const getProductRatesBySamenwerking = (provider: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/contracts/aanbieder/' + provider + '/samenwerkinsgverband/productdetails/', null, headers);
};

const getContractTariefHistory = (provider: number, contractregel_id: number, headers?: Options['headers']) => {
	return apiRequest('GET', '/contracts/samenwerkinsgverband/' + provider + '/tariefhistory/' + contractregel_id, null, headers);
};

// POST

/** @deprecated */
const sendVot = (provider: number, client: number, allocreqs: string, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocreqs/providers/' + provider + '/clients/' + client + '/send/' + allocreqs, null, headers);
};

/** @deprecated */
const generateInvoiceRules = (provider: number, body: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/invoices/providers/' + provider, body, headers);
};

/** @deprecated */
const sendInvoices = (provider: number, body: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/invoices/providers/' + provider + '/invoices/send', body, headers);
};

/** @deprecated */
const sendVow = (provider: number, client: number, changereq: number, headers?: Options['headers']) => {
	return apiRequest('POST', '/changereqs/providers/' + provider + '/clients/' + client + '/changereq/' + changereq + '/send', null, headers);
};

/** @deprecated */
const addVow = (provider: number, client: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/changereqs/providers/' + provider + '/clients/' + client, requestBody, headers);
};

/** @deprecated */
const addStartZorg = (provider: number, client: number, alloc: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/start', requestBody, headers);
};

/** @deprecated */
const addStopZorg = (provider: number, client: number, alloc: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/stop', requestBody, headers);
};

/** @deprecated */
const addVerantwoording = (provider: number, client: number, alloc: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc, requestBody, headers);
};

/** @deprecated */
const addVot = (provider: number, client: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/allocreqs/providers/' + provider + '/clients/' + client, requestBody, headers);
};

/** @deprecated */
const uploadMessage = (requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/app/messages/upload', requestBody, headers);
};

/** @deprecated */
const addClient = (provider: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('POST', '/clients/providers/' + provider, requestBody, headers);
};

// DELETE

/** @deprecated */
const archiveInvoice = (provider: number, invoice: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/invoices/providers/' + provider + '/invoice/' + invoice, null, headers);
};

/** @deprecated */
const archiveVow = (provider: number, client: number, changereq: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/changereqs/providers/' + provider + '/clients/' + client + '/changereq/' + changereq, null, headers);
};

/** @deprecated */
const archiveStartStop = (provider: number, client: number, alloc: number, startstop: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/startstops/' + startstop, null, headers);
};

/** @deprecated */
const archiveVerantwoording = (provider: number, client: number, alloc: number, statement: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/statements/' + statement, null, headers);
};

/** @deprecated */
const archiveVot = (provider: number, client: number, allocreq: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/allocreqs/providers/' + provider + '/clients/' + client + '/allocreq/' + allocreq, null, headers);
};

/** @deprecated */
const archiveClient = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/clients/providers/' + provider + '/clients/' + client, null, headers);
};

/** @deprecated */
const archiveNotification = (user: number, notification: number, headers?: Options['headers']) => {
	return apiRequest('DELETE', '/users/' + user + '/notifications/' + notification, null, headers);
};

// PUT

/** @deprecated */
const updateVow = (provider: number, client: number, changereq: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('PUT', '/changereqs/providers/' + provider + '/clients/' + client + '/changereq/' + changereq, requestBody, headers);
};

/** @deprecated */
const updateVerantwoording = (provider: number, client: number, alloc: number, statement: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('PUT', '/allocs/providers/' + provider + '/clients/' + client + '/alloc/' + alloc + '/statements/' + statement, requestBody, headers);
};

/** @deprecated */
const updateVot = (provider: number, client: number, allocreq: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('PUT', '/allocreqs/providers/' + provider + '/clients/' + client + '/allocreq/' + allocreq, requestBody, headers);
};

/** @deprecated */
const updateDefaultAanbieder = (user: number, provider: number, headers?: Options['headers']) => {
	return apiRequest('PUT', '/users/' + user + '/providers/' + provider, null, headers);
};

/** @deprecated */
const updateUserData = (user: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('PUT', '/users/' + user, requestBody, headers);
};

/** @deprecated */
const updateClient = (provider: number, client: number, requestBody: any, headers?: Options['headers']) => {
	return apiRequest('PUT', '/clients/providers/' + provider + '/clients/' + client, requestBody, headers);
};

/** @deprecated */
const unArchiveClient = (provider: number, client: number, headers?: Options['headers']) => {
	return apiRequest('PUT', '/clients/providers/' + provider + '/clients/' + client + '/unarchive', null, headers);
};

/** @deprecated */
const updateNotification = (user: number, notification: number, status: number, headers?: Options['headers']) => {
	return apiRequest('PUT', '/users/' + user + '/notifications/' + notification + '/status/' + status, null, headers);
};

/**
 * @deprecated
 * @description: endpoint has been deleted in the backend */
const updateTariefValue = (provider: number, tarief_id: number, new_begindatum: string,
	new_einddatum: string, incoming_contractregel_id: number, new_tarief: number, headers?: Options['headers']) => {
	return apiRequest('PUT', '/contracts/samenwerkinsgverband/' + provider + '/tarief/' + tarief_id + '/begindatum/' + new_begindatum + '/einddatum/' 
		+ new_einddatum + '/contractregel_id/' + incoming_contractregel_id + '/tarief/' + new_tarief, null, headers);
};


/**@deprecated */
const updateContractRatesBySamenwerking = (contract_id: number, new_begindatum: Date, new_tarief: number, headers?: Options['headers']) => {
	return apiRequest('PUT', '/contracts/samenwerkingsverband/contract_id/' + contract_id + '/new_begindatum/' + new_begindatum + '/new_tarief/' + new_tarief);
};

export default {
	addClient,
	addStartZorg,
	addStopZorg,
	addVerantwoording,
	addVot,
	addVow,
	appManageApiCall,
	archiveClient,
	archiveInvoice,
	archiveNotification,
	archiveStartStop,
	archiveVerantwoording,
	archiveVot,
	archiveVow,
	checkDuplicateProduct,
	generateInvoiceRules,
	getAanbieder,
	getActieveToewijzing,
	getAllocationList,
	getAllocationRequestList,
	getBsnUnique,
	getChangeRequestList,
	getChangedNotification,
	getCheckToewijzing,
	getClientList,
	getContract,
	getContractRates,
	getGemeente,
	getInvoice,
	getInvoiceRules,
	getInvoiceRulesForBatch,
	getInvoiceUnprocessed,
	getMessageList,
	getNotification,
	getPeriod,
	getRulesForInvoice,
	getStartStopList,
	getStatement,
	getStatementDateRange,
	getStatementsUnprocessed,
	getToewijzing,
	getToewijzingAfterUpdate,
	getToewijzingBerichtForExport,
	getTrajectFinanciering,
	getUser,
	getValueList,
	getVerantwoording,
	getVotInProgress,
	getVotList,
	getVowInProgress,
	getVowList,
	getXMLData,
	getCategorieList,
	getSamenwerkingsList,
	getProductRatesBySamenwerking,
	getContractTariefHistory,
	findProvider,
	makeHeader,
	sendInvoices,
	sendVot,
	sendVow,
	setInvoiceRuleCredit,
	unArchiveClient,
	updateClient,
	updateDefaultAanbieder,
	updateNotification,
	updateUserData,
	updateVerantwoording,
	updateVot,
	updateVow,
	uploadMessage,
	updateTariefValue,
	updateContractRatesBySamenwerking,
};
