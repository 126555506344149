import React from 'react';
import api from '../../util/apiRequest';
import ClientSidebarList from './ClientSidebarList';
import {confirmAlert} from 'react-confirm-alert';
import isAuthorized from '../../util/isAuthorized';

export default class ClientSidebar extends React.Component {
	constructor() {
		super();
		this.state = {
			loaded: false,
			search: '',
			sortby: 'achternaam',
			order: true,
			count: 0,
			aanbieder: 0,
			trigger: 0,
			updateClients: 0,
		};
		this.searchField = React.createRef();
	}

	handleSearch = event => {
		const value = event.target.value;
		this.setState(state => {
			state.search = value;
			state.trigger = state.trigger + 1;
			return state;
		});
	};

	handleOrder = field => {
		this.setState(state => {
			if (field !== state.sortby) {
				state.sortby = field;
				if (field === 'recent' || field === 'berichten') {
					state.order = false;
				} else {
					state.order = true;
				}
			} else {
				state.order = !state.order;
			}
			state.trigger = state.trigger + 1;
			return state;
		});
	};

	confirmArchive = (client, event) => {
		event.stopPropagation();
		const data = this.props.clients.find(item => item.id === client);
		const {autorisatie} = this.props.dbUser;

		isAuthorized(autorisatie, 'functie.uitvoeren')
			? confirmAlert({
				title: 'Cliënt archiveren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt archiveren?`,
				buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.handleArchive(data)}],
			})
			: confirmAlert({
				title: 'Cliënt archiveren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt archiveren?`,
				buttons: [{label: 'Annuleer'}],
			});
	};

	handleArchive = async data => {
		const {aanbieder, token} = this.props.dbUser;
		const index = this.props.clients.findIndex(item => item.id === data.id);
		data.verwijderd = 1;

		const archiveClient = await api.archiveClient(aanbieder, data.id, api.makeHeader(token));
		if (archiveClient.error && archiveClient.error === true) {
			this.props.handleForm(archiveClient);
			return null;
		}

		this.props.handleUpdateClientList({
			data: data,
			index: index,
		});
	};

	componentDidMount = () => {
		this.handleClientList();
	};

	handleClientList = () => {
		const {aanbieder} = this.props.dbUser;
		this.setState(state => {
			state.aanbieder = aanbieder;
			state.loaded = true;
			state.trigger = state.trigger + 1;
			state.updateClients = this.props.updateClients;
			return state;
		});
	};

	componentDidUpdate() {
		const {aanbieder, updateClients} = this.state;
		if (aanbieder !== this.props.dbUser.aanbieder || updateClients !== this.props.updateClients) {
			return this.handleClientList();
		}
	}

	handleEdit = (client, event) => {
		event.stopPropagation();
		const data = this.props.clients.find(item => item.id === client);
		const index = this.props.clients.findIndex(item => item.id === client);

		this.props.handleForm({
			data: {...data, index: index},
			gemeentes: this.props.gemeentes,
			formid: 'client-wijzigen',
		});
	};

	handleAdd = event => {
		event.stopPropagation();
		this.props.handleForm({
			data: {bsn: '', index: null},
			gemeentes: this.props.gemeentes,
			formid: 'client-toevoegen',
		});
	};

	handleChooseClient = data => {
		this.props.handleChooseClient(data, `/client/${data.id}`);
	};

	render = () => {
		const {loaded, search, sortby, order, trigger} = this.state;
		const {collapse, clients, handleCollapseSidebar} = this.props;
		const {autorisatie} = this.props.dbUser;

		if (!loaded) return null;

		return (
			<>
				<section className={collapse ? 'sidebar collapsed' : 'sidebar'}>
					<header>
						<h1>Cliënten</h1>
						<button
							onClick={event => this.handleAdd(event)}
							className={isAuthorized(autorisatie, 'client.aanmaken') ? 'add' : 'add disabled'}
							tabIndex='-1'
							disabled={isAuthorized(autorisatie, 'client.aanmaken') ? false : true}></button>
					</header>
					<section className='searchsort'>
						<section className='search'>
							<input onChange={event => this.handleSearch(event)} tabIndex='1' type='text' placeholder='Zoek op cliëntgegevens' value={search} ref={this.searchField} />
						</section>
						<section className='sort'>
							<button onClick={() => this.handleOrder('achternaam')} className={sortby === 'achternaam' ? 'name selected' : 'name'} tabIndex='-1'>
                                Naam
							</button>
							<button onClick={() => this.handleOrder('recent')} className={sortby === 'recent' ? 'recent selected' : 'recent'} tabIndex='-1'>
								<aside>{new Date().getDate()}</aside>
                                Recent
							</button>
							<button onClick={() => this.handleOrder('berichten')} className={sortby === 'berichten' ? 'messages selected' : 'messages'} tabIndex='-1'>
                                Berichten
							</button>
						</section>
					</section>

					<ClientSidebarList
						search={search}
						sortby={sortby}
						order={order}
						clients={clients}
						handleEdit={this.handleEdit}
						handleChooseClient={this.handleChooseClient}
						confirmArchive={this.confirmArchive}
						trigger={trigger}
						autorisatie={autorisatie}
					/>

					<section className={collapse ? 'collapse handle' : 'collapse'} onClick={() => handleCollapseSidebar()}>
						<button className={collapse ? 'arrow' : ''} tabIndex='-1'></button>
					</section>
				</section>
			</>
		);
	};
}
