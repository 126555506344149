import {BaseSyntheticEvent, Component, createRef, Fragment, SyntheticEvent} from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import removeDuplicates from '../../util/removeDuplicates';
import lastDayOfPreviousMonth from '../../util/lastDayOfPreviousMonth';
import isAuthorized from '../../util/isAuthorized';
import formatCurrency from '../../util/formatCurrency';
import {confirmAlert} from 'react-confirm-alert';
import {FILTER_STATEMENTS_FOR_INVOICES, FILTER_DATE_MAX} from '../../configuration';
import clearSelection from '../../util/clearSelection';
import InvoiceInfo from './InvoiceInfo';
import ExcelManageStatement from '../report/ExcelManageStatement';
import ExcelManageInvoice from '../report/ExcelManageInvoice';
import assertNever from '../../util/assertNever';
import produce from 'immer';
import { VerantwoordingLegacy, DbUser, HandleForm, LegacyTable, Declaratie, DeclaratieRegel } from '../../types';
import { DeclaratieButtons } from './DeclaratieButtons';

interface Props {
	sidebarTrigger: number
	handleForm: HandleForm
	dbUser: DbUser
	handleCollapseSidebarTrigger: unknown;
}

interface State {
	tables: {
		1: LegacyTable<VerantwoordingLegacy>
		2: LegacyTable<Declaratie>
	}
	statements: VerantwoordingLegacy[],
	invoices: Declaratie[],
	loaded: boolean,
	filters: {
		periode: string,
		gemeente: string,
		client: string,
		periode2: string,
		gemeente2: string,
	},
	batch: unknown,
	clients: {
		client: number,
		client_id: number,
		naam: string
		count: number
		total: number,
	}[],
	gemeentes: {
		total: number,
		count: number
		gemeente: string
		gemeente_code: string,
		gemeente_naam: string
	}[],
	gemeentes2: {
		total: number,
		count: number
		gemeente: string
		gemeente_code: string,
		gemeente_naam: string
	}[],
	stage: number,
	rules: DeclaratieRegel[],
	rules_loaded: number | false | null,
	initial_statements: number,
	initial_invoices: number,
	sidebarTrigger: number,
	loadingIndicators: {
		main?: boolean
	},
	update: number,
	trigger: number
	filtered_statements: VerantwoordingLegacy[],
	filtered_invoices: Declaratie[],
	periodes: {periode: string, count: number}[]
	periodes2:  {periode: string, count: number}[];
}

export default class InvoiceMain extends Component<Props, State> {
	myRef1: React.RefObject<any>;
	myRef2: React.RefObject<any>;

	constructor(props: Props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Verantwoordingen',
					figure: '',
					column: 'periode',
					label: 'Periode',
					order: false,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				2: {
					name: 'Declaraties',
					figure: '',
					column: 'tabel_periode',
					label: 'Periode',
					order: false,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			statements: [],
			invoices: [],
			loaded: false,
			filters: {
				periode: 'all',
				gemeente: 'all',
				client: 'all',
				periode2: 'all',
				gemeente2: 'all',
			},
			batch: null,
			clients: [],
			gemeentes: [],
			gemeentes2: [],
			stage: 1,
			rules: [],
			rules_loaded: false,
			initial_statements: 0,
			initial_invoices: 0,
			sidebarTrigger: 0,
			loadingIndicators: {},
			filtered_statements: [],
			filtered_invoices: [],
			trigger: 0,
			update: 0,
			periodes: [],
			periodes2: [],
		};
		this.myRef1 = createRef();
		this.myRef2 = createRef();
	}

	scrollToMyRef1 = (event: BaseSyntheticEvent) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		window.scrollTo(0, this.myRef1.current.offsetTop - 80);
		clearSelection();
	};

	scrollToMyRef2 = (event: BaseSyntheticEvent) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		window.scrollTo(0, this.myRef2.current.offsetTop - 80);
		clearSelection();
	};

	componentDidMount = async () => {
		await this.handleInit();
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	initFilters = async (source: 'statements' | 'invoices', data) => {
		if (source === 'statements') {
			const periodes = this.makePeriodeOptions(data);
			const periode = periodes[0] ? periodes[0].periode : 'all';
			const filtered = this.filterByPeriod(periode, data);
			const gemeentes = this.makeGemeenteOptions(periode, filtered);
			const clients = this.makeClientOptions(periode, 'all', filtered);

			return {
				periodes: periodes,
				periode: periode,
				gemeentes: gemeentes,
				gemeente: 'all',
				clients: clients,
				client: 'all',
				filtered: filtered,
			};
		}
		if (source === 'invoices') {
			const periodes = this.makePeriodeOptions(data);
			const periode = periodes[0] ? periodes[0].periode : 'all';
			const filtered = this.filterByPeriod(periode, data);
			const gemeentes = this.makeGemeenteOptions(periode, filtered);

			return {
				periodes: periodes,
				periode: periode,
				gemeentes: gemeentes,
				gemeente: 'all',
				clients: [],
				client: 'all',
				filtered: filtered,
			};
		}
		return assertNever(source);
	};

	handleInit = async (keepFiltersAndSelection = false, keepFiltersAndSelection2 = false) => {
		const {aanbieder, token} = this.props.dbUser;

		const getStatementsUnprocessed = await api.getStatementsUnprocessed({ aanbieder, token });
		if ('error' in getStatementsUnprocessed) {
			this.props.handleForm(getStatementsUnprocessed);
			return null;
		}

		const statements = getStatementsUnprocessed.response; // []
		const init_statements = await this.initFilters('statements', statements);
		// console.log(`init_statements`, init_statements)

		const getInvoiceUnprocessed = await api.getInvoiceUnprocessed({ aanbieder, token });
		if ('error' in getInvoiceUnprocessed) {
			this.props.handleForm(getInvoiceUnprocessed);
			return null;
		}
		const invoices = removeDuplicates(getInvoiceUnprocessed.response.slice(), 'id'); // []
		const init_invoices = await this.initFilters('invoices', invoices);
		// console.log(`init_invoices`, init_invoices)

		setTimeout(() => {
			if (!keepFiltersAndSelection) {
				this.handleSelectAll(1, init_statements.filtered, true, 'client', 'all');
			}
			if (!keepFiltersAndSelection2) {
				this.handleSelectAll(2, init_invoices.filtered, true, 'gemeente2', 'all');
			}
		}, 250);

		setTimeout(() => {
			this.updateScroll();
		}, 500);

		this.setState(state => ({
			...state,

			loaded: true,
			statements: statements,
			initial_statements:  statements.length,

			filters: {
				periode:  keepFiltersAndSelection ?  state.filters.periode : init_statements.periode,
				gemeente:  keepFiltersAndSelection ?  state.filters.gemeente : init_statements.gemeente,
				client:  keepFiltersAndSelection ?  state.filters.client : init_statements.client,
				periode2 :  keepFiltersAndSelection2 ?  state.filters.periode2 : init_invoices.periode,
				gemeente2:  keepFiltersAndSelection2 ?  state.filters.gemeente2 : init_invoices.gemeente,
			},
			periodes:  init_statements.periodes,
			gemeentes:  init_statements.gemeentes,
			clients:  init_statements.clients,

			filtered_statements:  init_statements.filtered,

			invoices:  invoices,
			initial_invoices:  invoices.length,

			periodes2:  init_invoices.periodes,
			gemeentes2 :  init_invoices.gemeentes,

			filtered_invoices :  init_invoices.filtered,
		}));
	};

	filterByPeriod = (periode, data) => {
		if (periode === 'all') {
			return data;
		}
		return data.filter(row => {
			return (row.tabel_periode ?? row.periode) === periode;
		});
	};

	makePeriodeOptions = data => {
		let periodes = data.map(row => {
			return {
				periode: row.tabel_periode ?? row.periode,
				count: data.filter(datarow => {
					return (row.tabel_periode ?? row.periode) === (datarow.tabel_periode ?? datarow.periode);
				}).length,
			};
		});

		periodes = removeDuplicates(periodes, 'periode');
		periodes = sortArray(periodes, false, 'periode');
		return periodes;
	};

	filterByGemeente = (gemeente, data) => {
		if (gemeente === 'all') {
			return data;
		}
		return data.filter(row => {
			return row.gemeente_code === gemeente;
		});
	};

	makeGemeenteOptions = (periode, data) => {
		let gemeentes = data;

		if (periode !== 'all') {
			gemeentes = gemeentes.filter(row => {
				return row.periode === periode;
			});
		}

		gemeentes = gemeentes.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,

				count: data.filter(datarow => {
					return row.gemeente_code === datarow.gemeente_code;
				}).length,
			};
		});

		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');
		gemeentes = sortArray(gemeentes, true, 'gemeente_naam');

		let total = 0;
		for (const gemeente of gemeentes) {
			total += gemeente.count;
		}
		for (const gemeente of gemeentes) {
			gemeente.total = total;
		}

		return gemeentes;
	};

	filterByClient = (client, statements) => {
		if (client === 'all') {
			return statements;
		}
		return statements.filter(row => {
			return row.client_id === parseInt(client);
		});
	};

	makeClientOptions = (periode, gemeente, statements) => {
		let clients = statements;

		if (periode !== 'all') {
			clients = clients.filter(row => {
				return row.periode === periode;
			});
		}

		if (gemeente !== 'all') {
			clients = clients.filter(row => {
				return row.periode === periode;
			});
		}

		clients = clients.map(row => {
			return {
				client_id: row.client_id,
				naam: row.naam,

				count: statements.filter(statement => {
					return row.gemeente_code === statement.gemeente_code && row.client_id === statement.client_id;
				}).length,
			};
		});

		clients = removeDuplicates(clients, 'client_id');
		clients = sortArray(clients, true, 'naam');

		let total = 0;
		for (const client of clients) {
			total += client.count;
		}
		for (const client of clients) {
			client.total = total;
		}

		return clients;
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	updateScroll = () => {
		const tables = [1, 2];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => ({
			...state,
			update: state.update + 1,
			sidebarTrigger: this.props.sidebarTrigger,
		}));
	};

	handleScroll = id => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const table = document.querySelector(`#table${id}`)!;
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => produce(state, state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		}));
	};

	handleSelect = (id, table) => {
		this.setState(state => produce(state, state => {
			const {select} = state.tables[table];
			const index = select.findIndex(row => {
				return row === id && row.has_tarief !== 0;
			});
			if (index === -1) {
				state.tables[table].select.push(id);
				return state;
			}
			state.tables[table].select = select.filter(row => {
				return row !== id;
			});
			return state;
		}));
	};

	handleCheckSelect = (id: number, table: 1 | 2) => {
		const {select} = this.state.tables[table];
		return select.includes(id);
	};
    
	handleSelectAll = async <T extends 1 | 2>(
		table: T,
		filtered1: (T extends 1 ? VerantwoordingLegacy : Declaratie)[],
		mode = true,
		filter: (T extends 1 ? 'periode' | 'gemeente' | 'client' : 'periode2' | 'gemeente2' | 'client')  | '' = '',
		value = '',
	) => {
		this.setState(state => produce(state, state => {
			const filtered = filtered1 as (VerantwoordingLegacy & Declaratie)[];
			const ids = filtered.map(row => row.id);

			if (mode === true) {
				state.tables[table].select = filtered.filter(row => row.has_tarief !== 0).map(row => row.id);
			} else {
				if (ids.length === state.tables[table].select.length) {
					state.tables[table].select = filtered.filter(e => e.previeus_declaratieregel_id !== null && table === 1).map(row => row.id);
				} else {
					state.tables[table].select = filtered.filter(row => row.has_tarief !== 0).map(row => row.id);
				}
			}

			if (filter === 'periode') {
				state.filters.periode = value;
				state.filters.gemeente = 'all';
				state.gemeentes = this.makeGemeenteOptions(value, filtered);
				state.filters.client = 'all';
				state.clients = this.makeClientOptions(value, 'all', filtered);
				state.filtered_statements = filtered;
			} else if (filter === 'periode2') {
				state.filters.periode2 = value;
				state.filters.gemeente2 = 'all';
				state.gemeentes2 = this.makeGemeenteOptions(value, filtered);
				state.filtered_invoices = filtered;
			} else if (filter === 'gemeente') {
				state.filters.gemeente = value;
				state.filters.client = 'all';
				state.clients = this.makeClientOptions(this.state.filters.periode, 'all', filtered);
				state.filtered_statements = filtered;
			} else if (filter === 'gemeente2') {
				state.filters.gemeente2 = value;
				state.filtered_invoices = filtered;
			} else if (filter === 'client') {
				state.filters.client = value;
				state.filtered_statements = filtered;
			}

			return state;
		}));
	};

	handleFilter = (filter, value) => {
		const {statements, invoices, filters} = this.state;

		let filtered = [];

		if (filter === 'periode') {
			filtered = this.filterByPeriod(value, statements);
			this.handleSelectAll(1, filtered, true, filter, value);
		} else if (filter === 'periode2') {
			filtered = this.filterByPeriod(value, invoices);
			this.handleSelectAll(2, filtered, true, filter, value);
		} else if (filter === 'gemeente') {
			filtered = this.filterByPeriod(filters.periode, statements);
			filtered = this.filterByGemeente(value, filtered);
			this.handleSelectAll(1, filtered, true, filter, value);
		} else if (filter === 'gemeente2') {
			filtered = this.filterByPeriod(filters.periode2, invoices);
			filtered = this.filterByGemeente(value, filtered);
			this.handleSelectAll(2, filtered, true, filter, value);
		} else if (filter === 'client') {
			filtered = this.filterByPeriod(filters.periode, statements);
			filtered = this.filterByGemeente(filters.gemeente, filtered);
			filtered = this.filterByClient(value, filtered);
			this.handleSelectAll(1, filtered, true, filter, value);
		}
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleSend = (event: SyntheticEvent | null, table: keyof State['tables']) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.setState(state => ({
			loadingIndicators: {
				...state.loadingIndicators,
				main: true,
			},
		}));
		const requiredEntries = this.state.statements.filter(e => e.previeus_declaratieregel_id !== null).map(e => e.id);
		for (const entry of this.state.tables[table].select) {
			const index = requiredEntries.indexOf(entry);
			if (index > -1) {
				requiredEntries.splice(index, 1);
			}
		}

		this.handleGenerateInvoiceRules([...this.state.tables[table].select, ...requiredEntries]).finally(() => {
			this.setState(state => ({
				loadingIndicators: {
					...state.loadingIndicators,
					main: false,
				},
				tables: {
					...state.tables,
					[table]: {
						...state.tables[table],
						select: [],
					},
				},
			}));
		});
	};

	handleSend2 = (event, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.handleSendInvoices(this.state.tables[table].select);
	};

	handleGenerateInvoiceRules = async selection => {
		const {aanbieder, token} = this.props.dbUser;

		const generateInvoiceRules = await api.generateInvoiceRules(aanbieder, {ids: selection}, api.makeHeader(token));
		if (generateInvoiceRules.error && generateInvoiceRules.error === true) {
			this.props.handleForm(generateInvoiceRules);
			return null;
		}

		return this.handleInit(true, false);
	};

	handleSendInvoices = async selection => {
		const {aanbieder, token} = this.props.dbUser;

		const sendInvoices = await api.sendInvoices(aanbieder, {ids: selection}, api.makeHeader(token));
		if (sendInvoices.error && sendInvoices.error === true) {
			this.props.handleForm(sendInvoices);
			return null;
		}

		return this.handleInit(true, true);
	};

	handleEnableSend = (table: 1 | 2) => {
		const {tables, statements} = this.state;
		return tables[table].select.length !== 0 || statements.length === 0;
	};

	handleEnableSend2 = (table: 1 | 2) => {
		const {tables, invoices} = this.state;
		return tables[table].select.length !== 0 || invoices.length === 0;
	};

	handleListInvoiceRules = async (event: BaseSyntheticEvent, declaratie_id: number) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (declaratie_id === this.state.rules_loaded) {
			return this.setState({
				rules_loaded: null,
				rules: [],
			});
		}
		const {aanbieder, token} = this.props.dbUser;
		const getRulesForInvoice = await api.getRulesForInvoice(aanbieder, declaratie_id, api.makeHeader(token));
		if (getRulesForInvoice.error && getRulesForInvoice.error === true) {
			this.props.handleForm(getRulesForInvoice);
			return null;
		}
		const rules = getRulesForInvoice.response;
		this.setState({
			rules_loaded: declaratie_id,
			rules: rules,
		});
	};

	handleArchiveInvoice = async (event: BaseSyntheticEvent, declaratie_id: number) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		const {autorisatie} = this.props.dbUser;
		isAuthorized(autorisatie, 'functie.uitvoeren')
			? confirmAlert({
				title: 'Declaratie archiveren',
				message: 'Weet u zeker dat u deze declaratie niet wilt indienen maar archiveren? De originele verantwoording(en) mogen dan weer worden gewijzigd.',
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				buttons: [{label: 'Annuleer', onClick: () => {}}, {label: 'OK', onClick: () => {
					this.setState((state) => ({
						loadingIndicators: {
							...state.loadingIndicators,
							[declaratie_id]: true,
						},
					}));

					this.doArchiveInvoice(declaratie_id).finally(() => {
						this.setState((state) => ({
							loadingIndicators: {
								...state.loadingIndicators,
								[declaratie_id]: false,
							},
						}));
					});
				}}],
			})
			: confirmAlert({
				title: 'Declaratie archiveren',
				message: 'Weet u zeker dat u deze declaratie niet wilt indienen maar archiveren? De originele verantwoording(en) mogen dan weer worden gewijzigd.',
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				buttons: [{label: 'Annuleer', onClick: () => {}}],
			});
	};

	doArchiveInvoice = async declaratie_id => {
		const {aanbieder, token} = this.props.dbUser;

		const archiveInvoice = await api.archiveInvoice(aanbieder, declaratie_id, api.makeHeader(token));
		if (archiveInvoice.error && archiveInvoice.error === true) {
			this.props.handleForm(archiveInvoice);
			return null;
		}

		const invoices = this.state.invoices.filter(row => {
			return Number(row.id) !== Number(declaratie_id);
		});
		const init_invoices = await this.initFilters('invoices', invoices);

		const getStatementsUnprocessed = await api.getStatementsUnprocessed({ aanbieder, token });
		if ('error' in getStatementsUnprocessed) {
			this.props.handleForm(getStatementsUnprocessed);
			return null;
		}

		const statements = getStatementsUnprocessed.response;
		const init_statements = await this.initFilters('statements', statements);

		this.setState(state => ({
			...state,

			loaded: true,
			statements: statements,
			initial_statements:  statements.length,

			filters: {
				periode: init_statements.periode,
				gemeente: init_statements.gemeente,
				client: init_statements.client,
				periode2 : init_invoices.periode,
				gemeente2: init_invoices.gemeente,
			},
			periodes:  init_statements.periodes,
			gemeentes:  init_statements.gemeentes,
			clients:  init_statements.clients,

			filtered_statements:  init_statements.filtered,

			invoices:  invoices,
			initial_invoices:  invoices.length,

			periodes2:  init_invoices.periodes,
			gemeentes2 :  init_invoices.gemeentes,

			filtered_invoices :  init_invoices.filtered,
		}));

		setTimeout(() => {
			this.handleSelectAll(1, init_statements.filtered, true, 'client', 'all');
			this.handleSelectAll(2, init_invoices.filtered, true, 'gemeente2', 'all');
		}, 0);
	};

	render = () => {
		const {
			loaded,
			clients,
			tables,
			statements,
			initial_statements,
			filtered_statements,
			gemeentes,
			periodes,
			gemeentes2,
			periodes2,
			filters,
			invoices,
			initial_invoices,
			filtered_invoices,
			stage,
			rules,
			rules_loaded,
			loadingIndicators,
		} = this.state;
		const {dbUser} = this.props;
		const {autorisatie} = dbUser;

		if (!loaded) return <Loading message='' />;
		if (!isAuthorized(autorisatie, 'declareren.tonen')) return '';

		// statements

		const data = sortArray(filtered_statements, tables[1].order, tables[1].column);


		// invoices

		const data2 = sortArray(filtered_invoices, tables[2].order, tables[2].column);

		let label2 = '';
		if (tables[2].select.length <= 1) {
			label2 = 'Deze declaratie indienen';
		} else {
			label2 = `Deze ${tables[2].select.length} declaraties indienen`;
		}
		if (invoices.length === 0) {
			label2 = 'Er zijn geen nieuwe declaraties';
		}

		// styling //
		const noBorder = {
			border: 'none',
		};
		const noHover = {
			backgroundColor: 'transparent',
		};

		return initial_statements === 0 && initial_invoices === 0 ? (
			<>
				<InvoiceInfo initial_statements={initial_statements} initial_invoices={initial_invoices} scrollToMyRef1={this.scrollToMyRef1} scrollToMyRef2={this.scrollToMyRef2} />
			</>
		) : (
			<>
				<InvoiceInfo initial_statements={initial_statements} initial_invoices={initial_invoices} scrollToMyRef1={this.scrollToMyRef1} scrollToMyRef2={this.scrollToMyRef2} />

				<section ref={this.myRef1} className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[1].figure}></figure>
							<h2>{tables[1].name}</h2>
							<div className='filters'>
								<label>Filters</label>
								<label>
									<span>
										<strong>Periode</strong>
									</span>
									<select
										name='periode'
										value={filters.periode}
										onChange={event => this.handleFilter(event.target.name, event.target.value)}
										className={statements.length === 0 ? 'disabled' : ''}
										disabled={statements.length === 0 ? true : false}>
										<option key='all' value='all'>
											{`Toon alle (${statements.length})`}
										</option>
										{!periodes.find(e => e.periode === filters.periode) ?
											<option disabled value={filters.periode}></option> :
											<option disabled></option>
										}
										{periodes.map(row => (
											<option key={row.periode} value={row.periode}>
												{`${row.periode} (${row.count})`}
											</option>
										))}
									</select>
								</label>
								<label>
									<span>
										<strong>Gemeente</strong>
									</span>
									<select
										name='gemeente'
										value={filters.gemeente}
										onChange={event => this.handleFilter(event.target.name, event.target.value)}
										className={statements.length === 0 ? 'disabled' : ''}
										disabled={statements.length === 0 ? true : false}>
										<option key='all' value='all'>
											{`Toon alle (${gemeentes[0] ? gemeentes[0].total : 0})`}
										</option>
										{!gemeentes.find(e => e.gemeente === filters.gemeente) ?
											<option disabled value={filters.gemeente}></option> :
											<option disabled></option>
										}
										{gemeentes.map(row => (
											<option key={row.gemeente_code} value={row.gemeente_code}>
												{`${row.gemeente_naam} (${row.count})`}
											</option>
										))}
									</select>
								</label>

								<label>
									<span>
										<strong>Cliënt</strong>
									</span>
									<select
										name='client'
										value={filters.client}
										onChange={event => this.handleFilter(event.target.name, event.target.value)}
										className={filtered_statements.length === 0 ? 'disabled' : ''}
										disabled={filtered_statements.length === 0 ? true : false}>
										<option key='all' value='all'>
											{`Toon alle (${clients[0] ? clients[0].total : 0})`}
										</option>
										<option disabled></option>
										{clients.map(row => (
											<option key={row.client_id} value={row.client_id}>
												{`${row.naam} (${row.count})`}
											</option>
										))}
									</select>
								</label>

								<label className='options'>Download</label>
								<label>
									{data.length !== 0 ? (
										<ExcelManageStatement dbUser={dbUser} data={data} />
									) : (
										<label>
											<button disabled={true} className='button download disabled'>
												<strong>Excel</strong>
											</button>
										</label>
									)}
								</label>
							</div>
						</section>
						<DeclaratieButtons
							selected={tables[1].select}
							data={data}
							dbUser={dbUser}
							loading={loadingIndicators.main}
							handleSend={() => this.handleSend(null, 1)}
						/>

						{data.length === 0 ? (
							<section className='table-empty'>
                                Er zijn geen nieuwe verantwoordingen gevonden van {FILTER_STATEMENTS_FOR_INVOICES ? formatDate(lastDayOfPreviousMonth(), 5) : formatDate(FILTER_DATE_MAX, 5)} en eerder.
							</section>
						) : (
							<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
								<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
								<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td onClick={() => this.handleSelectAll(1, filtered_statements, false)} className='col-check'>
												<button className='col-check-btn'/>
											</td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='periode' label='Periode' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='gemeente_naam' label='Gemeente' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='naam' label='Cliënt' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='toewijzing_nummer' label='Toewijzing' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='product' label='Product' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='begindatum' label='Begindatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='einddatum' label='Einddatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='volume' label='Volume' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='eenheid' label='Eenheid' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='status' label='Status' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table={1} data='status_ts' label='Datum en tijd' />
										</tr>
										{data.map(row => (
											<tr
												key={row.id}
												onClick={row.status === 1 && row.previeus_declaratieregel_id === null && row.has_tarief !== 0 ? () => this.handleSelect(row.id, 1) : undefined}
												className={row.status === 1 ? (this.handleCheckSelect(row.id, 1) ? 'highlight' : '') : undefined}
												title={
													row.previeus_declaratieregel_id !== null ? 'Dit is een gecrediteerde verantwoording, en moet meegenomen worden bij deze indien poging' :
														row.has_tarief === 0 ? 'Voor deze contract regels zijn er nog geen tarieven bekent. Deze kan niet automatisch ingedient worden. Contacteer zorgverkeer support als dit opgepakt moet worden.' :
															undefined
												}
											>
												<td>
													<button
														className={row.status === 1 && this.handleCheckSelect(row.id, 1) ? 'button checkbox checked' : 'button checkbox'}
														disabled={row.status !== 1 || row.previeus_declaratieregel_id !== null || row.has_tarief === 0}
													/>
												</td>
												<td>{notEmpty(row.periode) ? row.periode : <label>Geen data</label>}</td>
												<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
												<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
												<td>{notEmpty(row.toewijzing_nummer) ? row.toewijzing_nummer : <label>Geen data</label>}</td>
												<td>{notEmpty(row.product) ? row.product : <label>Geen data</label>}</td>
												<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum, 2) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum, 2) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
												<td>{notEmpty(row.eenheid) ? row.eenheid : <label>Geen data</label>}</td>
												<td>
													{notEmpty(row.status) ? (
														row.status === 1 ? (
															'Aangemaakt'
														) : row.status === 2 ? (
															'Ingediend'
														) : row.status === 3 ? (
															'Goedgekeurd'
														) : row.status === 4 ? (
															'Afgekeurd'
														) : (
															<label>Geen data</label>
														)
													) : (
														<label>Geen data</label>
													)}
												</td>
												<td title={`${row.id}`}>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						)}
					</section>
				</section>

				<section ref={this.myRef2} className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[2].figure}></figure>
							<h2>{tables[2].name}</h2>
							<div className='filters'>
								<label>Filters</label>
								<label>
									<span>
										<strong>Periode</strong>
									</span>
									<select
										name='periode2'
										value={filters.periode2}
										onChange={event => this.handleFilter(event.target.name, event.target.value)}
										disabled={invoices.length === 0 ? true : false}
										className={invoices.length === 0 ? 'disabled' : ''}>
										<option key='all' value='all'>
											{`Toon alle (${invoices.length})`}
										</option>
										<option disabled></option>
										{periodes2.map(row => (
											<option key={row.periode} value={row.periode}>
												{`${row.periode} (${row.count})`}
											</option>
										))}
									</select>
								</label>
								<label>
									<span>
										<strong>Gemeente</strong>
									</span>
									<select
										name='gemeente2'
										value={filters.gemeente2}
										onChange={event => this.handleFilter(event.target.name, event.target.value)}
										disabled={invoices.length === 0 ? true : false}
										className={invoices.length === 0 ? 'disabled' : ''}>
										<option key='all' value='all'>
											{`Toon alle (${gemeentes2.length > 0 ? gemeentes2[0].total : 0})`}
										</option>
										<option disabled></option>
										{gemeentes2.map(row => (
											<option key={row.gemeente_code} value={row.gemeente_code}>
												{`${row.gemeente_naam} (${row.count})`}
											</option>
										))}
									</select>
								</label>

								<label className='options'>Download</label>
								{data2.length !== 0 ? (
									<label>
										<ExcelManageInvoice dbUser={dbUser} data={data} />
									</label>
								) : (
									<label>
										<button disabled={true} className='button download disabled'>
											<strong>Excel</strong>
										</button>
									</label>
								)}
							</div>
						</section>
						{isAuthorized(autorisatie, 'functie.uitvoeren') ? (
							invoices.length === 0 ? (
								<section className='table-footer-top'>
									<form>
										<button className={'secondary disabled no-margin'} disabled={true}>
											{label2}
										</button>
									</form>
								</section>
							) : (
								<section className='table-footer-top'>
									<form>
										<button
											className={this.handleEnableSend2(2) && stage <= 2 ? 'primary no-margin' : 'secondary disabled no-margin'}
											onClick={event => this.handleSend2(event, 2)}
											disabled={(this.handleEnableSend2(2) && stage <= 2 ? false : true) || loadingIndicators.main}>
											{label2}
										</button>
									</form>
								</section>
							)
						) : (
							<section className='table-footer-top'>
								<form>
									<button className={'secondary disabled no-margin'} disabled={true}>
										{label2}
									</button>
								</form>
							</section>
						)}

						{data2.length === 0 && (
							<section className='table-empty'>
                                Er zijn geen declaraties gevonden van {FILTER_STATEMENTS_FOR_INVOICES ? formatDate(lastDayOfPreviousMonth(), 5) : formatDate(FILTER_DATE_MAX, 5)} en eerder.
							</section>
						)}

						{data2.length !== 0 && (
							<section id='table2' className='table-scroll-x' onScroll={() => this.handleScroll(2)}>
								<aside className={tables[2].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(2)}></aside>
								<aside className={tables[2].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(2)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td onClick={() => this.handleSelectAll(2, filtered_invoices, false)} className='col-check'>
												<button className='col-check-btn'></button>
											</td>
											<td>Actie</td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='gemeente_naam' label='Gemeente' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='status' label='Status' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='tabel_periode' label='Periode' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='type_label' label='Type' />
											<TableColumnHeader
												handleSort={this.handleSort}
												column={tables[2].column}
												order={tables[2].order}
												table={2}
												data='ingediend'
												label='Ingediend'
												className='align-right'
											/>
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='factuurnummer' label='Factuur' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table={2} data='status_ts' label='Datum en tijd' />
										</tr>
										{data2.map(row => (
											<Fragment key={row.id}>
												<tr
													key={row.id}
													onClick={row.status === 1 ? () => this.handleSelect(row.id, 2) : undefined}
													className={row.status === 1 ? (this.handleCheckSelect(row.id, 2) ? 'highlight' : '') : undefined}>
													<td>
														<button
															className={row.status === 1 && this.handleCheckSelect(row.id, 2) ? 'button checkbox checked' : 'button checkbox'}
															disabled={row.status !== 1}></button>
													</td>
													<td>
														<button className='button remove' onClick={event => this.handleArchiveInvoice(event, row.id)} disabled={loadingIndicators[row.id]}></button>
														<button className='button history' onClick={event => this.handleListInvoiceRules(event, row.id)}></button>
													</td>
													<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
													<td>
														{notEmpty(row.status) ? (
															row.status === 1 ? (
																'Aangemaakt'
															) : row.status === 2 ? (
																'Ingediend'
															) : row.status === 3 ? (
																'Goedgekeurd'
															) : row.status === 4 ? (
																'Afgekeurd'
															) : (
																<label>Geen data</label>
															)
														) : (
															<label>Geen data</label>
														)}
													</td>
													<td>{notEmpty(row.tabel_periode) ? row.tabel_periode : notEmpty(row.periode) ? row.periode : <label>Geen data</label>}</td>
													<td>{notEmpty(row.type_label) ? row.type_label : <label>Geen data</label>}</td>
													<td className='align-right'>{notEmpty(row.ingediend) ? formatCurrency(row.ingediend) : <label>Geen data</label>}</td>
													<td>{notEmpty(row.factuurnummer) ? row.factuurnummer : <label>Geen data</label>}</td>
													<td>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
												</tr>

												{rules_loaded === row.id && rules.length !== 0 && (
													<tr style={noHover} key={row.uuid}>
														<td style={noBorder} colSpan={14}>
															<table className='inner-table'>
																<tbody>
																	<tr style={noHover}>
																		<td style={noBorder} colSpan={8}>
																			<h3>Declaratieregels</h3>
																		</td>
																	</tr>
																	<tr style={noHover}>
																		<td style={noBorder}></td>
																		<td style={noBorder}>Toewijzing</td>
																		<td style={noBorder}>Categorie</td>
																		<td style={noBorder}>Product</td>
																		<td style={noBorder}>Volume</td>
																		<td className='align-right' style={noBorder}>
                                                                            Tarief
																		</td>
																		<td className='align-right' style={noBorder}>
                                                                            Totaal
																		</td>
																		<td style={noBorder}>Type</td>
																		<td style={noBorder}>Status</td>
																		<td style={noBorder}>Periode</td>
																		<td style={noBorder}>Datum en tijd</td>
																	</tr>
																	{rules.map(regel => (
																		<tr key={regel.id} style={noHover}>
																			<td style={noBorder}></td>

																			<td style={noBorder}>{notEmpty(regel.toewijzing_nummer) ? regel.toewijzing_nummer : <label>Geen data</label>}</td>
																			<td style={noBorder}>{notEmpty(regel.categorie) ? regel.categorie : <label>Geen data</label>}</td>
																			<td style={noBorder}>{notEmpty(regel.product) ? regel.product : <label>Geen data</label>}</td>
																			<td style={noBorder}>{notEmpty(regel.volume) ? regel.volume : <label>Geen data</label>}</td>
																			<td className='align-right' style={noBorder}>
																				{notEmpty(regel.tarief) ? formatCurrency(regel.tarief) : <label>Geen data</label>}
																			</td>
																			<td className='align-right' style={noBorder}>
																				{notEmpty(regel.totaal) ? formatCurrency(regel.totaal) : <label>Geen data</label>}
																			</td>
																			<td style={noBorder}>{notEmpty(regel.debet) ? regel.debet === 1 ? 'Debet' : 'Credit' : <label>Geen data</label>}</td>
																			<td style={noBorder}>
																				{notEmpty(regel.status) ? (
																					regel.status === 1 ? (
																						'Aangemaakt'
																					) : regel.status === 2 ? (
																						'Ingediend'
																					) : regel.status === 3 ? (
																						'Goedgekeurd'
																					) : regel.status === 4 ? (
																						'Afgekeurd'
																					) : (
																						<label>Geen data</label>
																					)
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>
																			<td style={noBorder}>{regel.periode}</td>
																			<td style={noBorder}>
																				{notEmpty(regel.status_ts) ? (
																					formatDate(regel.status_ts, 2) + ', ' + formatTime(regel.status_ts, 2)
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</td>
													</tr>
												)}
											</Fragment>
										))}
									</tbody>
								</table>
							</section>
						)}
					</section>
				</section>
			</>
		);
	};
}
