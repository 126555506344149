import { MouseEventHandler, ReactNode } from 'react';
import styles from './index.module.css';


interface PrimaryButtonProps {
	children: ReactNode;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	onMouseEnter?: MouseEventHandler<HTMLButtonElement> | undefined;
	onMouseLeave?: MouseEventHandler<HTMLButtonElement> | undefined;
	disabled?: boolean | undefined;
	size?: 'tiny' | 'small' | 'normal' | 'big' | 'huge';
	color?: 'primary' | 'secondary' | 'success' | 'warning';
	type?: 'submit' | 'reset' | 'button';
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
	children,
	onClick, 
	onMouseEnter,
	onMouseLeave, 
	disabled = false, 
	size = 'normal',
	color = 'primary',
}) => {
	const props = {
		onClick,
		onMouseEnter,
		onMouseLeave,
		disabled,
	};
	const sizeStyleMap: {[Property in typeof size]: string} = {
		'tiny': styles.sizeTine,
		'small': styles.sizeSmall,
		'normal': styles.sizeNormal,
		'big': styles.sizeBig,
		'huge': styles.sizeHuge,
	};

	const colorStyleMap: {[Proprety in typeof color]: string} = {
		'primary': styles.colorPrimary,
		'secondary': styles.colorSecondary,
		'success': styles.colorSuccess,
		'warning': styles.colorWarning,
	};

	return (
		<button {...props} className={`${styles.button} ${sizeStyleMap[size]} ${colorStyleMap[color]}`}>
			{children}
		</button>
	);
};

export default PrimaryButton;
