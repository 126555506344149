import React, { ComponentType, useState } from 'react';
import { SelectableData, AddEntryOptions, AddForm } from '../types';
import styles from './index.module.css';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { DbUser } from '../../../../types';

interface ITableAddSubmitControlsProps<T> {
	resetAllHander: () => void;
	displayAddRowHandler: () => void;
	FormToRender?: ComponentType<AddForm<T>>
	cancelAddRowHandler: () => void;
	newTableRow: SelectableData<T> | null;
	saveNewRow: () => void;
	saveNewRowForm: (v: T) => void;
	onClickSubmitChanges: () => void;
	allowUpdateAndReset: boolean;
	addNewRow?: AddEntryOptions;
	dbUser: DbUser
}

function TableAddSubmitControls <T>({resetAllHander, displayAddRowHandler, 
	cancelAddRowHandler, newTableRow, FormToRender,
	saveNewRow, saveNewRowForm,onClickSubmitChanges, allowUpdateAndReset, addNewRow, dbUser}: ITableAddSubmitControlsProps<T>)  {
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const handleOpenForm = () => {
		setFormOpen(!formOpen);
	};

	const handleSaveNewRowForm = (v: T) => {
		setFormOpen(false);
		saveNewRowForm(v);
	};

	return (
		<section className={styles.outerContainer}>
			<div className={styles.innerContainer}>
				{addNewRow && (
					newTableRow ? (
						<div> 
							<button className={styles.saveButton} onClick={saveNewRow}/>
							<button className={styles.cancelButton} onClick={cancelAddRowHandler}/>
						</div>
					) : (
						<div>
							<button className={styles.addButton} onClick={addNewRow == 'regular' ? displayAddRowHandler : handleOpenForm}/>
						</div>
					))}
				{allowUpdateAndReset && (
					<div className={styles.mainButtonsContainer}>
						<PrimaryButton onClick={onClickSubmitChanges}>Update Alles</PrimaryButton>
						<PrimaryButton onClick={resetAllHander}>Reset Alles</PrimaryButton>
					</div>
				)}
				{formOpen && FormToRender && 
					(<FormToRender 
						onCancel={() => setFormOpen(false)} 
						onSave={handleSaveNewRowForm}
						dbUser={dbUser}
						isOpen={formOpen}
					/>)}
			</div>
		</section>
	);
}

export default TableAddSubmitControls;
