/**
 *  Basic sort subroutine to sort one column with sort order A-Z or Z-A
 */
const sortArray = <T>(array: T[], order: boolean, column: keyof T): T[] => {
	if (order) {
		return array.slice().sort((a, b) => {
			if (a[column] === null || a[column] === undefined) {
				if (b[column] === null || b[column] === undefined) {
					return 0;
				} else {
					return 1;
				}
			} else if (b[column] === null || b[column] === undefined) {
				return -1;
			}
			if (a[column] < b[column]) {
				return -1;
			} else if (a[column] > b[column]) {
				return 1;
			}
			return 0;
		});
	}
	return array.slice().sort((a, b) => {
		if (a[column] === null || a[column] === undefined) {
			if (b[column] === null || b[column] === undefined) {
				return 0;
			} else {
				return -1;
			}
		} else if (b[column] === null || b[column] === undefined) {
			return 1;
		}
		if (a[column] < b[column]) {
			return 1;
		} else if (a[column] > b[column]) {
			return -1;
		}
		return 0;
	});
};

export default sortArray;
