import { createRoot } from 'react-dom/client';

import './css/root.css';
import './css/form.css';
import './css/sidebar.css';
import './css/table.css';
import './css/notifications.css';

import AppAuthenticate from './comp/app/AppAuthenticate';
import {Auth0Provider} from './comp/app/Auth0';
import history from './util/history';
import {AUTH0_DOMAIN, AUTH0_ID, AUTH0_AUDIENCE, AUTH0_SCOPE} from './configuration';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppMain from './comp/app/AppMain';

interface AppState {
	targetUrl: string
}

const onRedirectCallback = (appState: AppState) => {
	history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
		},
	},
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Auth0Provider
				domain={AUTH0_DOMAIN}
				client_id={AUTH0_ID}
				redirect_uri={window.location.origin}
				onRedirectCallback={onRedirectCallback}
				audience={AUTH0_AUDIENCE}
				scope={AUTH0_SCOPE}
				cacheLocation='localstorage'
				max_age={60*60*8}
				useRefreshTokens
			>
				<AppAuthenticate>
					{(mainProps) => <AppMain {...mainProps} />}
				</AppAuthenticate>
			</Auth0Provider>
		</QueryClientProvider>
	);
}

const root = createRoot(document.getElementById('root')!);
root.render(<App/>);
