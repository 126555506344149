/**
 * Format time to string with various options
 * @param date The ISO date
 * @param type
 * If 1, then HH-MM
 * If 2, (default) then HH-MM-SS
 */
const formatTime = (date: string, type: 1 | 2 = 2) => {
	const newDate = new Date(date);

	const hour = newDate.getHours();
	const min = newDate.getMinutes();
	const sec = newDate.getSeconds();

	const hourstr = hour < 10 ? '0' + hour : hour;
	const minstr = min < 10 ? '0' + min : min;
	const secstr = sec < 10 ? '0' + sec : sec;

	// hours and minutes, type === 1
	if (type && type === 1) return `${hourstr}:${minstr}`;

	// hours, minutes and seconds, default or type === 2
	return `${hourstr}:${minstr}:${secstr}`;
};

export default formatTime;
