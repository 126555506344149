import Label from '../../ui/Label';
import styles from './index.module.css';



interface ClientsStatementFilterProps {
	title: string;
	gemeenteFilterList?: string[];
	clientsFilterList?: string[];
	productsFilterList?: string[];
	onGemeenteSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onClinetSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onProductSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ClientsStatementsFilter: React.FC<ClientsStatementFilterProps> = ({title, gemeenteFilterList, 
	clientsFilterList, productsFilterList, onGemeenteSelection, onClinetSelection, onProductSelection}) => {
	return (
		<section className={styles.container}>
			<h2>{title}</h2>
			<div className={styles.innerContainer}>
				<div className={styles.element}>
					<Label>Filters</Label>
				</div>
				<div className={styles.element}>
					<Label strong htmlFor="gemeente-list">Gemeente</Label>
					<select id="gemeente-list" name="gemeenteList" onChange={onGemeenteSelection}>
						<option value={undefined}>Toon Alle</option>
						{gemeenteFilterList?.map((gemeente, index) => (
							<option key={index} value={gemeente}>{gemeente}</option>
						))}
					</select>
				</div>
				<div className={styles.element}>
					<Label strong htmlFor= "clients-list">Client</Label>
					<select id="client-list" name="clientList" onChange={onClinetSelection}>
						<option value={undefined}>Toon Alle</option>
						{clientsFilterList?.map((client, index) => (
							<option key={index} value={client}>{client}</option>
						))}
					</select>
				</div>
				<div className={styles.element}>
					<Label strong htmlFor="products-list">Product</Label>
					<select id="products-list" name="productsList" onChange={onProductSelection}>
						<option value={undefined}>Toon Alle</option>
						{productsFilterList?.map((product, index) => (
							<option key={index} value={product}>{product}</option>
						))}
					</select>
				</div>
			</div>
		</section>
	);
};

export default ClientsStatementsFilter;
