import { preventNegativePastInput } from '../../../../../util/preventNegativePastInput';
import InputNumber from '../../../InputNumber';
import Label from '../../../Label';
import { ControlsValues, ColumnDefinitionNumbers } from '../../types';
import styles from './index.module.css';

interface CascadeInputNumberProps<T, K extends keyof T> {
	controlColumn: ColumnDefinitionNumbers<T, K>;
	controlValues: ControlsValues<T>;
	onChange: (value: number) => void;
	isCurrency?: boolean;
}

const CascadeInputNumber = <T, K extends keyof T>({controlColumn, controlValues, onChange, isCurrency}: CascadeInputNumberProps<T,K>) => {
	const onChangeHandler = (v: number) => {
		onChange(v);
	};
	return (
		<div className={styles.container}>
			<Label htmlFor={`input-${String(controlColumn.key)}`} strong>{controlColumn.name} {isCurrency && ( <span>{'€'}</span>)}</Label>
			<InputNumber id={`input-${String(controlColumn.key)}`}
				className={styles.input} 
				step={0.01}
				min={controlColumn.validations ? controlColumn.validations.min : undefined}
				max={controlColumn.validations ? controlColumn.validations.max : undefined}
				// value={controlColumn.key in controlValues ? (controlValues[controlColumn.key] as unknown as number)/100 : 0}
				value={(controlValues[controlColumn.key] as unknown as number)/100}
				onChange={onChangeHandler}
				onPaste={preventNegativePastInput}
			/>
		</div>
	);
};

export default CascadeInputNumber;
