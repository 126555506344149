import React from 'react';
import api from '../../util/apiRequest';
import formatDate from '../../util/formatDate';
import notEmpty from '../../util/notEmpty';
import DisplayRetourcode from '../element/retourcode/DisplayRetourcode';
import isAuthorized from '../../util/isAuthorized';

export default class FormAllocationStart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			traject: {},
			loaded: false,
			submiting: false,
			percentage: null,
		};
	}

	handleCancel = () => {
		this.props.handleForm({});
	};

	componentDidMount = async () => {
		if (!this.state.loaded) {
			const {id} = this.props.form.data;
			const {client} = this.props;
			const {token} = this.props.dbUser;

			const getTrajectFinanciering = await api.getTrajectFinanciering(
				client.aanbieder_id,
				client.id,
				id,
				api.makeHeader(token),
			);
			if (getTrajectFinanciering.error && getTrajectFinanciering.error === true) {
				this.props.handleForm(getTrajectFinanciering);
				return null;
			}
			this.setState(state => {
				state.traject = getTrajectFinanciering.response;
				state.percentage = getTrajectFinanciering.response.begin;
				state.loaded = true;
				return state;
			});
		}
	};

	handleSubmit = async (event, toewijzing, aanbieder, client, startstop, formid) => {
		event.preventDefault();
		if (this.state.submiting) {
			return;
		}
		this.setState({ submiting: true });
		try {
			const {token} = this.props.dbUser;

			let data = {
				toewijzing_id: toewijzing,
				aanbieder_id: aanbieder,
				client_id: client,
				startstop_id: startstop,
				formid: formid,
			};

			const elements = event.target.elements;
			Object.keys(elements).forEach(key => {
				let element = elements[key];
				if (element.type !== 'submit' && element.name !== '') {
					data[element.name] = element.value;
				}
			});
			if (formid === 'start-zorg') {
				const addStartZorg = await api.addStartZorg(aanbieder, client, toewijzing, data, api.makeHeader(token));
				if (addStartZorg.error && addStartZorg.error === true) {
					this.props.handleForm(addStartZorg);
					return null;
				}
				data = addStartZorg.response;
				data.formid = formid;
				this.props.handleForm({});
				this.props.handleStartZorgTable(data);
			} else if (formid === 'start-zorg-verwijderen') {
				const archiveStartStop = await api.archiveStartStop(
					aanbieder,
					client,
					toewijzing,
					startstop,
					api.makeHeader(token),
				);
				if (archiveStartStop.error && archiveStartStop.error === true) {
					this.props.handleForm(archiveStartStop);
					return null;
				}
				this.props.handleForm({});
				this.props.handleStartZorgTable(data);
			} else {
				throw new Error('Unknown form: ' + formid);
			}
		} finally {
			this.setState({ submiting: false });
		}
	};

	handlePercentage = value => {
		this.setState(state => {
			state.percentage = Number(value);
			return state;
		});
	};

	resetPercentage = value => {
		if (!value) {
			this.setState(state => {
				state.percentage = this.state.traject.begin;
				return state;
			});
		}
	};

	render = () => {
		const {data, formid} = this.props.form;
		const {traject, loaded, submiting} = this.state;
		const {autorisatie} = this.props.dbUser;

		if (loaded === false) return null;

		return (
			<form
				onSubmit={event =>
					!submiting &&
						this.handleSubmit(
							event,
							data.toewijzing.id,
							data.aanbieder_id,
							data.client_id,
							data.id,
							formid,
						)
				}
				style={{
					maxWidth: data.dialogWidth,
				}}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<header>
					<h1>
						{formid === 'start-zorg'
							? 'Zorg Starten'
							: formid === 'start-zorg-verwijderen'
								? 'Start zorg verwijderen'
								: null}
					</h1>
				</header>
				{formid !== 'start-zorg-verwijderen' ? (
					<main>
						{data.retourcodes && (
							<label htmlFor='f0'>
								<span className='error-color'>
									<DisplayRetourcode retourcodes={data.retourcodes} list={data.lists.WJ001} />
								</span>
							</label>
						)}
						<label htmlFor='f1' className='col2 col2-break'>
							<span>Startdatum zorg</span>
							<input
								type='date'
								name='datum'
								placeholder='Startdatum'
								id='f1'
								defaultValue={data.start_stop_status === 4 ? data.startdatum : ''}
								min={data.start305_voor301 === 0 ? data.begindatum : ''}
								max={formatDate(new Date(), 1)}
								required
								autoFocus
							/>
						</label>
						{data.product !== null && (
							<label htmlFor='f2' className='col1'>
								<span>Product</span>
								<input
									type='text'
									name='product'
									placeholder='Product'
									id='f2'
									defaultValue={
										notEmpty(data.product_label) && notEmpty(data.product_label)
											? data.product_label
											: notEmpty(data.product)
												? data.product
												: 'Geen data'
									}
									disabled={true}
								/>
							</label>
						)}

						{traject.traject && !data.startdatum && (
							<>
								<label htmlFor='f3' className='col2'>
									<span>Start declaratie</span>
									<select name='traject' placeholder='Start declaratie' id='f3' defaultValue={this.state.percentage === 0 ? 'Nee' : 'Ja'}>
										{['Nee', 'Ja'].map((value, id) => (
											<option key={id} value={value}>
												{value}
											</option>
										))}
									</select>
								</label>
								<label htmlFor='f4' className='col2'>
									<span>Start percentage</span>
									<input
										onChange={event => this.handlePercentage(event.target.value)}
										onBlur={event => this.resetPercentage(event.target.value)}
										type='number'
										name='percentage'
										placeholder='Start percentage'
										id='f4'
										value={this.state.percentage}
										required
										maxLength={3}
										min={0}
										max={100}
									/>
								</label>
							</>
						)}
					</main>
				) : (
					<main>{'Weet u zeker dat u deze Start Zorg wilt verwijderen?'}</main>
				)}

				<footer>
					<button disabled={submiting} type='button' className='secondary' onClick={this.handleCancel}>
							Annuleer
					</button>
					{isAuthorized(autorisatie, 'functie.uitvoeren') && formid === 'start-zorg-verwijderen' && (
						<button disabled={submiting} type='submit' className='default'>
								OK
						</button>
					)}
					{isAuthorized(autorisatie, 'functie.uitvoeren') && formid !== 'start-zorg-verwijderen' && (
						<button disabled={submiting} type='submit' className='default'>
								Bewaar
						</button>
					)}
				</footer>
			</form>
		);
	};
}
