import { TSortOrder } from '../components/ui/TableComponents/types';

/**
 * Sort based on sortArray utility function but with minor changes on the parameters
 * to match data tables and sort types
 * 
 * @param array 
 * @param sortOrder 
 * @param column 
 * @returns 
 */

const sortData = <T, K extends keyof T>(array: T[], sortOrder: TSortOrder, column: K): T[] => {
	const sortedArray = [...array];
	if (sortOrder === 'asc') {
		return sortedArray.sort((a, b) => {
			if (a[column] === null || a[column] === undefined) {
				if (b[column] === null || b[column] === undefined) {
					return 0;
				} else {
					return 1;
				}
			} else if (b[column] === null || b[column] === undefined) {
				return -1;
			}
			if (a[column] < b[column]) {
				return -1;
			} else if (a[column] > b[column]) {
				return 1;
			}
			return 0;
		});
	}
	return sortedArray.sort((a, b) => {
		if (a[column] === null || a[column] === undefined) {
			if (b[column] === null || b[column] === undefined) {
				return 0;
			} else {
				return -1;
			}
		} else if (b[column] === null || b[column] === undefined) {
			return 1;
		}
		if (a[column] < b[column]) {
			return 1;
		} else if (a[column] > b[column]) {
			return -1;
		}
		return 0;
	});
};

export default sortData;
