import React from 'react';
import notEmpty from '../../util/notEmpty';
import formatDate from '../../util/formatDate';
import ReactExport from 'react-export-excel';

const ExcelContractRule = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Contractregels'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Contractregels'>
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Tarief' value='tarief' />
				<ExcelColumn label='Eenheid' value='eenheid_label' />
				<ExcelColumn label='Frequentie' value='frequentie_label' />
				<ExcelColumn label='Categorie' value='categorie_label' />
				<ExcelColumn label='Product' value='product_label' />
				<ExcelColumn label='Begindatum' value={col => (notEmpty(col.begindatum) ? formatDate(col.begindatum, 2) : '')} />
				<ExcelColumn label='Einddatum' value={col => (notEmpty(col.einddatum) ? formatDate(col.einddatum, 2) : '')} />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelContractRule;
