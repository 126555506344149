import React from 'react';
import isAuthorized from '../../util/isAuthorized';

interface Props {
	dbAanbiederInit: number
	handleChooseAanbieder: any
	dbUser: any
}

interface State {
	standard: number
}

interface Aanbieders {
	id: number
	label: string
	naam: string
}

export default class ChooseProvider extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			standard: Number(this.props.dbAanbiederInit),
		};
	}

	handleDefault = (id: number) => {
		this.setState(() => ({
			standard: Number(id),
		}));
	};

	render = () => {
		const {dbUser, handleChooseAanbieder} = this.props;
		const {standard} = this.state;
		const {autorisatie} = this.props.dbUser;

		return (
			<div className='screen'>
				<div className='dialog'>
					<form>
						<header>
							<h1>Kies een aanbieder</h1>
						</header>
						<main style={{maxHeight: '60vh'}}>
							{isAuthorized(autorisatie, 'aanbieder.wisselen') && (
								<ul className='aanbieder'>
									{dbUser.aanbieders.map((row: Aanbieders) => (
										<li key={row.id}>
											<input
												onChange={() => this.handleDefault(row.id)}
												type='radio'
												name='standard'
												value={row.id}
												checked={row.id === standard && true}
											/>
											<button
												type='submit'
												onClick={event => {
													event.preventDefault();
													event.stopPropagation();
													handleChooseAanbieder(dbUser.id, row.id, standard);
												}}
											>
												{row.label} {row.naam}
											</button>
										</li>
									))}
								</ul>
							)}
						</main>
						<footer>
							<br />
                            Tip: Klik op de knop links van de aanbieder,
							<br />
                            om deze als standaard in te stellen.
						</footer>
					</form>
				</div>
			</div>
		);
	};
}
