import Declaratie from '../types/Declaratie';
import DeclaratieRegel from '../types/DeclaratieRegel';
import { VerantwoordingLegacy } from '../types/Verantwoording';
import { Get } from './helpers/builder';

export const declaratieRegelList =
	Get`/invoices/providers/${'aanbieder'}/invoicerules`.json<{ response: DeclaratieRegel[] }>();
export const declaratieRegelListBatch =
	Get`/invoices/providers/${'aanbieder'}/batch/${'batch'}`.json<{ response: DeclaratieRegel[] }>();
export const declaratieRegelListInvoice =
	Get`/invoices/providers/${'aanbieder'}/invoice/${'invoice'}`.json<{ response: DeclaratieRegel[] }>();
export const declaratieRegelCrediteren =
	Get`/invoices/providers/${'aanbieder'}/invoice/${'invoice'}/invoicerule/${'invoicerule'}`.json<{ response: unknown }>();
export const declaratieList = 
	Get`/invoices/providers/${'aanbieder'}/invoices`.json<{ response: Declaratie[] }>();
export const declaratieListUnprocessed = 
	Get`/invoices/providers/${'aanbieder'}/invoices/unprocessed`.json<{ response: Declaratie[] }>();

export const declaratieVerantwoordingListUnprocessed = 
	Get`/invoices/providers/${'aanbieder'}/unprocessed`.json<{ response: VerantwoordingLegacy[] }>();
export const declaratieVerantwoordingListUsed = 
	Get`/invoices/providers/${'aanbieder'}/used`.json<{ toewijzing_id: number, periode_id: number, is_credit: 1 | 0 }[]>();
