import React from 'react';
import Logo from '../../../img/zorgverkeer.svg';

const LogoZorgVerkeerMain = () => {
	return (
		<aside className='zorgverkeer'>
			<img src={Logo} alt='ZorgVerkeer — Berichtenverkeer in de zorg' />
		</aside>
	);
};

export default LogoZorgVerkeerMain;
