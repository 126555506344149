import React from 'react';
import {useAuth0} from '../app/Auth0';
import {Link, Router, withRouter} from 'react-router-dom';
import {AUTH0_RETURN_TO, ENVIRONMENT, MAX_NOTIFICATION_ITEMS} from '../../configuration';
import isAuthorized from '../../util/isAuthorized';
import history from '../../util/history';
import isPageActive from '../../util/isPageActive';

const NavMain = ({dbUser, handleChooseAanbieder, notifications}: any) => {
	const {isAuthenticated, loginWithRedirect, logout} = useAuth0();
	const autorisatie = dbUser.autorisatie;

	const aanbieder = dbUser.aanbieders.find((row: any) => {
		return row.id === dbUser.aanbieder;
	});

	let activeLink = true;
	if (dbUser.aanbieders.length === 1) {
		activeLink = false;
	}

	const notificationMenu = notifications.filter((item: any) => {
		return item.is_gelezen === 0 && item.verwijderd === 0;
	});

	const highLight = {fontWeight: 600};

	return (
		<Router history={history}>
			<nav>
				<ul>
					{isAuthorized(autorisatie, 'aanbieder.wisselen') ? (
						<li className='logo'>
							{activeLink ? (
								<Link to='' onClick={() => handleChooseAanbieder(dbUser.id, 0, null)}>
									<span>{aanbieder.label}</span>
									<span>{aanbieder.naam}</span>
								</Link>
							) : (
								<Link to='/start'>
									<span>{aanbieder.label}</span>
									<span>{aanbieder.naam}</span>
								</Link>
							)}

							{dbUser.aanbieders.length > 1 ? (
								<div className='sub_menu logo_menu'>
									<ul>
										{dbUser.aanbieders.map((row: any) => (
											<li key={row.id}>
												<Link to='' onClick={() => handleChooseAanbieder(dbUser.id, row.id, null)}>
													{dbUser.aanbieder === row.id ? (
														<strong>
															{row.label} {row.naam}
														</strong>
													) : (
														<>
															{row.label} {row.naam}
														</>
													)}
												</Link>
											</li>
										))}
									</ul>
								</div>
							) : null}
						</li>
					) : (
						<li className='logo'>
							<Link to='/start'>
								<span>{aanbieder.label}</span>
								<span>{aanbieder.naam}</span>
							</Link>
						</li>
					)}
				</ul>
				<ul>
					{isAuthorized(autorisatie, 'overzichten.tonen') && (
						<li className={isPageActive('/overzicht') ? 'active report' : 'report'}>
							<Link to='#'>Overzicht</Link>
							<div className='sub_menu report_menu'>
								<ul>
									<li>
										<Link style={isPageActive('/overzicht/berichten') ? highLight : {}} to='/overzicht/berichten'>
                                            Berichten
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/clienten') ? highLight : {}} to='/overzicht/clienten'>
                                            Cliënten
										</Link>
									</li>
									{isAuthorized(autorisatie, 'verantwoording.bulk') && (
										<li>
											<Link style={isPageActive('/overzicht/verantwoordingclienten') ? highLight : {}} to='/overzicht/verantwoordingclienten'>
											Cliënten Verantwoordingen
											</Link>
										</li>
									)}
									<li>
										<Link style={isPageActive('/overzicht/declaraties') ? highLight : {}} to='/overzicht/declaraties'>
												Declaraties
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/declaratieregels') ? highLight : {}} to='/overzicht/declaratieregels'>
                                            Declaratieregels
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/toewijzingen') ? highLight : {}} to='/overzicht/toewijzingen'>
                                            Toewijzingen
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/verantwoordingen') ? highLight : {}} to='/overzicht/verantwoordingen'>
                                            Verantwoordingen
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/verzoeken-om-toewijzing') ? highLight : {}} to='/overzicht/verzoeken-om-toewijzing'>
                                            Verzoeken om toewijzing
										</Link>
									</li>
									<li>
										<Link style={isPageActive('/overzicht/verzoeken-om-wijziging') ? highLight : {}} to='/overzicht/verzoeken-om-wijziging'>
                                            Verzoeken om wijziging
										</Link>
									</li>
								</ul>
							</div>
						</li>
					)}
					{isAuthorized(autorisatie, 'declareren.tonen') && (
						<li className={isPageActive('/declareren') ? 'active' : ''}>
							<Link to='/declareren'>Declareren</Link>
						</li>
					)}
					{isAuthorized(autorisatie, 'contractregels.tonen') && (
						<li className={isPageActive('/contract') ? 'active report' : 'report'}>
							<Link to="#">Contracten</Link>
							<div className='sub_menu report_menu'>
								<ul>
									<li>
										<Link style={isPageActive('/contract/contractregels') ? highLight : {}} to="/contract/contractregels">Overzicht Contractregels</Link>
									</li>
									{isAuthorized(autorisatie, 'contract.bulk') && (
										<li>
											<Link style={isPageActive('/contract/contractbijwerken') ? highLight : {}} to="/contract/contractbijwerken">Bijwerken Contractregels</Link>
										</li>
									)}
								</ul>
							</div>
						</li>
					)}

					{isAuthorized(autorisatie, 'meldingen.tonen') && (
						<li className={isPageActive('/meldingen') ? 'active notify' : 'notify'}>
							<Link to='/meldingen/0'>Meldingen{notificationMenu.length !== 0 ? <aside className='count'>{notificationMenu.length}</aside> : ''}</Link>

							<div className='sub_menu notify_menu'>
								<ul>
									{notificationMenu.map((notification: any, index: number) => {
										if (index < MAX_NOTIFICATION_ITEMS) {
											return (
												<li key={notification.id}>
													<Link to={`/meldingen/${notification.id}`}>
														{notification.onderwerp} van {notification.van_naam}
													</Link>
												</li>
											);
										} else return null;
									})}
									{notificationMenu.length > MAX_NOTIFICATION_ITEMS && (
										<li>
											<Link to='/meldingen/0'>Toon alle meldingen</Link>
										</li>
									)}
								</ul>
							</div>
						</li>
					)}

					{isAuthorized(autorisatie, 'gebruiker.tonen') ? (
						<>
							<li className={isPageActive('/instellingen') || isPageActive('/beheer') ? 'active user' : 'user'}>
								{dbUser.voornaam} {dbUser.tussenvoegsel} {dbUser.achternaam}
								<div className='sub_menu'>
									<ul>
										<li>
											<Link style={isPageActive('/instellingen') ? highLight : {}} to='/instellingen'>
                                                Instellingen
											</Link>
										</li>
										{isAuthorized(autorisatie, 'beheren.tonen') && (
											<li>
												<Link style={isPageActive('/beheer') ? highLight : {}} to='/beheer'>
                                                    Beheer
												</Link>
											</li>
										)}
										{ENVIRONMENT === 'production' ? (
											isAuthenticated ? (
												<li onClick={() => logout({returnTo: AUTH0_RETURN_TO})}>
													<Link to=''>Afmelden</Link>
												</li>
											) : (
												<li onClick={() => loginWithRedirect({})}>
													<Link to=''>Aanmelden</Link>
												</li>
											)
										) : null}
									</ul>
								</div>
							</li>
						</>
					) : (
						''
					)}
				</ul>
			</nav>
		</Router>
	);
};

export default withRouter(NavMain);
