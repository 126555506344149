import AppLogin from './AppLogin';
import AppReload from './AppReload';
import {DISPLAY_RELOAD, DISPLAY_RELOGIN} from '../../../configuration';

interface Props {
	className: string
	message: string
	response: {
		status?: number
		response?: string
	}
}

const defaultMessage = 'Helaas, er is een onbekende fout opgetreden. Probeer de pagina te verversen of log opnieuw in.';

const Warning = ({
	className = 'error',
	message = defaultMessage,
	response = {
		status: 200,
		response: 'OK',
	},
}: Props): JSX.Element => {

	return (
		<div className='screen'>
			<div className='dialog'>
				<form>
					<div className={className}></div>
					<div>{message}</div>
					{response.status !== 200 && (
						<div className='errorMessage'>
							{response.status ? 'Status ' + response.status + ': ' : ''}
							{response.response ? response.response : 'Server error'}
						</div>
					)}
					{DISPLAY_RELOAD && <AppReload />} {DISPLAY_RELOGIN && <AppLogin />}
				</form>
			</div>
		</div>
	);
};

export default Warning;
