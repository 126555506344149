import React from 'react';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import {confirmAlert} from 'react-confirm-alert';
import notEmpty from '../../util/notEmpty';
import nameLetters from '../../util/nameLetters';
import isAuthorized from '../../util/isAuthorized';
import removeDuplicates from '../../util/removeDuplicates';
import ExcelClient from '../report/ExcelClient';

export default class ListClient extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Cliënten',
					figure: 'client-figure',
					column: 'gemeente_naam',
					label: 'Gemeente',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			clients: [],
			update: 0,
			trigger: 0,
			loaded: false,
			filters: {gemeente: 'all', current: true, removed: true},
			action: true,
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			if (filter === 'gemeente') {
				state.filters[filter] = value;
			} else {
				state.filters[filter] = !state.filters[filter];
			}
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	confirmArchive = client_id => {
		const data = this.props.clients.find(item => item.id === client_id);
		const {autorisatie} = this.props.dbUser;
		isAuthorized(autorisatie, 'functie.uitvoeren')
			? confirmAlert({
				title: 'Cliënt archiveren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt archiveren?`,
				buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.handleArchive(data, 1)}],
			})
			: confirmAlert({
				title: 'Cliënt archiveren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt archiveren?`,
				buttons: [{label: 'Annuleer'}],
			});
	};

	confirmUnArchive = client_id => {
		const data = this.props.clients.find(item => item.id === client_id);
		const {autorisatie} = this.props.dbUser;
		isAuthorized(autorisatie, 'functie.uitvoeren')
			? confirmAlert({
				title: 'Cliënt activeren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt activeren?`,
				buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.handleArchive(data, 0)}],
			})
			: confirmAlert({
				title: 'Cliënt activeren',
				message: `Weet u zeker dat u cliënt “${data.naam}” wilt activeren?`,
				buttons: [{label: 'Annuleer'}],
			});
	};

	handleArchive = async (data, verwijderd) => {
		const {aanbieder, token} = this.props.dbUser;
		const index = this.props.clients.findIndex(item => item.id === data.id);
		data.verwijderd = verwijderd;

		if (verwijderd === 1) {
			const archiveClient = await api.archiveClient(aanbieder, data.id, api.makeHeader(token));
			if (archiveClient.error && archiveClient.error === true) {
				this.props.handleForm(archiveClient);
				return null;
			}
		} else {
			const unArchiveClient = await api.unArchiveClient(aanbieder, data.id, api.makeHeader(token));
			if (unArchiveClient.error && unArchiveClient.error === true) {
				this.props.handleForm(unArchiveClient);
				return null;
			}
		}
		this.props.handleUpdateClientList({data: data, index: index});
	};

	render = () => {
		const {tables, filters} = this.state;
		const {clients, dbUser} = this.props;

		// gemeente
		let gemeenteList = clients.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeenteList = removeDuplicates(gemeenteList, 'gemeente_code');
		gemeenteList = sortArray(gemeenteList, true, 'gemeente_naam');

		let gemeenteList2 = [];
		for (const gemeente of gemeenteList) {
			const count = clients.filter(row => row.gemeente_code === gemeente.gemeente_code).length;
			gemeenteList2.push({...gemeente, count: count});
		}
		gemeenteList = gemeenteList2;

		let clientList = [];
		if (filters.gemeente === 'all') {
			clientList = clients.filter(row => {
				if (filters.current === true && filters.removed === true) {
					return row.verwijderd === 0 || row.verwijderd === 1;
				} else if (filters.current === true && filters.removed === false) {
					return row.verwijderd === 0;
				} else if (filters.current === false && filters.removed === true) {
					return row.verwijderd === 1;
				} else {
					return null;
				}
			});
		} else {
			clientList = clients.filter(row => {
				if (filters.current === true && filters.removed === true) {
					return (row.verwijderd === 0 || row.verwijderd === 1) && row.gemeente_code === filters.gemeente;
				} else if (filters.current === true && filters.removed === false) {
					return row.verwijderd === 0 && row.gemeente_code === filters.gemeente;
				} else if (filters.current === false && filters.removed === true) {
					return row.verwijderd === 1 && row.gemeente_code === filters.gemeente;
				} else {
					return null;
				}
			});
		}

		clientList = sortArray(clientList, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f3'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f3'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={clients.length === 0 ? true : false}>
									<option key='all' value='all'>
										{`Toon alle (${clients.length})`}
									</option>
									<option disabled></option>
									{gemeenteList.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{`${row.gemeente_naam} (${row.count})`}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f1'>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.current}
									name='current'
									id='f1'
									defaultValue='0'
									onChange={event => this.handleFilter('current', event.target.value)}
									disabled={clients.length === 0 ? true : false}></input>
								<span> Actieve cliënten</span>
							</label>
							<label htmlFor='f2'>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.removed}
									name='removed'
									id='f2'
									defaultValue='0'
									onChange={event => this.handleFilter('removed', event.target.value)}
									disabled={clients.length === 0 ? true : false}></input>
								<span> Gearchiveerde cliënten</span>
							</label>
							<label className='options'>Download</label>
							{clientList.length !== 0 ? (
								<label>
									<ExcelClient dbUser={dbUser} data={clientList} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>
					{clientList.length !== 0 ? (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<td className='align-right'>#</td>
										<td>Actie</td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Naam' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='voorletters' label='Voorletters' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='geslacht' label='Geslacht' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='geboortedatum' label='Geboortedatum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='bsn' label='BSN' />
									</tr>

									{clientList.map((row, index) => (
										<tr key={row.id} style={noHover}>
											<td className='align-right'>{index + 1}</td>
											<td>
												{row.verwijderd === 0 && <button className='button remove' onClick={() => this.confirmArchive(row.id)} disabled={false}></button>}
												{row.verwijderd === 1 && <button className='button history' onClick={() => this.confirmUnArchive(row.id)} disabled={false}></button>}
											</td>
											<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.voorletters) ? nameLetters(row.voorletters) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.geslacht) ? row.geslacht === 1 ? 'Man' : row.geslacht === 2 ? 'Vrouw' : 'Onbekend' : <label>Geen data</label>}</td>
											<td>{notEmpty(row.geboortedatum) ? formatDate(row.geboortedatum, 2) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.naam) ? row.bsn : <label>Geen data</label>}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					) : (
						<section className='table-empty'>Er zijn geen cliënten gevonden die aan de criteria voldoen.</section>
					)}
				</section>
			</section>
		);
	};
}
