import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import removeDuplicates from '../../util/removeDuplicates';
import ExcelAllocation from '../report/ExcelAllocation';

export default class ListAllocation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Toewijzingen',
					figure: '',
					column: 'status_ts',
					label: 'Datum en tijd',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			allocations: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {client: 'all', gemeente: 'all', current: true, removed: false},
			clients: [],
			sidebarTrigger: 0,
		};
	}

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getAllocationList = await api.getAllocationList(aanbieder, api.makeHeader(token));
		if (getAllocationList.error && getAllocationList.error === true) {
			this.props.handleForm(getAllocationList);
			return null;
		}

		let gemeentes = getAllocationList.response.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');

		let clients = getAllocationList.response.map(row => {
			return {
				client_id: row.client_id,
				naam: row.naam,
				gemeente_code: row.gemeente_code,
			};
		});
		clients = removeDuplicates(clients, 'client_id');

		this.setState(state => {
			state.loaded = true;
			state.allocations = getAllocationList.response;
			state.clients = clients;
			state.gemeentes = gemeentes;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, allocations, clients, gemeentes, filters} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let clientList = sortArray(clients, true, 'naam');

		let table = [];

		if (filters.gemeente === 'all') {
			table = allocations;
		} else {
			table = allocations.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
			clientList = clientList.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		if (filters.client !== 'all') {
			table = allocations.filter(row => {
				return row.client_id === Number(filters.client);
			});
		}

		const today = new Date();

		table = table.filter(row => {
			if (filters.current === true && filters.removed === false) {
				return new Date(row.einddatum) >= today;
			} else if (filters.current === false && filters.removed === true) {
				return new Date(row.einddatum) <= today;
			} else if (filters.current === true && filters.removed === true) {
				return true;
			}
			return false;
		});

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocations.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{gemeentes.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{row.gemeente_naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f2'>
								<span>
									<strong>Cliënt</strong>
								</span>
								<select
									selected={filters.client}
									name='client'
									id='f2'
									value={filters.client}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocations.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{clientList.map(row => (
										<option key={row.client_id} value={row.client_id}>
											{row.naam}
										</option>
									))}
								</select>
							</label>

							<label htmlFor='f3'>
								<span>
									<strong>Status </strong>
								</span>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.current}
									name='current'
									id='f3'
									onChange={event => this.handleFilter(event.target.name, event.target.checked)}
									disabled={allocations.length === 0 ? true : false}></input>
								<span> Actief</span>
							</label>
							<label htmlFor='f4'>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.removed}
									name='removed'
									id='f4'
									onChange={event => this.handleFilter(event.target.name, event.target.checked)}
									disabled={allocations.length === 0 ? true : false}></input>
								<span> Inactief</span>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelAllocation dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen toewijzingen gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Cliënt' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='bsn' label='Bsn' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='toewijzing_nummer' label='Toewijzing' />

										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='referentie_aanbieder' label='Referentie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='categorie_label' label='Categorie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product' label='Product' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product_label' label='Product naam' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='volume'
											label='Volume'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid_label' label='Eenheid' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='frequentie_label' label='Frequentie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='budget' label='Budget' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='reden_wijziging' label='Reden wijziging' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='startdatum' label='Start datum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='stopdatum' label='Stop datum' />
									</tr>
									{data.map(row => (
										<tr key={row.uuid} style={noHover}>
											<td id={`row-${row.id}`}>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.bsn) ? row.bsn : <label>Geen data</label>}</td>
											<td>{notEmpty(row.toewijzing_nummer) ? row.toewijzing_nummer : <label>Geen data</label>}</td>
											<td>{notEmpty(row.referentie_aanbieder) ? row.referentie_aanbieder : <label>Geen data</label>}</td>

											<td title={row.categorie}>{notEmptyString(row.categorie) ? row.categorie === null ? 'Generiek' : row.categorie_label : <label>Geen data</label>}</td>

											<td>
												{row.product}
											</td>
											<td>
												{notEmptyString(row.product) ? (
													row.product === null && row.categorie === null ? (
														'Generiek'
													) : row.product === null && row.categorie !== null ? (
														'Aspecifiek'
													) : row.product === row.product_label ? (
														''
													) : (
														row.product_label
													)
												) : (
													<label>Geen data</label>
												)}
											</td>

											<td title={row.begindatum}>{notEmpty(row.begindatum) ? formatDate(row.begindatum, 2) : <label>Geen data</label>}</td>
											<td title={row.einddatum}>{notEmpty(row.einddatum) ? formatDate(row.einddatum, 2) : <label>Geen data</label>}</td>
											<td className='align-right'>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
											<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.frequentie_label) ? row.frequentie_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.budget) ? row.budget : <label>Geen data</label>}</td>
											<td>{notEmpty(row.reden_wijziging) ? row.reden_wijziging : <label>Geen data</label>}</td>

											<td>
												{notEmpty(row.status) ? (
													row.status === 1 ? (
														'Aangemaakt'
													) : row.status === 2 ? (
														'Ingediend'
													) : row.status === 3 ? (
														'Goedgekeurd'
													) : row.status === 4 ? (
														'Afgekeurd'
													) : row.status === 5 ? (
														'Toegewezen'
													) : row.status === 6 ? (
														'Afgewezen'
													) : row.status === 7 ? (
														'In onderzoek'
													) : (
														'Onbekend'
													)
												) : (
													<label>Geen data</label>
												)}
											</td>
											<td title={row.status_ts}>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
											<td title={row.startdatum}>{notEmpty(row.startdatum) ? formatDate(row.startdatum, 2) : <label>Geen data</label>}</td>
											<td title={row.stopdatum}>{notEmpty(row.stopdatum) ? formatDate(row.stopdatum, 2) : <label>Geen data</label>}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
