import styles from './index.module.css';
import { TableColumnDefinition, TSortOrder } from '../../types';

interface ColumnHeaderProps <T, K extends keyof T> {
	columnName?: string;
	column?: TableColumnDefinition<T,K>
	isTableIndex?: boolean;
	isActionColumn?: boolean;
	isSelectionColumn?: boolean;
	toggleSort?: (col: TableColumnDefinition<T,K>) => void;
	onClickSelectAll?: () => void,
	sortOrder?: TSortOrder
}

export const ColumnHeader = <T, K extends keyof T>({columnName, column,isTableIndex, isActionColumn, toggleSort, 
	onClickSelectAll, isSelectionColumn, sortOrder}: ColumnHeaderProps<T,K>): JSX.Element => {
	if (isTableIndex) {
		return (
			<th className={styles.indexCol}>
				{'#'}
				<button />
			</th>
		);
	}
	if (isSelectionColumn && onClickSelectAll) {
		return (
			<th className={styles.regular}>
				{' '}
				<button 
					className={styles.selectAllButton}
					onClick={onClickSelectAll} />
			</th>
		);
	}
	if (isActionColumn) {
		return (
			<th className={styles.regular}>
				{'Actie'}
				<button />
			</th>
		);
	}
	return (
		<th className={styles.regular}>
			{column ? column.name : columnName ? columnName : ''}
			<button 
				onClick={toggleSort && column ? () => toggleSort(column) : undefined}
				className={`${styles['col-sort']} ${sortOrder === 'asc' ? styles.colSortAsc : 
					sortOrder === 'desc' ? styles.colSortDesc : styles.colSortNone}`}
			/>
		</th>
	);
};
