import React, { FC, useEffect, useMemo, useState } from 'react';
import {Link} from 'react-router-dom';
import DbUser from '../../../types/DbUser';
import api from '../../../util/apiRequest';



interface Props {
	dbUser: DbUser;
	clientId: number;
	handleChooseAanbieder: (user: unknown, aanbieder: number, standard: 0 | 1) => void;
}

const ClientNotFound: FC<Props> = ({
	dbUser: {
		aanbieder,
		aanbieders,
		token,
	},
	clientId,
	handleChooseAanbieder,
}): JSX.Element => {

	const [alternatives, setAlternatives] = useState<{aanbieder_naam: string, aanbieder_id: number, client_naam: string, client_id: number}[]>([]);
	useEffect(() => {
		let cancelled = false;
		api.findProvider(clientId, api.makeHeader(token)).then((res) => {
			if (!cancelled) {
				setAlternatives(res.response);
			}
		});
		return () => {
			cancelled = true;
		};
	}, [clientId, token]);

	const huidigeAanbiederNaam = useMemo(() => aanbieders.find(r => r.id === aanbieder)?.naam ?? '', [aanbieder, aanbieders]);
	return (
		<section className='client-data'>
			<div className='table-element'>
				<div style={{padding: '1.25rem'}}>
					<h1 style={{marginBottom: '1em', fontSize: '2em'}}>404 client niet gevonden</h1>
					<p style={{marginBottom: '1em'}}>Wij hebben gezocht, maar wij kunnen client {clientId} niet vinden onder de clienten lijst &quot;{huidigeAanbiederNaam}&quot;</p>
					{alternatives.length > 0 && (
						<div>
							<p style={{marginBottom: '1em'}}>Bedoelde u de volgende client{alternatives.length > 1 ? 'en' : ''}?</p>
							<ul>
								{alternatives.map(row => (
									<li key={`${row.aanbieder_id}_${row.client_id}`} style={{listStyle: 'initial', marginLeft: '1em'}}>
										<Link
											to={{ pathname: `/client/${row.client_id}`}}
											onClick={() => aanbieder !== row.aanbieder_id && handleChooseAanbieder(null, row.aanbieder_id, 0)}
										>
											{row.client_naam} van {row.aanbieder_naam}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default ClientNotFound;
