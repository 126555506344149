import {CDT_Telefoon, CDT_Telefoonnummers} from './bericht301v31';

interface HTMLFragment {
	raw: string,
}

export function escapePart(r: string){
	return r.replace(/[\x26\n<>'"]/g,function(r){return '&#'+r.charCodeAt(0)+';';});
}

export function escape(template: TemplateStringsArray, ...args: (string | number | boolean | HTMLFragment | undefined | HTMLFragment[])[]): HTMLFragment {
	let result = template[0];
	for (let i = 0; i < args.length; i++) {
		const arg = args[i];
		result +=
			typeof arg === 'object' && 'raw' in arg ? arg.raw :
				Array.isArray(arg) ? arg.map(e => e.raw).join('') :
					arg === undefined ? '' :
						escapePart(`${arg}`);
		result += template[i+1];
	}
	return { raw: result };
}

export function map<T, R>(input: T | T[] | undefined, cb: (val: T, index: number, arr: T[]) => R): R[] {
	return input === undefined ? [] : (Array.isArray(input) ? input : [input]).map(cb);
}

export function waardelijst(list: {code: string, betekenis: string}[], code: string | number | undefined): string {
	if (code === undefined) return '';
	const asString = typeof code === 'string' ? code : `${code}`;
	return list.find(e => e.code === asString)?.betekenis ?? `<Unknown code: ${asString}`;
}

export function waardelijstCode(list: {code: string, betekenis: string}[], code: string | number | undefined): string {
	if (code === undefined) return '';
	const asString = typeof code === 'string' ? code : `${code}`;
	const found = list.find(e => e.code === asString);
	return found ? `${found.betekenis} (${found.code})` : `<Unknown code: ${asString}`;
}

export function makePrintablePopup<F extends (...args: A) => string | HTMLFragment, A extends any[]>(baseFunction: F): (...args: A) => Promise<void> {
	return (...args) => new Promise<void>((resolve, reject) => {
		try {
			const result = baseFunction(...args);
			const html = typeof result === 'object' ? result.raw : result;
			console.log(html);
			const tab = window.open('about:blank', '_blank', `popup,scrollbars=yes,status=no,location=no,resizable=yes,innerWidth=794,innerheight=${Math.min(1122, window.screen.availHeight - (window.outerHeight - window.innerHeight))}`);
			if (!tab) {
				alert('Het is niet gelukt een export te maken, controleer uw popup blokker.');
				return null;
			}
			tab.document.write(html);
			tab.document.close();
			tab.addEventListener('load', () => {
				tab.print();
				tab.close();
				resolve();
			});
		} catch (e) {
			reject(e);
		}
	});
}

export function telefoonNummer({telefoonnummer, landnummer}: CDT_Telefoon) {
	return `(${landnummer.startsWith('00') ? `+${landnummer.substring(2)}` : landnummer}) ${telefoonnummer}`;
}
export function telefoonNummers(telefoon: CDT_Telefoonnummers | undefined) {
	if (!telefoon) {
		return '';
	}
	const {telefoon01, telefoon02} = telefoon;
	return telefoon02 ? `${telefoonNummer(telefoon01)} ${telefoonNummer(telefoon02)}` : telefoonNummer(telefoon01);
}
