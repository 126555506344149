/**
 *  Clears text selection in front-end
 */
const clearSelection = () => {
	try {
		return window.getSelection()?.removeAllRanges();
	} catch (e) {
		//
	}
};

export default clearSelection;
