import { useState } from 'react';
import { numberToString } from '../../../util/numberToString';
import { stringToNumber } from '../../../util/stringToNumber';

interface InputNumberProps {
	id?: string;
	step?: number | string
	min?: number;
	max?: number;
	value: number;
	onChange: (value: number) => void
	onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
	className?: string;
}

const InputNumber: React.FC<InputNumberProps> = ({id, step, min, max, value, onChange, onPaste, className: styleClassName }) => {
	const [displayedValue, setDisplayedValue] = useState<string>(numberToString(value));
	
	const [oldValue, setOldValue] = useState<number>(value);
	if (!Object.is(value, oldValue)) {
		setOldValue(value);
		setDisplayedValue(numberToString(value));
	}

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const liftedValue = stringToNumber(e.target.value);
		onChange(liftedValue);
		setDisplayedValue(e.target.value);
		setOldValue(liftedValue);
	};

	return (
		<input type="number"
			id={id}
			className={styleClassName}
			step={step}
			min={min}
			max={max}
			value={displayedValue}
			onChange={onChangeHandler}
			onPaste={onPaste}
			onBlur={() => setDisplayedValue(numberToString(value))}
		/>
	);
};

export default InputNumber;
