import React from 'react';
import formatDate from '../../util/formatDate';
import Loading from '../element/miscellaneous/Loading';
import notEmpty from '../../util/notEmpty';
import nameLetters from '../../util/nameLetters';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';

export default class client extends React.Component {
	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	render = () => {
		const {client, loaded} = this.props;

		if (!loaded) {
			return <Loading message='' />;
		}

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className='client-figure'></figure>
						<h2>Persoonsgegevens</h2>
					</section>
					<table className='client-table flex50'>
						<tbody>
							<tr>
								<td>ID</td>
								<td></td>
								<td>{client.id}</td>
							</tr>
							<tr>
								<td>Voorletters</td>
								<td></td>
								<td>{notEmpty(client.voorletters) ? nameLetters(client.voorletters) : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Roepnaam</td>
								<td></td>
								<td>{notEmpty(client.roepnaam) ? client.roepnaam : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Tussenvoegsel</td>
								<td></td>
								<td>{notEmpty(client.tussenvoegsel) ? client.tussenvoegsel : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Achternaam</td>
								<td></td>
								<td>{notEmpty(client.achternaam) ? client.achternaam : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Geboortedatum</td>
								<td></td>
								<td>{notEmpty(client.geboortedatum) ? formatDate(client.geboortedatum, 2) : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Datumgebruik</td>
								<td></td>
								<td>
									{notEmpty(client.datumgebruik) ? (
										client.datumgebruik === 1 ? (
											'Dag onbekend; alleen maand en jaar gebruiken'
										) : client.datumgebruik === 2 ? (
											'Dag en maand onbekend; alleen jaar gebruiken'
										) : client.datumgebruik === 3 ? (
											'Dag, maand en jaar onbekend; onbekende datum'
										) : (
											'Onbekend'
										)
									) : (
										<label>Geen data</label>
									)}
								</td>
							</tr>
							<tr>
								<td>BSN</td>
								<td></td>
								<td>{notEmpty(client.bsn) ? client.bsn : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Geslacht</td>
								<td></td>
								<td>{notEmpty(client.geslacht) >= 0 ? client.geslacht === 0 ? 'Onbekend' : client.geslacht === 1 ? 'Man' : client.geslacht === 2 ? 'Vrouw' : 'Onbekend' : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Gezagsdrager bekend</td>
								<td></td>
								<td>{notEmpty(client.gezagsdrager_bekend) ? client.gezagsdrager_bekend === 1 ? 'Ja' : client.gezagsdrager_bekend === 2 ? 'Nee' : 'Onbekend' : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Gemeente</td>
								<td></td>
								<td>{notEmpty(client.gemeente_naam) ? client.gemeente_naam : notEmpty(client.gemeente_id) ? client.gemeente_id : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Aangemaakt</td>
								<td></td>
								<td>{notEmpty(client.aangemaakt) ? formatDate(client.aangemaakt, 2) : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Gewijzigd</td>
								<td></td>
								<td>{notEmpty(client.recent) ? formatDate(client.recent, 2) : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Berichtenverkeer</td>
								<td></td>
								<td>{notEmpty(client.berichten) ? formatDate(client.berichten, 2) : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Commentaar</td>
								<td></td>
								<td>{notEmpty(client.commentaar) ? client.commentaar : <label>Geen data</label>}</td>
							</tr>
							<tr>
								<td>Retourcode</td>
								<td width={1}>{notEmpty(client.retourcode) && <button className='button info' onClick={() => this.handleRetourcode(client.retourcode)}></button>}</td>
								<td>{notEmpty(client.retourcode) ? splitJoin(client.retourcode, ',', ', ') : <label>Geen data</label>}</td>
							</tr>
						</tbody>
					</table>
				</section>
			</section>
		);
	};
}
