import React from 'react';
import ReactExport from 'react-export-excel';
import formatDate from '../../util/formatDate';

const ExcelMessage = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Berichten'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Berichten'>
				<ExcelColumn label='Periode' value='periode' />
				<ExcelColumn label='Dagtekening' value={col => formatDate(col.dagtekening, 2)} />
				<ExcelColumn label='Bericht' value='bericht' />
				<ExcelColumn label='Identificatie' value='identificatie' />
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Retourcode' value='retourcode' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelMessage;
