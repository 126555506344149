interface Props {
	status: number
}

const StatusCodeChangeRequest = (props: Props) => {
	const {status} = props;

	return status === 1
		? 'Aangemaakt'
		: status === 2
			? 'Ingediend'
			: status === 3
				? 'Goedgekeurd'
				: status === 4
					? 'Afgekeurd'
					: status === 5
						? 'Toegewezen'
						: status === 6
							? 'Afgewezen'
							: status === 7
								? 'In onderzoek'
								: status === 8
									? 'Gearchiveerd'
									: 'Onbekend';
};

export default StatusCodeChangeRequest;
