import React, { useEffect, useState } from 'react';
import DbUser from '../../types/DbUser';
import { MainContainer, DataTable, AlertBox, Spinner } from '../../components/ui';
import { ContractFilters } from '../../components/business';
import { DataTableOptions, TableColumnDefinition } from '../../components/ui/TableComponents/types';
import { IProductDetailsBySamenwerkings, Wet } from '../../types';
import { productsDetailsBySamenwerkingQuery, contractsUpdateBySamenwerkingQuery } from '../../requests';
import { SelectableData } from '../../components/ui/TableComponents/types';
import formatDate from '../../util/formatDate';
import { confirmAlert } from 'react-confirm-alert';
import { dtConstants } from '../../components/ui/TableComponents/constants';
interface IContractUpdateProps {
	dbUser: DbUser;
}

const ContractUpdate: React.FC<IContractUpdateProps> = ({dbUser}): JSX.Element => {
	// main data list
	const [productDetails, setProductDetails] = useState<IProductDetailsBySamenwerkings[]>();

	// filters states
	const [samenwerkingsUniqueList, setSamenwerkingsUniqueList] = useState<IProductDetailsBySamenwerkings['samenwerkings_naam'][]>();
	const [selectedFilterSamenwerking, setSelectedFilterSamenwerking] = useState<string | undefined>(undefined);
	
	const [productsUniqueList, setProductsUniqueList] = useState<IProductDetailsBySamenwerkings['product'][]>();
	const [selectedFilterProduct, setSelectedFilterProduct] = useState<string | undefined>(undefined);
    
	const [wetUniqueList, setWetUniqueList] = useState<IProductDetailsBySamenwerkings['wet'][]>();
	const [selectedFilterWet, setSelectedFilterWet] = useState<Wet | undefined>(undefined);

	const [selectedBegindatum, setSelectedBegindatum] = useState<Date | undefined>(undefined);

	// a state that indicates if the submit api is finished or not
	const [responseStatus, setResponseStatus] = useState<number | undefined>();
	const responseMessage = responseStatus !== undefined && responseStatus == 200 ? dtConstants.messages.success 
		: responseStatus !== undefined && responseStatus !== 200 ? dtConstants.messages.error : undefined;

	const [showAlertBox, setShowAlertBox] = useState<boolean>(false);

	// API Calls functions
	const getProducts = async () => {
		const data = await productsDetailsBySamenwerkingQuery.execute({token: dbUser.token, aanbieder_id: dbUser.aanbieder});
		const { response } = data;
		const samnewerkingList =  [... new Set(response.map((item) => item.samenwerkings_naam))];
		const productList = [... new Set(response.map((item) => item.product))];
		const wetList = [... new Set(response.map((item) => item.wet))];
	
		setProductDetails(response);
		setProductsUniqueList(productList);
		setSamenwerkingsUniqueList(samnewerkingList);
		setWetUniqueList(wetList);
	};

	useEffect(() => {
		const controller = new AbortController();

		if (!controller.signal.aborted) {
			getProducts();
		} 
		return () => controller.abort();
	},[]);

	const wetFilterSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon Alle'){
			setSelectedFilterWet(undefined);
		} else {
			setSelectedFilterWet(e.target.value as unknown as Wet);
		}
	};

	const onSamenwerkingFilterSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon alle') {
			setSelectedFilterSamenwerking(undefined);
		} else {
			setSelectedFilterSamenwerking(e.target.value);
		}
	};

	const onProductSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon alle') {
			setSelectedFilterProduct(undefined);
		} else {
			setSelectedFilterProduct(e.target.value);
		}
	};

	const onBegindatumFilterSelectionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.valueAsDate){
			setSelectedBegindatum(e.target.valueAsDate);
		} else {
			setSelectedBegindatum(undefined);
		}
	};

	const submitChangesHandler = async (changedData: SelectableData<IProductDetailsBySamenwerkings>[]): Promise<boolean> => {
		const modifiedEntries = changedData.map(item => {
			const transformedItem: SelectableData<IProductDetailsBySamenwerkings> = {...item, 
				begindatum: formatDate(item.begindatum,1), 
				einddatum: formatDate(item.einddatum, 1),
			};
			return transformedItem;
		});
		return new Promise<boolean>((resolve, reject) => {
			confirmAlert({
				title: 'Wijzigingen Bevestigen',
				message: `U heeft wijzigingen aangebracht op ${changedData.length} producten, weet u zeker dat u deze wijzigingen wilt doorgeven`,
				buttons: [
					{
						label: 'Ja',
						onClick: async () => {
							try {
								setResponseStatus(undefined);
								const result = await contractsUpdateBySamenwerkingQuery.execute({
									token: dbUser.token,
									aanbieder_id: dbUser.aanbieder,
									body: modifiedEntries,
								});
								setProductDetails(result.result.response.data);
								setResponseStatus(result.status);
								setShowAlertBox(true);
								resolve(true);
								
							} catch (error){
								reject(error);
							}
						},
					},
					{
						label: 'Nee',
						onClick: () => {
							resolve(false);
						},
					},
				],
			});
		});
	};

	const closeAlertBoxHandler = () => {
		setShowAlertBox(false);
	};

	const columns: TableColumnDefinition<IProductDetailsBySamenwerkings, keyof IProductDetailsBySamenwerkings>[] = [
		{name: 'Samenwerkingsverband', key: 'samenwerkings_naam', columnType: 'List'},
		{name: 'Gemeente', key: 'gemeente_naam', columnType: 'List'},
		{name: 'Product', key: 'product', columnType: 'Text'},
		{name: 'Wet', key: 'wet',columnType: 'List'},
		{name: 'Eenheid', key: 'betekenis', columnType: 'List'},
		{name: 'Begindatum', key: 'begindatum', columnType: 'Date', isEditable: true, isControls: true, validations: {
			min: (row) => formatDate(row.begindatum, 1),
			max: formatDate('4712-12-31', 1),
		}},
		{name: 'Einddatum', key: 'einddatum', columnType: 'Date'},
		{name: 'Tarief', key: 'tarief', columnType: 'Currency', isEditable: true, isControls: true, validations: {min: 0.00}},
	];

	const tableOptions: DataTableOptions<unknown> = {
		cascadeParameters: true,
		indexable: true,
		selectable: true,
		updateAndReset: true,
		resetAllCallback: getProducts,
	};

	const filters = {
		wet: selectedFilterWet,
		samenwerkings_naam: selectedFilterSamenwerking,
		product: selectedFilterProduct,
		begindatum: selectedBegindatum,
	};

	return (
		<MainContainer>
			{showAlertBox && (
				<AlertBox 
					alertType={responseStatus ? dtConstants.alertMap[responseStatus] : undefined}
					message={responseMessage}
					autoFocus
					onClose={closeAlertBoxHandler} 
				/>
			)}
			<ContractFilters 
				title={'Bijwerken Contractregels'}
				samenwerkingFilterList={samenwerkingsUniqueList}
				productsFilterList={productsUniqueList}
				wetFilterList={wetUniqueList}
				onWetSelection={wetFilterSelectionHandler}
				onSamenwerkingSelection={onSamenwerkingFilterSelectionHandler}
				onProductSelection={onProductSelectionHandler}
				onBegindatumSelection={onBegindatumFilterSelectionHandler}
			/>
			{ productDetails ? (
				<DataTable
					columns={columns} 
					data={productDetails}
					options={tableOptions}
					onSubmitChanges={submitChangesHandler}
					filters={filters}
					updateStatus={responseStatus}
					dbUser={dbUser}
				/>
			) : 
				(
					<Spinner contained/>
				)}
		</MainContainer>
	);
};

export default ContractUpdate;
