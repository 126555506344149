import React, {FormEvent} from 'react';
import api from '../../util/apiRequest';
import Loading from '../element/miscellaneous/Loading';
import isAuthorized from '../../util/isAuthorized';
import DbUser from '../../types/DbUser';

interface Calls {
	label: string
	path: string
	divider: boolean
}

const API_LIST: Calls[] = [
	{label: 'Database: Export', path: '/app/database/export', divider: false},
	{label: 'Database: Show Status', path: '/app/database/status', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Migrations: Update Allocation Data', path: '/app/migrations/update/allocs', divider: false},
	{label: 'Migrations: Update Client Data', path: '/app/migrations/update/clients', divider: false},
	{label: 'Migrations: Update Statement Data', path: '/app/migrations/update/statements', divider: false},
	{label: 'Migrations: Update Triggers', path: '/app/migrations/update/triggers', divider: false},
	{label: 'Migrations: Update Views', path: '/app/migrations/update/views', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Notifications: Add Test Notifications', path: '/app/notifications/test', divider: false},
	{label: 'Notifications: Process Unsent Notifications', path: '/app/notifications/process', divider: false},
	{label: 'Notifications: Send Daily Notification', path: '/app/notifications/process-daily', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Processor: Check Retour Messages', path: '/app/retourcheck', divider: false},
	{label: 'Processor: Process Messages', path: '/app/messages/process', divider: false},
	{label: 'Processor: Reset Processor', path: '/app/processor/reset', divider: false},
	{label: 'Processor: Show Status', path: '/app/processor/status', divider: false},
	{label: 'Processor: Send Credit Messages', path: '/app/invoices/credit/send', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Processor: Validate Messages with DVS (limit 100)', path: '/app/messages/dvs/limited', divider: false},
	{label: 'Processor: Validate Messages with DVS (unlimited)', path: '/app/messages/dvs/unlimited', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Statistics: Municipalities', path: '/app/statistics/municipalities', divider: false},
	{label: 'Statistics: Providers', path: '/app/statistics/providers', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Test: Validate with DVS', path: '/app/validate/xsd', divider: false},
	{label: 'Test: Generate Statements', path: '/allocs/statements/generate', divider: false},
	{label: 'Test: Disable Contracts', path: '/app/contracts/disable', divider: false},
	{label: 'Test: Enable Contracts', path: '/app/contracts/enable', divider: false},

	{label: '', path: '', divider: true},

	{label: 'Vecozo: Push Pending', path: '/app/vecozo/push', divider: false},
	{label: 'Vecozo: Pull Pending', path: '/app/vecozo/pull', divider: false},
];

interface Props {
	dbUser: DbUser;
	handleForm: any;
	handleChooseAanbieder: (user: unknown, aanbieder: number, standard: 0 | 1) => void;
}

interface Action {
	title: string,
	onClick: (options: {
		dismissNotificaton: (action: Action | MessageAlert) => void,
		action: Action,
		message: MessageAlert,
	}) => void,
}

interface MessageAlert {
	type: 'info' | 'warn' | 'error';
	internal_type: null | 'need_processing';
	title: string;
	descrition: string;
	actions: Action[]
}

interface State {
	bericht: string,
	valid: boolean,
	status: string,
	response: any,
	id: string,
	api_status: string,
	api_response: any,
	api: typeof API_LIST,
	api_choice: string,
	api_progess: boolean,
	valid_choice: boolean,
	upload_progress: boolean,
	messageAlerts: MessageAlert[],
}

export default class ManageMain extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			bericht: '',
			valid: false,
			status: '',
			response: '',
			id: '',
			api_status: '',
			api_response: '',
			api: API_LIST,
			api_choice: '',
			api_progess: false,
			valid_choice: false,
			upload_progress: false,
			messageAlerts: [],
		};
	}

	handleSubmit = async (event: any) => {
		event.preventDefault();
		const {token} = this.props.dbUser;
		const formData = {bericht: this.state.bericht};
		this.setState(() => ({
			upload_progress: true,
		}));
		const uploadMessage = await api.uploadMessage(formData, api.makeHeader(token));
		if (uploadMessage.error && uploadMessage.error === true) {
			this.props.handleForm(uploadMessage);
			return null;
		}
		const messageAlerts: MessageAlert[] = [];

		const aanbieder_agb_code = uploadMessage.response?.aanbieder;
		if (aanbieder_agb_code && aanbieder_agb_code !== this.props.dbUser.aanbieders.find(a => a.id === this.props.dbUser.aanbieder)?.agb_code) {
			const aanbieder = this.props.dbUser.aanbieders.find(a => a.agb_code === aanbieder_agb_code);
			messageAlerts.push({
				type: 'info',
				internal_type: null,
				title: 'Andere aanbieder',
				descrition: `U heeft een bericht ingelezen welke tot ${aanbieder?.label ?? '??'} ${aanbieder?.naam ?? '??'} behoord.`,
				actions: !aanbieder ? [] : [
					{
						title: `Schakel over naar ${aanbieder.label} ${aanbieder.naam}`,
						onClick: ({dismissNotificaton, message}) => {
							this.props.handleChooseAanbieder(null, aanbieder.id, 0);
							dismissNotificaton(message);
						},
					},
				],
			});
		}

		// Make sure to keep `=== false` in he below statement, undefined is not a condition we want to trigger on
		if (uploadMessage.response?.validatie?.result === false || uploadMessage.response?.validatie?.dvs === false) {
			messageAlerts.push({
				type: 'warn',
				internal_type: null,
				title: 'Validatie fout gedetecteerd',
				descrition: 'Er zijn validatie fouten in dit bericht gedetecteerd!',
				actions: [],
			});
		} else {
			messageAlerts.push({
				type: 'info',
				internal_type: 'need_processing',
				title: 'Bericht verwerking nodig!',
				descrition: 'Vergeet niet de brichten te verwerken!',
				actions: !isAuthorized(this.props.dbUser.autorisatie, 'back-endproces.uitvoeren') ? [] : [{
					title: 'Verwerk berichten',
					onClick: ({dismissNotificaton, message}) => {
						this.setState({
							api_choice: '/app/messages/process',
							valid_choice: true,
						}, () => {
							this.handleManageBackendProcess(undefined);
							dismissNotificaton(message);
						});
					},
				}],
			});
		}
		this.setState(() => ({
			status: uploadMessage.status,
			id: uploadMessage.response.id,
			response: uploadMessage.response,
			messageAlerts,
			upload_progress: false,
		}));
	};

	handleBericht = (value: string) => {
		this.setState((state: any) => {
			state.bericht = value;
			if (value !== '') {
				state.valid = true;
			}
			return state;
		});
	};

	handleReset = (event: any) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.setState((state: any) => {
			state.bericht = '';
			state.status = '';
			state.response = '';
			state.id = '';
			state.valid = false;
			return state;
		});
	};

	handleChooseBackendProcess = (event: any, path: string) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.setState((state: any) => {
			state.api_choice = path;
			state.valid_choice = path === '0' ? false : true;
			state.api_status = '';
			state.api_response = '';
			return state;
		});
	};

	handleManageBackendProcess = async (event?: FormEvent) => {
		event?.preventDefault();
		const {token} = this.props.dbUser;
		const {api_choice} = this.state;
		this.setState(() => ({
			api_progess: true,
			api_status: '',
			api_response: '',
		}));
		const appManageApiCall = await api.appManageApiCall(api_choice, api.makeHeader(token));
		if (appManageApiCall.error && appManageApiCall.error === true) {
			this.props.handleForm(appManageApiCall);
			return null;
		}
		this.setState(state => ({
			api_status: appManageApiCall.status,
			api_response: appManageApiCall.response,
			api_progess: false,
			messageAlerts: api_choice === '/app/messages/process' ? state.messageAlerts.filter(m => m.internal_type !== 'need_processing') : state.messageAlerts,
		}));
	};

	handleResetBackendProcess = (event: any) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		this.setState((state: any) => {
			state.api_status = '';
			state.api_response = '';
			state.api_choice = '0';
			state.valid_choice = false;
			return state;
		});
	};
    
	handleMessageDismissMessage = (action: Action | MessageAlert) => {
		this.setState(s => ({
			messageAlerts: s.messageAlerts.filter(m => m !== action && m.actions.indexOf(action as Action) < 0),
		}));
	};

	handleMessageOnclick = (action: Action, message: MessageAlert) => {
		action.onClick({
			dismissNotificaton: this.handleMessageDismissMessage,
			message,
			action,
		});
	};

	render = () => {
		const autorisatie = this.props.dbUser.autorisatie;

		return (
			<>
				<section className='client-data client-table--with-grid-2'>
					{isAuthorized(autorisatie, 'berichtenverkeer.testen') && (
						<section className='table-element'>
							<form onSubmit={this.handleSubmit} onReset={this.handleReset}>
								<header>
									<h1>Berichtenverkeer testen</h1>
								</header>
								<main>
									<label htmlFor='f1'>
										<span>Bericht</span>
										<textarea
											name='bericht'
											placeholder='Bericht'
											id='f1'
											value={this.state.bericht}
											onChange={event => this.handleBericht(event.target.value)}
											required
											style={{height: '10rem', resize: 'vertical'}}></textarea>
									</label>
									{this.state.status !== '' && (
										<div>
											<table>
												<tbody>
													<tr>
														<td>
                                                            Status: <strong>{this.state.status}</strong>
														</td>
													</tr>
													{this.state.messageAlerts.map((e, i) => (
														<tr key={i}>
															<td style={{
																backgroundColor: e.type === 'info' ? 'lightblue' : e.type === 'warn' ? 'lightgoldenrodyellow' : 'red',
																borderColor: e.type === 'info' ? 'blue' : e.type === 'warn' ? 'orange' : 'darkred',
																borderStyle: 'solid',
																borderWidth: 2,
																padding: 2,
															}}>
																<p>
																	<strong>Let op: </strong>{e.title}
																</p>
																{e.descrition.split('\n').map((l, i1) => (
																	<p key={i1}>
																		{l}
																	</p>
																))}
																{e.actions.map((a, i1) => (
																	<div key={`b${i1}`}>
																		<button className='button small' onClick={() => this.handleMessageOnclick(a, e)}>
																			{a.title}
																		</button>
																	</div>
																))}
																<div>
																	<button className='button small' onClick={() => this.handleMessageDismissMessage(e)}>
                                                                        Sluit melding
																	</button>
																</div>
															</td>
														</tr>
													))}
													<tr>
														<td>
															<pre>Resultaat: {this.state.response !== '' ? (JSON.stringify(this.state.response, null, 4) ?? '').replace(/"|,/g, '') : ''}</pre>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									)}
									{this.state.upload_progress && <Loading message='' />}
								</main>

								<footer>
									<button type='reset' className='secondary' onClick={event => this.handleReset(event)}>
                                        Reset
									</button>
									<button type='submit' className='default' disabled={this.state.valid ? false : true}>
                                        Verzend
									</button>
								</footer>
							</form>
						</section>
					)}
					{isAuthorized(autorisatie, 'back-endproces.uitvoeren') && (
						<section className='table-element'>
							<form onSubmit={event => this.handleManageBackendProcess(event)} onReset={this.handleResetBackendProcess}>
								<header>
									<h1>Back-end proces uitvoeren</h1>
								</header>
								<main>
									<label htmlFor='f2'>
										<span>API</span>
										<select name='process' placeholder='Back-end proces' id='f2' value={this.state.api_choice} onChange={event => this.handleChooseBackendProcess(event, event.target.value)} required>
											<option key='1' value=''>
                                                Kies een back-end proces
											</option>
											<option disabled></option>
											{this.state.api.map((row: Calls, index: number) => (
												<option key={row.path === '' ? index : row.path} value={row.path === '' ? '' : row.path} disabled={row.divider}>
													{row.label}
												</option>
											))}
										</select>
									</label>

									{this.state.api_status !== '' && (
										<label>
											<table>
												<tbody>
													<tr>
														<td>
                                                            Status: <strong>{this.state.api_status}</strong>
														</td>
													</tr>
													<tr>
														<td>
															<pre>Resultaat: {this.state.api_response !== '' ? (JSON.stringify(this.state.api_response, null, 4) ?? '').replace(/"|,/g, '') : ''}</pre>
														</td>
													</tr>
												</tbody>
											</table>
										</label>
									)}
									{this.state.api_progess && <Loading message='' />}
								</main>

								<footer>
									<button type='reset' className='secondary' onClick={event => this.handleResetBackendProcess(event)}>
                                        Reset
									</button>
									<button type='submit' className='default' disabled={this.state.valid_choice ? false : true}>
                                        Voer back-end proces uit
									</button>
								</footer>
							</form>
						</section>
					)}

					<section className='table-element'>
						<form onSubmit={this.handleSubmit} onReset={this.handleReset}>
							<header>
								<h1>App versie</h1>
							</header>
							<main>
								<p>
                                    Op dit moment draait app versie
									{' '}
									{process.env.REACT_APP_VERSION === 'develop' ? <em>speciale developer editie</em> : <a href={`https://github.com/Zorgverkeer/aanbieder.zorgverkeer.nl/commit/${process.env.REACT_APP_VERSION}`}>{process.env.REACT_APP_VERSION?.substring(0, 6)}</a>}
                                    , welke voor het laatst een aanpassing heeft gehad om {process.env.REACT_APP_TIME}
								</p>
							</main>
						</form>
					</section>
				</section>
			</>
		);
	};
}
