import { DbUser } from '../../../types';

export type TSortOrder = 'asc' | 'desc';

export type ColumnDefinitionText<T, K extends keyof T> = {
	key: K;
	name: string;
	columnType: 'Text';
	isActionable?: boolean;
	isEditable?: boolean;
	isControls?: boolean; 
    
};

// A typeguard for ColumnDefinitionText
export function colIsText<T, K extends keyof T>(col: TableColumnDefinition<T,K>): col is ColumnDefinitionText<T,K> {
	return col.columnType == 'Text';
}

export type ColumnDefinitionDate<T, K extends keyof T> = {
	key: K;
	name: string;
	columnType: 'Date';
	isActionable?: boolean;
	isEditable?: boolean;
	isControls?: boolean;
	validations?: {
		min: string | ((row: T) => string);
		max: string;
	}
};

// A typeguard for ColumnDefinitionDate
export function colIsDate <T, K extends keyof T> (col: TableColumnDefinition<T,K>): col is ColumnDefinitionDate<T,K> {
	return col.columnType == 'Date';
}

export type ColumnDefinitionNumbers<T, K extends keyof T> = {
	key: K;
	name: string;
	columnType: 'Number' | 'Currency';
	isActionable?: boolean;
	isEditable?: boolean;
	isControls?: boolean;
	validations?: {
		min?: number;
		max?: number;
	} 
};

// A typeguard for ColumnDefinitionNumbers
export function colIsNumber <T,K extends keyof T>(col: TableColumnDefinition<T,K>): col is ColumnDefinitionNumbers<T,K> {
	return  col.columnType == 'Currency' || col.columnType == 'Number';
}

export type ColumnDefinitionList<T, K extends keyof T> = {
	key: K;
	name: string;
	columnType:  'List';
	columnListValues?: string[];
	isActionable?: boolean;
	isEditable?: boolean;
	isControls?: boolean; 
};

// A typeguard for ColumnDefinitionList
export function colIsList <T,K extends keyof T> (col: TableColumnDefinition<T,K>): col is ColumnDefinitionList<T, K> {
	return col.columnType == 'List';
}

export type TableColumnDefinition<T, K extends keyof T> = ColumnDefinitionText<T,K> | ColumnDefinitionList<T,K> | ColumnDefinitionDate<T,K> |ColumnDefinitionNumbers<T,K>;


export const getControlsColumns = <T, K extends keyof T> (colArr: TableColumnDefinition<T,K>[]) => {
	const controlsColArr = colArr.filter((col) => col.isControls == true);
	return controlsColArr;
};

/**
 * @description: Type used to inidcate the status of a row upon usage.
 * more description required
 */

type RowStatus = 'Origineel' | 'Bewerking' | 'Aangepast' | 'Nieuwe Invoer' | 'Gearchiveerd' | 'Fout' | 'Verkeerde Waarde';

interface ISelectable {
	selected: boolean;
	rowStatus: RowStatus;
	originalRowIndex: number;
	updateStatus?: string;
}

export type SelectableData<T>  = T & ISelectable;

export type U<T> = T | SelectableData<T>;

export const checkIfSelectable = <T>(value: U<T>): value is SelectableData<T>  => {
	if (typeof value === 'object' && value != null 
        && 'selected' in value && 'rowStatus' in value  && 'originalRowIndex' in value) {
		return true;
	} 
	return false;
};


/**
 * @description Function that transforms an array of object into a type SelectableData, a type compatible with an
 * editable data table. The function also checks if the a single row is already of type SelectableData yet with a FOUT status 
 * and leaves it as it is for the user to check.
 * @param row Array of generic T
 * @param hardChange boolean that indicates whether to override the Fout check of not.
 * @returns an array of object of type SelectableData
 */
export const changeToSelectable = <T>(row: T[], hardChange: boolean): SelectableData<T>[] => {
	const transformedRow = row.map((item, index) => {
		if (!hardChange && item && checkIfSelectable(item)) {
			return {...item, originalRowIndex: index} as SelectableData<T>;
		}
		const newItem: SelectableData<T> = {...item, selected: false, rowStatus: 'Origineel', originalRowIndex: index};
		return newItem;
	});
	return transformedRow;
};


/**
 * @type ControlsValues
 * @param T: generic
 * @description Used to store the keys and values updated in TableUpdateControls component (More description needed)
 */

export type ControlsValues<T> = {
	[K in keyof T]?: string | number | Date;
};

export interface AddForm<T> {
	onCancel: () => void;
	onSave: (value: T) => void;
	dbUser?: DbUser;
	isOpen: boolean;
}

interface DataTableOptionsRegular {
	indexable: boolean;
	selectable: boolean;
	updateAndReset: boolean;
	addNewEntries?: 'regular';
	cascadeParameters: boolean;
	resetAllCallback?: () => void;
}

interface DataTableOptionsComplex <T> {
	indexable: boolean;
	selectable: boolean;
	updateAndReset: boolean;
	addNewEntries: 'complex';
	FormToRender: React.ComponentType<AddForm<T>>;
	cascadeParameters: boolean;
	resetAllCallback?: () => void;
}

export type DataTableOptions<T> = DataTableOptionsRegular | DataTableOptionsComplex<T>;

/**
 * type that represents two options of adding an entry to a datatable
 */
export type AddEntryOptions = DataTableOptions<unknown>['addNewEntries'];



// /**
//  * Interface that represents options to add certain features on the data table.
//  */
// export interface DataTableOptions {
// 	/**
//      * adds an index column to the data table.
//      */
// 	indexable: boolean;
// 	/**
//      * adds a checkbox column at the start of the table and an action column at 
//      * the end. This option allows the user to edit, save and mark entries for deletion.
//      */
// 	selectable: boolean;
// 	/**
//      * renders two buttons at the bottom of the table that allows users to
//      * submit their modifications made on the data.
//      */
// 	updateAndReset: boolean;
// 	/**
//      * Renders a button at the bottom of the table that allows users to insert 
//      * a new entry in the table by choosing one of the two options available.
//      */
// 	addNewEntries?: AddEntryOptions;
// 	/**
//      * Renders a block on top of the data table to cascade changes.
//      */

// 	cascadeParameters: boolean;
// 	/**
//      * a callback function that is passed to the table to reset all the entries. 
//      * Typically this should be the API request that fetches the original data.
//      * Reset all will not work if the selectable option is set to false
//      */
// 	resetAllCallback?: () => void;
// }

