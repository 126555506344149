import Checksum from 'jsum';
import {User, GetTokenSilentlyOptions} from '@auth0/auth0-spa-js';
import ClientSidebar from '../client/ClientSidebar';
import ClientTab from '../client/ClientTab';
import FormSwitch from '../form/FormSwitch';
import InvoiceMain from '../invoice/InvoiceMain';
import ListAllocation from '../list/ListAllocation';
import ListAllocationRequest from '../list/ListAllocationRequest';
import ListChangeRequest from '../list/ListChangeRequest';
import ListClient from '../list/ListClient';
import ContractRuleMain from '../contractrule/ContractRuleMain';
import ListInvoice from '../list/ListInvoice';
import ListInvoiceRule from '../list/ListInvoiceRule';
import ListMessage from '../list/ListMessage';
import ListStatement from '../list/ListStatement';
import LogoZorgVerkeerMain from '../element/logo/LogoZorgVerkeerMain';
import ManageMain from '../manage/ManageMain';
import NavMain from '../nav/NavMain';
import Start from '../element/miscellaneous/Start';
import ClientNotFound from '../client/ClientNotFound/ClientNotFound';
import UserChooseProvider from '../user/UserChooseProvider';
import UserNotification from '../user/UserNotification';
import UserSettings from '../user/UserSettings';
import Warning from '../element/miscellaneous/Warning';
import api from '../../util/apiRequest';
import history from '../../util/history';
import {NOTIFICATION_TIMER, AUTH0_AUDIENCE, AUTH0_SCOPE, ENVIRONMENT, TOKEN_TIMER} from '../../configuration';
import {Router, Route, Redirect, Switch} from 'react-router-dom';
import isAuthorized from '../../util/isAuthorized';
import {NotificationContainer, Notifications} from 'react-notifications';
import DbUser from '../../types/DbUser';
import HandleForm from '../../types/HandleForm';
import Form from '../../types/Form';
import unknownToError from '../../util/unknownToError';
import Client from '../../types/Client';
import Gemeente from '../../types/Gemeente';
import { Component } from 'react';
import { Melding } from '../../types';
import ContractUpdate from '../../pages/ContractUpdate';
import ClientsStatements from '../../pages/ClientsStatements';

const EMPTY_CLIENT: Client = {
	id: 0,
	aanbieder_id: 0,
	bsn: '',
	gemeente_id: null,
	geslacht: null,
	ropenaam: null,
	achternaam: null,
	voorletters: null,
};

let notification_timer: ReturnType<typeof window['setTimeout']>;
let token_timer: ReturnType<typeof window['setTimeout']>;

interface Table {
	data: {},
	update: {},
	trigger: 0
}

interface Props {
	user: User | undefined;
	getTokenSilently: (payload?: GetTokenSilentlyOptions) => Promise<string>;
}

interface State {
	dbUser: DbUser | null;
	dbUserLoaded: boolean;
	dbNoUser: boolean;
	dbNoAanbieder: boolean;
	dbClientsLoaded: boolean;
	dbGemeentesLoaded: boolean;
	dbNoGemeentes: boolean;
	dbPeriodsLoaded: boolean;
	dbCategoriesLoaded: boolean;
	dbRetourcodesLoaded: boolean;
	dbRedenenLoaded: boolean;
	dbValueListLoaded: boolean;
	dbContractLoaded: boolean;
	dbAanbiederInit: number;
	apiError: { status: number, response: string, error?: boolean};
	errorMessage: null | string;
	user: User | undefined;
	sidebar: {collapse: boolean, trigger: number};
	form: {display: true, data: Form} | {display: false}
	votTable: Table,
	vowTable: Table,
	toewijzingTable: Table,
	startZorgTable: Table,
	stopZorgTable: Table,
	verantwoordingTable: Table,
	clients: Client[],
	updateClients: number,
	gemeentes: Gemeente[],
	notifications: { data: Melding[], checksum: string},
	periods: unknown[],
	onLine: boolean,
	loading: {
		requestId: number
		method: string
	}[],
	retourcodes: unknown[],
	redenen: unknown[],
	valuelists: unknown[],
	contracts: unknown[],
}

export default class AppMain extends Component<Props, State> {
	private client: Client;

	constructor(props: Props) {
		super(props);
		this.state = {
			dbUser: null,
			dbUserLoaded: false,
			dbNoUser: false,
			dbNoAanbieder: false,
			dbClientsLoaded: false,
			dbGemeentesLoaded: false,
			dbCategoriesLoaded: false,
			dbRetourcodesLoaded: false,
			dbNoGemeentes: false,
			dbPeriodsLoaded: false,
			dbRedenenLoaded: false,
			dbValueListLoaded: false,
			dbContractLoaded: false,
			dbAanbiederInit: 0,
			apiError: {status: 200, response: 'OK'},
			errorMessage: null,
			user: props.user,
			sidebar: {collapse: false, trigger: 0},
			form: {display: false},
			votTable: {data: {}, update: {}, trigger: 0},
			vowTable: {data: {}, update: {}, trigger: 0},
			toewijzingTable: {data: {}, update: {}, trigger: 0},
			startZorgTable: {data: {}, update: {}, trigger: 0},
			stopZorgTable: {data: {}, update: {}, trigger: 0},
			verantwoordingTable: {data: {}, update: {}, trigger: 0},
			clients: [],
			updateClients: 0,
			gemeentes: [],
			notifications: {data: [], checksum: ''},
			periods: [],
			onLine: window.navigator.onLine !== false,
			loading: [],
			retourcodes: [],
			redenen: [],
			valuelists: [],
			contracts: [],
		};
		// This is not stored in the state, because it is a value calculated from other fields. If the other fields
		// update, there is a already is a render. This field never updates on its own. The reason we cache it is
		// because it is CPU intensive to call find on an array every time.
		this.client = EMPTY_CLIENT;
	}

	componentDidMount = async () => {
		let token;
		let user;

		if (ENVIRONMENT === 'production') {
			token = await this.props.getTokenSilently({
				audience: AUTH0_AUDIENCE,
				scope: AUTH0_SCOPE,
			});

			user = this.props.user;
		}
		if (ENVIRONMENT === 'development') {
			token = 'development';
		}

		const getUser = await api.getUser({ token });
		if ('error' in getUser) {
			if (getUser.status >= 401) {
				this.setState(() => ({
					dbUserLoaded: false,
					dbNoUser: true,
					apiError: getUser,
				}));
				return null;
			}
			this.setState(() => ({
				apiError: getUser,
			}));
			return null;
		}

		const dbUser: DbUser = {
			...getUser.response,
			token,
		};
		const {aanbieder} = dbUser;

		if (dbUser.aanbieders.length === 0) {
			this.setState(() => ({
				dbNoAanbieder: true,
			}));
			return null;
		}

		if (!dbUser.aanbieders.find(row => row.id === aanbieder)) {
			this.setState(() => ({
				dbNoAanbieder: true,
			}));
			return null;
		}

		const [
			getClientList,
			getGemeenteList,
			getPeriodeList,
			getRetourcodeList,
			getRedenList,
			getValueList,
			getContractList,
			getCategories,
		] = await Promise.all([
			this.handleGetClientList(aanbieder, token),
			this.handleGetGemeenteList(aanbieder, token),
			this.handleGetPeriodeList(aanbieder, token),
			this.handleGetRetourcodeList(token),
			this.handleGetRedenList(token),
			this.handleGetValueList(token),
			this.handleGetContractList(aanbieder, token),
			this.handleGetCategories(token),
		]/* as const*/);

		this.setState(() => ({
			dbUser: {...dbUser},
			dbUserLoaded: true,
			dbGemeentesLoaded: true,
			dbAanbiederInit: dbUser.aanbieder,
			gemeentes: getGemeenteList,
			dbClientsLoaded: true,
			clients: getClientList,
			dbPeriodsLoaded: true,
			periods: getPeriodeList,
			dbRetourcodesLoaded: true,
			retourcodes: getRetourcodeList,
			dbRedenenLoaded: true,
			redenen: getRedenList,
			dbValueListLoaded: true,
			valuelists: {
				...getValueList,
				WMO020: getCategories,
			},
			dbContractLoaded: true,
			contracts: getContractList,
			dbCategoriesLoaded: true,
			user: user,
		}));

		notification_timer = setInterval(() => this.handleNotifications(), NOTIFICATION_TIMER);
		setTimeout(() => this.handleNotifications(), 1000);

		token_timer = setInterval(() => this.handleTokenRefresh(), TOKEN_TIMER);
		setTimeout(() => this.handleTokenRefresh(), 1000);

		window.addEventListener('online', this.handlOnlineEvent);
		window.addEventListener('offline', this.handlOfflineEvent);
		window.addEventListener('aanbieder-url-loading', this.handleUrlLoading);
		window.addEventListener('aanbieder-url-loading-done', this.handleUrlLoadingDone);
	};

	handleUrlLoading = (event) => {
		this.setState(state => ({
			loading: {
				...state.loading,
				[event.detail.requestId]: event.detail,
			},
		}));
	};

	handleUrlLoadingDone = (event) => {
		this.setState(state => {
			const loading = {...state.loading};
			delete loading[event.detail.requestId];
			return { loading };
		});
	};

	handlOnlineEvent = () => {
		this.setState({onLine: true});
	};

	handlOfflineEvent = () => {
		this.setState({onLine: false});
	};

	componentWillUnmount = () => {
		clearInterval(notification_timer);
		clearInterval(token_timer);
		window.removeEventListener('online', this.handlOnlineEvent);
		window.removeEventListener('offline', this.handlOfflineEvent);
		window.removeEventListener('aanbieder-url-loading', this.handleUrlLoading);
		window.removeEventListener('aanbieder-url-loading-done', this.handleUrlLoadingDone);
	};

	handleWindowBeforeUnload = event => {
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

	handleTokenRefresh = async () => {
		if (ENVIRONMENT === 'production') {
			const token = await this.props.getTokenSilently({
				audience: AUTH0_AUDIENCE,
				scope: AUTH0_SCOPE,
			});
			this.setState(() => ({
				dbUser: this.state.dbUser ? {...this.state.dbUser, token} : null,
			}));
		}
	};

	handleNotifications = async () => {
		if (!this.state.dbUser) return;
		const {id, token} = this.state.dbUser;
		const {checksum} = this.state.notifications;

		const getChangedNotification = await api.getChangedNotification({ userId: id, token });

		if ('error' in getChangedNotification) {
			this.setState(() => ({
				apiError: getChangedNotification,
			}));
			return null;
		}

		const newChecksum = this.handleMakeChecksum(getChangedNotification.response);

		if (newChecksum !== checksum) {
			const getNotification = await api.getNotification({ userId: id, token });

			if ('error' in getNotification) {
				this.setState(() => ({
					apiError: getNotification,
				}));
				return null;
			}
			this.setState(() => ({
				notifications: {
					data: getNotification.response,
					checksum: newChecksum,
				},
			}));
		}
	};

	handleNotificationStatus = (notification, status) => {
		// mark all read
		if (Number(notification) === 0) {
			return this.setState(state => {
				state.notifications.data.map(row => {
					// TODO strict mode error
					row.is_gelezen = Number(status) === 0 ? 0 : 1;
					return row;
				});
				return state;
			});
		}
		const index = this.state.notifications.data.findIndex(row => {
			return row.id === Number(notification);
		});
		if (index > -1) {
			this.setState(state => {
				// TODO strict mode error
				state.notifications.data[index].is_gelezen = Number(status) === 0 ? 0 : 1;
				return state;
			});
		}
	};

	handleNotificationRemove = (notification, status) => {
		// remove all
		if (Number(notification) === 0) {
			return this.setState(() => ({
				notifications: {data: [], checksum: ''},
			}));
		}

		const index = this.state.notifications.data.findIndex(row => {
			return row.id === Number(notification);
		});
		if (index > -1) {
			this.setState(state => {
				// TODO strict mode error
				state.notifications.data[index].verwijderd = Number(status) === 0 ? 0 : 1;
				return state;
			});
		}
	};

	handleMakeChecksum = data => {
		return Checksum.digest(data, 'SHA256', 'hex');
	};

	handleUpdateClientList = (data: {
		data: Client,
		index: number | null
	}) => {
		// Update computed field client
		if (data.data.id === this.client.id) {
			this.client = data.data;
		}
		this.setState(state => {
			const clients = [...state.clients];
			if (data.index === null) {
				clients.push(data.data);
				this.handleChooseClient(data.data, `/client/${data.data.id}`);
			} else {
				clients[data.index] = data.data;
			}
			return {
				clients,
				updateClients: state.updateClients + 1,
			};
		});
	};

	handleChooseClient = (data: unknown, destination) => {
		this.handleRoute(destination);
	};

	handleUpdateUserData = async data => {
		if (!this.state.dbUser) throw new Error('This operation requires a DbUser');
		const {token} = this.state.dbUser;
		const newAanbieder = Number(data.aanbieder);

		const getClientList = await this.handleGetClientList(newAanbieder, token);
		const getGemeenteList = await this.handleGetGemeenteList(newAanbieder, token);
		const getContractList = await this.handleGetContractList(newAanbieder, token);

		this.setState(state => {
			let dbUser = state.dbUser;
			if (!dbUser) {
				return null;
			}
			dbUser = {
				...dbUser,
			};
			dbUser.voornaam = data.voornaam;
			dbUser.tussenvoegsel = data.tussenvoegsel;
			dbUser.achternaam = data.achternaam;
			dbUser.aanbieder = newAanbieder;
			return {
				dbAanbiederInit: newAanbieder,
				gemeentes: getGemeenteList,
				clients: getClientList,
				dbUser: dbUser,
				contracts: getContractList,
			};
		});
	};

	handleCollapseSidebar = () => {
		this.setState(state => {
			state.sidebar.collapse = !state.sidebar.collapse;
			state.sidebar.trigger = state.sidebar.trigger + 1;
			return state;
		});
	};

	handleCollapseSidebarTrigger = () => {
		this.state.sidebar.trigger;
	};

	handleChooseAanbieder = async (user: unknown, aanbieder: number, standard: 0 | 1) => {
		const {id, token} = this.state.dbUser ?? {id: 0, token: ''};
		if (standard !== null && standard !== 0) {
			const updateDefaultAanbieder = await api.updateDefaultAanbieder(id, standard, api.makeHeader(token));
			if (updateDefaultAanbieder.error && updateDefaultAanbieder.error === true) {
				this.setState(() => ({
					apiError: updateDefaultAanbieder,
				}));
				return null;
			}

			const getClientList = await this.handleGetClientList(aanbieder, token);
			const getGemeenteList = await this.handleGetGemeenteList(aanbieder, token);
			const getContractList = await this.handleGetContractList(aanbieder, token);
			return this.setState(state => ({
				dbUser: state.dbUser ? {
					...state.dbUser,
					aanbieder,
				} : null,
				dbAanbiederInit: standard,
				gemeentes: getGemeenteList,
				clients: getClientList,
				contracts: getContractList,
			}));
		}

		const getClientList = await this.handleGetClientList(aanbieder, token);
		const getGemeenteList = await this.handleGetGemeenteList(aanbieder, token);
		const getContractList = await this.handleGetContractList(aanbieder, token);
		return this.setState(state => ({
			dbUser: state.dbUser ? {
				...state.dbUser,
				aanbieder,
			} : null,
			gemeentes: getGemeenteList,
			clients: getClientList,
			contracts: getContractList,
		}));
	};

	handleGetContractList = async (aanbieder, token) => {
		const data = await api.getContract(aanbieder, api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbContractLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetCategories = async token => {
		const data = await api.getCategorieList(api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbCategoriesLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetValueList = async token => {
		const lists = ['COD327', 'JZ588', 'WMO588', 'JW758', 'JZ757', 'COD757', 'COD046', 'WJ001', 'WJ756', 'WMO757', 'JZ002', 'WMO002'].join(',');
		const data = await api.getValueList(lists, api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbValueListLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetClientList = async (aanbieder, token) => {
		const data = await api.getClientList(aanbieder, api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbClientsLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetPeriodeList = async (aanbieder, token) => {
		const data = await api.getPeriod(aanbieder, api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbPeriodsLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetRetourcodeList = async token => {
		const data = await api.getValueList('WJ001', api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbRetourcodesLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetRedenList = async token => {
		const data = await api.getValueList('WJ759', api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbRetourcodesLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleGetGemeenteList = async (aanbieder, token) => {
		const data = await api.getGemeente(aanbieder, api.makeHeader(token));
		if (data.error && data.error === true) {
			this.setState(() => ({
				apiError: data,
			}));
			return null;
		}
		if (data.status !== 200) {
			this.setState(() => ({
				dbGemeentesLoaded: false,
			}));
			return null;
		}
		return data.response;
	};

	handleRoute = to => {
		history.push(to);
	};

	handleVotTable = data => {
		this.setState(state => {
			state.votTable.update = data;
			state.votTable.trigger++;
			return state;
		});
	};

	handleVowTable = data => {
		this.setState(state => {
			state.vowTable.update = data;
			state.vowTable.trigger++;
			return state;
		});
	};

	handleToewijzingTable = data => {
		this.setState(state => {
			state.toewijzingTable.update = data;
			state.toewijzingTable.trigger++;
			return state;
		});
	};

	handleStartZorgTable = data => {
		this.setState(state => {
			state.startZorgTable.update = data;
			state.startZorgTable.trigger++;
			return state;
		});
	};

	handleStopZorgTable = data => {
		this.setState(state => {
			state.stopZorgTable.update = data;
			state.stopZorgTable.trigger++;
			return state;
		});
	};

	handleVerantwoordingTable = data => {
		this.setState(state => {
			state.verantwoordingTable.update = data;
			state.verantwoordingTable.trigger++;
			return state;
		});
	};

	resetVotTable = () => {
		this.setState(state => {
			state.votTable.data = {};
			state.votTable.update = {};
			return state;
		});
	};

	resetVowTable = () => {
		this.setState(state => {
			state.vowTable.data = {};
			state.vowTable.update = {};
			return state;
		});
	};

	resetToewijzingTable = () => {
		this.setState(state => {
			state.toewijzingTable.data = {};
			state.toewijzingTable.update = {};
			return state;
		});
	};

	resetStartZorgTable = () => {
		this.setState(state => {
			state.startZorgTable.data = {};
			state.startZorgTable.update = {};
			return state;
		});
	};

	resetStopZorgTable = () => {
		this.setState(state => {
			state.stopZorgTable.data = {};
			state.stopZorgTable.update = {};
			return state;
		});
	};

	resetVerantwoordingTable = () => {
		this.setState(state => {
			state.verantwoordingTable.data = {};
			state.verantwoordingTable.update = {};
			return state;
		});
	};

	handleForm: HandleForm = (data) => {
		return this.setState(state => {
			if (data === null) {
				return {
					form: {  display: false  },
					apiError: state.apiError,
				};
			}
			if ('error' in data) {
				return {
					form: {  display: false  },
					apiError: data,
				};
			}
			return {
				form: { display: true, data  },
				apiError: state.apiError,
			};
		});
	};

	static getDerivedStateFromError(error: unknown): Partial<State> {
		const e = unknownToError(error);
		return {
			apiError: {
				error: true,
				response: e.toString(),
				status: 500,
			},
		};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		console.error('React error detected');
		console.error(error);
		console.error(errorInfo);
	}

	getNotification = () => {
		return this.state.notifications;
	};

	render = () => {
		const {
			dbUserLoaded,
			dbNoUser,
			dbNoAanbieder,
			dbGemeentesLoaded,
			dbNoGemeentes,
			apiError,
			dbPeriodsLoaded,
			dbRetourcodesLoaded,
			dbRedenenLoaded,
			dbValueListLoaded,
			dbContractLoaded,
			dbCategoriesLoaded,
			user,
		} = this.state;

		if (dbUserLoaded === false) {
			return <Warning className={'loading'} message={'Wacht op verbinding met de database...'} response={apiError} />;
		}

		if (apiError.error) return <Warning className={'error'} message={'Er is een fout opgetreden tijdens het communiceren met de back-end...'} response={apiError} />;
		if (dbNoUser === true) return <Warning className={'error'} message={`Helaas, u heeft geen toegang tot deze applicatie met emailadres: ${user?.email}.`} response={apiError} />;
		if (dbNoAanbieder === true) return <Warning className={'error'} message={'Helaas, uw account is nog niet aan een aanbieder gekoppeld.'} response={apiError} />;
		if (dbGemeentesLoaded === false) return <Warning className={'loading'} message={'Wacht op verbinding met de database...'} response={apiError} />;
		if (dbNoGemeentes === true) return <Warning className={'error'} message={'Helaas, uw account is nog niet aan één of meer gemeentes gekoppeld.'} response={apiError} />;
		if (dbPeriodsLoaded === false) return <Warning className={'error'} message={'De lijst met jaren en periodes kon niet worden geladen.'} response={apiError} />;
		if (dbRetourcodesLoaded === false) return <Warning className={'error'} message={'De lijst met retourcodes kon niet worden geladen.'} response={apiError} />;
		if (dbRedenenLoaded === false) return <Warning className={'error'} message={'De lijst met reden afwijzing verzoek kon niet worden geladen.'} response={apiError} />;
		if (dbValueListLoaded === false) return <Warning className={'error'} message={'De waardelijsten voor formulieren konden niet worden geladen.'} response={apiError} />;
		if (dbContractLoaded === false) return <Warning className={'error'} message={'De lijst met contactregels kon niet worden geladen.'} response={apiError} />;
		if (dbCategoriesLoaded === false) return <Warning className={'error'} message={'De lijst met categorien kon niet worden geladen.'} response={apiError} />;

		const {
			dbUser,
			sidebar,
			form,
			clients,
			gemeentes,
			votTable,
			vowTable,
			toewijzingTable,
			startZorgTable,
			stopZorgTable,
			verantwoordingTable,
			dbAanbiederInit,
			notifications,
			updateClients,
			periods,
			retourcodes,
			redenen,
			valuelists,
			contracts,
			onLine,
			loading,
		} = this.state;
		if (!dbUser) return null;
		const {autorisatie} = dbUser;

		if (dbUser.aanbieder === 0) {
			return <UserChooseProvider dbUser={dbUser} handleChooseAanbieder={this.handleChooseAanbieder} dbAanbiederInit={dbAanbiederInit} />;
		}

		return (
			<Router history={history}>
				<NotificationContainer />
				<Notifications
					notifications={
						[
							...onLine
								? []
								: [
									{
										id: 1,
										type: 'error',
										title: 'Let op: u bent offline',
										message: 'Deze applicatie heeft een internetverbinding nodig',
										timeOut: 0,
										priority: true,
									},
								],
							...Object.values(loading).map(e => ({
								id: e.requestId,
								type: e.method === 'GET' ? 'info' : 'warning',
								title: `Bezig met ${e.method === 'GET' ? 'ophalen' : 'verwerken'} van gegevens`,
								message: 'Dit duurt langer dan verwacht, een ogenblik geduld alstublieft.',
								timeOut: 0,
							})),
						]
					}
				/>
				{form.display && (
					<FormSwitch
						dbUser={dbUser}
						handleForm={this.handleForm}
						form={form.data}
						// For a short term solution, we also need to consider passing the client with the form
						//  data, a long term solution would be implementing the form using portals.
						client={this.client}
						handleVotTable={this.handleVotTable}
						handleVowTable={this.handleVowTable}
						handleStartZorgTable={this.handleStartZorgTable}
						handleStopZorgTable={this.handleStopZorgTable}
						handleVerantwoordingTable={this.handleVerantwoordingTable}
						handleUpdateClientList={this.handleUpdateClientList}
						periods={periods}
						retourcodes={retourcodes}
					/>
				)}
				<header>
					<NavMain dbUser={dbUser} handleChooseAanbieder={this.handleChooseAanbieder} notifications={notifications.data} />
				</header>
				<main className='container'>
					{isAuthorized(autorisatie, 'clienten.tonen') && (
						<ClientSidebar
							dbUser={dbUser}
							collapse={sidebar.collapse}
							handleCollapseSidebar={this.handleCollapseSidebar}
							handleChooseClient={this.handleChooseClient}
							clients={clients}
							gemeentes={gemeentes}
							handleForm={this.handleForm}
							handleUpdateClientList={this.handleUpdateClientList}
							updateClients={updateClients}
						/>
					)}
					<section className={isAuthorized(autorisatie, 'clienten.tonen') ? (sidebar.collapse ? 'content-wide' : 'content') : 'content-wide'}>
						<LogoZorgVerkeerMain />
						<Switch>
							<Route exact path='/instellingen'>
								<UserSettings dbUser={dbUser} user={user} handleUpdateUserData={this.handleUpdateUserData} dbAanbiederInit={dbAanbiederInit} />
							</Route>

							<Route
								exact
								path='/client/:id'
								render={({
									match: {
										params: {id},
									},
								}) => {
									const clientId = Number(id);
									// Handle computed field client
									if (clientId !== this.client?.id) {
										this.client = clientId ? clients.find(c => c.id === clientId) ?? EMPTY_CLIENT : EMPTY_CLIENT;
									}
									if (this.client === EMPTY_CLIENT && !clientId) {
										return (
											<Redirect to='/start' />
										);
									}
									if (this.client === EMPTY_CLIENT) {
										return (
											<ClientNotFound clientId={clientId} dbUser={dbUser} handleChooseAanbieder={this.handleChooseAanbieder}/>
										);
									}
									// Note that client is also used in the form, the form should ideally be closed on any
									//  navigation event.
									return (
										<ClientTab
											client={this.client}
											updateClients={updateClients}
											dbUser={dbUser}
											votTable={votTable}
											resetVotTable={this.resetVotTable}
											vowTable={vowTable}
											resetVowTable={this.resetVowTable}
											toewijzingTable={toewijzingTable}
											resetToewijzingTable={this.resetToewijzingTable}
											startZorgTable={startZorgTable}
											resetStartZorgTable={this.resetStartZorgTable}
											stopZorgTable={stopZorgTable}
											resetStopZorgTable={this.resetStopZorgTable}
											verantwoordingTable={verantwoordingTable}
											resetVerantwoordingTable={this.resetVerantwoordingTable}
											handleRoute={this.handleRoute}
											handleForm={this.handleForm}
											retourcodes={retourcodes}
											redenen={redenen}
											valuelists={valuelists}
											contracts={contracts}
											gemeentes={gemeentes}
											handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
											sidebarTrigger={sidebar.trigger}
										/>
									);
								}}
							/>

							<Route exact path='/start'>
								<Start />
							</Route>

							<Route exact path='/overzicht/clienten'>
								<ListClient
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									sidebarTrigger={sidebar.trigger}
									dbUser={dbUser}
									handleUpdateClientList={this.handleUpdateClientList}
									handleForm={this.handleForm}
									clients={clients}
									retourcodes={retourcodes}
								/>
							</Route>

							<Route exact path='/overzicht/verzoeken-om-toewijzing'>
								<ListAllocationRequest
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									sidebarTrigger={sidebar.trigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
									redenen={redenen}
								/>
							</Route>

							<Route exact path='/overzicht/verzoeken-om-wijziging'>
								<ListChangeRequest
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
									redenen={redenen}
								/>
							</Route>

							<Route exact path='/overzicht/toewijzingen'>
								<ListAllocation
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
								/>
							</Route>

							<Route exact path='/overzicht/verantwoordingen'>
								<ListStatement
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
								/>
							</Route>

							<Route exact path='/overzicht/declaratieregels'>
								<ListInvoiceRule
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
								/>
							</Route>

							<Route exact path='/overzicht/berichten'>
								<ListMessage
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
									clienten={clients}
									valuelists={valuelists}
								/>
							</Route>

							<Route exact path='/beheer'>
								<ManageMain handleChooseAanbieder={this.handleChooseAanbieder} dbUser={dbUser} handleForm={this.handleForm} />
							</Route>

							<Route exact path='/overzicht/declaraties'>
								<ListInvoice
									sidebarTrigger={sidebar.trigger}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									dbUser={dbUser}
									handleForm={this.handleForm}
									retourcodes={retourcodes}
								/>
							</Route>
							<Route exact path='/overzicht/verantwoordingclienten'>
								<ClientsStatements dbUser={dbUser} />
							</Route>
							<Route exact path='/declareren'>
								<InvoiceMain sidebarTrigger={sidebar.trigger} handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger} dbUser={dbUser} handleForm={this.handleForm} />
							</Route>

							<Route exact path='/contract/contractregels'>
								<ContractRuleMain sidebarTrigger={sidebar.trigger} handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger} dbUser={dbUser} handleForm={this.handleForm} />
							</Route>
							<Route exact path='/contract/contractbijwerken'>
								<ContractUpdate
									dbUser={dbUser}
								/>
							</Route>

							<Route exact path='/meldingen/:id'>
								<UserNotification
									dbUser={dbUser}
									notifications={notifications.data}
									checksum={notifications.checksum}
									handleNotificationStatus={this.handleNotificationStatus}
									handleNotificationRemove={this.handleNotificationRemove}
									handleForm={this.handleForm}
									data={notifications}
									clienten={clients}
									handleCollapseSidebarTrigger={this.handleCollapseSidebarTrigger}
									sidebarTrigger={sidebar.trigger}
									aanbieder={dbUser.aanbieder}
									handleChooseAanbieder={this.handleChooseAanbieder}
								/>
							</Route>

							<Redirect to='/start' />
						</Switch>
					</section>
				</main>
			</Router>
		);
	};
}
