import React from 'react';
import notEmpty from '../../util/notEmpty';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import ReactExport from 'react-export-excel';

const ExcelListInvoice = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Declaraties'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Declaraties'>
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Status' value={col => (col.status === 1 ? 'Aangemaakt' : col.status === 2 ? 'Ingediend' : col.status === 3 ? 'Goedgekeurd' : col.status === 4 ? 'Afgekeurd' : '')} />
				<ExcelColumn label='Periode' value='periode' />
				<ExcelColumn label='Ingediend' value='ingediend' />
				<ExcelColumn label='Toegekend' value='toegekend' />
				<ExcelColumn label='Factuur' value='factuurnummer' />
				<ExcelColumn label='Datum en tijd' value={col => (notEmpty(col.status_ts) ? formatDate(col.status_ts, 2) + ', ' + formatTime(col.status_ts, 2) : '')} />
				<ExcelColumn label='Retourcode' value='retourcode' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelListInvoice;
