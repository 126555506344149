import { referenceEquals } from './equals';

export default function findAndDelete<A extends any[]>(
	array: A,
	value: A[number],
	equal: (a: A[number], b: A[number]) => boolean = referenceEquals,
): boolean {
	const index = equal === referenceEquals ? array.indexOf(value) : array.findIndex(e => equal(e, value));
	if (index >= 0) {
		array.splice(index, 1);
		return true;
	}
	return false;
}
