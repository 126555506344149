import React, {useEffect, useState} from 'react';

interface Attachment {
	naam: string
	inhoud: string | BlobPart
	schermnaam?: string
	symbool?: boolean
}

const Attachment = ({naam, inhoud, schermnaam = naam, symbool = true}: Attachment) => {
	const [download, setDownload] = useState({data: '', icon: ''});

	useEffect(() => {
		const ext = naam.split('.');
		let data:Blob;
		let icon: string;
		switch (ext[1]) {
			case 'xml':
				data = new Blob([inhoud], {type: 'application/xml'});
				icon = 'xml';
				break;
			case 'json':
				data = new Blob([inhoud], {type: 'application/json'});
				icon = 'json';
				break;
			default:
				data = new Blob([inhoud], {type: 'text/plain'});
				icon = 'default';
				break;
		}
		const url = window.URL.createObjectURL(data);
		setDownload({data: url, icon: icon});
		return () => {
			window.URL.revokeObjectURL(url);
		};
	}, [naam, inhoud, schermnaam, symbool]);

	return (
		<a download={naam} href={download['data']} className={`link${symbool ? ' ' + download['icon'] : ''}`}>
			{schermnaam ? schermnaam : naam}
		</a>
	);
};

export default Attachment;
