import styles from './index.module.css';

interface TableRowProps {
	children: React.ReactNode;
	rowStyle: 'regular' | 'fout'
}

const TableRow: React.FC<TableRowProps> = ({children, rowStyle = 'regular'}) => {
	return (
		<tr className={styles[rowStyle]}>
			{children}
		</tr>
	);
};

export default TableRow;
