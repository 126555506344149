import VerzoekOmToewijzing from '../types/VerzoekOmToewijzing';
import { Get, Post } from './helpers/builder';

export const votList = 
	Get`/lists/providers/${'provider'}/list/allocreqs`.json<{ response: VerzoekOmToewijzing[] }>();
	
export const votListInProgress =
	Get`/allocreqs/providers/${'provider'}/clients/${'client'}/status`.json<{ response: VerzoekOmToewijzing[] }>();
export const votCheckDuplicateProduct = 
	Post<{
		product: string | null,
		categorie_code: string | null,
		begindatum: string,
		einddatum: string,
		allocreq: number | null,
		wet: string,
	}>()`/allocreqs/providers/${'provider'}/clients/${'client'}/conflict-check`.json<{response: unknown}>();
