/**
 *  Format currency with 1000’s separator
 */
const formatCurrency = (number: number) => {
	const value1 = number / 100;
	const value2 = value1.toLocaleString('nl-NL', {currency: 'EUR', style: 'currency'});
	return value2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export default formatCurrency;
