import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import sortArray from '../../util/sortArray';
import StatusCodeChangeRequest from '../element/statuscode/StatusCodeChangeRequest';
import notEmpty from '../../util/notEmpty';
import api from '../../util/apiRequest';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';

export default class ClientChangeRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Actie',
					figure: 'action-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				2: {
					name: 'In behandeling',
					figure: 'in-progress-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				3: {
					name: 'Historie',
					figure: 'history-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: true,
					left_hint: false,
				},
			},
			vows: this.props.data,
			client: this.props.client,
			count: 0,
			trigger: 0,
			loaded2: false,
			locked: false,
			locked2: false,
			lockedByMissingContracts: !this.props.contractData.find(c => c.gemeente_code === this.props.client.gemeente_code),
			thissidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleReden = reden => {
		const codes = reden.split(',');
		const redenen = this.props.redenen;
		const message = codes.map(row => {
			return redenen
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
                                Reden voor afwijzing verzoek: <strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: 'Reden afwijzing verzoek',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getVotInProgress = await api.getVotInProgress(aanbieder, this.props.client.id, api.makeHeader(token));
		if (getVotInProgress.error && getVotInProgress.error === true) {
			this.props.handleForm(getVotInProgress);
			return null;
		}

		const getCheckToewijzing = await api.getCheckToewijzing(aanbieder, this.props.client.id, api.makeHeader(token));
		if (getCheckToewijzing.error && getCheckToewijzing.error === true) {
			this.props.handleForm(getCheckToewijzing);
			return null;
		}

		this.setState(state => {
			state.loaded2 = true;
			state.locked2 = getVotInProgress.response;
			state.hastoewijzing = getCheckToewijzing.response;
		});

		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = () => {
		const {update, trigger} = this.props.vowTable;
		const {count, sidebarTrigger} = this.state;

		if (this.state.trigger !== this.props.trigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		if (sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		if (trigger !== count && update !== undefined) {
			if (update.formid === 'vow-toevoegen') {
				let index = this.state.vows.findIndex(row => {
					return row.id === Number(update.id);
				});

				this.setState(state => {
					state.count = trigger;
					state.vows.push(update.response);
					if (index >= 0) state.vows[index].status = 8;
					return state;
				});

				if (update.next === 'vow-toevoegen') {
					this.handleAdd(null, update.table, 1);
				}

				this.props.resetVowTable();
			} else if (update.formid === 'vow-verzenden') {
				this.setState(state => {
					state.count = trigger;
					state.vows = update.response;

					return state;
				});

				this.props.resetVowTable();
			} else if (update.formid === 'vow-wijzigen') {
				let index = this.state.vows.findIndex(row => {
					return row.id === update.id;
				});

				this.setState(state => {
					state.count = trigger;
					state.vows[index] = update.response;
					return state;
				});

				if (update.next === 'vow-toevoegen') {
					this.handleAdd(null, update.table, 0);
				}

				this.props.resetVowTable();
			} else if (update.formid === 'vow-verwijderen') {
				const index = this.state.vows.findIndex(row => {
					return row.id === update.id;
				});
				const select = this.state.tables[1].select.filter(row => {
					return row !== update.id;
				});
				this.setState(state => {
					state.count = trigger;
					state.vows[index].verwijderd = 1;
					state.vows[index].status = 8;
					state.tables[1].select = select;
					return state;
				});
				this.props.resetVowTable();
			}
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	updateScroll = () => {
		const tables = [1, 2, 3];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSelect = (id, table) => {
		this.setState(state => {
			let {select} = state.tables[table];
			const index = select.findIndex(row => {
				return row === id;
			});
			if (index === -1) {
				state.tables[table].select.push(id);
				return state;
			}
			state.tables[table].select = select.filter(row => {
				return row !== id;
			});
			return state;
		});
	};

	handleCheckSelect = (id, table) => {
		const {select} = this.state.tables[table];
		return select.includes(id);
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleSelectAll = table => {
		this.setState(state => {
			const {vows} = this.state;
			const ids = vows
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => row.id);
			if (ids.length === state.tables[table].select.length) {
				state.tables[table].select = [];
			} else {
				state.tables[table].select = ids;
			}
			return state;
		});
	};

	handleAdd = (event, table, add, id) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = id !== undefined ? this.handleGetRow(id) : {};
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.vow_id = id;
		this.props.handleForm({formid: 'vow-toevoegen', data: data});
	};

	handleGetRow = id => {
		return this.props.data.find(row => row.id === id);
	};

	handleEdit = (event, id) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.locked = this.state.locked2;
		data.hastoewijzing = this.state.hastoewijzing;
		this.props.handleForm({formid: 'vow-wijzigen', data: data});
	};

	handleShow = (event, id) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.locked = this.state.locked2;
		data.hastoewijzing = this.state.hastoewijzing;
		data.readOnly = true;
		this.props.handleForm({formid: 'vow-wijzigen', data: data});
	};

	handleRemove = (event, id) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '40vw';
		data.client = this.state.client;
		data.vowCount = 0;
		data.vowList = [];
		data.vowTable = 0;
		data.locked = this.state.locked2;
		data.hastoewijzing = this.state.hastoewijzing;
		this.props.handleForm({formid: 'vow-verwijderen', data: data});
	};

	handleStatus = status => {
		const legend = [
			{
				status: 1,
				description: 'Verzoek om wijziging is aangemaakt, maar nog niet door u ingediend.',
				label: 'Aangemaakt',
			},
			{
				status: 2,
				description: 'Verzoek om wijziging is ingediend; bericht is verzonden naar de betreffende gemeente.',
				label: 'Ingediend',
			},
			{
				status: 3,
				description: 'Verzoek om wijziging is goedgekeurd.',
				label: 'Goedgekeurd',
			},
			{
				status: 4,
				description: 'Verzoek om wijziging is afgekeurd; zie retourcode voor de reden tot afkeur.',
				label: 'Afgekeurd',
			},
			{
				status: 5,
				description: 'Verzoek om wijziging is toegewezen.',
				label: 'Toegewezen',
			},
			{
				status: 6,
				description: 'Verzoek om wijziging is afgewezen; zie retourcode of reden voor afwijzing.',
				label: 'Afgewezen',
			},
			{
				status: 7,
				description: 'Verzoek om wijziging is in onderzoek; zie reden voor onderzoek.',
				label: 'In onderzoek',
			},
			{
				status: 8,
				description: 'Verzoek om wijziging is gearchiveerd; door u of door een automatisch proces binnen de applicatie.',
				label: 'Gearchiveerd',
			},
		];

		const message = legend.map(item => {
			return (
				<p key={item.status} className='legend'>
					{status === item.status ? (
						<div className='active'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					) : (
						<div className='inactive'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					)}
				</p>
			);
		});

		confirmAlert({
			title: 'Status',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleModal = () => {
		this.setState(state => {
			state.modal = !state.modal;
			return state;
		});
	};

	handleShowHideTable = table => {
		this.setState(state => {
			state.tables[table].hidden = !state.tables[table].hidden;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		let {loaded} = this.props;
		const {vows, tables, loaded2, locked2, lockedByMissingContracts} = this.state;

		if (!loaded || !loaded2) {
			return <Loading message='' />;
		}

		const table1 = vows.filter(row => {
			return (row.status === 1 || row.status === 4 || row.status === 6) && row.verwijderd === 0;
		});
		const data1 = sortArray(table1, tables[1].order, tables[1].column);

		const table2 = vows.filter(row => {
			return (row.status === 2 || row.status === 3 || row.status === 7) && row.verwijderd === 0;
		});
		const data2 = sortArray(table2, tables[2].order, tables[2].column);

		const table3 = vows.filter(row => {
			return row.status === 5 || row.verwijderd === 1 || row.status === 8;
		});
		const data3 = sortArray(table3, tables[3].order, tables[3].column);

		const data4 = vows.findIndex(row => {
			return row.status === 2;
		});
		const locked = data4 !== -1 ? true : false;

		const noHover = {
			backgroundColor: 'transparent',
		};

		let error;
		if (lockedByMissingContracts) {
			error = 'U kunt op dit moment geen verzoek om wijziging (VOW) aanmaken, omdat er voor deze gemeente geen geldige contracten bekend zijn.';
		} else if (locked2) {
			error = 'U kunt op dit moment geen verzoek om wijzing (VOW) indienen, omdat er een verzoek om toewijzing (VOT) is ingediend.';
		} else if (locked) {
			error = 'U kunt op dit moment geen verzoek om wijziging (VOW) aanmaken, omdat er al een verzoek ingediend of in behandeling is.';
		} else if (this.state.hastoewijzing !== true) {
			error = 'U kunt op dit moment geen verzoek om wijziging (VOW) aanmaken, omdat er voor deze cliënt geen actieve toewijzing is.';
		} else {
			error = '';
		}

		return (
			<>
				<section className='client-data'>
					<section className='table-element'>
						{error && <section className='table-header error-color'>{error}</section>}

						<section className='table-header'>
							<figure className={tables[1].figure}></figure>
							<h2>{tables[1].name}</h2>
							<button
								disabled={data1.length > 0 || locked || locked2 || lockedByMissingContracts || !this.state.hastoewijzing ? true : false}
								className='add'
								onClick={event => this.handleAdd(event, 1, 0)}></button>
						</section>
						{data1.length !== 0 ? (
							<>
								<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
									<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
									<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
									<table className='data-table'>
										<tbody>
											<tr>
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status' label='Status' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status_reden' label='Reden' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='retourcodes' label='Retourcodes' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='reden_label' label='Reden' />
												<TableColumnHeader
													handleSort={this.handleSort}
													column={tables[1].column}
													order={tables[1].order}
													table='1'
													data='aantal_toewijzingen'
													label='Toewijzingen'
												/>
												<TableColumnHeader
													handleSort={this.handleSort}
													column={tables[1].column}
													order={tables[1].order}
													table='1'
													data='aantal_ongewijzigd'
													label='Ongewijzigd'
												/>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_gewijzigd' label='Gewijzigd' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_nieuw' label='Nieuw' />
											</tr>

											{data1.map(row => (
												<tr key={row.id} style={noHover}>
													<td>
														<button
															className='button edit'
															onClick={row.status === 4 || row.status === 6 ? event => this.handleAdd(event, 1, 0, row.id) : event => this.handleEdit(event, row.id)}
														></button>

														<button
															className='button remove'
															onClick={event => this.handleRemove(event, row.id)}
															disabled={row.status === 1 || row.status === 4 || row.status === 6 ? false : true}></button>
													</td>
													<td>{<StatusCodeChangeRequest status={row.status} />}</td>
													<td>
														<button
															className='button info'
															onClick={() => this.handleStatus(row.status)}
															disabled={notEmpty(row.status) ? false : true}></button>
													</td>
													<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
													<td width={1}>
														<button
															className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleReden(row.status_reden)}
															disabled={notEmpty(row.status_reden) ? false : true}></button>
													</td>
													<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>
													<td width={1}>
														<button
															className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleRetourcode(row.retourcodes)}
															disabled={notEmpty(row.retourcodes) ? false : true}></button>
													</td>
													<td>{notEmpty(row.reden_label) ? row.reden_label : <label>Geen data</label>}</td>
													<td>{notEmpty(row.aantal_toewijzingen) ? row.aantal_toewijzingen : <label>Geen data</label>}</td>
													<td>{notEmpty(row.aantal_ongewijzigd) ? row.aantal_ongewijzigd : <label>Geen data</label>}</td>
													<td>{notEmpty(row.aantal_gewijzigd) ? row.aantal_gewijzigd : <label>Geen data</label>}</td>
													<td>{notEmpty(row.aantal_nieuw) ? row.aantal_nieuw : <label>Geen data</label>}</td>
												</tr>
											))}
										</tbody>
									</table>
								</section>
							</>
						) : (
							<section className='table-empty'>Er is nog geen verzoek om wijziging aangemaakt</section>
						)}
					</section>
				</section>

				<section className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[2].figure}></figure>
							<h2>{tables[2].name}</h2>
						</section>
						{data2.length !== 0 ? (
							<section id='table2' className='table-scroll-x' onScroll={() => this.handleScroll(2)}>
								<aside className={tables[2].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(2)}></aside>
								<aside className={tables[2].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(2)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status' label='Status' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status_reden' label='Reden' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='retourcodes' label='Retourcodes' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='reden_label' label='Reden' />
											<TableColumnHeader
												handleSort={this.handleSort}
												column={tables[2].column}
												order={tables[2].order}
												table='2'
												data='aantal_toewijzingen'
												label='Toewijzingen'
											/>
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='aantal_ongewijzigd' label='Ongewijzigd' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='aantal_gewijzigd' label='Gewijzigd' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='aantal_nieuw' label='Nieuw' />
										</tr>

										{data2.map(row => (
											<tr key={row.id} style={noHover}>
												<td>
													<button className={'button read'} onClick={event => this.handleShow(event, row.id)}></button>

													<button className={'button remove disabled'} disabled={true}></button>
												</td>
												<td>{<StatusCodeChangeRequest status={row.status} />}</td>
												<td>
													<button
														className={notEmpty(row.status) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleStatus(row.status)}
														disabled={notEmpty(row.status) ? false : true}></button>
												</td>
												<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
												<td width={1}>
													<button
														className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleReden(row.status_reden)}
														disabled={notEmpty(row.status_reden) ? false : true}></button>
												</td>
												<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>
												<td width={1}>
													<button
														className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleRetourcode(row.retourcodes)}
														disabled={notEmpty(row.retourcodes) ? false : true}></button>
												</td>
												<td>{notEmpty(row.reden_label) ? row.reden_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_toewijzingen) ? row.aantal_toewijzingen : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_ongewijzigd) ? row.aantal_ongewijzigd : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_gewijzigd) ? row.aantal_gewijzigd : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_nieuw) ? row.aantal_nieuw : <label>Geen data</label>}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						) : (
							<section className='table-empty pb2'>Er is nog geen verzoek om wijziging in behandeling</section>
						)}
					</section>
				</section>
				<section className='client-data'>
					<button className={'btn-unhide'} onClick={() => this.handleShowHideTable(3)}>
						{tables[3].hidden ? 'Toon historie' : 'Verberg historie'}
					</button>
				</section>
				<section className={tables[3].hidden ? 'client-data table-hidden' : 'client-data'}>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[3].figure}></figure>
							<h2>{tables[3].name}</h2>
						</section>
						{data3.length !== 0 ? (
							<section id='table3' className='table-scroll-x' onScroll={() => this.handleScroll(3)}>
								<aside className={tables[3].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(3)}></aside>
								<aside className={tables[3].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(3)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status' label='Status' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='status_reden' label='Reden' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='retourcodes' label='Retourcodes' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='reden_label' label='Reden verzoek' />
											<TableColumnHeader
												handleSort={this.handleSort}
												column={tables[3].column}
												order={tables[3].order}
												table='3'
												data='aantal_toewijzingen'
												label='Toewijzingen'
											/>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='aantal_ongewijzigd' label='Ongewijzigd' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='aantal_gewijzigd' label='Gewijzigd' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='aantal_nieuw' label='Nieuw' />
										</tr>

										{data3.map(row => (
											<tr key={row.id} style={noHover}>
												<td>
													<button className={'button read'} onClick={event => this.handleShow(event, row.id)}></button>

													<button className={'button remove disabled'} disabled={true}></button>
												</td>
												<td>{<StatusCodeChangeRequest status={row.status} />}</td>
												<td>
													<button
														className={notEmpty(row.status) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleStatus(row.status)}
														disabled={notEmpty(row.status) ? false : true}></button>
												</td>
												<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
												<td width={1}>
													<button
														className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleReden(row.status_reden)}
														disabled={notEmpty(row.status_reden) ? false : true}></button>
												</td>
												<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>
												<td width={1}>
													<button
														className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleRetourcode(row.retourcodes)}
														disabled={notEmpty(row.retourcodes) ? false : true}></button>
												</td>
												<td>{notEmpty(row.reden_label) ? row.reden_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_toewijzingen) ? row.aantal_toewijzingen : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_ongewijzigd) ? row.aantal_ongewijzigd : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_gewijzigd) ? row.aantal_gewijzigd : <label>Geen data</label>}</td>
												<td>{notEmpty(row.aantal_nieuw) ? row.aantal_nieuw : <label>Geen data</label>}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						) : (
							<section className='table-empty pb2'>Er is nog geen historie van verzoeken om wijziging</section>
						)}
					</section>
				</section>
			</>
		);
	};
}
