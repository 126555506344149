/**
 *  Format dates to string with various options
 * @param date The ISO date or a date object
 * @param type
 * If 1, then YYYY-MM-DD (ISO standard)
 * If 2, (default) then DD-MM-YYYY (dutch format)
 * If 3, then YYYY-MM (period format)
 * If 4, then YYYY-MM-DD (Note, 120 is subtracted from the year)
 * If 5, then DD MM YYYY 
 */
const formatDate = (date: string | Date, format: 1 | 2 | 3 | 4 | 5 = 2): string => {
	if (date === null) throw new Error('date is not a string');
	const newDate = new Date(date); // Always make a copy here, we don't want to accidentally change our input

	// minimum client date of birth
	if (format === 4) {
		newDate.setFullYear(newDate.getFullYear() - 120); // Why?
		format = 1;
	}

	const year = newDate.getFullYear();
	const month = newDate.getMonth() + 1;
	const day = newDate.getDate();
	const monthName = newDate.toLocaleString('nl-NL', {month: 'long'});

	const daystr = day < 10 ? '0' + day : day;
	const monthstr = month < 10 ? '0' + month : month;
	const yearstr = year < 10 ? '000' + year : year < 100 ? '00' + year : year < 1000 ? '0' + year : year;

	// database and calc date format
	if (format === 1) {
		return `${yearstr}-${monthstr}-${daystr}`;
	}

	// year and month
	if (format === 3) {
		return `${yearstr}-${monthstr}`;
	}

	// dutch date format with month as name
	if (format === 5) {
		return `${daystr} ${monthName} ${yearstr}`;
	}

	// dutch date format, default or format === 2
	return `${daystr}-${monthstr}-${yearstr}`;
};

export default formatDate;
