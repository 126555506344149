import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { AlertBox, DataTable, MainContainer } from '../../components/ui';
import { dtConstants } from '../../components/ui/TableComponents/constants';
import { DataTableOptions, SelectableData, TableColumnDefinition } from '../../components/ui/TableComponents/types';
import { getClientVerantwoordingListQuery, updateClientVerantwoordingListQuery } from '../../requests';
import { ClientVerantwoording, DbUser } from '../../types';
import { ClientStatementsForm, ClientsStatementsFilter } from '../../components/business';

interface ClientsStatementsProps {
	dbUser: DbUser,
}

const ClientsStatements: React.FC<ClientsStatementsProps> = ({dbUser}): JSX.Element => {
	// main dataset state
	const [clientsVerantwoording, setClientsVerantwoording] = useState<ClientVerantwoording[]>();

	// filter related states
	const [gemeenteUniqueList, setGemeenteUniqueList] = useState<ClientVerantwoording['gemeente_naam'][]>();
	const [selecedFilterGemeente, setSelectedFilterGemeente] = useState<string | undefined>(undefined);

	const [clientNamesUniqueList, setClientNamesUniqueList] = useState<ClientVerantwoording['client_naam'][]>();
	const [selectedFilterClient, setSelectedFilterClient] = useState<string | undefined>(undefined);

	const [productUniqueList, setProductUniqueList] = useState<ClientVerantwoording['product'][]>();
	const [selectedFilterProduct, setSelectedFilterProduct] = useState<string | undefined>(undefined);

	// submit related states
	const [responseStatus, setResponseStatus] = useState<number | undefined>();
	const responseMessage = responseStatus !== undefined && responseStatus == 200 ? dtConstants.messages.success 
		: responseStatus !== undefined && responseStatus !== 200 ? dtConstants.messages.error : undefined;
   
	const [showAlertBox, setShowAlertBox] = useState<boolean>(false);
	// Alert box handlers
	const closeAlertBoxHandler = () => {
		// setResponseStatus(undefined);
		setShowAlertBox(false);
	};


	// API Reuest handlers
	const getClientVerantwoording = async () => {
		const data = await getClientVerantwoordingListQuery.execute({token: dbUser.token, aanbieder_id: dbUser.aanbieder});
		const { response } = data;
		const gemeenteFilterData = [... new Set(response.map((item) => item.gemeente_naam))];
		const clientNamesFilterData = [... new Set(response.map((item) => item.client_naam))];
		const productFilterData = [... new Set(response.map((item) => item.product))];
		setClientsVerantwoording(response);
		setGemeenteUniqueList(gemeenteFilterData);
		setClientNamesUniqueList(clientNamesFilterData);
		setProductUniqueList(productFilterData);
	};

	useEffect(()=>{
		const controller = new AbortController();

		if (!controller.signal.aborted) {
			getClientVerantwoording();
		}

		return () => controller.abort();
	},[]);

	// Page filters selection handlers
	const gemeenteFilterSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon Alle') {
			setSelectedFilterGemeente(undefined);
		} else {
			setSelectedFilterGemeente(e.target.value);
		}
	};

	const clientFilterSelectionHAndler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon Alle') {
			setSelectedFilterClient(undefined);
		} else {
			setSelectedFilterClient(e.target.value);
		}
	};

	const productFilterSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e.target.value == 'Toon Alle') {
			setSelectedFilterProduct(undefined);
		} else {
			setSelectedFilterProduct(e.target.value);
		}
	};

	const pageFilters = {
		gemeente_naam: selecedFilterGemeente,
		client_naam: selectedFilterClient,
		product: selectedFilterProduct, 
	};

	const submitChangeHandler = async (changedData: SelectableData<ClientVerantwoording>[]): Promise<boolean> => {
		confirmAlert({
			title: 'Wijzigingen Bevestigen',
			message: `U heeft wijzigingen aangebracht op ${changedData.length} ${changedData.length > 1 ? 'verantwoordingen' : 'verandwoording'}, 
			weet u zeker dat u deze ${changedData.length > 1 ? 'wijzigingen' : 'wijziging'} wilt doorgeven`,
			buttons: [
				{
					label: 'Ja',
					onClick: async () => {
						const result = await updateClientVerantwoordingListQuery.execute({ 
							token: dbUser.token,
							aanbieder_id: dbUser.aanbieder,
							body: changedData,
						});
						setClientsVerantwoording(result.result);
						setResponseStatus(result.status);
						setShowAlertBox(true);
					},
				},
				{
					label: 'Nee',
					onClick: () => null,
				},
			],
		});
		return true;
	};

	const columns: TableColumnDefinition<ClientVerantwoording, keyof ClientVerantwoording>[] = [
		{name: 'Client', key: 'client_naam', columnType: 'Text'},
		{name: 'BSN', key: 'bsn', columnType: 'Text'},
		{name: 'Gemeente', key: 'gemeente_naam', columnType: 'Text'},
		{name: 'Toewijzingnummer', key: 'toewijzing_nummer', columnType: 'Text'},
		{name: 'Toewijzing begindatum', key: 'toewijzing_begindatum', columnType: 'Date'},
		{name: 'Toewijzing einddatum', key: 'toewijzing_einddatum', columnType: 'Date'},
		{name: 'Product', key: 'product', columnType: 'List', columnListValues: ['OZL2V', 'ABL233']},
		{name: 'Eenheid', key: 'eenheid', columnType: 'Text'},
		{name: 'Verantwoording begindatum', key: 'verantwoording_begindatum', columnType: 'Date'},
		{name: 'Verantwoording einddatum', key: 'verantwoording_einddatum', columnType: 'Date'},
		{name: 'Volume', key: 'volume', columnType: 'Number', isEditable: true, 
			validations: {
				min:1,
			}},
	];
	const tableOptions: DataTableOptions<ClientVerantwoording> = {
		addNewEntries: 'complex',
		FormToRender: ClientStatementsForm,
		cascadeParameters: false,
		indexable: true,
		selectable: true,
		updateAndReset: true,
		resetAllCallback: getClientVerantwoording,
	};

	return (
		<MainContainer>
			{showAlertBox && (
				<AlertBox
					alertType={responseStatus ? dtConstants.alertMap[responseStatus] : undefined}
					message={responseMessage}
					autoFocus
					onClose={closeAlertBoxHandler}
				/>
			)}
			<ClientsStatementsFilter 
				title='Cliënten Verantwoordingen'
				gemeenteFilterList={gemeenteUniqueList}
				clientsFilterList={clientNamesUniqueList}
				productsFilterList={productUniqueList}
				onGemeenteSelection={gemeenteFilterSelectionHandler}
				onClinetSelection={clientFilterSelectionHAndler}
				onProductSelection={productFilterSelectionHandler}
			/>
			{clientsVerantwoording && (
				<DataTable 
					columns={columns}
					data={clientsVerantwoording}
					options={tableOptions}
					filters={pageFilters}
					onSubmitChanges={submitChangeHandler}
					updateStatus={responseStatus}
					dbUser={dbUser}
				/>
			)}
		</MainContainer>
	);
};

export default ClientsStatements;
