interface InputTextProp {
	id?: string;
	onChange: (v: string) => void;
	className: string;
	value?: string;
	disabled?: boolean
}


const InputText: React.FC<InputTextProp> = ({id, onChange, className, value, disabled = false}) => {
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
	};
	return (
		<input type='text'
			id={id}
			onChange={onChangeHandler}
			className={className}
			value={value}
			disabled={disabled}
		/>
	);
};

export default InputText;
