import { API_BASE_URL } from '../../configuration';
import { Values } from '../../types';
import assertNever from '../../util/assertNever';
import { ApiCaller } from './ApiCaller';
import doRequest from './apiRequest';

/**
 * Extra options to set when handling a request
 */
interface RequestOptions {
	/**
	 * Checks the status code of a request
	 * 
	 * If a boolean of true is passed in, it allows any 2xx status code
	 * 
	 * If a boolean of false is passed in, it does not check the status code
	 * 
	 * If an array of numbers is passed in, it allows status codes in that list
	 * 
	 * if an function is passed, the function needs to return true for success. The function is also allowed to throw an error directly.
	 * 
	 * Defaults to true
	 */
	checkStatus?: boolean | number[] | ((status: number, response: Response) => boolean | void)
	/**
	 * Sets the accept header send with the request
	 */
	acceptHeader?: string | undefined | null
}
interface PartialApiBuilder<I> {
	json<O>(): (response: Response, input: I) => Promise<O>
	text(): (response: Response, input: I) => Promise<string>
	expectSuccess(): (response: Response, input: I) => true
	returnOk(): (response: Response, input: I) => boolean
	blob(): (response: Response, input: I) => Promise<Blob>
	custom<O>(
		callback: (response: Response, input: I) => O,
	): (response: Response, input: I) => Promise<Awaited<O>>
}
/**
 * A partially build api caller, call one of its function to get an apicaller object
 */
interface PartialApiCaller<I> {
	/**
	 * Marks this endpoint as returning a json object
	 * @param options Optional extra options
	 */
	json<O>(options?: RequestOptions): ApiCaller<I, O>
	/**
	 * Marks this endpoint as returning a text
	 * @param options Optional extra options
	 */
	text(options?: RequestOptions): ApiCaller<I, string>
	/**
	 * Marks this endpoint as returning a blob
	 * @param options Optional extra options
	 */
	blob(options?: RequestOptions): ApiCaller<I, Blob>
	/**
	 * Add custom request handling code
	 * @param options Optional extra options
	 */
	custom<O>(
		callback: (response: Response, input: I) => O,
		options?: RequestOptions
	): ApiCaller<I, Awaited<O>>
	/**
	 * Expect the endpoint to succeed, without caring about the body. Customize the checkStatus option for more status checks
	 * @param options Optional extra options
	 */
	expectSuccess(options?: RequestOptions): ApiCaller<I, true>
	/**
	 * Returns the ok key of the final response
	 * @param options Optional extra options
	 */
	returnOk(options?: RequestOptions): ApiCaller<I, boolean>
	/**
	 * Maps different status codes to different response bodies
	 * @param factory 
	 * @param options
	 * @example
	 * .status(f => ({
	 *     200: f.json<SuccessResponse>(),
	 *     422: f.json<BadEntityResponse>(),
	 *     400: f.json<BadRequestResponse>(),
	 * }))
	 */
	status<M extends Record<number, (response: Response, input: I) => unknown> = never>(
		factory: (builder: PartialApiBuilder<I>) => M,
		options?: Omit<RequestOptions, 'checkStatus'>,
	): ApiCaller<I, Values<{
		[K in keyof M]: { status: K, result: Awaited<ReturnType<M[K extends string | symbol ? never : K]>>}
	}>>
}

/**
 * Internal function to check the response depending on the state of the checkStatus flag in the request options
 * @param response The response
 * @param checkStatus The checkStatus value
 * @returns true if the request is accepted, false if not, or an e
 */
function doCheckStatus(response: Response, checkStatus: RequestOptions['checkStatus'] = true): void {
	switch (typeof checkStatus) {
		case 'boolean':
			if (checkStatus) {
				if (!response.ok) {
					throw new Error(`Failed to load ${response.url}: ${response.status} ${response.statusText} is not 2xx`);
				}
			}
			break;
		case 'function':
			if (!(checkStatus(response.status, response) ?? true)) {
				throw new Error(`Failed to load ${response.url}: ${response.status} ${response.statusText} was not allowed by the custom check`);
			}
			break;
		case 'object':
			if (!checkStatus.includes(response.status)) {
				throw new Error(`Failed to load ${response.url}: ${response.status} ${response.statusText} was not in the allowed list of ${checkStatus}`);
			}
			break;
		default:
			return assertNever(checkStatus);
	}
}
/**
 * A wrapper that allows you to finalize an api returning a Response to an fully typed api
 * @param backend A function returning a promise
 * @param wrap A wrapper function for attaching the extra fields to the apiCaller after the user has specified the output type
 * @returns The partial api
 */
function partialApiCallerBuilder<I>(
	backend: (
		input: I,
		acceptHeader: string | null | undefined,
		fetchOptions: Parameters<ApiCaller<I, unknown>['execute']>[1]
	) => Promise<Response>,
	wrap: <O>(base: Pick<ApiCaller<I, O>, 'execute'>) => ApiCaller<I, O>,
): PartialApiCaller<I> {
	function wrapFully<O>(
		finalizer: (response: Response, input: I) => Promise<O> | O,
		{checkStatus, acceptHeader}: RequestOptions = {},
	) {
		return wrap({
			execute: async (input, fetchOptions) => {
				// Call the sending code
				const response = await backend(input, acceptHeader, fetchOptions);
				// Check status
				doCheckStatus(response, checkStatus);
				// Run the response mapper
				return finalizer(response, input);
			},
		});
	}

	const builder: PartialApiBuilder<I> = {
		blob: () => r => r.blob(),
		custom: (callback) => (r, i) => Promise.resolve(callback(r, i)),
		json: () => r => {
			const contentType = r.headers.get('content-type');
			if (!contentType || (contentType !== 'application/json' && !contentType.startsWith('application/json;'))) {
				return Promise.reject('Request returned non json: ' + contentType);
			}
			return r.json();
		},
		text: () => r => r.text(),
		expectSuccess: () => () => true,
		returnOk: () => r => r.ok,
	};

	return {
		blob: options => wrapFully(builder.blob(), options),
		custom: (callback, options) => wrapFully(builder.custom(callback), options),
		json: (options = {}) => wrapFully(builder.json(), {
			acceptHeader: 'application/json',
			...options,
		}),
		text: (options = {}) => wrapFully(builder.text(), {
			acceptHeader: 'text/*, application/json',
			...options,
		}),
		expectSuccess: options => wrapFully(builder.expectSuccess(), options),
		returnOk: (options = {}) => wrapFully(builder.returnOk(), { checkStatus: false, ...options}),
		status: (mapFactory, options = {}) => {
			const buildStatusMap = mapFactory(builder);
			return wrapFully(async (response, input) => {
				const mapping = buildStatusMap[response.status];
				if (!mapping) throw new Error(`Failed to load ${response.url}: ${response.status} ${response.statusText} was not defined in the list of responses for this endpoint: ${Object.keys(buildStatusMap)}`);
				return { status: response.status, result: await mapping(response, input) as any };
			}, { checkStatus: false, ...options});
		},
	};
}

type TokensToInput<V extends string> = { [K in V]: string | number | boolean };

/**
 * Converts a template string to a request
 * @param literals The template string parts
 * @param placeholders The place holders inside the template string
 * @param method The HTTP method
 * @param alter Any other request changes that need to happen (like the body/headers)
 * @returns A partially build api request
 */
function fromTemplateString<V extends string, B = {}>(
	literals: TemplateStringsArray,
	placeholders: V[],
	method: string,
	urlPrefix: string,
	alter?: {
		body?: (input: { token: string } & TokensToInput<V> & B, headers: Headers, url: URL) => BodyInit | null | undefined,
		headers?: (headers: Headers, url: URL) => void;
		request?: (request: Request) => Request | undefined;
		url?: (url: URL) => void;
		requestInit?: (init: RequestInit) => RequestInit
	},
) {
	type I = { token: string } & TokensToInput<V> & B;
	return partialApiCallerBuilder((input: I, acceptHeader, { signal, retry } = {}) => {
		// This is the actual sending code
		let url = urlPrefix + literals[0];
		let hasSeenQuestionMark = url.includes('?');
		for (let i = 0; i < placeholders.length; i++) {
			const asString = `${input[placeholders[i] as never]}`;
			url += hasSeenQuestionMark ? encodeURIComponent(asString) : encodeURI(asString).replace(/\//g, '%2F');
			url += literals[i + 1];
			hasSeenQuestionMark = hasSeenQuestionMark || literals[i + 1].includes('?');
		}
		const base = new URL(API_BASE_URL);
		const u = new URL(base.pathname + url, base);
		alter?.url?.(u);

		const headers = new Headers();
		if (input.token) headers.set('authorization', 'Bearer ' + input.token);
		if (acceptHeader) headers.set('accept', acceptHeader);

		alter?.headers?.(headers, u);

		const init = { headers, body: alter?.body?.(input, headers, u), signal, method } satisfies RequestInit;

		const request = new Request(u.toString(), alter?.requestInit?.(init) ?? init);

		return doRequest(alter?.request?.(request) ?? request, { retry });
	}, <O>(base: Pick<ApiCaller<I, O>, 'execute'>): ApiCaller<I, O> => ({
		...base,
		toString: () => {
			let url = method + ':' + urlPrefix + literals[0];
			let hasSeenQuestionMark = url.includes('?');
			for (let i = 0; i < placeholders.length; i++) {
				url += '{' + placeholders[i] + '}';
				url += literals[i + 1];
				hasSeenQuestionMark = hasSeenQuestionMark || literals[i + 1].includes('?');
			}
			return url;
		},
		getKey(input) {
			const key = [urlPrefix, literals[0]];
			let hasSeenQuestionMark = literals[0].includes('?');
			for (let i = 0; i < placeholders.length; i++) {
				const asString = `${input[placeholders[i] as never]}`;
				key.push(hasSeenQuestionMark ? encodeURIComponent(asString) : encodeURI(asString).replace(/\//g, '%2F'));
				key.push(literals[i + 1]);
				hasSeenQuestionMark = hasSeenQuestionMark || literals[i + 1].includes('?');
			}
			return key;
		},
		reactQueryPredicate: ({queryKey}) => {
			if (queryKey.length !== placeholders.length * 2 + 2) {
				return false;
			}
			if (queryKey[0] !== urlPrefix) return false;
			for (let i = 1; i < literals.length; i++) {
				if (queryKey[i * 2] !== literals[i]) return false;
			}
			return true;
		},
		method,
	}));
}

interface RequestWithBodyOptions {
	contentType?: string
}

const requestBuilderWithBody = (method: string, urlPrefix: string) => <B>({ contentType }: RequestWithBodyOptions = {}) => <V extends string = never>(
	literals: TemplateStringsArray, ...placeholders: V[]
): PartialApiCaller<{ token: string } & TokensToInput<V> & { body: B }> => {
	return fromTemplateString(literals, placeholders, method, urlPrefix, { 
		body: (input, headers) => {
			if (input.body instanceof FormData || input.body instanceof ArrayBuffer || input.body instanceof DataView || input.body instanceof Uint8Array || input.body instanceof URLSearchParams) {
				if (contentType) headers.set('content-type', contentType);
				return input.body;
			} else if (input.body instanceof Blob) {
				headers.set('content-type', contentType ?? input.body.type);
				return input.body;
			} else {
				headers.set('content-type', contentType ?? 'application/json');
				return JSON.stringify(input.body);
			}
		},
	});
};
const requestBuilder = (method: string, urlPrefix: string) => <V extends string = never>(
	literals: TemplateStringsArray, ...placeholders: V[]
): PartialApiCaller<{ token: string } & TokensToInput<V>> => {
	return fromTemplateString(literals, placeholders, method, urlPrefix);
};

interface Router {
	/**
	 * Defines a get request. Call it as a template tag containing embedded strings as parameters, then follow up with the response definition.
	 * 
	 * Every parameter is either a string, a number or a boolean.
	 * 
	 * @example <caption>Makes an request executor to /api/test, retrieving the specified output json.</caption>
	 * interface Output {
	 *     field1: string,
	 *     field2: number
	 *     field3: boolean
	 * }
	 * 
	 * Get`/api/test`.json<Output>()
	 * @example <caption>Makes an request executor to /api/test, retrieving the output as a blob.</caption>
	 * 
	 * Get`/api/test`.blob()
	 */
	Get: ReturnType<typeof requestBuilder>
	/**
	 * Defines a delete request. Call it as a template tag containing embedded strings as parameters, then follow up with the response definition.
	 * 
	 * Every parameter is either a string, a number or a boolean.
	 * 
	 * @example <caption>Makes an request executor to /api/test, retrieving the specified output json.</caption>
	 * interface Output {
	 *     field1: string,
	 *     field2: number
	 *     field3: boolean
	 * }
	 * 
	 * Delete`/api/test`.json<Output>()
	 */
	Delete: ReturnType<typeof requestBuilder>
	/**
	 * Defines a post request. Call it as a function with an object describing the input json or a Blob/FormData for other input types, then call it as a template tag containing embedded strings as parameters, then follow up with the response definition.
	 * 
	 * Every parameter is either a string, a number or a boolean.
	 * 
	 * @example <caption>Makes an request to /api/test with the specified input JSON, retrieving the specified output json.</caption>
	 * interface Input {
	 *     field1: string,
	 *     field2: number
	 * }
	 * interface Output {
	 *     field1: string,
	 *     field2: number
	 *     field3: boolean
	 * }
	 * 
	 * Post<Input>()`/api/test`.json<Output>()
	 * @example <caption>Makes an request executor to /api/test with the specified Blob, just expecting success. (eg, a file upload)</caption>
	 * 
	 * Post<Blob>()`/api/test`.expectSuccess()
	 * @example <caption>More advanced version, handles multiple status codes</caption>
	 * 
	 * const handler = Post<NewEntity>()`/api/test`.status(f => {
	 *     200 => f.json<Entity>(),
	 *     422 => f.json<BadRequestOptions>(),
	 * })
	 * 
	 * ...
	 * // Inside the component
	 * const updateEntity = useRequestUpdate(handler, {
	 *     onSuccess(data) {
	 *         switch(data.status) {
	 *             case 200:
	 *                 // data.result is now Entity
	 *                 alert('success');
	 *                 break;
	 *             case 422:
	 *                 // data.result is now BadRequestOptions
	 *                 alert('Failure');
	 *                 break;
	 *             default:
	 *                 return assertNever(data);
	 *         }
	 *     },
	 * });
	 * 
	 * <button disabled={updateEntity.isLoading} onClick={updateEntity.mutate({
	 *     name: 'test',
	 *     description: 'hi'
	 * })}
	 * 
	 */
	Post: ReturnType<typeof requestBuilderWithBody>
	/**
	 * Defines a patch request. Call it as a function with an object describing the input json or a Blob/FormData for other input types, then call it as a template tag containing embedded strings as parameters, then follow up with the response definition.
	 * 
	 * Every parameter is either a string, a number or a boolean.
	 * 
	 * @example <caption>Makes an request to /api/test with the specified input JSON, retrieving the specified output json.</caption>
	 * interface Input {
	 *     field1: string,
	 *     field2: number
	 * }
	 * interface Output {
	 *     field1: string,
	 *     field2: number
	 *     field3: boolean
	 * }
	 * 
	 * Patch<Input>()`/api/test`.json<Output>()
	 * @example <caption>Makes an request executor to /api/test with the specified Blob, just expecting success. (eg, a file upload)</caption>
	 * 
	 * Patch<Blob>()`/api/test`.expectSuccess()
	 * 
	 */
	Patch: ReturnType<typeof requestBuilderWithBody>
	/**
	 * Defines a put request. Call it as a function with an object describing the input json or a Blob/FormData for other input types, then call it as a template tag containing embedded strings as parameters, then follow up with the response definition.
	 * 
	 * Every parameter is either a string, a number or a boolean.
	 * 
	 * @example <caption>Makes an request executor to /api/test with the specified input JSON, retrieving the specified output json.</caption>
	 * interface Input {
	 *     field1: string,
	 *     field2: number
	 * }
	 * interface Output {
	 *     field1: string,
	 *     field2: number
	 *     field3: boolean
	 * }
	 * 
	 * Put<Input>()`/api/test`.json<Output>()
	 * @example <caption>Makes an request executor to /api/test with the specified Blob, just expecting success. (eg, a file upload)</caption>
	 * 
	 * Put<Blob>()`/api/test`.expectSuccess()
	 * 
	 */
	Put: ReturnType<typeof requestBuilderWithBody>
	/**
	 * Makes a router for the specified url prefix
	 */
	makeRouter(urlPrefix: string): Router
}

function makeRouterInternal(urlPrefix: string): Router {
	return {
		Get: requestBuilder('get', urlPrefix),
		Delete: requestBuilder('delete', urlPrefix),
		Post: requestBuilderWithBody('post', urlPrefix),
		Patch: requestBuilderWithBody('patch', urlPrefix),
		Put: requestBuilderWithBody('put', urlPrefix),
		makeRouter: newPrefix => makeRouterInternal(urlPrefix + newPrefix),
	};
}

export const {
	Get,
	Delete,
	Post,
	Patch,
	Put,
	makeRouter,
} = makeRouterInternal('');
