import React from 'react';

interface Props {
	retourcodes: string | null
	list: List[]
}

interface List {
	betekenis: string
	code: string
}

const DisplayRetourcode = ({retourcodes, list}: Props) => {
	const retourcodeslist = retourcodes?.split(',') ?? [];

	const output: JSX.Element[] = [
		<strong key={-1}>
            Retourcode{retourcodeslist.length > 1 && 's'}
			<br />
			<br />
		</strong>,
	];
	for (let i = 0; i < retourcodeslist.length; i++) {
		const retourcode = retourcodeslist[i];
		const text = list.find(row => row.code === retourcode);
		output.push(
			<React.Fragment key={i}>
				{<strong>{retourcode}</strong>}: {text?.betekenis ?? 'Geen omschrijving'}
				<br />
			</React.Fragment>,
		);
	}
	output.push(
		<React.Fragment key={3}>
			<br />
		</React.Fragment>,
	);
	return output;
};

export default DisplayRetourcode;
