import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import removeDuplicates from '../../util/removeDuplicates';
import StatusCodeAllocationRequest from '../element/statuscode/StatusCodeAllocationRequest';
import StatusCodeAllocationRequestDateTime from '../element/statuscode/StatusCodeAllocationRequestDateTime';
import ExcelAllocationRequest from '../report/ExcelAllocationRequest';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';

export default class ListAllocationRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Verzoeken om toewijzing',
					figure: '',
					column: 'begindatum',
					label: 'Begindatum',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			allocreqs: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {client: 'all', gemeente: 'all', open: false},
			clients: [],
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleReden = reden => {
		const codes = reden.split(',');
		const redenen = this.props.redenen;
		const message = codes.map(row => {
			return redenen
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
                                Reden voor afwijzing verzoek: <strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: 'Reden afwijzing verzoek',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getAllocationRequestList = await api.getAllocationRequestList(aanbieder, api.makeHeader(token));
		if (getAllocationRequestList.error && getAllocationRequestList.error === true) {
			this.props.handleForm(getAllocationRequestList);
			return null;
		}

		const allocreqs = getAllocationRequestList.response;

		let gemeentes = allocreqs.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');
		gemeentes = sortArray(gemeentes, true, 'gemeente_naam');

		let clients = allocreqs.map(row => {
			return {
				client_id: row.client_id,
				naam: row.naam,
				gemeente_code: row.gemeente_code,
			};
		});
		clients = removeDuplicates(clients, 'client_id');

		this.setState(state => {
			state.loaded = true;
			state.allocreqs = allocreqs;
			state.clients = clients;
			state.gemeentes = gemeentes;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, allocreqs, clients, gemeentes, filters} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let clientList = clients;

		let table = [];

		if (filters.gemeente === 'all') {
			table = allocreqs;
		} else {
			table = allocreqs.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
			clientList = table.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		clientList = sortArray(clientList, true, 'naam');
		clientList = removeDuplicates(clientList, 'client_id');

		if (filters.client !== 'all') {
			table = table.filter(row => {
				return row.client_id === Number(filters.client);
			});
		}

		table = table.filter(row => {
			if (filters.open === true) {
				return row.openstaand === null;
			}
			return true;
		});

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocreqs.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{gemeentes.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{row.gemeente_naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f2'>
								<span>
									<strong>Cliënt</strong>
								</span>
								<select
									selected={filters.client}
									name='client'
									id='f2'
									value={filters.client}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocreqs.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{clientList.map(row => (
										<option key={row.client_id} value={row.client_id}>
											{row.naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f3'>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.open}
									name='open'
									id='f3'
									onChange={event => this.handleFilter(event.target.name, event.target.checked)}
									disabled={allocreqs.length === 0 ? true : false}></input>
								<span> Openstaand</span>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelAllocationRequest dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen toewijzingen gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Cliënt' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='soort_label' label='Soort verwijzer' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='verwijzer' label='Verwijzer' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='zorgverlenercode' label='Zorgverlenercode' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='categorie' label='Categorie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product_code' label='Product' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='volume'
											label='Volume'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid' label='Eenheid' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='frequentie' label='Frequentie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_reden' label='reden' />
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcodes' label='Retourcodes' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='openstaand' label='Openstaand' />
									</tr>
									{data.map(row => (
										<tr key={row.uuid} style={noHover}>
											<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.soort_label) ? row.soort_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.verwijzer) ? row.verwijzer : <label>Geen data</label>}</td>
											<td>{notEmpty(row.zorgverlenercode) ? row.zorgverlenercode : <label>Geen data</label>}</td>
											<td>{notEmptyString(row.categorie_code) ? row.categorie_code === null ? 'Generiek' : row.categorie_label : <label>Geen data</label>}</td>

											<td>
												{notEmptyString(row.product_code) ? (
													row.product_code === null && row.categorie_code === null ? (
														'Generiek'
													) : row.product_code === null && row.categorie_code !== null ? (
														'Aspecifiek'
													) : (
														row.product_label
													)
												) : (
													<label>Geen data</label>
												)}
											</td>

											<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum, 2) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum, 2) : <label>Geen data</label>}</td>
											<td className='align-right'>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
											<td>{notEmpty(row.eenheid) ? row.eenheid_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.frequentie) ? row.frequentie_label : <label>Geen data</label>}</td>

											<td>{<StatusCodeAllocationRequest status={row.status} />}</td>
											<td>{<StatusCodeAllocationRequestDateTime vot={row} />}</td>
											<td width={1}>
												<button
													className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleReden(row.status_reden)}
													disabled={notEmpty(row.status_reden) ? false : true}></button>
											</td>
											<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
											<td width={1}>
												<button
													className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleRetourcode(row.retourcodes)}
													disabled={notEmpty(row.retourcodes) ? false : true}></button>
											</td>
											<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>
											<td>{notEmptyString(row.openstaand) ? row.openstaand === null ? 'Ja' : 'Nee' : <label>Geen data</label>}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
