import React from 'react';
import api from '../../util/apiRequest';
import formatDate from '../../util/formatDate';
import DisplayRetourcode from '../element/retourcode/DisplayRetourcode';
import isAuthorized from '../../util/isAuthorized';

export default class FormAllocationStop extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			traject: {},
			loaded: false,
			percentage: null,
			submiting: false,
		};
	}

	componentDidMount = async () => {
		if (!this.state.loaded) {
			const {id} = this.props.form.data;
			const {client} = this.props;
			const {token} = this.props.dbUser;

			const getTrajectFinanciering = await api.getTrajectFinanciering(
				client.aanbieder_id,
				client.id,
				id,
				api.makeHeader(token),
			);
			if (getTrajectFinanciering.error && getTrajectFinanciering.error === true) {
				this.props.handleForm(getTrajectFinanciering);
				return null;
			}
			this.setState(state => {
				state.traject = getTrajectFinanciering.response;
				state.percentage = getTrajectFinanciering.response.eind;
				state.loaded = true;
				return state;
			});
		}
	};

	handleCancel = () => {
		this.props.handleForm({});
	};

	handleSubmit = async (event, toewijzing, aanbieder, client, startstop, formid) => {
		event.preventDefault();
		if (this.state.submiting) {
			return;
		}
		this.setState({ submiting: true });
		try {
			const {token} = this.props.dbUser;

			let data = {
				toewijzing_id: toewijzing,
				aanbieder_id: aanbieder,
				client_id: client,
				startstop_id: startstop,
				formid: formid,
			};
			const elements = event.target.elements;
			Object.keys(elements).forEach(key => {
				let element = elements[key];
				if (element.type !== 'submit' && element.name !== '') {
					data[element.name] = element.value;
				}
			});

			if (formid === 'stop-zorg') {
				const addStopZorg = await api.addStopZorg(aanbieder, client, toewijzing, data, api.makeHeader(token));
				if (addStopZorg.error && addStopZorg.error === true) {
					this.props.handleForm(addStopZorg);
					return null;
				}
				data = addStopZorg.response;
				data.formid = formid;
				this.props.handleForm({});
				this.props.handleStopZorgTable(data);
			} else if (formid === 'stop-zorg-verwijderen') {
				const archiveStartStop = await api.archiveStartStop(
					aanbieder,
					client,
					toewijzing,
					startstop,
					api.makeHeader(token),
				);
				if (archiveStartStop.error && archiveStartStop.error === true) {
					this.props.handleForm(archiveStartStop);
					return null;
				}
				this.props.handleForm({});
				this.props.handleStopZorgTable(data);
			}
		} finally {
			this.setState({ submiting: false });
		}
	};

	handlePercentage = value => {
		this.setState(state => {
			state.percentage = Number(value);
			return state;
		});
	};

	resetPercentage = value => {
		if (!value) {
			this.setState(state => {
				state.percentage = this.state.traject.eind;
				return state;
			});
		}
	};

	render = () => {
		const {data, formid} = this.props.form;
		const {traject, loaded, submiting} = this.state;
		if (loaded === false) return null;
		const {autorisatie} = this.props.dbUser;

		return (
			<form
				onSubmit={event =>
					!submiting &&
                    this.handleSubmit(event, data.toewijzing.id, data.aanbieder_id, data.client_id, data.id,	formid,
                    )
				}
				style={{
					maxWidth: data.dialogWidth,
				}}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<header>
					<h1>
						{formid === 'stop-zorg'
							? 'Zorg Stoppen'
							: formid === 'stop-zorg-verwijderen'
								? 'Stop zorg verwijderen'
								: null}
					</h1>
				</header>
				{formid !== 'stop-zorg-verwijderen' ? (
					<main>
						{data.retourcodes && (
							<label htmlFor='f0'>
								<span className='error-color'>
									<DisplayRetourcode retourcodes={data.retourcodes} list={data.lists.WJ001} />
								</span>
							</label>
						)}
						<label htmlFor='f1' className='col2 col2-break'>
							<span>Einddatum zorg</span>
							<input
								type='date'
								name='datum'
								placeholder='Einddatum'
								id='f1'
								defaultValue={data.start_stop_status === 4 ? data.einddatum : ''}
								max={formatDate(new Date(Math.min((data.stop307_na301 !== 1 && data.toewijzing.einddatum ? new Date(data.toewijzing.einddatum) : new Date()).getTime(), new Date().getTime())), 1)}
								min={data.begindatum}
								required
								autoFocus
							/>
						</label>
						<label htmlFor='f2'>
							<span>Reden beëindiging zorg</span>
							<select
								defaultValue={data.start_stop_status === 4 ? data.reden : ''}
								name='reden'
								placeholder='Reden'
								id='f2'
								required>
								<option value=''></option>
								{data.wet === 'jw'
									? data.lists.JZ588.map((row, id) => (
										<option key={id} value={row.code}>
											{row.betekenis}
										</option>
									))
									: data.lists.WMO588.map((row, id) => (
										<option key={id} value={row.code}>
											{row.betekenis}
										</option>
									))}
							</select>
						</label>
						{traject.traject && !data.startdatum && (
							<>
								<label htmlFor='f3' className='col2'>
									<span>Stop declaratie</span>
									<select name='traject' placeholder='Stop declaratie' id='f3' defaultValue={this.state.percentage === 0 ? 'Nee' : 'Ja'}>
										{['Nee', 'Ja'].map((value, id) => (
											<option key={id} value={value}>
												{value}
											</option>
										))}
									</select>
								</label>
								<label htmlFor='f4' className='col2'>
									<span>Stop percentage</span>
									<input
										onChange={event => this.handlePercentage(event.target.value)}
										onBlur={event => this.resetPercentage(event.target.value)}
										type='number'
										name='percentage'
										placeholder='Stop percentage'
										id='f4'
										value={this.state.percentage}
										required
										maxLength={3}
										min={10}
										max={100}
									/>
								</label>
							</>
						)}
					</main>
				) : (
					<main>{'Weet u zeker dat u deze Stop Zorg wilt verwijderen?'}</main>
				)}
				<footer>
					<button disabled={submiting} type='button' className='secondary' onClick={this.handleCancel}>
                        Annuleer
					</button>
					{isAuthorized(autorisatie, 'functie.uitvoeren') && formid === 'stop-zorg-verwijderen' && (
						<button disabled={submiting} type='submit' className='default'>
                            OK
						</button>
					)}
					{isAuthorized(autorisatie, 'functie.uitvoeren') && formid !== 'stop-zorg-verwijderen' && (
						<button disabled={submiting} type='submit' className='default'>
                            Bewaar
						</button>
					)}
				</footer>
			</form>
		);
	};
}
