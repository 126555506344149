import React from 'react';
import ReactExport from 'react-export-excel';

const ExcelClient = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Cliënten'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Cliënten'>
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Naam' value='naam' />
				<ExcelColumn label='Voorletters' value='voorletters' />
				<ExcelColumn label='Geslacht' value={col => (col.geslacht === 1 ? 'Man' : col.geslacht === 2 ? 'Vrouw' : 'Onbekend')} />
				<ExcelColumn label='Geboortedatum' value='geboortefilter' />
				<ExcelColumn label='BSN' value='bsn' />
				<ExcelColumn label='Retourcode' value='retourcode' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelClient;
