import React from 'react';

interface TableColumnHeader {
	handleSort: (data: string, label: string, table: number) => void
	column: string
	order: boolean
	data: string
	label: string
	table: number
	className?: string
}

const TableColumnHeader = (props: TableColumnHeader) => {
	const {handleSort, column, order, data, label, table, className} = props;

	return (
		<td className={className} onClick={() => handleSort(data, label, table)}>
			{label}
			<button className={column === data ? (order === true ? 'col-sort col-sort-asc' : 'col-sort col-sort-desc') : 'col-sort col-no-sort'}></button>
		</td>
	);
};

export default TableColumnHeader;
