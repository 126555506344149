import styles from './index.module.css';

interface TooltipProps {
	id: string;
	text: string;
	type?: 'regular' | 'regular-down' | 'warning' | 'warning-down'
}

const Tooltip: React.FC<TooltipProps> = ({id, text, type = 'regular'}): JSX.Element => {
	return (
		<div id={id} role={'tooltip'} className={styles[type]}>
			<p className={styles.text}>{text}</p>
		</div>
	);
};

export default Tooltip;
