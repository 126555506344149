import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import removeDuplicates from '../../util/removeDuplicates';
import notEmpty from '../../util/notEmpty';
import isAuthorized from '../../util/isAuthorized';
import formatCurrency from '../../util/formatCurrency';
import ExcelContractRule from '../report/ExcelContractRule';

export default class ContractRuleMain extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Contractregels',
					figure: '',
					column: 'product_label',
					label: 'Product',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			rules: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {gemeente: 'all'},
			rates: [],
			rloaded: false,
			sidebarTrigger: 0,
		};
	}

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getContract = await api.getContract(aanbieder, api.makeHeader(token));
		if (getContract.error && getContract.error === true) {
			this.props.handleForm(getContract);
			return null;
		}

		const rules = getContract.response;

		// console.log(`rules`, rules)
		const filteredRules =  this.handleInitialFilterTable(rules);
		let gemeentes = filteredRules.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});

		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');
		gemeentes = sortArray(gemeentes, true, 'gemeente_naam');

		const gemeenteList = gemeentes.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
				count: filteredRules.filter(rule => {
					return row.gemeente_code === rule.gemeente_code;
				}).length,
			};
		});

		const gemeente = rules.length === 0 ? 'all' : gemeenteList[0].gemeente_code;

		this.setState(state => {
			state.loaded = true;
			state.rules = rules;
			state.gemeentes = gemeenteList;
			state.filters.gemeente = gemeente;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleRates = async contract => {
		const {rloaded} = this.state;

		if (contract === rloaded) {
			return this.setState(state => {
				state.rloaded = false;
				state.rates = [];
				return state;
			});
		}

		const {aanbieder, token} = this.props.dbUser;
		const getContractRates = await api.getContractRates(aanbieder, Number(contract), api.makeHeader(token));
		if (getContractRates.error && getContractRates.error === true) {
			this.props.handleForm(getContractRates);
			return null;
		}
		const rates = getContractRates.response;

		this.setState(state => {
			state.rloaded = contract;
			state.rates = rates;
			return state;
		});
	};

	handleFilterTable = (rules) => {
		const {tables, filters} = this.state;
		let table;
		if (filters.gemeente === 'all') {
			table = rules.slice();
		} else {
			table = rules.filter(row => row.gemeente_code === filters.gemeente);
		}
		const data = sortArray(table, tables[1].order, tables[1].column);
		return data;
	};

	handleInitialFilterTable = (rules = this.state.rules) => {
		const mapById = {};
		for (const row of rules) {
			if (rules[row.id] === undefined) {
				mapById[row.id] = row.tarief_begindatum;
			} else {
				if (mapById[row.id] !== null) {
					if (mapById[row.id] < row.tarief_begindatum) {
						mapById[row.id] = row.tarief_begindatum;
					}
				}
			}
		}
		return rules.filter(e => mapById[e.id] === e.tarief_begindatum);
	};

	render = () => {
		const {loaded, tables, gemeentes, rules, filters, rates, rloaded} = this.state;
		const {autorisatie} = this.props.dbUser;
		const {dbUser} = this.props;

		if (!loaded) {
			return <Loading message='' />;
		}

		if (!isAuthorized(autorisatie, 'contractregels.tonen')) {
			return '';
		}

		const baseRules = this.handleInitialFilterTable();
		const data = this.handleFilterTable(baseRules);

		const noHover = {backgroundColor: 'transparent'};
		const noBorder = {border: 'none'};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>

								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={rules.length === 0 ? true : false}>
									<option key='all' value='all' style={{fontWeight: 600}}>
										{`Toon alle (${baseRules.length})`}
									</option>
									<option disabled></option>
									{gemeentes.map((row, index) => (
										<option key={index} value={row.gemeente_code} style={{fontWeight: 600}}>
											{`${row.gemeente_naam} (${row.count})`}
										</option>
									))}
								</select>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelContractRule dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen contractregels gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<td className='align-right'>#</td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='categorie_label' label='Categorie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product' label='Product code' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product_label' label='Product' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid_label' label='Eenheid' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='frequentie_label' label='Frequentie' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='tarief'
											label='Tarief'
											className='align-right'
										/>
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
									</tr>
									{data.map((row, index) => (
										<React.Fragment key={`${row.id}-${row.tarief}-${row.begindatum}`}>
											<tr style={noHover}>
												<td className='align-right'>{index + 1}</td>
												<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
												<td>{notEmpty(row.categorie_label) ? row.categorie_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.product) ? row.product : <label>Geen data</label>}</td>
												<td>{notEmpty(row.product_label) ? row.product_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.frequentie_label) ? row.frequentie_label : <label>Geen data</label>}</td>

												<td className='align-right'>{notEmpty(row.tarief) ? formatCurrency(row.tarief) : <label>Geen data</label>}</td>
												<td>
													<button className='button rate' onClick={() => this.handleRates(row.id)}></button>
												</td>


												<td>{row.begindatum ? formatDate(row.begindatum, 2) : <label>Geen data</label>}</td>
												<td>{row.einddatum ? formatDate(row.einddatum, 2) : <label>Geen data</label>}</td>
											</tr>
											{rloaded === row.id && (
												<tr style={noHover} key={row.uuid + 'rule'}>
													<td style={noBorder} colSpan={14}>
														<table className='inner-table'>
															<tbody>
																<tr style={noHover}>
																	<td style={noBorder} colSpan={8}>
																		<h3>
                                                                            Tarieven<span>({rates.length})</span>
																		</h3>
																	</td>
																</tr>
																<tr style={noHover}>
																	<td style={noBorder}>#</td>
																	<td style={noBorder}>Begindatum</td>
																	<td style={noBorder}>Einddatum</td>
																	<td className='align-right' style={noBorder}>
                                                                        Tarief
																	</td>
																</tr>
																{rates.map(rate => (
																	<tr key={rate.begindatum} style={noHover}>
																		<td style={noBorder}>{notEmpty(rate.index) ? rate.index : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(rate.begindatum) ? formatDate(rate.begindatum, 2) : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(rate.einddatum) ? formatDate(rate.einddatum, 2) : <label>Geen</label>}</td>
																		<td className='align-right' style={noBorder}>
																			{notEmpty(rate.tarief) ? formatCurrency(rate.tarief) : <label>Geen data</label>}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</td>
												</tr>
											)}
										</React.Fragment>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
