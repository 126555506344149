import React from 'react';
import styles from './index.module.css';
interface ILabel {
	strong?: boolean;
	children?: React.ReactNode;
	htmlFor?: string;
	labelClassName?: string;
}

export const Label: React.FC<ILabel> = ({strong, children, htmlFor, labelClassName}): JSX.Element => {
	if (strong) {
		return (
			<label htmlFor={htmlFor} className={labelClassName ? labelClassName : styles.Label}>
				<span>
					<strong>{children}</strong>
				</span>
			</label>
		);
	}
	return (
		<label htmlFor={htmlFor} className={labelClassName ? labelClassName : styles.Label}>{children}</label>
	);
};

export default Label;

