import React, { ReactNode } from 'react';
import Warning from '../element/miscellaneous/Warning';

interface Props {
	children: ReactNode
}

interface State {
	hasError: boolean
}

export default class AppErrorBoundary extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError = () => {
		return {hasError: true};
	};

	componentDidCatch(error: any, errorInfo: any) {
		console.error('Error', error);
		console.error('Info', errorInfo);
	}

	render = () => {
		const {hasError} = this.state;
		if (hasError) {
			return (
				<Warning
					className='error'
					message={'Helaas, er ging iets fout met de weergave. Probeer de pagina te verversen of log opnieuw in.'}
					response={{
						status: -1,
						response: 'Error in front-end',
					}}
				/>
			);
		}
		return this.props.children;
	};
}
