import React from 'react';

interface Props {
	message: string
}

const Loading = (props: Props) => {
	const {message = ''} = props;
	return (
		<>
			<div className='loading'></div>
			{message !== '' && <p>{message}</p>}
		</>
	);
};

export default Loading;
