export type SelectOptions = {
	label: string;
	value: string;
};

type GetSelectValuesRegular<T extends Object> = {
	arr: T[];
	labelKey: keyof T;
	valueKey: keyof T;
};

type GetSelectValuesComplex<T extends Object> = {
	arr: T[];
	valueKey: keyof T;
	labelCallBack: (v: T) => string;
};

type getSelectValuesFunction<T extends Object> =
  | GetSelectValuesRegular<T>
  | GetSelectValuesComplex<T>;

function selectFunctionIsRegular<T extends Object>(
	prop: getSelectValuesFunction<T>,
): prop is GetSelectValuesRegular<T> {
	return (prop as GetSelectValuesRegular<T>).labelKey !== undefined;
}

export function getSelectOptions<T extends Object>(
	props: getSelectValuesFunction<T>,
): SelectOptions[] {
	if (selectFunctionIsRegular(props)) {
		const newOptionssArray = props.arr.map((item) => {
			const label = item[props.labelKey];
			const value = item[props.valueKey];
			const newSelection = {
				label: label as unknown as string,
				value: value as unknown as string,
			};
			return newSelection;
		});
		return newOptionssArray;
	}
	const newOptionsArray = props.arr.map((item) => {
		const label = props.labelCallBack(item);
		const value = item[props.valueKey];
		const newSelection = {
			label: label as unknown as string,
			value: value as unknown as string,
		};
		return newSelection;

	});
	return newOptionsArray;
}


