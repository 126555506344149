/**
 *  Returns array without duplicate entries
 *  (sometimes duplicates come from back-end API calls or
 *    front-end calculations for select inputs for example)
 */
const removeDuplicates = <T>(array: T[], property: keyof T): T[] => {
	const mapped = array.map(map => map[property]);
	return array.filter((obj, position) => {
		return mapped.indexOf(obj[property]) === position;
	});
};

export default removeDuplicates;
