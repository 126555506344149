export const dtConstants = {
	messages: {
		success: 'De gegevens zijn bijgewerkt',
		error: 'Fout bij het bijwerken van gegevens, plaats de muisaanwijzer op de foutstatus om de oorzaak te zien.',
	},
	alertMap: {
		200: 'success',
		422: 'error',
	},
};
