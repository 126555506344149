import { shallowEquals } from '../../util/equals';
import findOrInsert from '../../util/findOrInsert';
import { declaratieVerantwoordingListUsed, RequestOutput, useRequest } from '../../requests';
import { DbUser, VerantwoordingLegacy } from '../../types';
import isAuthorized from '../../util/isAuthorized';
import findAndDelete from '../../util/findAndDelete';

function checkDisabledReasons({
	data,
	selected,
	loading,
	dbUser,
	usedData,
}: Pick<DeclaratieButtons, 'data' | 'selected' | 'dbUser' | 'loading'> & {
	usedData: RequestOutput<typeof declaratieVerantwoordingListUsed> | undefined
}) {
	const warnings = new Set<string>();
	if (!isAuthorized(dbUser.autorisatie, 'functie.uitvoeren')) {
		warnings.add('Jou account heeft geen rechten voor deze functie');
	}
	if (loading) {
		warnings.add('');
	}
	if (selected.length === 0) {
		warnings.add('Selecteer tenminste 1 verantwoording');
	}
	const existingEntries: [VerantwoordingLegacy['periode_id'], VerantwoordingLegacy['toewijzing_id']][] = [];
	if (usedData) {
		for (const row of usedData) {
			if (row.is_credit) {
				findAndDelete(existingEntries, [row.periode_id, row.toewijzing_id], shallowEquals);
			} else {
				findOrInsert(existingEntries, [row.periode_id, row.toewijzing_id], shallowEquals);
			}
		}
	}
	const requiredEntries: [VerantwoordingLegacy['periode_id'], VerantwoordingLegacy['toewijzing_id']][] = [];
	for (const row of data) {
		if (row.previeus_declaratieregel_id !== null && selected.includes(row.id)) {
			findOrInsert(requiredEntries, [row.periode_id, row.toewijzing_id], shallowEquals);
		}
	}
	for (const row of data) {
		if (!selected.includes(row.id)) {
			if (requiredEntries.find(e => shallowEquals(e, [row.periode_id, row.toewijzing_id]))) {
				warnings.add(`Het is verplicht om voor ${row.naam} toewijzing ${row.toewijzing_nummer} in ${row.periode} de credit mee te nemen in de nieuwe declaratie`);
			}
			if (existingEntries.find(e => shallowEquals(e, [row.periode_id, row.toewijzing_id]))) {
				warnings.add(`Je kan voor ${row.naam} toewijzing ${row.toewijzing_nummer} in ${row.periode} niet declareren, omdat er in deze periode al eens is gedeclareert. Crediteer de bestaande toewijzing`);
			}
		} else {
			if (!row.has_tarief) {
				warnings.add('Voor sommige geselecteerde regels zijn er nog geen tarieven bekent. Deze kunnen niet automatisch ingedient worden.');
			}
		}
	}
	return [...warnings];
}

interface DeclaratieButtons {
	dbUser: DbUser,
	selected: number[],
	data: VerantwoordingLegacy[],
	loading: boolean | undefined,
	handleSend: () => void
}
export function DeclaratieButtons({ selected, data, dbUser, loading, handleSend }: DeclaratieButtons) {
	const { data: usedData } = useRequest(declaratieVerantwoordingListUsed, {
		token: dbUser.token,
		aanbieder: dbUser.aanbieder,
	});
	let label: string;
	if (data.length === 0) {
		label = '';
	} else if (selected.length <= 1) {
		label = 'Declaratie aanmaken voor deze verantwoording';
	} else {
		label = `Declaraties aanmaken voor ${selected.length} verantwoordingen`;
	}
	const disabledReasons: string[] = checkDisabledReasons({
		data,
		dbUser,
		loading: loading || !usedData,
		selected,
		usedData,
	});
	if (!label) return null;
	return (
		<section className='table-footer-top'>
			<form>
				<button
					disabled={disabledReasons.length > 0}
					className={'primary no-margin'}
					onClick={event => {
						event.preventDefault();
						if (disabledReasons.length == 0) {
							handleSend();
						}
					}}
				>
					{label}
				</button>
				<ul>
					{!loading && disabledReasons.map(e => (
						<li className='error-color' key={e}>
							{e}
						</li>
					))}
				</ul>
			</form>
		</section>
	);
}
