import { ReactNode, useEffect, useRef } from 'react';
import styles from './index.module.css';
import { createPortal } from 'react-dom';
interface ModalProps {
	children?: ReactNode;
	isOpen: boolean;
	onClose?: () => void;
}
const Modal: React.FC<ModalProps> = ({children, onClose, isOpen}) => {
	const ref = useRef<HTMLDialogElement>(null);
	useEffect(() => {
		if (isOpen) {
			return ref.current?.showModal();
		}
		return ref.current?.close();
	},[isOpen, ref]);
	
	return createPortal(
		<dialog className={styles.overlay}
			onClose={onClose} 
			aria-modal={true} 
			ref={ref}>
			<div className={styles.container}>
				{children}
			</div>
		</dialog>,
		document.body,
	);
};

export default Modal;
