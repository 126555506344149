import React from 'react';
import FormClient from './FormClient';
import FormAllocationStart from './FormAllocationStart';
import FormAllocationStop from './FormAllocationStop';
import FormAllocationStatement from './FormAllocationStatement';
import FormAllocationShow from './FormAllocationShow';
import FormAllocationRequest from './FormAllocationRequest';
import FormChangeRequest from './FormChangeRequest';

const switchboard = [
	{id: 'client-toevoegen', key: 'client'},
	{id: 'client-wijzigen', key: 'client'},

	{id: 'vot-toevoegen', key: 'vot'},
	{id: 'vot-wijzigen', key: 'vot'},
	{id: 'vot-verwijderen', key: 'vot'},

	{id: 'toewijzing', key: 'toewijzing'},

	{id: 'start-zorg', key: 'start'},
	{id: 'start-zorg-verwijderen', key: 'start'},
	{id: 'stop-zorg', key: 'stop'},
	{id: 'stop-zorg-verwijderen', key: 'stop'},

	{id: 'verantwoording-toevoegen', key: 'verantwoording'},
	{id: 'verantwoording-wijzigen', key: 'verantwoording'},
	{id: 'verantwoording-verwijderen', key: 'verantwoording'},

	{id: 'vow-toevoegen', key: 'vow'},
	{id: 'vow-wijzigen', key: 'vow'},
	{id: 'vow-verwijderen', key: 'vow'},
] as const;
type Switchboard = (typeof switchboard)[number];

interface Props {
	dbUser,
	handleForm,
	form: {
		formid: Switchboard['id']
	},
	client,
	handleVotTable,
	handleVowTable,
	handleStartZorgTable,
	handleStopZorgTable,
	handleVerantwoordingTable,
	handleUpdateClientList,
	periods,
	retourcodes,
}

export default class FormSwitch extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	componentDidMount = () => {
		document.addEventListener('keydown', event => this.handleCloseEscape(event), {once: true});
	};

	handleSwitchboard = <K extends Switchboard['key']>(id: Switchboard['id'], value: K): id is Extract<Switchboard['key'], { key: K }>['id'] => {
		const form = switchboard.find((row: Switchboard) => row.id === id);
		return form === undefined ? false : form.key === value ? true : false;
	};

	handleCloseEscape = (event: any) => {
		event.stopPropagation();
		if (event.key === 'Escape') {
			const {handleForm}: any = this.props;
			return handleForm({});
		}
	};

	handleClose = (event: any) => {
		event.stopPropagation();
		// disabled on request

		// if (event.target.className === 'model-backdrop') {
		//     const {handleForm}: any = this.props
		//     handleForm({})
		// }
	};

	render = () => {
		const {
			dbUser,
			handleForm,
			form,
			client,
			handleVotTable,
			handleVowTable,
			handleStartZorgTable,
			handleStopZorgTable,
			handleVerantwoordingTable,
			handleUpdateClientList,
			periods,
			retourcodes,
		} = this.props;
		const {formid} = form;

		if (this.handleSwitchboard(formid, 'client')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormClient
						dbUser={dbUser}
						handleForm={handleForm}
						form={form}
						handleUpdateClientList={handleUpdateClientList}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'vot')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormAllocationRequest
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form}
						handleVotTable={handleVotTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'toewijzing')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormAllocationShow
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form}
						handleVotTable={handleVotTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'start')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormAllocationStart
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form}
						handleStartZorgTable={handleStartZorgTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'stop')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormAllocationStop
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form}
						handleStopZorgTable={handleStopZorgTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'verantwoording')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormAllocationStatement
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form as any}
						handleVerantwoordingTable={handleVerantwoordingTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		if (this.handleSwitchboard(formid, 'vow')) {
			return (
				<div className='model-backdrop' onClick={event => this.handleClose(event)}>
					<FormChangeRequest
						dbUser={dbUser}
						client={client}
						handleForm={handleForm}
						form={form as any}
						handleVowTable={handleVowTable}
						periods={periods}
						retourcodes={retourcodes}
					/>
				</div>
			);
		}

		return null;
	};
}
