
import { SelectableData } from '../components/ui/TableComponents/types';
import { IProductDetailsBySamenwerkings, ContractUpdateResponse } from '../types';
import { Get, Post } from './helpers/builder';

export const productsDetailsBySamenwerkingQuery =
Get`/contracts/aanbieder/${'aanbieder_id'}/samenwerkinsgverband/productdetails/`.json<{response: IProductDetailsBySamenwerkings[]}>();

export const contractsUpdateBySamenwerkingQuery = 
// Post<SelectableData<IProductDetailsBySamenwerkings>[]>()`/contracts/samenwerkingsverband/update/aanbieder/${'aanbieder_id'}/`.json<{response: ContractUpdateResponse}>({checkStatus: [200, 201, 400, 422]});
Post<SelectableData<IProductDetailsBySamenwerkings>[]>()`/contracts/samenwerkingsverband/update/aanbieder/${'aanbieder_id'}/`.status(f => ({    
	200: f.json<{response: ContractUpdateResponse}>(),
	422: f.json<{response: ContractUpdateResponse}>(),
}));
