import InputDate from '../../../InputDate';
import Label from '../../../Label';
import { ColumnDefinitionDate, ControlsValues } from '../../types';
import styles from './index.module.css';

interface CascadeInputDate<T, K extends keyof T> {
	controlColumn: ColumnDefinitionDate<T, K>;
	controlValues: ControlsValues<T>;
	onChange: (value: string) => void;
}

const CascadeInputDate = <T, K extends keyof T>({controlColumn, controlValues, onChange}: CascadeInputDate<T,K>) => {
	const onChangeHandler = (value: string) => {
		onChange(value);
	};
	return (
		<div className={styles.container}>
			<Label htmlFor={`input-${String(controlColumn.key)}`} strong>{controlColumn.name}</Label>
			<InputDate id={`input-${String(controlColumn.key)}`}
				className={styles.input}
				min={controlColumn.validations && typeof controlColumn.validations.min !== 'function' ? controlColumn.validations.min : undefined}
				max={controlColumn.validations ? controlColumn.validations.max : undefined}
				value={controlColumn.key in controlValues ? controlValues[controlColumn.key as unknown as string] : undefined}
				onChange={onChangeHandler}
			/>
		</div>
	);
};

export default CascadeInputDate;
