import React from 'react';

export default class InvoiceInfo extends React.Component {
	render = () => {
		const {initial_statements, scrollToMyRef1, initial_invoices, scrollToMyRef2} = this.props;
		let info = ['Er ' + (initial_statements + initial_invoices === 1 || (initial_statements === 1 && initial_invoices === 1) ? 'is' : 'zijn') + ' op dit moment '];
		info.push(
			initial_statements !== 0 ? (
				<button onClick={event => scrollToMyRef1(event)} className='button primary small'>
					{initial_statements}
				</button>
			) : (
				''
			),
		);
		info.push(initial_statements === 1 ? ' verantwoording' : initial_statements > 1 ? ' verantwoordingen' : '');
		info.push(initial_statements !== 0 && initial_invoices !== 0 ? ' en ' : '');
		info.push(
			initial_invoices !== 0 ? (
				<button onClick={event => scrollToMyRef2(event)} className='button primary small'>
					{initial_invoices}
				</button>
			) : (
				''
			),
		);
		info.push(initial_invoices === 1 ? ' declaratie' : initial_invoices > 1 ? ' declaraties' : '');
		info.push(initial_statements === 0 && initial_invoices === 0 ? ' geen nieuwe verantwoordingen en declaraties' : '');
		info.push(' te verwerken.');
		info.push(initial_statements !== 0 || initial_invoices !== 0 ? <span>Klik op de knop om naar de tabel te navigeren, indien deze niet zichtbaar is.</span> : '');
		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<h2>Declareren</h2>
					</section>
					<section className='table-footer'>
						<form>
							{info.map((row, index) => {
								return <React.Fragment key={index}>{row}</React.Fragment>;
							})}
						</form>
					</section>
				</section>
			</section>
		);
	};
}
