import React from 'react';
import api from '../../util/apiRequest';
import Loading from '../element/miscellaneous/Loading';
import ClientDetails from './ClientDetails';
import ClientAllocationRequest from './ClientAllocationRequest';
import ClientAllocation from './ClientAllocation';
import ClientChangeRequest from './ClientChangeRequest';
import Tab from '../element/miscellaneous/Tab';
import nameLetters from '../../util/nameLetters';
import makeName from '../../util/makeName';

export default class ClientTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contractData: [],
			display: 'client',
			lists: [],
			loaded: 0,
			pending: false,
			startZorgData: [],
			stopZorgData: [],
			toewijzingData: [],
			trigger: 0,
			triggerUpdate: false,
			verantwoordingData: [],
			votData: [],
			vowData: [],
		};
	}

	componentDidMount = () => {
		const {client} = this.props;

		if (client.id === undefined) {
			return null;
		} else {
			this.handleLoadData();
		}
	};

	componentDidUpdate = () => {
		const {client} = this.props;

		if (client.id === undefined) {
			return;
		} else {
			this.handleLoadData();
		}
	};

	handleLoadData = () => {
		const {client, dbUser, gemeentes, valuelists, contracts, updateClients} = this.props;
		const {aanbieder, token} = dbUser;
        
		if (this.state.pending !== false || this.state.loaded === client.id) {
			return;
		}
        
		(async () =>{
			if (this.state.pending !== false) {
				return;
			}
			console.log('Started instance of client fetcher');
			this.setState({ pending: true });
			let client = this.props.client;
			while (this.state.loaded !== client.id) {
				if (!client.id) {
					this.setState({
						lists: {gemeentes: gemeentes, ...valuelists},
						contractData: contracts,
						loaded: client.id,
						display: 'client',
						updateClients: updateClients,
					});
					continue;
				}
				console.log('Fetching information for ' + client.id);
				const [
					getVotList,
					getToewijzing,
					getVowList,
				] = await Promise.all([
					api.getVotList(aanbieder, client.id, api.makeHeader(token)),
					api.getToewijzing(aanbieder, client.id, api.makeHeader(token)),
					api.getVowList(aanbieder, client.id, api.makeHeader(token)),
				]);

				if (getVotList.error === true) {
					throw getVotList.exception ?? getVotList.response;
				}
				if (getToewijzing.error === true) {
					throw getToewijzing.exception ?? getToewijzing.response;
				}
				if (getVowList.error === true) {
					throw getVowList.exception ?? getVowList.response;
				}

				this.setState(state => {
					state.contractData = contracts;
					state.display = 'client';
					state.lists = {gemeentes: gemeentes, ...valuelists};
					state.loaded = client.id;
					state.toewijzingData = getToewijzing.response;
					state.votData = getVotList.response;
					state.vowData = getVowList.response;
					state.updateClients = updateClients;
					return state;
				});
				client = this.props.client;
			}
			console.log('Ended instance of client fetcher');
			this.setState({ pending: false });
		})().catch((exception) => {
			this.props.handleForm({ error: true, response: `${exception}`, exception});
		});
	};

	handleDisplaySwitch = id => {
		this.setState(state => {
			state.display = id;
			state.trigger++;
			return state;
		});
	};

	render = () => {
		const {
			client,
			dbUser,
			handleForm,
			resetStartZorgTable,
			resetStopZorgTable,
			resetToewijzingTable,
			resetVerantwoordingTable,
			resetVotTable,
			resetVowTable,
			startZorgTable,
			stopZorgTable,
			toewijzingTable,
			verantwoordingTable,
			votTable,
			vowTable,
			retourcodes,
			redenen,
			updateClients,
			handleCheckSidebarStatus,
			sidebarTrigger,
		} = this.props;

		const {contractData, display, lists, loaded, startZorgData, stopZorgData, toewijzingData, trigger, verantwoordingData, votData, vowData} = this.state;

		if (!loaded) {
			return <Loading message='' />;
		}

		const name = makeName(client.achternaam, nameLetters(client.voorletters), client.tussenvoegsel, client.roepnaam);

		return (
			<>
				<section className='client-buttons'>
					<Tab click={event => this.handleDisplaySwitch(event.target.id)} id='client' display={display} label={name} signal='' />
					<Tab click={event => this.handleDisplaySwitch(event.target.id)} id='vot' display={display} label='Verzoek om toewijzing' signal='' />
					<Tab click={event => this.handleDisplaySwitch(event.target.id)} id='toewijzing' display={display} label='Toewijzing' signal='' />
					<Tab click={event => this.handleDisplaySwitch(event.target.id)} id='vow' display={display} label='Verzoek om wijziging' signal='' />
				</section>
				{display === 'client' && <ClientDetails client={client} loaded={loaded} retourcodes={retourcodes} />}
				{display === 'vot' && (
					<ClientAllocationRequest
						sidebarTrigger={sidebarTrigger}
						handleCheckSidebarStatus={handleCheckSidebarStatus}
						client={client}
						updateClients={updateClients}
						data={votData}
						handleForm={handleForm}
						lists={lists}
						contractData={contractData}
						loaded={loaded}
						votTable={votTable}
						resetVotTable={resetVotTable}
						trigger={trigger}
						dbUser={dbUser}
						retourcodes={retourcodes}
						redenen={redenen}
					/>
				)}
				{display === 'toewijzing' && (
					<ClientAllocation
						sidebarTrigger={sidebarTrigger}
						handleCheckSidebarStatus={handleCheckSidebarStatus}
						client={client}
						data={toewijzingData}
						handleForm={handleForm}
						lists={lists}
						contractData={contractData}
						loaded={loaded}
						toewijzingTable={toewijzingTable}
						resetToewijzingTable={resetToewijzingTable}
						startZorgData={startZorgData}
						startZorgTable={startZorgTable}
						resetStartZorgTable={resetStartZorgTable}
						stopZorgData={stopZorgData}
						stopZorgTable={stopZorgTable}
						resetStopZorgTable={resetStopZorgTable}
						verantwoordingData={verantwoordingData}
						verantwoordingTable={verantwoordingTable}
						resetVerantwoordingTable={resetVerantwoordingTable}
						trigger={trigger}
						dbUser={dbUser}
						retourcodes={retourcodes}
						valuelists={this.props.valuelists}
					/>
				)}
				{display === 'vow' && (
					<ClientChangeRequest
						sidebarTrigger={sidebarTrigger}
						handleCheckSidebarStatus={handleCheckSidebarStatus}
						client={client}
						data={vowData}
						handleForm={handleForm}
						lists={lists}
						contractData={contractData}
						loaded={loaded}
						vowTable={vowTable}
						resetVowTable={resetVowTable}
						trigger={trigger}
						dbUser={dbUser}
						retourcodes={retourcodes}
						redenen={redenen}
					/>
				)}
			</>
		);
	};
}
