/**
 *  Eleven proof function to check for valid BSN number
 */
const elevenProof = (bsn: string) => {
	let sum = 0;

	const calculations = [9, 8, 7, 6, 5, 4, 3, 2, -1];
	const digits = bsn.split('').map(digit => {
		return parseInt(digit);
	});

	for (let index = 0; index < 9; index++) {
		sum += digits[index] * calculations[index];
	}
	return Number.isInteger(sum / 11);
};

export default elevenProof;
