type E = 'development' | 'production';
const ENV_OVERRIDE: E | null = null;
export const ENVIRONMENT: E = ENV_OVERRIDE ?? (process.env.NODE_ENV === 'development' ? 'development' : 'production');

export const API_BASE_URL = new URL((window.location.host === 'localhost:3000' ? 'http://localhost:3333' : '') + '/api/v1', location.href).href;

export const AUTH0_DOMAIN = 'zorgverkeer.eu.auth0.com';
export const AUTH0_ID = '8NQnVS1vMX6BzV7oH28tQJqcruvv6kcf';
export const AUTH0_AUDIENCE = 'https://zorgverkeer.eu.auth0.com/api/v2/';
export const AUTH0_SCOPE = 'read:current_user';
export const AUTH0_RETURN_TO =
	window.location.host === 'aanbieder-pretest.zorgverkeer.nl'
		? 'https://aanbieder-pretest.zorgverkeer.nl/'
		: window.location.host === 'aanbieder-test.zorgverkeer.nl'
			? 'https://aanbieder-test.zorgverkeer.nl/'
			: window.location.host === 'aanbieder-acc.zorgverkeer.nl'
				? 'https://aanbieder-acc.zorgverkeer.nl/'
				: window.location.host === 'aanbieder.zorgverkeer.nl'
					? 'https://aanbieder.zorgverkeer.nl/'
					: 'http://localhost:3000/';

export const CLIENT_LIST_INDEX = 20;

export const DISPLAY_RELOAD = true;
export const DISPLAY_RELOGIN = true;

const SECOND = 1000;
const MINUTE = 1000 * 60;
export const FETCH_RETRIES = 12;
export const FETCH_MIN_DELAY = 10 * SECOND;
export const FETCH_TIMEOUT = 30 * SECOND;
export const FETCH_NOTIFY = 1 * SECOND;

export const NOTIFICATION_TIMER = 5 * MINUTE;
export const TOKEN_TIMER = 15 * MINUTE;

export const FILTER_STATEMENTS_FOR_INVOICES = true;
export const FILTER_DATE_MAX = '4712-12-31';

export const MAX_NOTIFICATION_ITEMS = 9;

export type BerichtVersie = '3.0' | '3.1'; //major 0 minor
export const BERICHT_VERSIE: BerichtVersie = window.location.host === 'aanbieder-acc.zorgverkeer.nl' || window.location.host === 'aanbieder.zorgverkeer.nl' || window.location.host === 'aanbieder-pretest.zorgverkeer.nl' ? '3.0' : '3.1' as const;
