/**
 *  Returns last day of previous month as string
 */
const lastDayOfPreviousMonth = () => {
	const today = new Date();
	today.setDate(1);
	today.setDate(today.getDate() - 1);
	const year = today.getFullYear();
	const month = today.getMonth() + 1;
	const day = today.getDate();
	return year + '-' + month + '-' + day;
};

export default lastDayOfPreviousMonth;
