interface InputDateProps {
	id?: string;
	min?: string;
	max?: string;
	value?: string;
	onChange: (value: string) => void;
	className?: string;
	required?: boolean;
	disabled?: boolean;
}

const InputDate: React.FC<InputDateProps> = ({
	id,
	min,
	max,
	value,
	onChange,
	className,
	required,
	disabled,
}) => {
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
	};
	return (
		<input
			type="date"
			id={id}
			min={min}
			max={max}
			value={value}
			onChange={onChangeHandler}
			className={className}
			required={required}
			disabled={disabled}
		/>
	);
};

export default InputDate;
