import React from 'react';
import elevenProof from '../../util/elevenProof';
import api from '../../util/apiRequest';
import formatDate from '../../util/formatDate';
import filterValue from '../../util/filterValue';
import makeName from '../../util/makeName';
import notEmpty from '../../util/notEmpty';
import isAuthorized from '../../util/isAuthorized';
import nameLetters from '../../util/nameLetters';
import DisplayRetourcode from '../element/retourcode/DisplayRetourcode';

export default class FormClient extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bsn: props.form.data.bsn ? props.form.data.bsn : '',
			valid: 0,
			message: '',
			unique: 0,
			gemeente: notEmpty(props.form.data.gemeente_id) ? props.form.data.gemeente_id : props.form.gemeentes.length !== 0 ? props.form.gemeentes[0].id : '',
			voorletters: props.form.data.voorletters ? props.form.data.voorletters : '',
			geboortedatum: notEmpty(props.form.data.geboortedatum) ? props.form.data.geboortedatum : '',
			datumgebruik: notEmpty(props.form.data.datumgebruik) ? props.form.data.datumgebruik : '',
		};
	}

	componentDidMount = () => {
		this.handleBSN(this.state.bsn);
	};

	handleCancel = () => {
		this.props.handleForm({});
	};

	handleSubmit = async (event, data) => {
		event.preventDefault();

		const formid = this.props.form.formid;
		const {aanbieder, token} = this.props.dbUser;
		const index = data.index;

		const elements = event.target.elements;
		Object.keys(elements).forEach(key => {
			let element = elements[key];
			if (element.type !== 'submit' && element.name !== '') {
				data[element.name] = element.value;
			}
		});

		if (formid === 'client-wijzigen') {
			const updateClient = await api.updateClient(aanbieder, data.id, data, api.makeHeader(token));
			if (updateClient.error && updateClient.error === true) {
				this.props.handleForm(updateClient);
				return null;
			}
			data = updateClient.response;
			data.formid = formid;
			data.naam = makeName(data.achternaam, nameLetters(data.voorletters), data.tussenvoegsel, data.roepnaam);
			this.props.handleForm({});
			this.props.handleUpdateClientList({data: data, index: index});
		}

		if (formid === 'client-toevoegen') {
			const addClient = await api.addClient(aanbieder, data, api.makeHeader(token));
			if (addClient.error && addClient.error === true) {
				this.props.handleForm(addClient);
				return null;
			}
			data = addClient.response;
			data.formid = formid;
			data.verwijderd = 0;
			data.naam = makeName(data.achternaam, nameLetters(data.voorletters), data.tussenvoegsel, data.roepnaam);
			this.props.handleForm({});
			this.props.handleUpdateClientList({data: data, index: null});
		}
	};

	handleGemeente = value => {
		this.setState(state => {
			state.gemeente = value;
			return state;
		});
	};

	getBsnUnique = async (aanbieder, client, bsn) => {
		const {token} = this.props.dbUser;
		if (this.state.last_bsn !== bsn) {
			const getBsnUnique = await api.getBsnUnique(aanbieder, client, bsn, api.makeHeader(token));
			const unique = getBsnUnique.response;
			this.setState(state => {
				if (unique === true) {
					state.unique = 1; // unique
				} else if (unique === false) {
					state.unique = 2; // not unique
					state.message = (
						<>
							<strong>BSN:</strong> Let op: ‘{bsn}’ is niet uniek. Bewaren kan pas als deze waarde uniek en geldig is.
							<br />
							<br />
						</>
					);
				}
				state.bsn = bsn;
				state.last_bsn = bsn;
				return this.state;
			});
		}
	};

	handleBSN = input => {
		const {aanbieder} = this.props.dbUser;
		const {data} = this.props.form;

		input = filterValue('0-9', input).substr(0, 9);

		if (input.length < 9 || input === '') {
			this.setState(state => {
				state.valid = 0; // no check
				state.message = '';
				state.bsn = input;
				return state;
			});
			return;
		}

		this.setState(state => {
			const proof = elevenProof(input);
			if (proof === true) {
				state.valid = 1; // valid
			} else if (proof === false) {
				state.valid = 2; // invalid
				state.message = (
					<>
						<strong>BSN:</strong> De waarde ‘{input}’ is geen geldig BSN. Bewaren kan pas als deze waarde geldig is.
						<br />
						<br />
					</>
				);
			}
			state.bsn = input;
			return state;
		});

		this.getBsnUnique(aanbieder, data.id ? data.id : 0, input);
	};

	handleVoorletters = value => {
		value = value.toUpperCase();
		value = filterValue('A-Z', value);
		this.setState(state => {
			state.voorletters = value;
			return state;
		});
	};

	handleKeyPress = (event, type) => {
		const char = event.charCode;
		if (type === 'A-Z') {
			if (char >= 97 && char <= 122) {
				return true;
			} else if (char >= 65 && char <= 90) {
				return true;
			}
		} else if (type === '0-9') {
			if (char >= 48 && char <= 57) {
				return true;
			}
		}
		event.preventDefault();
		event.stopPropagation();
		return false;
	};

	handleDatum = value => {
		this.setState(state => {
			state.geboortedatum = value;
			return state;
		});
		this.handleDatumGebruik(this.state.datumgebruik, value);
	};

	handleDatumGebruik = (datumgebruik = '', geboortedatum = this.state.geboortedatum) => {
		if (notEmpty(datumgebruik)) {
			datumgebruik = Number(datumgebruik);

			if (notEmpty(geboortedatum)) {
				geboortedatum = new Date(geboortedatum);
			} else {
				geboortedatum = new Date(this.state.geboortedatum);
			}
			let year = geboortedatum.getFullYear();
			let month = geboortedatum.getMonth() + 1;

			if (datumgebruik === 1) {
				geboortedatum = year + '-' + ('0' + month).substr(-2, 2) + '-01';
			} else if (datumgebruik === 2) {
				geboortedatum = year + '-01-01';
			} else if (datumgebruik === 3) {
				geboortedatum = '1900-01-01';
			}

			this.setState(state => {
				state.geboortedatum = geboortedatum;
				state.datumgebruik = datumgebruik;
				return state;
			});
		} else {
			this.setState(state => {
				state.geboortedatum = geboortedatum;
				state.datumgebruik = '';
				return state;
			});
		}
	};

	render = () => {
		const {valid, unique, message} = this.state;
		const {data, formid, gemeentes} = this.props.form;
		const {autorisatie} = this.props.dbUser;

		return (
			<form onSubmit={event => this.handleSubmit(event, data)} style={{maxWidth: data.dialogWidth}}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<header>
					<h1>{formid === 'client-wijzigen' ? 'Cliënt wijzigen' : 'Cliënt toevoegen'}</h1>
				</header>
				<main>
					{data.retourcode && (
						<section className='error-color'>
							<DisplayRetourcode retourcodes={data.retourcode} list={this.props.retourcodes} />
						</section>
					)}
					{valid === 1 && unique === 1 ? (
						''
					) : (
						<section className='error-color'>
							<span>{message}</span>
						</section>
					)}
					<label htmlFor='f1'>
						<span>Gemeente</span>
						<select
							name='gemeente_id'
							placeholder='Gemeente'
							id='f1'
							value={this.state.gemeente}
							onChange={event => this.handleGemeente(event.target.value)}
							disabled={gemeentes.length <= 1}
							autoFocus={gemeentes.length > 1}
							required>
							{gemeentes.map(row => (
								<option key={row.id} value={row.gemeente_code}>
									{row.gemeente_naam}
								</option>
							))}
						</select>
					</label>
					<label htmlFor='f2' className='col4'>
						<span>BSN</span>
						<input
							name='bsn'
							autoFocus={gemeentes.length === 1}
							onChange={event => this.handleBSN(event.target.value)}
							onBlur={event => this.handleBSN(event.target.value)}
							onKeyPress={event => this.handleKeyPress(event, '0-9')}
							type='number'
							placeholder='BSN'
							id='f2'
							value={this.state.bsn}
							required
							min={0}
							max={999999999}
							className={
								valid === 0
									? ''
									: valid === 2 && unique === 2
										? 'invalid'
										: valid === 2 && unique === 1
											? 'invalid'
											: valid === 1 && unique === 2
												? 'invalid'
												: valid === 1 && unique === 1
													? 'valid'
													: ''
							}
						/>
					</label>
					<label htmlFor='f8' className='col4'>
						<span>Geboortedatum</span>
						<input
							type='date'
							name='geboortedatum'
							placeholder='Geboortedatum'
							id='f8'
							value={this.state.geboortedatum}
							onChange={event => this.handleDatum(event.target.value)}
							max={formatDate(new Date(), 1)}
							min={this.state.datumgebruik === 3 ? '1900-01-01' : formatDate(new Date(), 4)}
							required
						/>
					</label>
					<label htmlFor='f11' className='col2'>
						<span>Datumgebruik</span>
						<select name='datumgebruik' onChange={event => this.handleDatumGebruik(event.target.value)} placeholder='Datumgebruik' id='f11' value={this.state.datumgebruik}>
							<option value=''></option>
							<option value='1'>Dag onbekend; alleen maand en jaar gebruiken</option>
							<option value='2'>Dag en maand onbekend; alleen jaar gebruiken</option>
							<option value='3'>Dag, maand en jaar onbekend; onbekende datum</option>
						</select>
					</label>
					<label htmlFor='f3' className='col4'>
						<span>Voorletters</span>
						<input
							name='voorletters'
							type='text'
							placeholder='Voorletters'
							id='f3'
							value={this.state.voorletters ? this.state.voorletters : ''}
							onChange={event => this.handleVoorletters(event.target.value)}
							onKeyPress={event => this.handleKeyPress(event, 'A-Z')}
							maxLength={6}
							required
						/>
					</label>
					<label htmlFor='f4' className='col2 break-25'>
						<span>Roepnaam</span>
						<input name='roepnaam' type='text' placeholder='Roepnaam' id='f4' defaultValue={data.roepnaam ? data.roepnaam : ''} required maxLength={200} />
					</label>
					<label htmlFor='f5' className='col4'>
						<span>Tussenvoegsel</span>
						<input name='tussenvoegsel' type='text' placeholder='Tussenvoegsel' id='f5' defaultValue={data.tussenvoegsel ? data.tussenvoegsel : ''} maxLength={10} />
					</label>
					<label htmlFor='f6' className='col2 break-25'>
						<span>Achternaam</span>
						<input name='achternaam' type='text' placeholder='Achternaam' id='f6' defaultValue={data.achternaam ? data.achternaam : ''} required maxLength={200} />
					</label>
					<label htmlFor='f7' className='col4'>
						<span>Geslacht</span>
						<select name='geslacht' placeholder='Geslacht' id='f7' defaultValue={data.geslacht ? data.geslacht : 0} required>
							<option disabled={true} value=''></option>
							<option value='0'>Onbekend</option>
							<option value='1'>Man</option>
							<option value='2'>Vrouw</option>
						</select>
					</label>
					<label htmlFor='f9' className='col4'>
						<span>Gezagsdrager bekend</span>
						<select name='gezagsdrager_bekend' placeholder='Gezagsdrager bekend' id='f9' defaultValue={data.gezagsdrager_bekend} required>
							<option value='2'>Nee</option>
							<option value='1'>Ja</option>
						</select>
					</label>

					<label htmlFor='f10'>
						<span>Commentaar</span>
						<textarea name='commentaar' placeholder='Commentaar' id='f10' defaultValue={data.commentaar ? data.commentaar : ''} maxLength={1000} />
					</label>
				</main>

				<footer>
					<button type='button' className='secondary' onClick={this.handleCancel}>
                        Annuleer
					</button>
					{isAuthorized(autorisatie, 'functie.uitvoeren') && (
						<button type='submit' className='default' disabled={valid === 1 && unique === 1 ? false : true}>
                            Bewaar
						</button>
					)}
				</footer>
			</form>
		);
	};
}
