import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiCaller } from './ApiCaller';

export function useRequest<I, O, R = O>(
	request: ApiCaller<I, O>,
	input: I,
	options?: Omit<UseQueryOptions<O, unknown, R>, 'queryFn' | 'queryKey' | 'initialData'>,
) {
	const newOptions: UseQueryOptions<O, unknown, R> = {
		queryFn: ({ signal }) => request.execute(input, { signal, retry: false}),
		queryKey: request.getKey(input),
		useErrorBoundary: true,
		...options,
	};
	return useQuery(newOptions);
}
