import { ReactNode } from 'react';
import styles from './index.module.css';

interface TableCellProps {
	children: ReactNode;
	cellStyle: 'regular' | 'index' | 'currency' | 'fout' | 'geen-data' | 'check-box'
}

const TableCell: React.FC<TableCellProps> = ({children, cellStyle}) => {
	const styleMap = {
		'regular': 'regularCell',
		'index' : 'indexCell',
		'currency': 'currencyCell',
		'fout': 'foutCell',
		'geen-data': 'geenData',
		'check-box' : 'checkBoxCell',
	};
	return (
		<td className={styles[styleMap[cellStyle]]}>
			{children}
		</td>
	);
};

export default TableCell;
