import { FormEvent, useEffect, useReducer } from 'react';
import {
	Client,
	ClientVerantwoording,
	Product,
	Toewijzing,
} from '../../../types';
import {
	Form,
	InputDate,
	InputNumber,
	Label,
	Modal,
	PrimaryButton,
	SearchableSelect,
} from '../../ui';
import { AddForm } from '../../ui/TableComponents/types';
import styles from './index.module.css';
import {
	getAllCurrentAanbiederClients,
	toewijzingListActive,
} from '../../../requests';
import { getAllProducts } from '../../../requests/product';
import { getSelectOptions } from '../../ui/SearchableSelect/types';
import { SelectOptions } from '../../ui/SearchableSelect/types';
import formatDate from '../../../util/formatDate';
import { preventNegativePastInput } from '../../../util/preventNegativePastInput';

function substringClientName (client: Client): string {
	return `${client.voorletters?.substring(0,1)}. ${client.achternaam} (${client.bsn})`;
}

function substringToewijzingProduct (toewijzing: Toewijzing) {
	return `${toewijzing.toewijzing_nummer} (${toewijzing.product ? toewijzing.product : `Generiek ${toewijzing.categorie}`})`;
}

interface ClientStatementFormState {
	data: ClientVerantwoording;
	clients: Client[]
	aanbiederToewijzing: Toewijzing[];
	products: Product[];
	clientOptions: SelectOptions[],
	toewijzingOptions: SelectOptions[],
	einheidOptions: SelectOptions[],
}

type ReducerActions = {type: 'REQUEST_GET'} 
| {type: 'SUCCESSFUL_GET', payload: ClientStatementFormState} 
| {type: 'CHANGE_CLIENT', payload: string} 
| {type: 'CHANGE_TOEWIJZING_PRODUCT', payload: number}
| {type: 'CHANGE_BEGINDATUM', payload: string} 
| {type: 'CHANGE_EINDDATUM', payload: string} 
| {type: 'CHANGE_VOLUME', payload: number} 
| {type: 'CHANGE_EINHEID', payload: string};

function reducer(state: ClientStatementFormState, action: ReducerActions) {
	switch (action.type) {
		case 'REQUEST_GET': {
			return state;
		}
		case 'SUCCESSFUL_GET': {
			return {
				...action.payload,
			};
		}
		case 'CHANGE_CLIENT': {
			// find the client
			const client = state.clients.find(client => client.bsn === action.payload)!;

			// get the list of toewizing for that client
			const toewijzingList = state.aanbiederToewijzing.filter((item) => item.client_id === client.id);

			// check if toewizing state already set by user
			if (state.data.toewijzing_id !== 0) {

				// check if the client has the selected toewizing
				const clientToewizing = toewijzingList.find((item) => item.id === state.data.toewijzing_id);

				if (!clientToewizing) {
					// get the new list of options
					const newToewizingOptions = getSelectOptions({
						arr: toewijzingList,
						valueKey: 'id',
						labelCallBack: substringToewijzingProduct,
					});
					
					// return new state with a cleared towizing list
					const newState: ClientStatementFormState = {
						...state,
						data: {
							...state.data,
							client_id: client.id,
							bsn: client.bsn,
							client_naam: substringClientName(client),
							contractregel_id: 0,
							toewijzing_nummer: 0,
							toewijzing_id: 0,
							toewijzing_begindatum: '',
							toewijzing_einddatum: '',
						},
						toewijzingOptions: newToewizingOptions,
					};
					return newState;
				}
				
			}
				
			// get the new list of options
			const newToewizingOptions = getSelectOptions({
				arr: toewijzingList,
				valueKey: 'id',
				labelCallBack: substringToewijzingProduct,
			});
			
			// return new state
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					client_id: client.id,
					bsn: client.bsn,
					client_naam: substringClientName(client),
				},
				toewijzingOptions: newToewizingOptions,
			};
			return newState;

		}
		case 'CHANGE_TOEWIJZING_PRODUCT':
		{
			// get selected toewijzing
			const toewijzing = state.aanbiederToewijzing.find((item) => item.id == action.payload)!;
			const product = state.products.find((product) => product.code === toewijzing.product)!;

			// get the new list of eeinheid
			const newEinheidOptions = getSelectOptions({
				arr: [toewijzing],
				labelKey: 'eenheid_label',
				valueKey: 'eenheid',
			});

			// check if client is not selected
			if (state.data.client_id === 0 && state.data.client_naam === '') {
				const newClientList = state.clients.filter((client) => client.id === toewijzing.client_id);
				const newClientOptions = getSelectOptions({
					arr: newClientList,
					valueKey: 'bsn',
					labelCallBack: substringClientName,
				});

				// update and return new state
				const newState: ClientStatementFormState = {
					...state,
					data: {
						...state.data,
						toewijzing_nummer: toewijzing.toewijzing_nummer,
						toewijzing_id: toewijzing.id,
						toewijzing_begindatum: formatDate(toewijzing.begindatum, 1),
						toewijzing_einddatum: formatDate(toewijzing.einddatum, 1),
						product: toewijzing.product,
						product_id: product.id,
						gemeente_id: toewijzing.gemeente_id,
						gemeente_naam: toewijzing.gemeente_naam,
						contractregel_id: toewijzing.contractregel_id,
					},
					einheidOptions: newEinheidOptions,
					clientOptions: newClientOptions,
				};
				
				return newState;
			}

			// update and return new state
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					toewijzing_nummer: toewijzing.toewijzing_nummer,
					toewijzing_begindatum: formatDate(toewijzing.begindatum, 1),
					toewijzing_einddatum: formatDate(toewijzing.einddatum, 1),
					toewijzing_id: toewijzing.id,
					product: toewijzing.product,
					product_id: product.id,
					gemeente_id: toewijzing.gemeente_id,
					gemeente_naam: toewijzing.gemeente_naam,
					contractregel_id: toewijzing.contractregel_id,
				},
				einheidOptions: newEinheidOptions,
			};

			return newState;
		}
		case 'CHANGE_BEGINDATUM': {
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					verantwoording_begindatum: action.payload,
				},
			};
			return newState;
		}
		case 'CHANGE_EINDDATUM': {
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					verantwoording_einddatum: action.payload,
				},
			};
			return newState;
		}
		case 'CHANGE_EINHEID': {
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					eenheid: action.payload,
				},
			};
			return newState;
		}
		case 'CHANGE_VOLUME': {
			const newState: ClientStatementFormState = {
				...state,
				data: {
					...state.data,
					volume: action.payload,
				},
			};
			return newState;
		}
	}
}

const initFormState: ClientStatementFormState = {
	data: {
		client_id: 0,
		client_naam: '',
		gemeente_naam: '',
		gemeente_id: 0,
		contractregel_id: 0,
		toewijzing_nummer: 0,
		toewijzing_id: 0,
		toewijzing_begindatum: '',
		toewijzing_einddatum: '',
		product: '',
		eenheid: '',
		verantwoording_begindatum: '',
		verantwoording_einddatum: null,
		volume: 0,
		bsn: '',
		id: 0,
		toewijzing_frequentie: '',
		status_vw: 1,
		product_id: 0,
	},
	aanbiederToewijzing: [],
	clients: [],
	products: [],
	clientOptions: [],
	einheidOptions: [],
	toewijzingOptions: [],
};

const ClientStatementsForm: React.FC<AddForm<ClientVerantwoording>> = ({
	onCancel,
	onSave,
	isOpen,
	dbUser,
}) => {

	const [state, dispatch] = useReducer(reducer, initFormState);

	useEffect(() => {
		dispatch({type: 'REQUEST_GET'});
		const fetchData = async () => {
			try {
				if (dbUser) {
					const aanbiederToewijzingData = await toewijzingListActive.execute({
						token: dbUser.token,
						provider: dbUser.aanbieder,
					});
					// only toewizing with contractregelid for now
					const aanbiederToewijzing = aanbiederToewijzingData.result.filter((item) => item.contractregel_id !== null);
					const toewizingOptions = getSelectOptions({
						arr: aanbiederToewijzing,
						valueKey: 'id',
						labelCallBack: substringToewijzingProduct,
					});
	
					const einheidOptions = getSelectOptions({
						arr: aanbiederToewijzing,
						labelKey: 'eenheid_label',
						valueKey: 'eenheid',
					});
	
					const aanbiederClientData = await getAllCurrentAanbiederClients.execute({
						token: dbUser.token,
						provider_id: dbUser.aanbieder,
					});
					const { response: clientList } = aanbiederClientData;
					const getClientsWithToewizing = (clients: Client[], toewijzingList: Toewijzing[]) => {
						return clients.filter((client) => {
							const found = toewijzingList.find(toewizing => toewizing.client_id === client.id);
							if (found) return client;
						});
					};
					const clients = getClientsWithToewizing(clientList, aanbiederToewijzing);
					const clientOptions = getSelectOptions({
						arr: clients,
						valueKey: 'bsn',
						labelCallBack: substringClientName,
					});
	
					const productsData = await getAllProducts.execute({ token: dbUser.token });
					const { response: products } = productsData;
	
	
					dispatch({type: 'SUCCESSFUL_GET', payload: {
						data: {
							client_id: 0,
							client_naam: '',
							contractregel_id: 0,
							gemeente_id: 0,
							gemeente_naam: '',
							toewijzing_id: 0,
							toewijzing_nummer: 0,
							toewijzing_begindatum: '',
							toewijzing_einddatum: '',
							product: '',
							eenheid: '',
							verantwoording_begindatum: '',
							verantwoording_einddatum: null,
							volume: 0,
							bsn: '',
							id: 0,
							toewijzing_frequentie: '',
							status_vw: 1,
							product_id: 0,
						},
						clients: clients,
						aanbiederToewijzing: aanbiederToewijzing,
						products: products,
						clientOptions: clientOptions,
						toewijzingOptions: toewizingOptions,
						einheidOptions: einheidOptions,
					}});
				}
			} catch (e) {
				console.error('Error has occured on fetching',e);
			}
			
		};
		fetchData();
	}, []);


	const onClientSelectionHandler = (v: string | number) => {
		if (typeof v == 'string') {
			dispatch({type: 'CHANGE_CLIENT', payload: v});
		}
	};

	const onToewijzingSelectionHandler = (v: string | number) => {
		if (typeof v == 'number') {
			dispatch({type: 'CHANGE_TOEWIJZING_PRODUCT', payload: v});
		}
	};

	const onBegindatumSelectionHandler = (v: string | number) => {
		if (typeof v == 'string') {
			dispatch({type: 'CHANGE_BEGINDATUM', payload: v});
		}
	};

	const onEinddatumSelectionHandler = (v: string | number) => {
		if (typeof v == 'string') {
			dispatch({type :'CHANGE_EINDDATUM', payload: v});
		}
	};

	const onEenheidSelectionHandler = (v: string | number) => {
		if (typeof v == 'string') {
			dispatch({type: 'CHANGE_EINHEID', payload: v});
		}
	};

	const onVolumeSelectionHandler = (v: string | number) => {
		if (typeof v == 'number') {
			dispatch({type: 'CHANGE_VOLUME', payload: v});
		}
	};

	const onFormSubmitHandler = (e: FormEvent) => {
		e.preventDefault();
		onSave(state.data);
	};

	const disableDate = state.data.toewijzing_nummer === 0 ? true : false;
	const disableSubmit = (state.data.client_id === 0 || state.data.toewijzing_nummer === 0 
		|| state.data.verantwoording_begindatum === '' ||
		state.data.volume === 0 || state.data.eenheid === '') ? true : false;
	
	return (
		<Modal isOpen={isOpen} onClose={onCancel}>
			<div>
				<button className={styles.closeButton} onClick={onCancel}/>
			</div>
			<Form title="Nieuwe Verantwoording">
				<div className={styles.inputWrapper}>
					<Label strong>
						Client
					</Label>
					<SearchableSelect
						options={state.clientOptions}
						onSelection={onClientSelectionHandler}
						value={state.data.bsn}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Label strong>
					Toewijzing Product
					</Label>
					<SearchableSelect 
						options={state.toewijzingOptions}
						onSelection={onToewijzingSelectionHandler}
						value={state.data.toewijzing_id}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Label htmlFor="client-verantwoording-begindatum" strong>
            Verantwoording Begindatum
					</Label>
					<InputDate
						id="client-verantwoording-begindatum"
						onChange={onBegindatumSelectionHandler}
						min={state.data.toewijzing_begindatum !== '' ? state.data.toewijzing_begindatum : undefined}
						max={state.data.toewijzing_einddatum !== '' ? state.data.toewijzing_einddatum : undefined}
						disabled={disableDate}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Label htmlFor="client-verantwoording-einddatum" strong>
            Verantwoording Einddatum
					</Label>
					<InputDate
						id="client-verantwoording-einddatum"
						onChange={onEinddatumSelectionHandler}
						disabled={disableDate}
						min={state.data.verantwoording_begindatum !== '' ? 
							state.data.verantwoording_begindatum : state.data.toewijzing_begindatum}
						max={state.data.toewijzing_einddatum !== '' ? state.data.toewijzing_einddatum : undefined}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Label htmlFor="client-verantwoording-volume" strong>
            Volume
					</Label>
					<InputNumber
						id="clinet-verantwoording-volume"
						step={1}
						value={state.data.volume}
						onChange={onVolumeSelectionHandler}
						min={0}
						onPaste={preventNegativePastInput}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Label strong>
            Eenheid
					</Label>
					<SearchableSelect 
						options={state.einheidOptions}
						onSelection={onEenheidSelectionHandler}
						value={state.data.eenheid}
					/>
				</div>
				<div className={styles.letOpNote}>
					<p><strong>let op:</strong> Op dit moment worden alleen specifieke toewijzingen ondersteund.</p>
				</div>
			</Form>
			<div className={styles.buttonsContainer}>
				<PrimaryButton
					onClick={onFormSubmitHandler} 
					disabled={disableSubmit} 
					color="success" 
					type="submit">
						Opslaan
				</PrimaryButton>
				<PrimaryButton color="warning" onClick={onCancel}>
          Annuleren
				</PrimaryButton>
			</div>
		</Modal>
	);
};

export default ClientStatementsForm;
