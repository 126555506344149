import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.css';
import { KeyboardEvent } from 'react';
import { SelectOptions } from './types';


interface SearchableSelectProps {
	options: SelectOptions[];
	onSelection?: (e: string | number) => void;
	value?: string | number | null;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ options, onSelection, value }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const [searchValue, setSearchValue] = useState<string>('');
	const displayedOptions = useMemo(() => {
		if (searchValue !== '') {
			return options.filter(
				(option) =>
					option.label
						.toLocaleLowerCase()
						.indexOf(searchValue.toLocaleLowerCase()) >= 0,
			);
		}
		return options;
	}, [options, searchValue]);

	const selectedValue = options.find((item) => item.value === value)?.label ?? null; 

	const onClickSelectValueHandler = (e: React.MouseEvent<HTMLDivElement>, option: SelectOptions, index: number) => {
		e.preventDefault();
		setHighlightedIndex(index);
		if (onSelection) {
			onSelection(option.value);
		}
		setOpen(false);
	};

	const handleOpenMenu = () => {
		if (open) {
			setHighlightedIndex(-1);
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (
				containerRef &&
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
			) {
				setOpen(false);
			}
		};

		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, []);

	const scrollFocusRef = (e: HTMLDivElement) => {
		if (e && e.parentElement) {
			const elementMiddle = e.offsetTop + e.offsetHeight / 2;
			e.parentElement.scrollTop = -e.parentElement.offsetHeight / 2 + elementMiddle;
		}
	};

	const keyboardEventHandler = (e: KeyboardEvent<HTMLDivElement>) => {
		switch (e.key) {
			case ' ': 
				if (containerRef && containerRef.current?.focus) {
					setOpen(true);
				}
				break;
			case 'Escape':
				if (containerRef) {
					if (open && containerRef.current?.focus) {
						e.preventDefault();
						setOpen(false);
						setSearchValue('');
						setHighlightedIndex(-1);
						containerRef.current.focus();
					}
				}
				break;
			case 'ArrowDown':
				if (open) 
					if (highlightedIndex < displayedOptions.length - 1) {
						setHighlightedIndex(highlightedIndex + 1);
					} else {
						setHighlightedIndex(0);
					}
				break;
			case 'ArrowUp':
				if (open) {
					if (highlightedIndex > 0 && highlightedIndex < displayedOptions.length) {
						setHighlightedIndex(highlightedIndex - 1);
					} else {
						setHighlightedIndex(displayedOptions.length - 1);
					}
				}
				break;
			case 'Enter':
				if (open) {
					if (highlightedIndex !== -1) {
						if (onSelection) {
							onSelection(options[highlightedIndex].value);
						}
						setOpen(false);
					}
				}
				break;
		}
	};

	return (
		<div
			className={styles.container}
			tabIndex={0}
			ref={containerRef}
			onKeyDown={keyboardEventHandler}
		>
			<div className={open ? styles.overlayOpen : styles.overlayClosed} onClick={handleOpenMenu} />
			<div className={styles.menuContainer}>
				{selectedValue ? selectedValue : 'Selecteer...'}
				{open && (
					<div className={styles.menu}>
						<div className={styles.menuItemInput}>
							<input
								autoFocus
								placeholder="zoeken.."
								type="text"
								className={styles.inputBox}
								onChange={onInputChangeHandler}
								ref={inputRef}
							/>
						</div>
						{displayedOptions.length > 0 ? 
							(
								displayedOptions.map((item, i) => (
									<div
										className={i == highlightedIndex ? styles.menuItemHighlighted : styles.menuItem}
										key={`${item.label}-${i}`}
										onClick={(e) => onClickSelectValueHandler(e, item, i)}
										ref={i == highlightedIndex ? scrollFocusRef : undefined}
									>
										{item.label}
									</div>
								))
							) : 
							(
								<div className={styles.menuItemNotFound}>
									Geen resultanten gevonden
								</div>
							)
						
						}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchableSelect;
