import React from 'react';
import {Link} from 'react-router-dom';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import removeDuplicates from '../../util/removeDuplicates';
import {confirmAlert} from 'react-confirm-alert';
import ExcelMessage from '../report/ExcelMessage';
import Attachment from '../element/miscellaneous/Attachment';
import exportToewijzingen from '../report/toewijzingen/exportToewijzingen';

function ExportButton({ row, dbUser, valuelists }) {
	let exporter;
	switch (row.bericht) {
		case 'jw301':
		case 'wmo301':
			exporter = () => {
				exportToewijzingen({
					bericht: JSON.parse(row.json),
					gemeente_naam: row.gemeente,
					aanbieder_naam: dbUser.aanbieders.find(a => a.id === dbUser.aanbieder)?.naam,
					wet: row.bericht === 'jw301' ? 'JW' : 'WMO',
					ontvangen: row.ontvangen,
					valuelists,
				});
			};
			break;
		default:
			exporter = null;
	}
	return !exporter ? null : (
		<button className='button pdf-export' onClick={exporter} disabled={!row.json} title={!row.json ? 'Er is een probleem met deze toewijzing waardoor deze niet gexporteerd kan wordern' : null}/>
	);
}

export default class ListMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Berichten',
					figure: '',
					column: 'ontvangen_verzonden',
					order: false,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			messages: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {period: 'all', with_retour: true, without_retour: true},
			sidebarTrigger: 0,
			xml_loaded: false,
			xml_data: '',
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		try {
			const {aanbieder, token} = this.props.dbUser;
			const getMessageList = await api.getMessageList(aanbieder, api.makeHeader(token));
			if (getMessageList.error && getMessageList.error === true) {
				this.props.handleForm(getMessageList);
				return null;
			}
			const messagesResponse = getMessageList.response;
			const messages = messagesResponse.map(row => {
				const allClients = row.client_id || row.client_ids ? `${row.client_id ?? ''}${row.client_ids ?? ''}`.split(',').map(e => Number(e)) : [];

				return {
					...row,
					virtual_client_text: allClients.map(id => this.props.clienten.find(c => c.id === id)?.naam ?? `<Unknown user: ${id}>`).join(','),
					virtual_client_list: allClients.map(id => ({
						...this.props.clienten.find(c => c.id === id) ?? {},
						id,
					})),
				};
			});

			const periods = this.makePeriodOptions(messages);
			const today = formatDate(new Date(), 3);
			let period = messages.length === 0 ? 'all' : periods[0].period;
			let index = messages.findIndex(row => row.periode === today);
			if (index !== -1) {
				period = messages[index].periode;
			}

			const codes = this.makeCodesOptions(period, messages);

			this.setState(state => ({
				loaded: true,
				messages: messages,
				periods: periods,
				periodTotal: periods[0]?.total ?? 0,
				codes: codes,
				codesTotal: codes[0]?.total ?? 0,
				filters: {
					...state.filters,
					period: period,
					code: 'all',
					zonder_retour: false,
				},
			}));

			window.addEventListener('resize', this.updateScroll);
			setTimeout(() => this.updateScroll(), 500);
		} catch (exception) {
			this.props.handleForm({ error: true, response: `${exception}`, exception});
		}
	};

	makePeriodOptions = messages => {
		let options =
			messages.length === 0
				? []
				: messages.map(row => {
					return {
						period: row.periode,
						count: messages.filter(row2 => {
							return row.periode === row2.periode;
						}).length,
					};
				});
		options = sortArray(options, false, 'period');
		options = removeDuplicates(options, 'period');

		let total = 0;
		for (const option of options) {
			total += option.count;
		}
		for (const option of options) {
			option.total = total;
		}

		return options;
	};

	makeCodesOptions = (period, messages) => {
		let options = [];
		if (period === 'all') {
			options = messages.map(row => {
				return {
					code: row.code,
					count: messages.filter(row2 => {
						return row.code === row2.code;
					}).length,
				};
			});
		} else {
			options = messages.filter(row => {
				return period === row.periode;
			});

			options = options.map(row => {
				return {
					code: row.code,
					count: messages.filter(row2 => {
						return row.code === row2.code && row.periode === row2.periode;
					}).length,
				};
			});
		}

		options = sortArray(options, true, 'code');
		options = removeDuplicates(options, 'code');

		let total = 0;
		for (const option of options) {
			total += option.count;
		}
		for (const option of options) {
			option.total = total;
		}

		return options;
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			if (filter === 'period') {
				let codes = this.makeCodesOptions(value, this.state.messages);
				state.codes = codes;
				state.codesTotal = codes[0].total;
				state.filters.code = 'all';
			}

			if (filter === 'zonder_retour') {
				state.filters.zonder_retour = !state.filters.zonder_retour;
			} else {
				state.filters[filter] = value;
			}
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleDisplayXML = async id => {
		if (id === this.state.xml_loaded) {
			this.setState(state => {
				state.xml_loaded = null;
				state.xml_data = [];
				return state;
			});
			return setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
		const {aanbieder, token} = this.props.dbUser;
		const getXMLData = await api.getXMLData(aanbieder, id, api.makeHeader(token));

		if (getXMLData.error && getXMLData.error === true) {
			this.props.handleForm(getXMLData);
			return null;
		}
		const xml = getXMLData.response;
		this.setState(state => {
			state.xml_loaded = id;
			state.xml_data = xml.xml;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, messages, periods, periodTotal, codes, codesTotal, filters, xml_loaded, xml_data} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let table = messages;

		if (filters.period !== 'all') {
			table = table.filter(row => {
				return filters.period === row.periode;
			});
		}

		if (filters.code !== 'all') {
			table = table.filter(row => {
				return filters.code === row.code;
			});
		}

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		const noBorder = {
			border: 'none',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>

							<label>
								<span>
									<strong>Periode</strong>
								</span>
								<select
									selected={filters.period}
									name='period'
									value={filters.period}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={messages.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle ({periodTotal})
									</option>
									<option disabled></option>
									{periods.map(row => (
										<option key={row.period} value={row.period}>
											{row.period} ({row.count})
										</option>
									))}
								</select>
							</label>

							<label>
								<span>
									<strong>Bericht</strong>
								</span>
								<select
									selected={filters.code}
									name='code'
									value={filters.code}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={messages.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle ({codesTotal})
									</option>
									<option disabled></option>
									{codes.map(row => (
										<option key={row.code} value={row.code}>
											{row.code} ({row.count})
										</option>
									))}
								</select>
							</label>

							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelMessage dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen berichten gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<td className='align-right'>#</td>
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='ontvangen_verzonden' label='Verzonden/Ontvangen' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='periode' label='Periode' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='dagtekening' label='Dagtekening' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='bericht' label='Bericht' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='identificatie' label='Identificatie' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_id' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='virtual_client_text' label='Clienten' />
									</tr>
									{data.map((row, index) => (
										<React.Fragment key={row.uuid}>
											<tr style={noHover}>
												<td className='align-right'>{index + 1}</td>
												<td>
													<div>
														<button className='button source' onClick={() => this.handleDisplayXML(row.uuid)}/>
														<ExportButton row={row} dbUser={dbUser} valuelists={this.props.valuelists}/>
													</div>
												</td>
												<td
													title={[
														row.ontvangen ? `Ontvangen: ${new Date(row.ontvangen).toLocaleString()}` : null,
														row.verwerkt_ts ? `${row.ontvangen ? 'Verwerkt' : 'Aangemaakt'}: ${new Date(row.verwerkt_ts).toLocaleString()}` : null,
														row.verzonden ? `Gereed voor verzending: ${new Date(row.verzonden).toLocaleString()}` : null,
														row.vecozo_verzonden ? `Aangeboden aan VeCoZo: ${new Date(row.vecozo_verzonden).toLocaleString()}` : null,
														row.verzonden && !row.vecozo_verzonden ? 'Nog niet verzonden naar vecozo'[new Date().getTime() - new Date(row.verzonden).getTime() > 1000 * 60 * 60 ? 'toUpperCase' : 'substring']() : null,
													].filter(e => e).join('\n') || null}
												>
													<span>{notEmpty(row.ontvangen_verzonden) ? formatDate(row.ontvangen_verzonden, 2) + ' ' + formatTime(row.ontvangen_verzonden) : <label>Geen data</label>}</span>
													{row.ontvangen ? <span className="icon icon--download"/> : false}
													{row.verzonden && row.vecozo_verzonden ? <span className="icon icon--upload"/> : false}
													{row.verzonden && !row.vecozo_verzonden ? <span className="icon icon--upload-pending"/> : false}
												</td>
												<td>{notEmpty(row.periode) ? row.periode : <label>Geen data</label>}</td>
												<td>{notEmpty(row.dagtekening) ? formatDate(row.dagtekening, 2) : <label>Geen data</label>}</td>
												<td>
													{notEmpty(row.bericht) ? (
														<Attachment
															naam={row.bericht + ' (' + (notEmpty(row.dagtekening) ? formatDate(row.dagtekening, 1) : '') + ' ' + row.gemeente + ' ' + row.identificatie + ').xml'}
															inhoud={row.xml}
															schermnaam={row.bericht + '.xml'}
															symbool={false}
														/>
													) : (
														<label>Geen data</label>
													)}
												</td>
												<td>{notEmpty(row.identificatie) ? row.identificatie : <label>Geen data</label>}</td>
												<td>{notEmpty(row.gemeente) ? row.gemeente : <label>Geen data</label>}</td>
												<td>
													{row.virtual_client_list.map(({id, naam}, index) => (
														<>
															<Link to={{ pathname: `/client/${id}`}} key={id}>
																{naam}
															</Link>
															<span key={`-${id}`}>
																{index === row.virtual_client_list.length - 1 ? '' : index === row.virtual_client_list.length - 2 ? ' en ' : ', '}
															</span>
														</>
													))}
												</td>
											</tr>
											{xml_loaded === row.uuid && (
												<tr style={noHover} key={row.uuid + 'rule'}>
													<td style={noBorder} colSpan={9}>
														<table className='inner-table'>
															<tbody>
																<tr></tr>
																<tr key={1} style={noHover}>
																	<td style={noBorder}>
																		<pre className='pre-format' onClick={() => this.handleDisplayXML(row.uuid)}>
																			{xml_data
																				.replace('><', '>\n<')
																				.split('\n')
																				.map(function (item, index) {
																					return (
																						<span key={index}>
																							{item}
																							<br />
																						</span>
																					);
																				})}
																		</pre>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											)}
										</React.Fragment>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
