import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import removeDuplicates from '../../util/removeDuplicates';
import formatCurrency from '../../util/formatCurrency';
import {confirmAlert} from 'react-confirm-alert';
import isAuthorized from '../../util/isAuthorized';
import splitJoin from '../../util/splitJoin';
import ExcelListInvoice from '../report/ExcelListInvoice';

export default class ListInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Declaraties',
					figure: '',
					column: 'periode',
					label: 'Periode',
					order: false,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			invoices: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {gemeente: 'all', status: 'all', periode: 'all'},
			rules: [],
			rules_loaded: null,
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getInvoice = await api.getInvoice(aanbieder, api.makeHeader(token));
		if (getInvoice.error && getInvoice.error === true) {
			this.props.handleForm(getInvoice);
			return null;
		}

		const invoices = removeDuplicates(getInvoice.response, 'id');

		const periodmap =
			invoices.length === 0
				? []
				: invoices.map(row => {
					return {
						period: row.periode,
					};
				});

		const periods = invoices.length === 0 ? [] : sortArray(removeDuplicates(periodmap, 'period'), false, 'period');
		const today = formatDate(new Date(), 3);
		let period = invoices.length === 0 ? 'all' : periods[0].period;
		let index = periods.findIndex(row => row.periode === today);
		if (index !== -1) {
			period = periods[index].periode;
		}

		if (period === null) {
			period = 'all';
		}
		this.setState(state => {
			state.loaded = true;
			state.invoices = invoices;
			state.filters.periode = period;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleListInvoiceRules = async declaratie_id => {
		if (declaratie_id === this.state.rules_loaded) {
			this.setState(state => {
				state.rules_loaded = null;
				state.rules = [];
				return state;
			});
			return setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
		const {aanbieder, token} = this.props.dbUser;
		const getRulesForInvoice = await api.getRulesForInvoice(aanbieder, declaratie_id, api.makeHeader(token));
		if (getRulesForInvoice.error && getRulesForInvoice.error === true) {
			this.props.handleForm(getRulesForInvoice);
			return null;
		}
		const rules = getRulesForInvoice.response;
		this.setState(state => {
			state.rules_loaded = declaratie_id;
			state.rules = rules;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleSetCredit = async (regel, invoice_id) => {
		let {rules} = this.state;

		const index = rules.findIndex(row => {
			return row.id === regel.id;
		});

		if (index !== -1) {
			rules[index].debet = 0;
			this.setState(() => ({
				rules: rules,
			}));
			const {aanbieder, token} = this.props.dbUser;
			const setInvoiceRuleCredit = await api.setInvoiceRuleCredit(aanbieder, invoice_id, regel.id, api.makeHeader(token));
			if (setInvoiceRuleCredit.error && setInvoiceRuleCredit.error === true) {
				this.props.handleForm(setInvoiceRuleCredit);
				return null;
			}
		}
	};

	confirmSetCredit = (regel, invoice_id) => {
		if (regel.debet === 0) return false;
		const total = notEmpty(regel.totaal) ? formatCurrency(regel.totaal, '€ ') : null;
		const message = total !== null ? `met een waarde van ${total}` : '';
		const {autorisatie} = this.props.dbUser;

		isAuthorized(autorisatie, 'functie.uitvoeren')
			? confirmAlert({
				title: 'Declaratieregel crediteren',
				message: `Weet u zeker dat u deze declaratieregel ${message} wilt crediteren? Alle onderliggende verantwoordingen worden toegankelijk voor bewerking. Let op: deze bewerking kan niet ongedaan worden gemaakt.`,
				buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.handleSetCredit(regel, invoice_id)}],
			})
			: confirmAlert({
				title: 'Declaratieregel crediteren',
				message: `Weet u zeker dat u deze declaratieregel ${message} wilt crediteren? Alle onderliggende verantwoordingen worden toegankelijk voor bewerking. Let op: deze bewerking kan niet ongedaan worden gemaakt.`,
				buttons: [{label: 'Annuleer'}],
			});
	};

	render = () => {
		const {loaded, tables, invoices, filters, rules, rules_loaded} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let table = [];

		let gemeentes = invoices.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
				count: invoices.filter(row2 => row2.gemeente_code === row.gemeente_code).length,
			};
		});
		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');

		if (filters.gemeente === 'all') {
			table = invoices;
		} else {
			table = invoices.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		if (filters.status !== 'all') {
			table = table.filter(row => {
				return row.status === Number(filters.status);
			});
			gemeentes = invoices.map(row => {
				return {
					gemeente_code: row.gemeente_code,
					gemeente_naam: row.gemeente_naam,
					count: table.filter(row2 => row2.gemeente_code === row.gemeente_code).length,
				};
			});
			gemeentes = removeDuplicates(gemeentes, 'gemeente_code');
		}

		let monthList = table.map(row => {
			return {
				periode: row.periode,
			};
		});

		monthList = removeDuplicates(monthList, 'periode');
		monthList = sortArray(monthList, false, 'periode');

		if (filters.periode !== 'all') {
			table = table.filter(row => {
				return row.periode === filters.periode;
			});
			gemeentes = invoices.map(row => {
				return {
					gemeente_code: row.gemeente_code,
					gemeente_naam: row.gemeente_naam,
					count: table.filter(row2 => row2.gemeente_code === row.gemeente_code).length,
				};
			});
			gemeentes = removeDuplicates(gemeentes, 'gemeente_code');
		}

		gemeentes = sortArray(gemeentes, true, 'gemeente_naam');

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noBorder = {
			border: 'none',
		};
		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoices.length === 0 ? true : false}>
									<option key='all' value='all'>
										{`Toon alle (${table.length})`}
									</option>
									<option disabled></option>
									{gemeentes.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{`${row.gemeente_naam} (${row.count})`}
										</option>
									))}
								</select>
							</label>

							<label htmlFor='f2'>
								<span>
									<strong>Status</strong>
								</span>
								<select
									selected={filters.status}
									name='status'
									id='f2'
									value={filters.status}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoices.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									<option key='key2' value='1'>
										Aangemaakt
									</option>
									<option key='key1' value='2'>
										Ingediend
									</option>
									<option key='key3' value='3'>
										Goedgekeurd
									</option>
									<option key='key4' value='4'>
										Afgekeurd
									</option>
								</select>
							</label>

							<label htmlFor='f3'>
								<span>
									<strong>Periode</strong>
								</span>
								<select
									selected={filters.periode}
									name='periode'
									id='f3'
									value={filters.periode}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoices.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									{monthList.map(row => (
										<option key={row.periode} value={row.periode}>
											{row.periode}
										</option>
									))}
								</select>
							</label>
							<label>
								<label className='options'>Download</label>
								{data.length !== 0 ? (
									<ExcelListInvoice dbUser={dbUser} data={data} />
								) : (
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								)}
							</label>
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen declaraties gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<td className='align-right'>#</td>
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='periode' label='Periode' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='ingediend'
											label='Ingediend'
											className='align-right'
										/>
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='toegekend'
											label='Toegekend'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='factuurnummer' label='Factuur' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />

										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcode' label='Retourcode' />
										<td></td>
									</tr>
									{data.map((row, index) => (
										<React.Fragment key={row.id}>
											<tr style={noHover}>
												<td className='align-right'>{index + 1}</td>
												<td>
													<button className='button history' onClick={() => this.handleListInvoiceRules(row.id)}></button>
												</td>
												<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
												<td>
													{notEmpty(row.status) ? (
														row.status === 1 ? (
															'Aangemaakt'
														) : row.status === 2 ? (
															'Ingediend'
														) : row.status === 3 ? (
															'Goedgekeurd'
														) : row.status === 4 ? (
															'Afgekeurd'
														) : (
															<label>Geen data</label>
														)
													) : (
														<label>Geen data</label>
													)}
												</td>
												<td>{notEmpty(row.periode) ? row.periode : <label>Geen data</label>}</td>
												<td className='align-right'>{notEmpty(row.ingediend) ? formatCurrency(row.ingediend) : <label>Geen data</label>}</td>
												<td className='align-right'>{notEmpty(row.toegekend) ? formatCurrency(row.toegekend) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.factuurnummer) ? row.factuurnummer : <label>Geen data</label>}</td>
												<td>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>

												<td>{notEmpty(row.retourcode) ? splitJoin(row.retourcode, ',', ', ') : <label>Geen data</label>}</td>
												<td width={1}>
													<button
														className={notEmpty(row.retourcode) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleRetourcode(row.retourcode)}
														disabled={notEmpty(row.retourcode) ? false : true}></button>
												</td>
											</tr>

											{rules_loaded === row.id && rules.length !== 0 && (
												<tr style={noHover} key={row.uuid + 'rule'}>
													<td style={noBorder} colSpan={14}>
														<table className='inner-table'>
															<tbody>
																<tr style={noHover}>
																	<td style={noBorder} colSpan={8}>
																		<h3>Declaratieregels</h3>
																	</td>
																</tr>
																<tr style={noHover}>
																	<td style={noBorder}>#</td>
																	<td style={noBorder}></td>
																	<td style={noBorder}>Cliënt</td>
																	<td style={noBorder}>BSN</td>
																	<td style={noBorder}>Toewijzing</td>
																	<td style={noBorder}>Categorie</td>
																	<td style={noBorder}>Product</td>
																	<td className='align-right' style={noBorder}>
																		Volume
																	</td>
																	<td style={noBorder}>Eenheid</td>
																	<td className='align-right' style={noBorder}>
																		Tarief
																	</td>
																	<td className='align-right' style={noBorder}>
																		Totaal
																	</td>
																	<td style={noBorder}>Type</td>
																	<td style={noBorder}>Status</td>
																	<td style={noBorder}>Gewijzigd</td>

																	<td colSpan={2} style={noBorder}>
																		Retourcode
																	</td>
																	<td style={noBorder}></td>
																</tr>
																{rules.map((regel, index) => (
																	<tr key={regel.id} style={noHover}>
																		<td style={noBorder} className='align-right'>
																			{index + 1}
																		</td>
																		<td style={noBorder}>
																			<button
																				id={regel.id}
																				className='button credit'
																				disabled={regel.status !== 3 || regel.totaal <= 0}
																				onClick={() => this.confirmSetCredit(regel, row.id)}></button>
																		</td>
																		<td style={noBorder}>{notEmpty(regel.naam) ? regel.naam : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(regel.bsn) ? regel.bsn : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(regel.toewijzing_nummer) ? regel.toewijzing_nummer : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(regel.categorie) ? regel.categorie : <label>Geen data</label>}</td>
																		<td style={noBorder}>{notEmpty(regel.product) ? regel.product : <label>Geen data</label>}</td>
																		<td className='align-right' style={noBorder}>
																			{notEmpty(regel.volume) ? regel.volume : <label>Geen data</label>}
																		</td>
																		<td style={noBorder}>{notEmpty(regel.eenheid_label) ? regel.eenheid_label : <label>Geen data</label>}</td>
																		<td className='align-right' style={noBorder}>
																			{notEmpty(regel.tarief) ? formatCurrency(regel.tarief) : <label>Geen data</label>}
																		</td>
																		<td className='align-right' style={noBorder}>
																			{notEmpty(regel.totaal) ? formatCurrency(regel.totaal) : <label>Geen data</label>}
																		</td>
																		<td style={noBorder}>{notEmpty(row.totaal) ? (row.totaal < 0 ? 'Credit' : 'Debet') : <label>Geen data</label>}</td>
																		<td style={noBorder}>
																			{notEmpty(regel.status) ? (
																				regel.status === 1 ? (
																					'Aangemaakt'
																				) : regel.status === 2 ? (
																					'Ingediend'
																				) : regel.status === 3 ? (
																					'Goedgekeurd'
																				) : regel.status === 4 ? (
																					'Afgekeurd'
																				) : regel.status === 5 ? (
																					'Gecrediteerd'
																				) : (
																					<label>Geen data</label>
																				)
																			) : (
																				<label>Geen data</label>
																			)}
																		</td>
																		<td style={noBorder}>
																			{notEmpty(regel.status_ts) ? (
																				formatDate(regel.status_ts, 2) + ', ' + formatTime(regel.status_ts, 2)
																			) : (
																				<label>Geen data</label>
																			)}
																		</td>

																		<td style={noBorder}>{notEmpty(regel.retourcode) ? splitJoin(regel.retourcode, ',', ', ') : <label>Geen data</label>}</td>
																		<td style={noBorder}>
																			<button
																				className={notEmpty(regel.retourcode) ? 'button info' : 'button info disabled'}
																				onClick={() => this.handleRetourcode(regel.retourcode)}
																				disabled={notEmpty(regel.retourcode) ? false : true}></button>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</td>
												</tr>
											)}
										</React.Fragment>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
