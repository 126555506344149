import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import removeDuplicates from '../../util/removeDuplicates';
import StatusCodeChangeRequest from '../element/statuscode/StatusCodeChangeRequest';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';
import ExcelChangeRequest from '../report/ExcelChangeRequest';

export default class ListChangeRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Verzoeken om wijziging',
					figure: '',
					column: 'begindatum',
					label: 'Begindatum',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			allocreqs: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {client: 'all', gemeente: 'all', open: false},
			clients: [],
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleReden = reden => {
		const codes = reden.split(',');
		const redenen = this.props.redenen;
		const message = codes.map(row => {
			return redenen
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
                                Reden voor afwijzing verzoek: <strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: 'Reden afwijzing verzoek',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getChangeRequestList = await api.getChangeRequestList(aanbieder, api.makeHeader(token));
		if (getChangeRequestList.error && getChangeRequestList.error === true) {
			this.props.handleForm(getChangeRequestList);
			return null;
		}

		const changereqs = getChangeRequestList.response;

		let gemeentes = changereqs.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');

		let clients = changereqs.map(row => {
			return {
				client_id: row.client_id,
				naam: row.naam,
				gemeente_code: row.gemeente_code,
			};
		});
		clients = removeDuplicates(clients, 'client_id');

		this.setState(state => {
			state.loaded = true;
			state.allocreqs = changereqs;
			state.clients = clients;
			state.gemeentes = gemeentes;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, allocreqs, clients, gemeentes, filters} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let clientList = clients;

		let table = [];

		if (filters.gemeente === 'all') {
			table = allocreqs;
		} else {
			table = allocreqs.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
			clientList = table.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		clientList = sortArray(clientList, true, 'naam');
		clientList = removeDuplicates(clientList, 'client_id');

		if (filters.client !== 'all') {
			table = table.filter(row => {
				return row.client_id === Number(filters.client);
			});
		}

		table = table.filter(row => {
			if (filters.open === true) {
				return row.openstaand === null;
			}
			return true;
		});

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocreqs.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{gemeentes.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{row.gemeente_naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f2'>
								<span>
									<strong>Cliënt</strong>
								</span>
								<select
									selected={filters.client}
									name='client'
									id='f2'
									value={filters.client}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={allocreqs.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{clientList.map(row => (
										<option key={row.client_id} value={row.client_id}>
											{row.naam}
										</option>
									))}
								</select>
							</label>

							<label htmlFor='f3'>
								<input
									className='checkbox'
									type='checkbox'
									checked={filters.open}
									name='open'
									id='f3'
									onChange={event => this.handleFilter(event.target.name, event.target.checked)}
									disabled={allocreqs.length === 0 ? true : false}></input>
								<span> Openstaand</span>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelChangeRequest dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen toewijzingen gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Cliënt' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_reden' label='Reden' />
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcodes' label='Retourcodes' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='reden_label' label='Reden verzoek' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_toewijzingen' label='Toewijzingen' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_ongewijzigd' label='Ongewijzigd' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_gewijzigd' label='Gewijzigd' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aantal_nieuw' label='Nieuw' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='openstaand' label='Openstaand' />
									</tr>
									{data.map(row => (
										<tr key={row.uuid} style={noHover}>
											<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{<StatusCodeChangeRequest status={row.status} />}</td>
											<td>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
											<td width={1}>
												<button
													className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleReden(row.status_reden)}
													disabled={notEmpty(row.status_reden) ? false : true}></button>
											</td>
											<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
											<td width={1}>
												<button
													className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleRetourcode(row.retourcodes)}
													disabled={notEmpty(row.retourcodes) ? false : true}></button>
											</td>
											<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>
											<td>{notEmpty(row.reden_label) ? row.reden_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.aantal_toewijzingen) ? row.aantal_toewijzingen : <label>Geen data</label>}</td>
											<td>{notEmpty(row.aantal_ongewijzigd) ? row.aantal_ongewijzigd : <label>Geen data</label>}</td>
											<td>{notEmpty(row.aantal_gewijzigd) ? row.aantal_gewijzigd : <label>Geen data</label>}</td>
											<td>{notEmpty(row.aantal_nieuw) ? row.aantal_nieuw : <label>Geen data</label>}</td>
											<td>{notEmptyString(row.openstaand) ? row.openstaand === null ? 'Ja' : 'Nee' : <label>Geen data</label>}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
