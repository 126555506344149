import { referenceEquals } from './equals';

export default function findOrInsert<A extends any[]>(
	array: A,
	value: A[number],
	equal: (a: A[number], b: A[number]) => boolean = referenceEquals,
): boolean {
	if (equal === referenceEquals ? !array.includes(value) : !array.find(e => equal(e, value))) {
		array.push(value);
		return true;
	}
	return false;
}
