import React from 'react';
import styles from './index.module.css';

interface IContractContainer {
	children?: React.ReactNode
}

const MainContainer: React.FC<IContractContainer> = ({children}): JSX.Element => {
	return (
		<section className={styles.mainOuterContainer}>
			<section className={styles.mainInnerContainer}>
				{children}
			</section>
		</section>
	);
};

export default MainContainer;
