import React, { MouseEvent } from 'react';

interface Props {
	click: (e: MouseEvent<HTMLElement>) => void
	id: string
	display: string
	label: string
	signal: string
}

const Tab = (props: Props) => {
	const {click, id, display, label, signal} = props;

	return (
		<button onClick={event => click(event)} id={id} className={display === id ? (signal !== '' ? 'button active has-signal' : 'button active') : signal !== '' ? 'button has-signal' : 'button'}>
			<i className={`icon icon-${id}`}></i>
			<label>{label}</label>
			{signal !== '' && <aside>{signal}</aside>}
		</button>
	);
};

export default Tab;
