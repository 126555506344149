interface Props {
	fase: number
	status: number
	start_stop_status: number
}

const StatusCodeAllocation = (props: Props) => {
	const {fase, status, start_stop_status} = props;

	return fase === 1 // start/stop
		? start_stop_status === 2
			? 'Start Ingediend'
			: start_stop_status === 3
				? 'Start Goedgekeurd'
				: start_stop_status === 4
					? 'Start Afgekeurd'
					: status === 1 // toewijzing
						? 'Aangemaakt'
						: status === 2
							? 'Ingediend'
							: status === 3
								? 'Goedgekeurd'
								: status === 4
									? 'Afgekeurd'
									: status === 5
										? 'Toegewezen'
										: status === 6
											? 'Afgewezen'
											: status === 7
												? 'In onderzoek'
												: 'Onbekend'
		: start_stop_status === 2 // start/stop
			? 'Stop Ingediend'
			: start_stop_status === 3
				? 'Stop Goedgekeurd'
				: start_stop_status === 4
					? 'Stop Afgekeurd'
					: status === 1 // toewijzing
						? 'Aangemaakt'
						: status === 2
							? 'Ingediend'
							: status === 3
								? 'Goedgekeurd'
								: status === 4
									? 'Afgekeurd'
									: status === 5
										? 'Toegewezen'
										: status === 6
											? 'Afgewezen'
											: status === 7
												? 'In onderzoek'
												: 'Onbekend';
};

export default StatusCodeAllocation;
