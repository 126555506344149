import React, {useState, useEffect, useContext, ReactNode} from 'react';
import createAuth0Client, {Auth0ClientOptions, Auth0Client, User, GetIdTokenClaimsOptions, IdToken, RedirectLoginOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions, LogoutOptions, PopupLoginOptions} from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

type ContextValue = {
	readonly isAuthenticated: boolean;
	readonly user: User | undefined;
	readonly loading: boolean;
	readonly popupOpen: boolean;
	readonly loginWithPopup: (payload?: PopupLoginOptions) => Promise<void>;
	readonly handleRedirectCallback: (payload?: string) => Promise<void>;
	readonly getIdTokenClaims: (payload?: GetIdTokenClaimsOptions) => Promise<IdToken | undefined>;
	readonly loginWithRedirect: (payload?: RedirectLoginOptions) => Promise<void>;
	readonly getTokenSilently: (payload?: GetTokenSilentlyOptions) => Promise<string>;
	readonly getTokenWithPopup: (payload?: GetTokenWithPopupOptions) => Promise<string>;
	readonly logout: (payload?: LogoutOptions) => Promise<void>;
};

export const Auth0Context = React.createContext<ContextValue>(null as unknown as ContextValue);
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions}: Auth0ClientOptions & {children: ReactNode, onRedirectCallback: (appstate: any) => void}) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [user, setUser] = useState<User | undefined>(undefined);
	const [auth0Client, setAuth0] = useState<Auth0Client>();
	const [loading, setLoading] = useState<boolean>(true);
	const [popupOpen, setPopupOpen] = useState<boolean>(false);

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client(initOptions);
			setAuth0(auth0FromHook);

			try {
				if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
					const result = await auth0FromHook.handleRedirectCallback();
					onRedirectCallback(result.appState);
				}
			} catch (e) {
				// This happens when the user is slow in authenticating, or other errors happen. We don't want the app
				// to crash in this situation, so we ignore this error. It also happens if the user goes back though
				// the history, and ends up on an url that inludes a code/state
				console.error(e);
			}

			const isAuthenticated = await auth0FromHook.isAuthenticated();

			setIsAuthenticated(isAuthenticated);

			if (isAuthenticated) {
				try {
					const user = await auth0FromHook.getUser();
					setUser(user);
				} catch (e) {
					console.error(e);
				}
			}

			setLoading(false);
		};
		initAuth0();
		// eslint-disable-next-line
    }, [])

	const loginWithPopup = async (params: PopupLoginOptions = {}) => {
		if (!auth0Client) return;
		setPopupOpen(true);
		try {
			await auth0Client.loginWithPopup(params);
		} catch (error) {
			console.error(error);
		} finally {
			setPopupOpen(false);
		}
		const user = await auth0Client.getUser();
		setUser(user);
		setIsAuthenticated(true);
	};

	const handleRedirectCallback = async (url?: string) => {
		if (!auth0Client) return;
		setLoading(true);
		await auth0Client.handleRedirectCallback(url);
		const user = await auth0Client.getUser();
		setLoading(false);
		setIsAuthenticated(true);
		setUser(user);
	};

	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				popupOpen,
				loginWithPopup,
				handleRedirectCallback,
				getIdTokenClaims: (...p) => auth0Client!.getIdTokenClaims(...p),
				loginWithRedirect: (...p) => auth0Client!.loginWithRedirect(...p),
				getTokenSilently: (...p) => auth0Client!.getTokenSilently(...p),
				getTokenWithPopup: (...p) => auth0Client!.getTokenWithPopup(...p),
				logout: async (...p) => auth0Client?.logout(...p),
			}}>
			{children}
		</Auth0Context.Provider>
	);
};
