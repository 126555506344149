import styles from './index.module.css';

interface CheckBoxProps {
	checked?: boolean;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckBox: React.FC<CheckBoxProps> = ({checked, disabled, onChange}) => {
	return (
		<input type={'checkbox'}
			checked={checked}
			disabled={disabled}
			onChange={onChange}
			className={styles.regular}
		/>
	);
};

export default CheckBox;
