import { ClientVerantwoording } from '../types';
import { makeRouter } from './helpers/builder';

const { Get, Put } = makeRouter('/verantwoording');

export const getClientVerantwoordingListQuery = 
    Get`/${'aanbieder_id'}/client_statments_list`.json<{response: ClientVerantwoording[]}>();

export const updateClientVerantwoordingListQuery = 
    Put<ClientVerantwoording[]>()`/${'aanbieder_id'}/update_client_statements_list`.status(f => ({200: f.json<ClientVerantwoording[]>(), 422: f.json<ClientVerantwoording[]>(),
    }));

