import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import StatusCodeAllocation from '../element/statuscode/StatusCodeAllocation';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import {confirmAlert} from 'react-confirm-alert';
import splitJoin from '../../util/splitJoin';
import exportToewijzingen from '../report/toewijzingen/exportToewijzingen';

export default class ClientAllocation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Actie',
					figure: 'action-figure',
					column: 'start_stop_status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				3: {
					name: 'Historie',
					figure: 'history-figure',
					column: 'start_stop_status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: true,
					left_hint: false,
				},
			},
			toewijzing: this.props.data,
			client: this.props.client,
			startzorg: this.props.startZorgData,
			stopzorg: this.props.stopZorgData,
			history: {data: [], id: 0},
			verantwoording: {data: [], id: 0},
			count: 0,
			countStart: 0,
			countStop: 0,
			countVerantwoording: 0,
			trigger: 0,
			lockedByMissingContracts: !this.props.contractData.find(c => c.gemeente_code === this.props.client.gemeente_code),
			sidebarTrigger: 0,
		};
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = async () => {
		const {client, countStart, countStop, countVerantwoording, sidebarTrigger} = this.state;
		const {token} = this.props.dbUser;

		if (this.state.trigger !== this.props.trigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		if (sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		const updateStart = this.props.startZorgTable.update;
		const triggerStart = this.props.startZorgTable.trigger;
		const updateStop = this.props.stopZorgTable.update;
		const triggerStop = this.props.stopZorgTable.trigger;
		const updateVerantwoording = this.props.verantwoordingTable.update;
		const triggerVerantwoording = this.props.verantwoordingTable.trigger;

		if (triggerStart !== countStart && updateStart !== undefined) {
			if (updateStart.toewijzing_id !== undefined) {
				const getToewijzingAfterUpdate = await api.getToewijzingAfterUpdate(client.aanbieder_id, client.id, updateStart.toewijzing_id, api.makeHeader(token));
				if (getToewijzingAfterUpdate.error && getToewijzingAfterUpdate.error === true) {
					this.props.handleForm(getToewijzingAfterUpdate);
					return null;
				}
				let index = this.state.toewijzing.findIndex(row => {
					return row.id === Number(updateStart.toewijzing_id);
				});
				this.setState(state => {
					state.countStart = triggerStart;
					state.toewijzing[index] = getToewijzingAfterUpdate.response;
					return state;
				});
				this.props.resetStartZorgTable();
				this.onStartStopLoadHistory(updateStart, null, true);
			}
		}

		if (triggerStop !== countStop && updateStop !== undefined) {
			if (updateStop.toewijzing_id !== undefined) {
				const getToewijzingAfterUpdate = await api.getToewijzingAfterUpdate(client.aanbieder_id, client.id, updateStop.toewijzing_id, api.makeHeader(token));
				if (getToewijzingAfterUpdate.error && getToewijzingAfterUpdate.error === true) {
					this.props.handleForm(getToewijzingAfterUpdate);
					return null;
				}
				let index = this.state.toewijzing.findIndex(row => {
					return row.id === Number(updateStop.toewijzing_id);
				});
				this.setState(state => {
					state.countStop = triggerStop;
					state.toewijzing[index] = getToewijzingAfterUpdate.response;
					return state;
				});
				this.props.resetStopZorgTable();
				this.onStartStopLoadHistory(updateStop, null, true);
			}
		}

		if (triggerVerantwoording !== countVerantwoording && updateVerantwoording !== undefined) {
			if (updateVerantwoording.toewijzing_id !== undefined) {
				const getToewijzingAfterUpdate = await api.getToewijzingAfterUpdate(client.aanbieder_id, client.id, updateVerantwoording.toewijzing_id, api.makeHeader(token));
				if (getToewijzingAfterUpdate.error && getToewijzingAfterUpdate.error === true) {
					this.props.handleForm(getToewijzingAfterUpdate);
					return null;
				}
				let index = this.state.toewijzing.findIndex(row => {
					return row.id === Number(updateVerantwoording.toewijzing_id);
				});
				this.setState(state => {
					state.countVerantwoording = triggerVerantwoording;
					state.toewijzing[index] = getToewijzingAfterUpdate.response;
					return state;
				});
				this.props.resetVerantwoordingTable();
				this.handleVerantwoordingHistory(null, updateVerantwoording.toewijzing_id, null, true);
			}
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	handleStatus = (status, start_stop_fase) => {
		let legend = [];
		if (start_stop_fase === null) {
			legend = [
				{
					status: 5,
					description: 'De toewijzing is door de gemeente toegewezen.',
					label: 'Toegewezen',
				},
				{
					status: 8,
					description: 'De toewijzing is gearchiveerd; door u of door een automatisch proces binnen de applicatie.',
					label: 'Gearchiveerd',
				},
			];
		}
		if (start_stop_fase === 0) {
			legend = [
				{
					status: 2,
					description: 'De stop zorg is ingediend.',
					label: 'Stop Ingediend',
				},
				{
					status: 3,
					description: 'De stop zorg is goedgekeurd.',
					label: 'Stop Goedgekeurd',
				},
				{
					status: 4,
					description: 'De stop zorg is afgekeurd.',
					label: 'Stop Afgekeurd',
				},
				{
					status: 5,
					description: 'De toewijzing is door de gemeente toegewezen.',
					label: 'Toegewezen',
				},
				{
					status: 8,
					description: 'De toewijzing is gearchiveerd; door u of door een automatisch proces binnen de applicatie.',
					label: 'Gearchiveerd',
				},
			];
		} else {
			legend = [
				{
					status: 2,
					description: 'De start zorg is ingediend.',
					label: 'Start Ingediend',
				},
				{
					status: 3,
					description: 'De start zorg is goedgekeurd.',
					label: 'Start Goedgekeurd',
				},
				{
					status: 4,
					description: 'De start zorg is afgekeurd.',
					label: 'Start Afgekeurd',
				},
				{
					status: 5,
					description: 'De toewijzing is door de gemeente toegewezen.',
					label: 'Toegewezen',
				},
				{
					status: 8,
					description: 'De toewijzing is gearchiveerd; door u of door een automatisch proces binnen de applicatie.',
					label: 'Gearchiveerd',
				},
			];
		}
		const message = legend.map(item => {
			return (
				<p key={item.status} className='legend'>
					{status === item.status ? (
						<div className='active'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					) : (
						<div className='inactive'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					)}
				</p>
			);
		});

		confirmAlert({
			title: 'Status',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	updateScroll = () => {
		const tables = [1, 3];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	onStartStopAddStart = (event, id, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = { ...this.getToewijzingRow(id) };
		data.toewijzing = this.getToewijzingRow(id);
		data.lists = this.props.lists;
		data.dialogWidth = '50vw';
		data.table = table;
		this.props.handleForm({formid: 'start-zorg', data: data});
	};

	onStartStopAddStop = async (event, id, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = { ...this.getToewijzingRow(id) };
		data.toewijzing = this.getToewijzingRow(id);
		const {token} = this.props.dbUser;
		const getStartStopList = await api.getStartStopList(data.aanbieder_id, data.client_id, data.id, api.makeHeader(token));
		if (getStartStopList.error && getStartStopList.error === true) {
			this.props.handleForm(getStartStopList);
			return;
		}
		if (getStartStopList.response.length > 0) {
			if (getStartStopList.response[0].startdatum > data.begindatum) {
				data.begindatum = getStartStopList.response[0].startdatum;
			}
		}
		data.lists = this.props.lists;
		data.dialogWidth = '50vw';
		data.table = table;
		this.props.handleForm({formid: 'stop-zorg', data: data});
	};


	handleShow = (event, id, table) => {
		event.preventDefault();
		event.stopPropagation();

		let votList = this.state.tables[table].select;
		let votCount = 0;

		if (votList.length === 0) {
			votList = this.state.toewijzing
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => {
					return row.id;
				});
			votCount = votList.length;
		} else {
			if (votList.findIndex(row => row === id) === -1) {
				votList.push(id);
			}
			votCount = votList.length;
		}

		let data = this.getToewijzingRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.votCount = votCount;
		data.votList = votList;
		data.votTable = table;
		data.locked = this.state.locked;
		data.readOnly = true;

		this.props.handleForm({formid: 'toewijzing', data: data});
	};

	onStartStopLoadHistory = async (data, table = null, refresh = false) => {
		const {history} = this.state;
		const {token} = this.props.dbUser;
		if (table) table = null;

		if (refresh === true) {
			if (history.id !== 0) {
				const getStartStopList = await api.getStartStopList(data.aanbieder_id, data.client_id, data.toewijzing_id, api.makeHeader(token));
				if (getStartStopList.error && getStartStopList.error === true) {
					this.props.handleForm(getStartStopList);
					return null;
				}
				this.setState(state => {
					state.history.data = getStartStopList.response;
					state.history.id = data.toewijzing_id;
					return state;
				});
			} else {
				this.setState(state => {
					state.history.data = [];
					state.history.id = 0;
					return state;
				});
			}
		} else {
			if (history.id === data.id) {
				this.setState(state => {
					state.history.data = [];
					state.history.id = 0;
					return state;
				});
			} else {
				const getStartStopList = await api.getStartStopList(data.aanbieder_id, data.client_id, data.id, api.makeHeader(token));
				if (getStartStopList.error && getStartStopList.error === true) {
					this.props.handleForm(getStartStopList);
					return null;
				}
				this.setState(state => {
					state.history.data = getStartStopList.response;
					state.history.id = data.id;
					return state;
				});
			}
		}
	};

	handleVerantwoordingHistory = async (event = null, id, table = null, refresh = false) => {
		const {verantwoording, client} = this.state;
		const {token} = this.props.dbUser;

		if (table) table = null;

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (refresh === true) {
			if (verantwoording.id !== 0) {
				const getVerantwoording = await api.getVerantwoording(client.aanbieder_id, client.id, id, api.makeHeader(token));
				if (getVerantwoording.error && getVerantwoording.error === true) {
					this.props.handleForm(getVerantwoording);
					return null;
				}
				this.setState(state => {
					state.verantwoording.data = getVerantwoording.response;
					return state;
				});
			} else {
				this.setState(state => {
					state.verantwoording.data = [];
					state.verantwoording.id = 0;
					return state;
				});
			}
		} else {
			if (verantwoording.id === id) {
				this.setState(state => {
					state.verantwoording.data = [];
					state.verantwoording.id = 0;
					return state;
				});
			} else {
				const getVerantwoording = await api.getVerantwoording(client.aanbieder_id, client.id, id, api.makeHeader(token));
				if (getVerantwoording.error && getVerantwoording.error === true) {
					this.props.handleForm(getVerantwoording);
					return null;
				}
				this.setState(state => {
					state.verantwoording.data = getVerantwoording.response;
					state.verantwoording.id = id;
					return state;
				});
			}
		}
	};

	onVerandwoordingAdd = (event, id, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = {};
		data.toewijzing = this.getToewijzingRow(id);
		data.dialogWidth = '60vw';
		data.table = table;
		data.contracts = this.props.contractData;
		data.lists = this.props.lists;
		this.props.handleForm({formid: 'verantwoording-toevoegen', data: data, verantwoordingen: this.state.verantwoording.data});
	};

	onVerandwoordingEdit = (event, id, id2, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = this.getVerantwoordingRow(id);
		data.toewijzing = this.getToewijzingRow(id2);
		data.dialogWidth = '60vw';
		data.table = table;
		data.contracts = this.props.contractData;
		data.lists = this.props.lists;
		this.props.handleForm({formid: 'verantwoording-wijzigen', data: data, verantwoordingen: this.state.verantwoording.data });
	};

	onStartStopEdit = (event, startStopId, toewijzingId, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = {...this.getStartStopRow(startStopId)};
		data.toewijzing = this.getToewijzingRow(toewijzingId);
		data.dialogWidth = '60vw';
		data.table = table;
		data.contracts = this.props.contractData;
		data.lists = this.props.lists;
		data.start_stop_status = data.toewijzing.start_stop_status;
		console.log(data);
		this.props.handleForm({formid: data.type === 'start' ? 'start-zorg' : 'stop-zorg', data});
	};

	getToewijzingRow = id => {
		return this.props.data.find(row => row.id === id);
	};

	getStartStopRow = id => {
		return this.state.history.data.find(row => row.id === id);
	};

	getVerantwoordingRow = id => {
		return this.state.verantwoording.data.find(row => row.id === id);
	};

	handleShowHideTable = table => {
		setTimeout(() => {
			this.updateScroll();
		}, 500);
		this.setState(state => {
			state.tables[table].hidden = !state.tables[table].hidden;
			return state;
		});
	};

	handleModal = () => {
		this.setState(state => {
			state.modal = !state.modal;
			return state;
		});
	};

	onStartStopRemove = (event, start_stop, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = start_stop;
		data.toewijzing = this.getToewijzingRow(data.toewijzing_id);
		data.lists = this.props.lists;
		data.dialogWidth = '50vw';
		data.table = table;
		if (data.type === 'start') {
			this.props.handleForm({formid: 'start-zorg-verwijderen', data: data});
		} else if (data.type === 'stop') {
			this.props.handleForm({formid: 'stop-zorg-verwijderen', data: data});
		}
	};

	onVerandwoordingRemove = (event, id, id2, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		let data = this.getVerantwoordingRow(id);
		data.toewijzing = this.getToewijzingRow(id2);
		data.dialogWidth = '50vw';
		data.table = table;
		data.contracts = this.props.contractData;
		this.props.handleForm({formid: 'verantwoording-verwijderen', data: data, verantwoordingen: this.state.verantwoording.data });
	};

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	getShowStartButton = row => {
		if (row.status === 5 && row.start_stop_count === 0) {
			return true;
		}
		if (row.start_stop_status === 4 && row.start_stop_fase === 1) {
			return true;
		}
		if (row.stop_reden === '20' || (row.start_stop_fase === 0 && row.start_stop_status !== 4)) {
			// start/stop pause || stopped or not stared
			return true;
		}
		// other status
		return false;
	};

	getShowStopButton = row => {
		if (row.start_stop_status === 4 && row.start_stop_fase === 0) {
			return true;
		}
		if (row.stop_reden === '20' || (row.start_stop_fase === 1 && row.start_stop_status !== 4)) {
			// start/stop pause || started or not stopped
			return true;
		}
		// other status
		return false;
	};

	getEnableStartStopButton = row => {
		if (row.start_stop_status === 2 || row.status !== 5) {
			// start/stop 2:ingediend || toewijzing not 5:toegewezen
			return false;
		}
		// other status
		return true;
	};

	getEnableStartStopHistoryButton = row => {
		if (row.start_stop_count > 0) {
			return true;
		}
		return false;
	};

	handleBerichtExport = async (toewijzingId) => {
		const {aanbieder, token} = this.props.dbUser;
		const response = await api.getToewijzingBerichtForExport(aanbieder, this.props.client.id, toewijzingId, api.makeHeader(token));

		if (response?.status === 200) {
			if (response.response !== null) {
				exportToewijzingen({
					bericht: JSON.parse(response.response.json),
					gemeente_naam: response.response.gemeente_naam,
					aanbieder_naam: this.props.dbUser.aanbieders.find(a => a.id === this.props.dbUser.aanbieder)?.naam,
					wet: response.response.bericht === 'jw301' ? 'JW' : 'WMO',
					ontvangen: response.response.ontvangen,
					valuelists: this.props.valuelists,
				});
			} else {
				alert('Deze toewijzing kan niet worden geëxporteerd wegens een onbrekende koppeling naar het bijbehorence bericht.');
			}
		}
	};

	render = () => {
		const {loaded} = this.props;
		const {toewijzing, tables, history, verantwoording, lockedByMissingContracts} = this.state;

		if (!loaded) {
			return <Loading message='' />;
		}

		const today = Date.parse(formatDate(Date.now(), 1));

		// Entries that are active now
		const table1 = toewijzing.filter(row => {
			const einddatum = row.einddatum ? Date.parse(formatDate(row.einddatum, 1)) : null;
			//const begindatum = row.begindatum ? Date.parse(formatDate(row.begindatum, 1)) : null
			return ((!einddatum || today <= einddatum) && row.reden_wijziging !== 13);
		});
		const data1 = sortArray(table1, tables[1].order, tables[1].column);

		// Historical/future entries (inverse of the above table)
		const table3 = toewijzing.filter(row => {
			const einddatum = row.einddatum ? Date.parse(formatDate(row.einddatum, 1)) : null;
			//const begindatum = row.begindatum ? Date.parse(formatDate(row.begindatum, 1)) : null
			return !((!einddatum || today <= einddatum) && row.reden_wijziging !== 13);
		});
		const data3 = sortArray(table3, tables[3].order, tables[3].column);

		const noBorder = {
			border: 'none',
		};
		const noHover = {
			backgroundColor: 'transparent',
		};

		const tableHasSpecificColumns = !!data1.find(e => e.volume || e.eenheid || e.frequentie_label);
		const tableHasAspecificColumns = !!data1.find(e => e.budget);

		return (
			<>
				<section className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[1].figure}></figure>
							<h2>{tables[1].name}</h2>
						</section>
						{data1.length !== 0 ? (
							<>
								<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
									<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
									<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
									<table className='data-table'>
										<tbody>
											<tr key={'TableColumnHeader'}>

												<td></td>
												<td>Start en stop</td>
												<td>Verantwoording</td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='start_stop_status' label='Status' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='toewijzing_nummer' label='Nummer' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='toewijzing_datum' label='Datum' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='categorie' label='Categorie' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product' label='Product' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
												{tableHasSpecificColumns && <>
													<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='volume' label='Volume' />
													<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid' label='Eenheid' />
													<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='frequentie_label' label='Frequentie' />
												</>}
												{tableHasAspecificColumns && (
													<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='budget' label='Budget' />
												)}
											</tr>

											{data1.map(toewijzing => (
												<React.Fragment key={toewijzing.uuid}>
													<tr style={noHover}>
														<td>
															<button id={toewijzing.id} className='button read' onClick={event => this.handleShow(event, toewijzing.id, 1)}/>
															<button className='button pdf-export' onClick={() => this.handleBerichtExport(toewijzing.id)}/>
														</td>
														<td>
															{/* Fase: {row.start_stop_fase} ID: {row.start_stop_id} Status: {row.start_stop_status} */}
															{this.getShowStartButton(toewijzing) &&
                                                                <button id={toewijzing.id} className='button start' onClick={event => this.onStartStopAddStart(event, toewijzing.id, 1)} disabled={!this.getEnableStartStopButton(toewijzing)}/>
															}
															{this.getShowStopButton(toewijzing) &&
                                                                <button id={toewijzing.id} className='button stop' onClick={event => this.onStartStopAddStop(event, toewijzing.id, 1)} disabled={!this.getEnableStartStopButton(toewijzing)}/>
															}
															<button id={toewijzing.id} className='button history' onClick={() => this.onStartStopLoadHistory(toewijzing, 1)} disabled={!this.getEnableStartStopHistoryButton(toewijzing)}/>
														</td>
														<td>
															<button
																id={toewijzing.id}
																className='button add'
																onClick={event => this.onVerandwoordingAdd(event, toewijzing.id, 1)}
																disabled={lockedByMissingContracts || toewijzing.status !== 5 || toewijzing.reden_wijziging === 13 ? true : false}></button>

															<button
																id={toewijzing.id}
																className='button history'
																onClick={event => this.handleVerantwoordingHistory(event, toewijzing.id, 1)}
																disabled={toewijzing.status !== 5 || !(toewijzing.verantwoording_count > 0) ? true : false}></button>
														</td>

														<td>{<StatusCodeAllocation fase={toewijzing.start_stop_fase} status={toewijzing.status} start_stop_status={toewijzing.start_stop_status} />}</td>
														<td>
															<button
																className={notEmpty(toewijzing.status) ? 'button info' : 'button info disabled'}
																onClick={() => this.handleStatus(notEmpty(toewijzing.start_stop_status) ? toewijzing.start_stop_status : toewijzing.status, toewijzing.start_stop_fase)}
																disabled={notEmpty(toewijzing.status) ? false : true}></button>
														</td>
														<td>{notEmpty(toewijzing.toewijzing_nummer) ? toewijzing.toewijzing_nummer : <label>Geen data</label>}</td>
														<td>{notEmpty(toewijzing.toewijzing_datum) ? formatDate(toewijzing.toewijzing_datum) : <label>Geen data</label>}</td>
														<td>{notEmptyString(toewijzing.categorie) ? toewijzing.categorie === null ? 'Generiek' : toewijzing.categorie : <label>Geen data</label>}</td>
														<td>
															{notEmptyString(toewijzing.product) ? (
																toewijzing.product === null && toewijzing.categorie === null ? (
																	'Generiek'
																) : toewijzing.product === null && toewijzing.categorie !== null ? (
																	'Aspecifiek'
																) : (
																	toewijzing.product
																)
															) : (
																<label>Geen data</label>
															)}
														</td>
														<td>{notEmpty(toewijzing.begindatum) ? formatDate(toewijzing.begindatum) : <label>Geen data</label>}</td>
														<td>{notEmpty(toewijzing.einddatum) ? formatDate(toewijzing.einddatum) : <label>Geen data</label>}</td>
														{tableHasSpecificColumns && <>
															<td>{notEmpty(toewijzing.volume) ? toewijzing.volume : <label>Geen data</label>}</td>
															<td>{notEmpty(toewijzing.eenheid_label) ? toewijzing.eenheid_label : <label>Geen data</label>}</td>
															<td>{notEmpty(toewijzing.frequentie_label) ? toewijzing.frequentie_label : <label>Geen data</label>}</td>
														</>}
														{tableHasAspecificColumns && (
															<td>{notEmpty(toewijzing.budget) ? toewijzing.budget : <label>Geen data</label>}</td>
														)}
													</tr>
													{history.id === toewijzing.id && history.data.length !== 0 && (
														<tr style={noHover} key={toewijzing.uuid + 'startstop'}>
															<td style={noBorder} colSpan={14}>
																<table className='inner-table'>
																	<tbody>
																		<tr style={noHover}>
																			<td style={noBorder} colSpan={9}>
																				<h3>
                                                                                    Start en stop voor toewijzing {notEmpty(toewijzing.toewijzing_nummer) && toewijzing.toewijzing_nummer}
																					<span>({history.data.length})</span>
																				</h3>
																			</td>
																		</tr>
																		<tr style={noHover}>
																			<td style={noBorder}></td>
																			<td style={noBorder}>#</td>
																			<td style={noBorder}>Type</td>
																			<td style={noBorder}>Datum</td>
																			<td style={noBorder}>Reden Stop</td>
																			<td style={noBorder}>Status</td>
																			<td style={noBorder}></td>
																			<td colSpan={2} style={noBorder}>
                                                                                Retourcode
																			</td>
																		</tr>
																		{history.data.map((startstop, index) => (
																			<tr key={startstop.uuid} style={noHover}>
																				<td style={noBorder}>
																					{startstop.status !== 1 && startstop.status !== 2 && startstop.status !== 4 && (
																						<button
																							id={toewijzing.id + ' | ' + startstop.id}
																							className={'button delete'}
																							onClick={event => this.onStartStopRemove(event, startstop, 1)}
																							disabled={index === 0 ? false : true}></button>
																					)}
																				</td>
																				<td style={noBorder}>{notEmpty(startstop.row) ? startstop.row : <label>Geen data</label>}</td>

																				<td style={noBorder}>
																					{notEmpty(startstop.type) ? startstop.type === 'start' ? 'Start' : 'Stop' : <label>Geen data</label>}
																				</td>
																				<td style={noBorder}>
																					{notEmpty(startstop.type) ? (
																						startstop.type === 'start' ? (
																							startstop.startdatum ? (
																								formatDate(startstop.startdatum)
																							) : (
																								''
																							)
																						) : startstop.einddatum ? (
																							formatDate(startstop.einddatum)
																						) : (
																							''
																						)
																					) : (
																						<label>Geen data</label>
																					)}
																				</td>

																				<td style={noBorder}>{notEmpty(startstop.reden_label) ? startstop.reden_label : <label>Geen data</label>}</td>
																				<td style={noBorder}>
																					{startstop.status === 2
																						? 'Ingediend'
																						: startstop.status === 3
																							? 'Goedgekeurd'
																							: startstop.status === 4
																								? 'Afgekeurd'
																								: 'Nog niet gestart'}
																				</td>
																				<td style={noBorder}>{formatDate(startstop.gewijzigd) + ' ' + formatTime(startstop.gewijzigd, 1)}</td>
																				<td style={noBorder}>
																					<button
																						className={notEmpty(startstop.retourcodes) ? 'button info' : 'button info disabled'}
																						onClick={() => this.handleRetourcode(startstop.retourcodes)}
																						disabled={notEmpty(startstop.retourcodes) ? false : true}></button>
																				</td>
																				<td style={noBorder}>
																					{notEmpty(startstop.retourcodes) ? splitJoin(startstop.retourcodes, ',', ', ') : <label>Geen data</label>}
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</td>
														</tr>
													)}
													{verantwoording.id === toewijzing.id && verantwoording.data.length !== 0 && (
														<tr style={noHover} key={toewijzing.uuid + 'verantwoording'}>
															<td style={noBorder} colSpan={14}>
																<table className='inner-table'>
																	<tbody>
																		<tr style={noHover}>
																			<td style={noBorder} colSpan={10}>
																				<h3>
                                                                                    Verantwoording voor toewijzing {notEmpty(toewijzing.toewijzing_nummer) && toewijzing.toewijzing_nummer}
																					<span>({verantwoording.data.length})</span>
																				</h3>
																			</td>
																		</tr>
																		<tr style={noHover}>
																			<td style={noBorder}></td>
																			<td style={noBorder}>#</td>
																			<td style={noBorder}>Begindatum</td>
																			<td style={noBorder}>Einddatum</td>
																			<td style={noBorder}>Product</td>
																			<td className='align-right' style={noBorder}>
                                                                                Volume
																			</td>
																			<td style={noBorder}>Eenheid</td>
																			<td style={noBorder}>Status</td>
																			<td style={noBorder}></td>
																			<td colSpan={2} style={noBorder}>
                                                                                Retourcode
																			</td>
																		</tr>
																		{verantwoording.data.map(verantwoording => (
																			<tr key={verantwoording.uuid} style={noHover}>
																				<td style={noBorder}>
																					<button
																						id={toewijzing.id + ' | ' + verantwoording.id}
																						className='button delete'
																						onClick={event =>
																							this.onVerandwoordingRemove(
																								event,
																								verantwoording.id,
																								toewijzing.id, // toewijzing
																								1,
																							)
																						}
																						disabled={
																							verantwoording.status !== 1 ||
                                                                                            verantwoording.traject === 1 ||
                                                                                            verantwoording.declaratieregel_id !== null ||
                                                                                            verantwoording.previeus_declaratieregel_id !== null
																						}
																					/>

																					<button
																						id={toewijzing.id + ' | ' + verantwoording.id}
																						className='button edit'
																						onClick={event =>
																							this.onVerandwoordingEdit(
																								event,
																								verantwoording.id,
																								toewijzing.id, // toewijzing
																								1,
																							)
																						}
																						disabled={
																							verantwoording.status !== 1 ||
                                                                                            verantwoording.traject === 1 ||
                                                                                            verantwoording.declaratieregel_id !== null ||
                                                                                            verantwoording.previeus_declaratieregel_id !== null
																						}
																					/>
																				</td>
																				<td style={noBorder}>{notEmpty(verantwoording.row) ? verantwoording.row : <label>Geen data</label>}</td>

																				<td style={noBorder}>{notEmpty(verantwoording.datum) ? formatDate(verantwoording.datum) : <label>Geen data</label>}</td>
																				<td style={noBorder}>
																					{notEmpty(verantwoording.einddatum) ? formatDate(verantwoording.einddatum) : <label>Geen data</label>}
																				</td>
																				<td style={noBorder} title={verantwoording.product_name}>
																					{notEmpty(verantwoording.product_code) ? verantwoording.product_code : <label>Geen data</label>}
																				</td>
																				<td className='align-right' style={noBorder}>
																					{notEmpty(verantwoording.volume) ? verantwoording.volume : <label>Geen data</label>}
																				</td>
																				<td style={noBorder}>
																					{notEmpty(verantwoording.eenheid_label) ? (
																						verantwoording.eenheid_label
																					) : notEmpty(verantwoording.eenheid_label_ca) ? (
																						verantwoording.eenheid_label_ca
																					) : (
																						<label>Geen data</label>
																					)}
																				</td>
																				<td style={noBorder}>
																					{notEmpty(verantwoording.status) ? (
																						verantwoording.status === 1 ? (
																							'Aangemaakt'
																						) : verantwoording.status === 2 ? (
																							'Ingediend'
																						) : verantwoording.status === 3 ? (
																							'Goedgekeurd'
																						) : verantwoording.status === 4 ? (
																							'Afgekeurd'
																						) : verantwoording.status === 5 ? (
																							'Gecrediteerd'
																						) : (
																							<label>Geen data</label>
																						)
																					) : (
																						<label>Geen data</label>
																					)}
																				</td>
																				<td style={noBorder}>
																					{notEmpty(verantwoording.aangemaakt) ? (
																						formatDate(verantwoording.aangemaakt, 2) + ', ' + formatTime(verantwoording.aangemaakt, 2)
																					) : (
																						<label>Geen data</label>
																					)}
																				</td>
																				<td style={noBorder}>
																					<button
																						className={notEmpty(verantwoording.retourcode) ? 'button info' : 'button info disabled'}
																						onClick={() => this.handleRetourcode(verantwoording.retourcode)}
																						disabled={notEmpty(verantwoording.retourcode) ? false : true}></button>
																				</td>
																				<td style={noBorder}>
																					{notEmpty(verantwoording.retourcode) ? splitJoin(verantwoording.retourcode, ',', ', ') : <label>Geen data</label>}
																				</td>
																			</tr>
																		))}
																	</tbody>
																</table>
															</td>
														</tr>
													)}
												</React.Fragment>
											))}
										</tbody>
									</table>
								</section>
								<section className='table-footer'></section>
							</>
						) : (
							<section className='table-empty'>Er zijn geen actieve toewijzingen</section>
						)}
					</section>
				</section>
				<section className='client-data'>
					<button className={'btn-unhide'} onClick={() => this.handleShowHideTable(3)}>
						{tables[3].hidden ? 'Toon historie' : 'Verberg historie'}
					</button>
				</section>
				<section className={tables[3].hidden ? 'client-data table-hidden' : 'client-data'}>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[3].figure}></figure>
							<h2>{tables[3].name}</h2>
						</section>
						{data3.length !== 0 ? (
							<section id='table3' className='table-scroll-x' onScroll={() => this.handleScroll(3)}>
								<aside className={tables[3].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(3)}></aside>
								<aside className={tables[3].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(3)}></aside>
								<table className='data-table'>
									<tbody>
										<tr key={'TableColumnHeader'}>
											<td></td>
											<td>Start/Stop</td>
											<td>Verantwoording</td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='start_stop_status' label='Status' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='toewijzing_nummer' label='Nummer' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='toewijzing_datum' label='Datum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='categorie' label='Categorie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='product' label='Product' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='begindatum' label='Begindatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='einddatum' label='Einddatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='volume' label='Volume' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='eenheid' label='Eenheid' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='frequentie' label='Frequentie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='budget' label='Budget' />
										</tr>

										{data3.map(toewijzing => (
											<React.Fragment key={toewijzing.uuid}>
												<tr style={noHover}>
													<td>
														<button id={toewijzing.id} className='button read' onClick={event => this.handleShow(event, toewijzing.id, 3)}/>
														<button className='button pdf-export' onClick={() => this.handleBerichtExport(toewijzing.id)}/>
													</td>
													<td>
														{/* Fase: {row.start_stop_fase} ID: {row.start_stop_id} Status: {row.start_stop_status} */}
														{this.getShowStartButton(toewijzing) && (
															<button id={toewijzing.id} className='button start' onClick={event => this.onStartStopAddStart(event, toewijzing.id, 3)} disabled={!this.getEnableStartStopButton(toewijzing)}/>
														)}
														{this.getShowStopButton(toewijzing) && (
															<button id={toewijzing.id} className='button stop' onClick={event => this.onStartStopAddStop(event, toewijzing.id, 3)} disabled={!this.getEnableStartStopButton(toewijzing)}/>
														)}
														<button id={toewijzing.id} className='button history' onClick={() => this.onStartStopLoadHistory(toewijzing, 3)} disabled={!this.getEnableStartStopHistoryButton(toewijzing)}/>
													</td>
													<td>
														<button
															id={toewijzing.id}
															className='button add'
															onClick={event => this.onVerandwoordingAdd(event, toewijzing.id, 3)}
															disabled={lockedByMissingContracts || toewijzing.status !== 5 || toewijzing.reden_wijziging === 13 ? true : false}></button>

														<button
															id={toewijzing.id}
															className='button history'
															onClick={event => this.handleVerantwoordingHistory(event, toewijzing.id, 3)}
															disabled={toewijzing.status !== 5 || !(toewijzing.verantwoording_count > 0) ? true : false}></button>
													</td>
													<td>{<StatusCodeAllocation fase={toewijzing.start_stop_fase} status={toewijzing.status} start_stop_status={toewijzing.start_stop_status} />}</td>
													<td>
														<button
															className={notEmpty(toewijzing.status) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleStatus(notEmpty(toewijzing.start_stop_status) ? toewijzing.start_stop_status : toewijzing.status, toewijzing.start_stop_fase)}
															disabled={notEmpty(toewijzing.status) ? false : true}></button>
													</td>
													<td>{toewijzing.toewijzing_nummer}</td>
													<td>{formatDate(toewijzing.toewijzing_datum)}</td>
													<td>{notEmptyString(toewijzing.categorie) ? toewijzing.categorie === null ? 'Generiek' : toewijzing.categorie : <label>Geen data</label>}</td>
													<td>
														{notEmptyString(toewijzing.product) ? (
															toewijzing.product === null && toewijzing.categorie === null ? (
																'Generiek'
															) : toewijzing.product === null && toewijzing.categorie !== null ? (
																'Aspecifiek'
															) : (
																toewijzing.product
															)
														) : (
															<label>Geen data</label>
														)}
													</td>
													<td>{formatDate(toewijzing.begindatum)}</td>
													<td>{formatDate(toewijzing.einddatum)}</td>
													<td>{notEmpty(toewijzing.volume) ? toewijzing.volume : <label>Geen data</label>}</td>
													<td>{notEmpty(toewijzing.eenheid_label) ? toewijzing.eenheid_label : <label>Geen data</label>}</td>
													<td>{notEmpty(toewijzing.frequentie_label) ? toewijzing.frequentie_label : <label>Geen data</label>}</td>
													<td>{notEmpty(toewijzing.budget) ? toewijzing.budget : <label>Geen data</label>}</td>
												</tr>
												{history.id === toewijzing.id && history.data.length !== 0 && (
													<tr style={noHover} key={toewijzing.uuid + 'startstop'}>
														<td style={noBorder} colSpan={14}>
															<table className='inner-table'>
																<tbody>
																	<tr style={noHover}>
																		<td style={noBorder} colSpan={8}>
																			<h3>
                                                                                Start en stop voor {notEmpty(toewijzing.toewijzing_nummer) && toewijzing.toewijzing_nummer}
																				<span>({history.data.length})</span>
																			</h3>
																		</td>
																	</tr>
																	<tr style={noHover}>
																		<td style={noBorder}></td>
																		<td style={noBorder}>#</td>
																		<td style={noBorder}>Type</td>
																		<td style={noBorder}>Datum</td>
																		<td style={noBorder}>Reden Stop</td>
																		<td style={noBorder}>Status</td>
																		<td style={noBorder}></td>
																		<td style={noBorder}>Retourcodes</td>
																	</tr>
																	{history.data.map((startstop, index) => (
																		<tr key={startstop.uuid} style={noHover}>
																			<td style={noBorder}>
																				<td style={noBorder}>
																					{startstop.status !== 1 && startstop.status !== 2 && startstop.status !== 4 && (
																						<button
																							id={toewijzing.id + ' | ' + startstop.id}
																							className={'button delete'}
																							onClick={event => this.onStartStopRemove(event, startstop, 1)}
																							disabled={index === 0 ? false : true}></button>
																					)}
																				</td>
																				{startstop.status === 4 && (
																					<button
																						id={toewijzing.id + ' | ' + startstop.id}
																						className='button edit'
																						onClick={event => this.onStartStopEdit(event, startstop.id, toewijzing.id, 3)}
																						disabled={index === 0 || verantwoording.declaratieregel_id !== null ? false : true}></button>
																				)}
																			</td>
																			<td style={noBorder}>{notEmpty(startstop.row) ? startstop.row : <label>Geen data</label>}</td>

																			<td style={noBorder}>
																				{notEmpty(startstop.type) ? startstop.type === 'start' ? 'Start' : 'Stop' : <label>Geen data</label>}
																			</td>
																			<td style={noBorder}>
																				{notEmpty(startstop.type) ? (
																					startstop.type === 'start' ? (
																						startstop.startdatum ? (
																							formatDate(startstop.startdatum)
																						) : (
																							''
																						)
																					) : startstop.einddatum ? (
																						formatDate(startstop.einddatum)
																					) : (
																						''
																					)
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>

																			<td style={noBorder}>{notEmpty(startstop.reden_label) ? startstop.reden_label : <label>Geen data</label>}</td>
																			<td style={noBorder}>
																				{startstop.status === 2
																					? 'Ingediend'
																					: startstop.status === 3
																						? 'Goedgekeurd'
																						: startstop.status === 4
																							? 'Afgekeurd'
																							: 'Nog niet gestart'}
																			</td>
																			<td style={noBorder}>{formatDate(startstop.gewijzigd) + ' ' + formatTime(startstop.gewijzigd, 1)}</td>
																			<td style={noBorder}>{notEmpty(startstop.retourcodes) ? startstop.retourcodes : <label>Geen data</label>}</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</td>
													</tr>
												)}
												{verantwoording.id === toewijzing.id && verantwoording.data.length !== 0 && (
													<tr style={noHover} key={toewijzing.uuid + 'verantwoording'}>
														<td style={noBorder} colSpan={14}>
															<table className='inner-table'>
																<tbody>
																	<tr style={noHover}>
																		<td style={noBorder} colSpan={8}>
																			<h3>
                                                                                Verantwoording voor {notEmpty(toewijzing.toewijzing_nummer) && toewijzing.toewijzing_nummer}
																				<span>({verantwoording.data.length})</span>
																			</h3>
																		</td>
																	</tr>
																	<tr style={noHover}>
																		<td style={noBorder}></td>
																		<td style={noBorder}>#</td>
																		<td style={noBorder}>Begindatum</td>
																		<td style={noBorder}>Einddatum</td>
																		<td style={noBorder}>Product</td>
																		<td className='align-right' style={noBorder}>
                                                                            Volume
																		</td>
																		<td style={noBorder}>Eenheid</td>
																		<td style={noBorder}>Status</td>
																		<td style={noBorder}></td>
																	</tr>

																	{verantwoording.data.map(verantwoording => (
																		<tr key={verantwoording.uuid} style={noHover}>
																			<td style={noBorder}>
																				<button
																					id={toewijzing.id + ' | ' + verantwoording.id}
																					className='button delete'
																					onClick={event =>
																						this.onVerandwoordingRemove(
																							event,
																							verantwoording.id,
																							toewijzing.id, // toewijzing
																							3,
																						)
																					}
																					disabled={
																						verantwoording.status !== 1 ||
                                                                                        verantwoording.traject === 1 ||
                                                                                        verantwoording.declaratieregel_id !== null ||
                                                                                        verantwoording.previeus_declaratieregel_id !== null
																					}
																				/>

																				<button
																					id={toewijzing.id + ' | ' + verantwoording.id}
																					className='button edit'
																					onClick={event =>
																						this.onVerandwoordingEdit(
																							event,
																							verantwoording.id,
																							toewijzing.id, // toewijzing
																							3,
																						)
																					}
																					disabled={
																						verantwoording.status !== 1 ||
                                                                                        verantwoording.traject === 1 ||
                                                                                        verantwoording.declaratieregel_id !== null ||
                                                                                        verantwoording.previeus_declaratieregel_id !== null
																					}
																				/>
																			</td>
																			<td style={noBorder}>{notEmpty(verantwoording.row) ? verantwoording.row : <label>Geen data</label>}</td>

																			<td style={noBorder}>{notEmpty(verantwoording.datum) ? formatDate(verantwoording.datum) : <label>Geen data</label>}</td>
																			<td style={noBorder}>
																				{notEmpty(verantwoording.einddatum) ? formatDate(verantwoording.einddatum) : <label>Geen data</label>}
																			</td>
																			<td style={noBorder} title={verantwoording.product_name}>
																				{notEmpty(verantwoording.product_code) ? verantwoording.product_code : <label>Geen data</label>}
																			</td>
																			<td className='align-right' style={noBorder}>
																				{notEmpty(verantwoording.volume) ? verantwoording.volume : <label>Geen data</label>}
																			</td>
																			<td style={noBorder}>
																				{notEmpty(verantwoording.eenheid_label) ? (
																					verantwoording.eenheid_label
																				) : notEmpty(verantwoording.eenheid_label_ca) ? (
																					verantwoording.eenheid_label_ca
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>
																			<td style={noBorder}>
																				{notEmpty(verantwoording.status) ? (
																					verantwoording.status === 1 ? (
																						'Aangemaakt'
																					) : verantwoording.status === 2 ? (
																						'Ingediend'
																					) : verantwoording.status === 3 ? (
																						'Goedgekeurd'
																					) : verantwoording.status === 4 ? (
																						'Afgekeurd'
																					) : verantwoording.status === 5 ? (
																						'Gecrediteerd'
																					) : (
																						<label>Geen data</label>
																					)
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>
																			<td style={noBorder}>
																				{notEmpty(verantwoording.aangemaakt) ? (
																					formatDate(verantwoording.aangemaakt, 2) + ', ' + formatTime(verantwoording.aangemaakt, 2)
																				) : (
																					<label>Geen data</label>
																				)}
																			</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</td>
													</tr>
												)}
											</React.Fragment>
										))}
									</tbody>
								</table>
							</section>
						) : (
							<section className='table-empty pb2'>Er is nog geen historie van toewijzingen</section>
						)}
					</section>
				</section>
			</>
		);
	};
}
