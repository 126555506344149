import React from 'react';
import GeenData from '../miscellaneous/GeenData';
import formatDate from '../../../util/formatDate';
import formatTime from '../../../util/formatTime';
import notEmpty from '../../../util/notEmpty';

interface Props {
	vot: {
		status: number
		aangemaakt: string
		ingediend: string
		goedgekeurd: string
		afgekeurd: string
		toegewezen: string
		afgewezen: string
		onderzoek: string
		gearchiveerd: string
	}
}

const separator = ', ';

const StatusCodeAllocationRequestDateTime = (props: Props) => {
	const {vot} = props;

	return vot.status === 1 ? (
		notEmpty(vot.aangemaakt) ? (
			formatDate(vot.aangemaakt, 2) + separator + formatTime(vot.aangemaakt, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 2 ? (
		notEmpty(vot.ingediend) ? (
			formatDate(vot.ingediend, 2) + separator + formatTime(vot.ingediend, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 3 ? (
		notEmpty(vot.goedgekeurd) ? (
			formatDate(vot.goedgekeurd, 2) + separator + formatTime(vot.goedgekeurd, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 4 ? (
		notEmpty(vot.afgekeurd) ? (
			formatDate(vot.afgekeurd, 2) + separator + formatTime(vot.afgekeurd, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 5 ? (
		notEmpty(vot.toegewezen) ? (
			formatDate(vot.toegewezen, 2) + separator + formatTime(vot.toegewezen, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 6 ? (
		notEmpty(vot.afgewezen) ? (
			formatDate(vot.afgewezen, 2) + separator + formatTime(vot.afgewezen, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 7 ? (
		notEmpty(vot.onderzoek) ? (
			formatDate(vot.onderzoek, 2) + separator + formatTime(vot.onderzoek, 2)
		) : (
			<GeenData />
		)
	) : vot.status === 8 ? (
		notEmpty(vot.gearchiveerd) ? (
			formatDate(vot.gearchiveerd, 2) + separator + formatTime(vot.gearchiveerd, 2)
		) : (
			<GeenData />
		)
	) : (
		<GeenData />
	);
};
export default StatusCodeAllocationRequestDateTime;
