import { DbUser, Melding } from '../types';
import { makeRouter } from './helpers/builder';

const {Get} = makeRouter('/users');

export const usersMe = 
	Get`/auth`.json<{ response: Omit<DbUser, 'token'> }>();
export const usersNotification = 
	Get`/${'userId'}/notifications`.json<{ response: Melding[] }>();
export const usersNotificationChanged = 
	Get`/${'userId'}/notifications/changed`.json<{ response: Pick<Melding, 'gewijzigd'>[] }>();
