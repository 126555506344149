
interface InputSelectProps {
	id?: string;
	valueOptions?: string[] | number[];
	onChange: (value: string) => void;
	className: string;
}

const InputSelect: React.FC<InputSelectProps> = ({id, onChange, className, valueOptions}) => {
	const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(e.target.value);
	};
	return (
		<select id={id} className={className} onChange={onChangeHandler}>
			<option value={undefined}>{'- '}</option>
			{valueOptions?.map((item, i) => (
				<option key={i} value={item}>{item}</option>
			))}
		</select>
	);
};

export default InputSelect;
