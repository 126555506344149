import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import removeDuplicates from '../../util/removeDuplicates';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';
import ExcelListStatement from '../report/ExcelListStatement';

export default class ListStatement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Verantwoordingen',
					figure: '',
					column: 'status_ts',
					label: 'Datum en tijd',
					order: false,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			statements: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {client: 'all', gemeente: 'all', status: 'all', periode: 'all'},
			clients: [],
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getStatement = await api.getStatement(aanbieder, api.makeHeader(token));
		if (getStatement.error && getStatement.error === true) {
			this.props.handleForm(getStatement);
			return null;
		}

		const statements = getStatement.response;

		let gemeentes = statements.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeentes = removeDuplicates(gemeentes, 'gemeente_code');

		let clients = statements.map(row => {
			return {
				client_id: row.client_id,
				naam: row.naam,
				gemeente_code: row.gemeente_code,
			};
		});
		clients = removeDuplicates(clients, 'client_id');

		const periodmap =
			statements.length === 0
				? []
				: statements.map(row => {
					return {
						period: row.beginmaand,
					};
				});
		const periods = statements.length === 0 ? [] : sortArray(removeDuplicates(periodmap, 'period'), false, 'period');
		const today = formatDate(new Date(), 3);
		let period = statements.length === 0 ? 'all' : periods[0].period;
		let index = statements.findIndex(row => row.periode === today);
		if (index !== -1) {
			period = statements[index].periode;
		}

		this.setState(state => {
			state.loaded = true;
			state.statements = getStatement.response;
			state.clients = clients;
			state.gemeentes = gemeentes;
			state.filters.periode = period;
			return state;
		});
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, statements, clients, gemeentes, filters} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let table = [];

		let clientList = sortArray(clients, true, 'naam');
		if (filters.gemeente === 'all') {
			table = statements;
		} else {
			table = statements.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
			clientList = clientList.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		if (filters.client !== 'all') {
			table = statements.filter(row => {
				return row.client_id === Number(filters.client);
			});
		}

		if (filters.status !== 'all') {
			table = table.filter(row => {
				return row.status === Number(filters.status);
			});
		}

		let monthList = table.map(row => {
			return {
				periode: row.beginmaand,
			};
		});
		monthList = removeDuplicates(monthList, 'periode');
		monthList = sortArray(monthList, false, 'periode');

		if (filters.periode !== 'all') {
			table = table.filter(row => {
				return row.beginmaand === filters.periode;
			});
		}

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={statements.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									{gemeentes.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{row.gemeente_naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f2'>
								<span>
									<strong>Cliënt</strong>
								</span>
								<select
									selected={filters.client}
									name='client'
									id='f2'
									value={filters.client}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={statements.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									{clientList.map(row => (
										<option key={row.client_id} value={row.client_id}>
											{row.naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f3'>
								<span>
									<strong>Status</strong>
								</span>

								<select
									selected={filters.status}
									name='status'
									id='f3'
									value={filters.status}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={statements.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									<option key='key2' value='1'>
										Aangemaakt
									</option>
									<option key='key1' value='2'>
										Ingediend
									</option>
									<option key='key3' value='3'>
										Goedgekeurd
									</option>
									<option key='key4' value='4'>
										Afgekeurd
									</option>
								</select>
							</label>
							<span>
								<strong>Periode</strong>
							</span>

							<label htmlFor='f4'>
								<select
									selected={filters.periode}
									name='periode'
									id='f4'
									value={filters.periode}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={statements.length === 0 ? true : false}>
									<option key='all' value='all'>
										Toon alle
									</option>
									<option disabled></option>
									{monthList.map(row => (
										<option key={row.periode} value={row.periode}>
											{formatDate(row.periode, 3)}
										</option>
									))}
								</select>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelListStatement dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen verantwoordingen gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
							<table className='data-table'>
								<tbody>
									<tr>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Cliënt' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='toewijzing_nummer' label='Toewijzing' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product_label' label='Product' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='volume'
											label='Volume'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid' label='Eenheid' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcode' label='Retourcode' />
										<td></td>
									</tr>
									{data.map(row => (
										<tr key={row.id} style={noHover}>
											<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.toewijzing_nummer) ? row.toewijzing_nummer : <label>Geen data</label>}</td>
											<td>{notEmpty(row.product_label) ? row.product_label : <label>Geen data</label>}</td>
											<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum, 2) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum, 2) : <label>Geen data</label>}</td>
											<td className='align-right'>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
											<td>{notEmpty(row.eenheid) ? row.eenheid : <label>Geen data</label>}</td>
											<td>
												{notEmpty(row.status) ? (
													row.status === 1 ? (
														'Aangemaakt'
													) : row.status === 2 ? (
														'Ingediend'
													) : row.status === 3 ? (
														'Goedgekeurd'
													) : row.status === 4 ? (
														'Afgekeurd'
													) : (
														<label>Geen data</label>
													)
												) : (
													<label>Geen data</label>
												)}
											</td>
											<td>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.retourcode) ? splitJoin(row.retourcode, ',', ', ') : <label>Geen data</label>}</td>
											<td width={1}>
												<button
													className={notEmpty(row.retourcode) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleRetourcode(row.retourcode)}
													disabled={notEmpty(row.retourcode) ? false : true}></button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
