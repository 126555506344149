import { useEffect, useRef } from 'react';
import styles from './index.module.css';

interface AlertBoxProps {
	alertType?: 'success' | 'warning' | 'error',
	message?: string,
	autoFocus?: boolean;
	onClose?: () => void;
}

const AlertBox: React.FC<AlertBoxProps> = ({alertType, message, autoFocus, onClose}): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (autoFocus) {
			ref.current?.scrollIntoView({block: 'center', behavior: 'auto'});
		}
	} ,[autoFocus]);
	return (
		<div className={styles.container} ref={ref}>
			<div className={alertType && styles[alertType]}>
				<div className={styles.textWrapper}>
					<p>{message}</p>
				</div>
				<div className={styles.buttonWrapper}>
					<button className={styles.closeButton} onClick={onClose}/>
				</div>
			</div>
		</div>
	);
};

export default AlertBox;
