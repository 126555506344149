import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import notEmpty from '../../util/notEmpty';
import removeDuplicates from '../../util/removeDuplicates';
import formatCurrency from '../../util/formatCurrency';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';
import ExcelListInvoiceRule from '../report/ExcelListInvoiceRule';

export default class ListInvoiceRule extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Declaratieregels',
					figure: '',
					column: 'periode',
					label: 'Periode',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			invoicerules: [],
			loaded: false,
			update: 0,
			trigger: 0,
			filters: {gemeente: 'all', client: 'all', periode: 'all', status: 'all', declaratie: 'all'},
			clients: [],
			gemeentes: [],
			periodes: [],
			sidebarTrigger: 0,
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const getInvoiceRules = await api.getInvoiceRules(aanbieder, api.makeHeader(token));
		if (getInvoiceRules.error && getInvoiceRules.error === true) {
			this.props.handleForm(getInvoiceRules);
			return null;
		}

		let invoicerules = getInvoiceRules.response;

		let periode = 'all';
		if (invoicerules.length !== 0) {
			invoicerules = invoicerules.map(e => ({
				...e,
				dc: !e.debit ? 'Credit' : 'Debet',
			}));
			const find = invoicerules.find(row => {
				return row.periode === formatDate(new Date(), 3);
			});
			if (find === undefined) {
				let periodeList = invoicerules.map(row => {
					return {periode: row.periode};
				});
				periodeList = removeDuplicates(periodeList, 'periode');
				periodeList = sortArray(periodeList, false, 'periode');
				periode = periodeList[0].periode;
			}
		}

		this.setState(state => {
			state.loaded = true;
			state.invoicerules = invoicerules;
			state.filters.periode = periode;
			return state;
		});

		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	componentDidUpdate = () => {
		if (this.state.sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleFilter = (filter, value) => {
		this.setState(state => {
			state.filters[filter] = value;

			if (filter === 'gemeente') {
				state.filters = {
					...state.filters,
					client: 'all',
					periode: 'all',
					status: 'all',
					declaratie: 'all',
				};
			} else if (filter === 'client') {
				state.filters = {
					...state.filters,
					periode: 'all',
					status: 'all',
					declaratie: 'all',
				};
			} else if (filter === 'periode') {
				state.filters = {
					...state.filters,
					status: 'all',
					declaratie: 'all',
				};
			} else if (filter === 'status') {
				state.filters = {
					...state.filters,
					declaratie: 'all',
				};
			}
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	render = () => {
		const {loaded, tables, invoicerules, filters} = this.state;
		const {dbUser} = this.props;

		if (!loaded) return <Loading message='' />;

		let table = invoicerules;

		// gemeente
		let gemeenteList = invoicerules.map(row => {
			return {
				gemeente_code: row.gemeente_code,
				gemeente_naam: row.gemeente_naam,
			};
		});
		gemeenteList = removeDuplicates(gemeenteList, 'gemeente_code');
		gemeenteList = sortArray(gemeenteList, true, 'gemeente_naam');

		if (filters.gemeente !== 'all') {
			table = table.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}

		// clients
		let clientList = invoicerules;
		if (filters.gemeente !== 'all') {
			clientList = clientList.filter(row => {
				return row.gemeente_code === filters.gemeente;
			});
		}
		clientList = removeDuplicates(clientList, 'client_id');
		clientList = sortArray(clientList, true, 'naam');

		if (filters.client !== 'all') {
			table = table.filter(row => {
				return row.client_id === Number(filters.client);
			});
		}

		// periode
		let periodeList = [];
		if (filters.gemeente !== 'all') {
			if (filters.client !== 'all') {
				periodeList = table
					.filter(row => {
						return row.gemeente_code === filters.gemeente && Number(row.client_id) === Number(filters.client);
					})
					.map(row => {
						return {periode: row.periode};
					});
			} else {
				periodeList = table
					.filter(row => {
						return row.gemeente_code === filters.gemeente;
					})
					.map(row => {
						return {periode: row.periode};
					});
			}
		} else {
			periodeList = table.map(row => {
				return {periode: row.periode};
			});
		}
		periodeList = removeDuplicates(periodeList, 'periode');
		periodeList = sortArray(periodeList, false, 'periode');

		if (filters.periode !== 'all') {
			table = table.filter(row => {
				return row.periode === filters.periode;
			});
		}

		if (filters.status !== 'all') {
			table = table.filter(row => {
				return row.status === Number(filters.status);
			});
		}

		let declaratieList = table;
		declaratieList = removeDuplicates(declaratieList, 'factuurnummer');
		declaratieList = sortArray(declaratieList, true, 'factuurnummer');

		if (filters.declaratie !== 'all') {
			table = table.filter(row => {
				return row.factuurnummer === filters.declaratie;
			});
		}

		const data = sortArray(table, tables[1].order, tables[1].column);

		const noHover = {
			backgroundColor: 'transparent',
		};

		return (
			<section className='client-data'>
				<section className='table-element'>
					<section className='table-header'>
						<figure className={tables[1].figure}></figure>
						<h2>{tables[1].name}</h2>
						<div className='filters'>
							<label>Filters</label>
							<label htmlFor='f1'>
								<span>
									<strong>Gemeente</strong>
								</span>
								<select
									selected={filters.gemeente}
									name='gemeente'
									id='f1'
									value={filters.gemeente}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoicerules.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{gemeenteList.map(row => (
										<option key={row.gemeente_code} value={row.gemeente_code}>
											{row.gemeente_naam}
										</option>
									))}
								</select>
							</label>
							<label htmlFor='f2'>
								<span>
									<strong>Cliënt</strong>
								</span>
								<select
									selected={filters.client}
									name='client'
									id='f2'
									value={filters.client}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoicerules.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{clientList.map(row => (
										<option key={row.client_id} value={row.client_id}>
											{`${row.naam}`}
										</option>
									))}
								</select>
							</label>

							<label htmlFor='f4'>
								<span>
									<strong>Periode</strong>
								</span>
								<select
									selected={filters.periode}
									name='periode'
									id='f4'
									value={filters.periode}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoicerules.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{periodeList.map(row => (
										<option key={row.periode} value={row.periode}>
											{row.periode}
										</option>
									))}
								</select>
							</label>

							<label htmlFor='f3'>
								<span>
									<strong>Status</strong>
								</span>
								<select
									selected={filters.status}
									name='status'
									id='f3'
									value={filters.status}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoicerules.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									<option key='key2' value='1'>
                                        Aangemaakt
									</option>
									<option key='key1' value='2'>
                                        Ingediend
									</option>
									<option key='key3' value='3'>
                                        Goedgekeurd
									</option>
									<option key='key4' value='4'>
                                        Afgekeurd
									</option>
									<option key='key5' value='5'>
                                        Gecrediteerd
									</option>
								</select>
							</label>

							<label htmlFor='f4'>
								<span>
									<strong>Declaratie</strong>
								</span>
								<select
									selected={filters.declaratie}
									name='declaratie'
									id='f4'
									value={filters.declaratie}
									onChange={event => this.handleFilter(event.target.name, event.target.value)}
									disabled={invoicerules.length === 0 ? true : false}>
									<option key='all' value='all'>
                                        Toon alle
									</option>
									<option disabled></option>
									{declaratieList.map(row => (
										<option key={row.factuurnummer} value={row.factuurnummer}>
											{row.factuurnummer}
										</option>
									))}
								</select>
							</label>
							<label className='options'>Download</label>
							{data.length !== 0 ? (
								<label>
									<ExcelListInvoiceRule dbUser={dbUser} data={data} />
								</label>
							) : (
								<label>
									<button disabled={true} className='button download disabled'>
										<strong>Excel</strong>
									</button>
								</label>
							)}
						</div>
					</section>

					{data.length === 0 && <section className='table-empty'>Er zijn geen declaratieregels gevonden die aan de criteria voldoen.</section>}

					{data.length !== 0 && (
						<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
							<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
							<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>

							<table className='data-table'>
								<tbody>
									<tr>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='gemeente_naam' label='Gemeente' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='naam' label='Cliënt' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='toewijzing_nummer' label='Toewijzing' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product' label='Product' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='volume'
											label='Volume'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid' label='Eenheid' />
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='tarief'
											label='Tarief'
											className='align-right'
										/>
										<TableColumnHeader
											handleSort={this.handleSort}
											column={tables[1].column}
											order={tables[1].order}
											table='1'
											data='totaal'
											label='Bedrag'
											className='align-right'
										/>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='dc' label='Debet/Credit' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='periode' label='Periode' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum en tijd' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='factuurnummer' label='Declaratienummer' />
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcode' label='Retourcode' />
										<td></td>
										<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='bsn' label='Bsn' />
									</tr>
									{data.map(row => (
										<tr key={row.id} style={noHover}>
											<td>{notEmpty(row.gemeente_naam) ? row.gemeente_naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.naam) ? row.naam : <label>Geen data</label>}</td>
											<td>{notEmpty(row.toewijzing_nummer) ? row.toewijzing_nummer : <label>Geen data</label>}</td>
											<td>{row.product}</td>
											<td className='align-right'>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
											<td>{notEmpty(row.eenheid) ? row.eenheid : <label>Geen data</label>}</td>
											<td className='align-right'>{notEmpty(row.tarief) ? formatCurrency(row.tarief) : <label>Geen data</label>}</td>
											<td className='align-right'>{notEmpty(row.totaal) ? formatCurrency(row.totaal) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.totaal) ? (row.totaal < 0 ? 'Credit' : 'Debet') : <label>Geen data</label>}</td>
											<td>{notEmpty(row.periode) ? row.periode : <label>Geen data</label>}</td>
											<td>
												{notEmpty(row.status) ? (
													row.status === 1 ? (
														'Aangemaakt'
													) : row.status === 2 ? (
														'Ingediend'
													) : row.status === 3 ? (
														'Goedgekeurd'
													) : row.status === 4 ? (
														'Afgekeurd'
													) : row.status === 5 ? (
														'Gecrediteerd'
													) : (
														<label>Geen data</label>
													)
												) : (
													<label>Geen data</label>
												)}
											</td>

											<td>{notEmpty(row.status_ts) ? formatDate(row.status_ts, 2) + ', ' + formatTime(row.status_ts, 2) : <label>Geen data</label>}</td>
											<td>{notEmpty(row.factuurnummer) ? row.factuurnummer : <label>Geen data</label>}</td>
											<td>{notEmpty(row.retourcode) ? splitJoin(row.retourcode, ',', ', ') : <label>Geen data</label>}</td>
											<td>
												<button
													className={notEmpty(row.retourcode) ? 'button info' : 'button info disabled'}
													onClick={() => this.handleRetourcode(row.retourcode)}
													disabled={notEmpty(row.retourcode) ? false : true}></button>
											</td>
											<td>{notEmpty(row.bsn) ? row.bsn : <label>Geen data</label>}</td>
										</tr>
									))}
								</tbody>
							</table>
						</section>
					)}
				</section>
			</section>
		);
	};
}
