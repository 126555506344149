import React, { useState, useMemo, useEffect, useRef } from 'react';
import sortData from '../../../../util/sortData';
import assertNever from '../../../../util/assertNever';
import formatDate from '../../../../util/formatDate';
import { changeToSelectable, checkIfSelectable, TableColumnDefinition, 
	TSortOrder, U, getControlsColumns, SelectableData, ControlsValues, DataTableOptions } from '../types';
import styles from './index.module.css';
import TableUpdateControls from '../TableUpdateControls';
import TableHeader from '../TableHeader';
import TableRows from '../TableRows';
import TableAddSubmitControls from '../TableAddSubmitControls';
import { DbUser } from '../../../../types';

type DataTableProps<T, K extends keyof T> = {
	data: Array<T>;
	columns: Array<TableColumnDefinition<T, K>>;
	options: DataTableOptions<T>;
	onSubmitChanges?: (d: SelectableData<T>[]) => Promise<boolean>;
	filters?: {[K in keyof T]?: unknown};
	updateStatus: number | undefined;
	dbUser: DbUser
};

/**@todo: check the dates selector across the component */
const DataTable = <T, K extends keyof T> ({data, columns, options, onSubmitChanges, filters, updateStatus, dbUser}: DataTableProps<T, K>): JSX.Element => {

	// upack data table options from options prop
	const {addNewEntries, cascadeParameters, indexable, resetAllCallback, selectable, updateAndReset} = options;
    
	// make a copy of the original dataset on first render
	const [originalDataSet, setOriginalDataSet] = useState(() => selectable ? changeToSelectable(data, true) : data);

	// data set that is going to be perform mutation on
	const [tableData, setTableData] = useState<U<T>[]>();

	// update datasets based on response status from the parent
	useEffect(() => {
		if (updateStatus == 200) {
			// change  oth orinal dataset and data table if status is 200
			setOriginalDataSet(selectable ? changeToSelectable(data, false) : data);
			setTableData(selectable ? changeToSelectable(data, false) : data);
		} else {
			// just change the dataset and keep original
			setTableData(selectable ? changeToSelectable(data, false) : data);
		}
	}, [data, selectable, updateStatus]);

	const today = formatDate((new Date),1);
	const [sortControl, setSortControl] = useState<[K, TSortOrder]>();
	const controlsColumns = getControlsColumns(columns);
	const [selectAll, setSelectAll] = useState<boolean>(false);

	// data that is displayed on the screen and synced with tableData (tableData)
	// is where we can perfom mutation. 
	const displayData = useMemo(()=>{
		if (tableData) {
			const newFilteredData = tableData.filter((item) => {
				if (filters) {
					for (const [key, value] of Object.entries(filters)) {
						if (value instanceof Date) {
							if (value == undefined) continue;
							if (item[key] <= value.toISOString()) return false;
						} else {
							if (value == undefined) continue;
							if (item[key] !== value) return false; 
						}
					}
				} 
				return true;
			});
			return newFilteredData;
		}
	},[tableData, filters]);
    
	const editedData = useMemo(() => {
		return tableData?.filter((entry): entry is SelectableData<T> => checkIfSelectable(entry) && (entry.rowStatus !== 'Origineel' 
       && entry.rowStatus !== 'Bewerking' && entry.rowStatus !== 'Verkeerde Waarde'));
	},[tableData]);

	const [newRowValues, setNewRowValues] = useState<SelectableData<T> | null>(null);

	const toggleSortHandler = (column: TableColumnDefinition<T,K>) => {
		setSortControl(prevState => {
			if (prevState?.[1] === 'asc' && prevState?.[0] === column.key) {
				return [column.key, 'desc'];
			}
			return [column.key, 'asc'];
		});
	};
	const [controlsValue, setControlValues] = useState<ControlsValues<T>>((controlsColumns).reduce((accumulator, col) => {
		switch (col.columnType) {
			case 'Text':
				return {...accumulator, [col.key]: ''};
			case 'Currency':
				return {...accumulator, [col.key]: col.validations ? col.validations.min : 0.00};
			case 'Number':
				return {...accumulator, [col.key]: col.validations ? col.validations.min : 0};
			case 'Date':
				return {...accumulator, [col.key]: today};
			case 'List':
				return {...accumulator, [col.key]: col.columnListValues ? col.columnListValues[0] : ''};
			default:
				return assertNever(col);
		}
	},{}));

	/**
     * Functions related to Table Row Headers
     */

	useEffect(() => {
		if (sortControl) {
			setTableData(prevData => {
				if (prevData) return sortData(prevData, sortControl[1], sortControl[0]);
			});
		}
	},[sortControl]);

	const selectAllHandler = () => {
		setSelectAll(!selectAll);
	};
	useEffect(() => {
		if (selectAll) {
			setTableData((prevData) => {
				const newData = prevData?.map((item) => {
					if (checkIfSelectable(item)) {
						if (item.rowStatus === 'Origineel') {
							return { ...item, selected: true };
						}
					}
					return item;
				});
				return newData;
			});
		} else {
			setTableData((prevData) => {
				const newData = prevData?.map((item) => {
					if (checkIfSelectable(item)) {
						if (item.rowStatus === 'Origineel') {
							return { ...item, selected: false };
						}
					}
					return item;
				});
				return newData;
			});
		}
	}, [selectAll, setTableData]);

	/**
     * Funcions related to TableRowscomponent
     */
	const tableEntriesOnChangeHandler = (v: string | number, row: SelectableData<T>, col: TableColumnDefinition<T,K>) => {
		if (col.key in row) {
			switch (col.columnType) {
				case 'Currency':
					setTableData((prevData) => {
						const newNumber = typeof v == 'string' ? Number.parseFloat(v) * 100 : v * 100;
						if (prevData) {
							const newData = [...prevData];
							const originalEntry = {...row};
							const { originalRowIndex } = originalEntry;
							const modifiedEntry: SelectableData<T> = {...originalEntry, selected: true, 
								rowStatus: 'Bewerking'};
							const targetKey = col.key.toString();
							modifiedEntry[targetKey] = newNumber;
							newData[originalRowIndex] = modifiedEntry;
							return newData;
						}
					});
					break;
				case 'Number':
					setTableData((prevData) => {
						const newNumber = typeof v == 'string' ? Number.parseInt(v) : v;
						if (prevData) {
							const newData = [...prevData];
							const originalEntry = {...row};
							const { originalRowIndex } = originalEntry;
							const modifiedEntry: SelectableData<T> = {...originalEntry, selected: true, 
								rowStatus: 'Bewerking'};
							const targetKey = col.key.toString();
							modifiedEntry[targetKey] = newNumber;
							newData[originalRowIndex] = modifiedEntry;
							return newData;
						}
					});
					break;
				case 'Date':
					setTableData((prevData) => {
						if (prevData) {
							// const newDate = new Date(e.target.value);
							const newDate = typeof v == 'string' ? v : v.toString();
							const newData = [...prevData];
							const originalEntry = {...row};
							const { originalRowIndex } = originalEntry;
							const modifiedEntry: SelectableData<T> = { ...originalEntry, selected: true, 
								rowStatus: 'Bewerking' };
							const targetKey = col.key.toString();
							modifiedEntry[targetKey] = newDate;
							newData[originalRowIndex] = modifiedEntry;
							return newData;
						}
					});
					break;
				case 'Text':
					setTableData((prevData) => {
						const newEntry = typeof v == 'string' ? v : v.toString();
						if (prevData) {
							const newData = [...prevData];
							const originalEntry = {...row};
							const { originalRowIndex } = row;
							const modifiedEntry: SelectableData<T> = {...originalEntry, selected: true, 
								rowStatus: 'Bewerking'};
							const targetKey = col.key.toString();
							modifiedEntry[targetKey] = newEntry;
							newData[originalRowIndex] = modifiedEntry;
							return newData;
						}
					});
					break;
				case 'List':
					setTableData((prevData) => {
						const newEntry = typeof v == 'string' ? v : v.toString();
						if (prevData) {
							const newData = [...prevData];
							const originalEntry = {...row};
							const { originalRowIndex } = row;
							const modifiedEntry: SelectableData<T> = { ...originalEntry, selected: true, rowStatus: 'Bewerking'};
							const targetKey = col.key.toString();
							modifiedEntry[targetKey] = newEntry;
							newData[originalRowIndex] = modifiedEntry;
							return newData;
						}
					});
					break;
			}
		}
	};

	const saveModifiedRowHandler = (row: SelectableData<T>) => {
		setTableData((prevData) => {
			if (prevData) {
				const newData = [...prevData];
				const originalEntry = {...row};
				const { originalRowIndex } = originalEntry;
				const modifiedEntry: SelectableData<T> = {...originalEntry, selected: true, rowStatus: 'Aangepast'};
				newData[originalRowIndex] = modifiedEntry;
				return newData;
			}
		});
	};

	const revertRowChangesHandler = (row: SelectableData<T>) => {
		setTableData((prevData) => {
			if (prevData) {
				// get original dataset
				const originalData = [...originalDataSet];
				
				// get original row index
				const { originalRowIndex } = row;
	
				// recreate the original row
				const originalRow: SelectableData<T> = {
					...originalData[originalRowIndex], 
					originalRowIndex: 
					originalRowIndex,
					selected: false, 
					rowStatus: 'Origineel',
				};
				
				// make a copy of previous state data
				const newData = [...prevData];
	
				// reassigne the recreated original row according to the index
				newData[originalRowIndex] = originalRow;
				return newData;
			}
		});
	};

	const markRowForDeletedHandler = (row: SelectableData<T>) => {
		setTableData((prevData) => {
			if (prevData) {
				const newData = [...prevData];
				const targetEntry = {...row};
				const { originalRowIndex } = targetEntry;
				if (targetEntry.rowStatus !== 'Nieuwe Invoer') {
					targetEntry.rowStatus = 'Gearchiveerd';
					newData[originalRowIndex] = targetEntry;
					return newData;
				} else {
					newData.splice(originalRowIndex, 1);
					return newData;
				}
			}
		});
	};

	const newTableRowOnChangeHandler = (v: string | number, col: TableColumnDefinition<T,K>) => {
		// const eventTarget = e.target;
		if (newRowValues !== null && col.key in newRowValues) {
			switch (col.columnType) {
				case 'Currency':
					setNewRowValues((prevData) => {
						return checkIfSelectable(prevData) ? {...prevData, [col.key]: v} : prevData;
					});
					break;
				case 'Number':
					setNewRowValues((prevData) => {
						return checkIfSelectable(prevData) ? {...prevData, [col.key]: v} : prevData;
					});
					break;
				case 'List':
					setNewRowValues((prevData) => {
						return checkIfSelectable(prevData) ? {...prevData, [col.key]: v} : prevData;
					});
					break;
				case 'Date':
					setNewRowValues((prevData) => {
						return checkIfSelectable(prevData) ? {...prevData, [col.key]: formatDate(String(v), 1)} : prevData;
					});
					break;
				case 'Text':
					setNewRowValues((prevData) => {
						return checkIfSelectable(prevData) ? {...prevData, [col.key]: v} : prevData;
					});
			} 
		}
	};


	/**
     * Funcions related to TableUpdateControls component
     */

	const onChangeControlsHandler = (v: number | string, col: TableColumnDefinition<T,K>) => {
		switch (col.columnType) {
			case 'Date':
				if (typeof v == 'string') {
					setControlValues(prevState => (
						{...prevState, [col.key]: formatDate(v, 1)}
					));
				}
				break;
			case 'Currency':
			{
				if (typeof v == 'number') {
					setControlValues(prevState => (
						{...prevState, [col.key]: v * 100}
					));
				}
				break;
			}
			case 'Number':
			{
				if (typeof v == 'number') {
					setControlValues(prevState => (
						{...prevState, [col.key]: v}
					));
				}
				break;
			}
			case 'Text':
				if (typeof v == 'string') {
					setControlValues(prevState => (
						{...prevState, [col.key]: v}
					));
				}
				break;
			case 'List':
				if (typeof v == 'string') {
					setControlValues(prevState => (
						{...prevState, [col.key]: v}
					));
				}
				break;
		}
	};

	const onClickCascadeHandler = () => {
		setTableData((prevState) => {
			if (prevState) {
				const currentData = [...prevState].map((item) => {
					if (checkIfSelectable(item) && item.selected && (item.rowStatus == 'Origineel' || item.rowStatus == 'Bewerking')) {
						return {...item, ...controlsValue, rowStatus: 'Bewerking'};   
					}
					return item;
				});
				return currentData;
			}
		});
	};

	const onResetParametersHandler = () => {
		setControlValues((controlsColumns).reduce((accumulator, col) => {
			switch (col.columnType) {
				case 'Text':
					return {...accumulator, [col.key]: ''};
				case 'Currency':
					return {...accumulator, [col.key]: col.validations ? col.validations.min : 1.00};
				case 'Number':
					return {...accumulator, [col.key]: col.validations ? col.validations.min : 1};
				case 'Date':
					return {...accumulator, [col.key]: today};
				case 'List':
					return {...accumulator, [col.key]: col.columnListValues ? col.columnListValues[0] : ''};
				default: 
					return assertNever(col);
			}
		},{}));
	};

	/**
     * Functions related to TableAddSubmitControls component
     */

	const onResetAllHandler = async () => {
		if (selectable && resetAllCallback) {
			await resetAllCallback();
		} else (setTableData(originalDataSet));
		setSortControl(undefined);
		setControlValues((controlsColumns).reduce((accumulator, col) => {
			switch (col.columnType) {
				case 'Text':
					return {...accumulator, [col.key]: ''};
				case 'Currency':
					return {...accumulator, [col.key]: col.validations ? col.validations.min : 0.00};
				case 'Number':
					return {...accumulator, [col.key]: col.validations ? col.validations.min : 0};
				case 'Date':
					return {...accumulator, [col.key]: today};
				case 'List':
					return {...accumulator, [col.key]: col.columnListValues ? col.columnListValues[0] : ''};
				default:
					return assertNever(col);
			}
		},{}));
		setNewRowValues(null);
	};

	const displayAddRowHandler = () => {
		setNewRowValues(() => {
			const newData = columns.reduce((accumulator, col) => {
				switch (col.columnType) {
					case 'Text':
						return {...accumulator, [col.key]: ''};
					case 'Currency':
						return {...accumulator, [col.key]: col.validations ? col.validations.min : 0.00};
					case 'Number':
						return {...accumulator, [col.key]: col.validations ? col.validations.min : 0};
					case 'Date':
						return {...accumulator, [col.key]: today};
					case 'List':
						return {...accumulator, [col.key]: col.columnListValues ? col.columnListValues[0] : ''};
					default:
						return assertNever(col);
				}
			}, {} as T);
			return {...newData, selected: true, rowStatus: 'Nieuwe Invoer', originalRowIndex: -1};
		});
	};

	const cancelAddRowHandler = () => {
		setNewRowValues(null);
	};

	const saveNewRowHandler = () => {

		setTableData(prevData => {
			if (tableData) {
				const copyArr = [...tableData] as SelectableData<T>[];
				if (newRowValues !== null) {
					copyArr.push(newRowValues);
					return copyArr;
				}
				return prevData;
			}
		});
		setNewRowValues(null);
	};

	const saveNewRomFormHandler = (value: T) => {
		setTableData((prevData) => {
			if (prevData) {
				const newRow: SelectableData<T> = {
					...value,
					selected: true,
					rowStatus: 'Nieuwe Invoer',
					originalRowIndex: prevData?.length,
				};
				const copyArr = [...prevData];
				copyArr.push(newRow);
				return copyArr;
			}
			return prevData;
		});
	};

	/**
     * @todo: check the query that fetches the contracts
     */

	const onClickSubmitChangesHandler = async () => {
		if (onSubmitChanges) {
			await onSubmitChanges(editedData as SelectableData<T>[]);
		} 
	};

	/**
     * Functions related to table display and styling
     */

	const [isLeftArrowIntersection, setIsLeftArrowIntersection] = useState<boolean>();
	const [isRightArrowIntersection, setIsRightArrowIntersection] = useState<boolean>();
	const leftArrowRef = useRef<HTMLDivElement>(null);
	const rightArrowRef = useRef<HTMLDivElement>(null);
	const tableRef = useRef<HTMLDivElement>(null);
    
	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		};
    
		const observer = new IntersectionObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === leftArrowRef.current) setIsLeftArrowIntersection(entry.isIntersecting);
				if (entry.target === rightArrowRef.current) setIsRightArrowIntersection(entry.isIntersecting);
			}
		}, options);

		if (leftArrowRef.current) observer.observe(leftArrowRef.current);
		if (rightArrowRef.current) observer.observe(rightArrowRef.current);

		return () => {
			if (leftArrowRef.current) observer.unobserve(leftArrowRef.current);
			if (rightArrowRef.current) observer.unobserve(rightArrowRef.current);
		};
	},[leftArrowRef,rightArrowRef]);

	const scrollRightHandler = () => {
		if (tableRef.current) {
			tableRef.current.scrollLeft = tableRef.current.scrollWidth;
		}
	};

	const scrollLeftHandler = () => {
		if (tableRef.current) {
			tableRef.current.scrollLeft = 0;
		}
	};

	return (
		<>
			{cascadeParameters && selectable && (
				<TableUpdateControls
					controlColumns={controlsColumns}
					controlValues={controlsValue}
					onChange={onChangeControlsHandler}
					onClickCascade={onClickCascadeHandler}
					resetParametersHandler={onResetParametersHandler}
				/>
			)}
			<div className={styles.outerContainer} ref={tableRef}>
				<div className={styles.innerContainer}>
					<div style={{position: 'absolute', width: '1px', height: '100%', left:'0'}} ref={leftArrowRef}/>
					<div style={{position: 'absolute', width: '1px', height: '100%', right:'0'}} ref={rightArrowRef}/>
					<table className={styles.dataTable}>
						<TableHeader 
							columns={columns}
							sortControl={sortControl}
							indexable={indexable}
							selectable={selectable}
							selectAll={selectAllHandler}
							toggleSort={toggleSortHandler}
						/>
						{displayData && setTableData && (
							<TableRows 
								data={displayData} 
								columns={columns}
								originalDataSet={data}
								indexable={indexable}
								selectable={selectable}
								setDesiplayedData={setTableData}
								entriesOnChange={tableEntriesOnChangeHandler}
								addNewEntry={addNewEntries}
								newTableRow={newRowValues}
								newTableEntryOnChange = {newTableRowOnChangeHandler}
								saveModifiedRow={saveModifiedRowHandler}
								revertRowChanges={revertRowChangesHandler}
								markRowForDelete={markRowForDeletedHandler}
							/>
						)}
					</table>

				</div>
			</div>
			<aside 
				className={!isLeftArrowIntersection ? styles.arrowVisible + ' ' + styles.leftArrow : styles.leftArrow}
				onClick={scrollLeftHandler} 
			/>
			<aside 
				className={!isRightArrowIntersection ? styles.arrowVisible + ' ' + styles.rightArrow : styles.rightArrow}
				onClick={scrollRightHandler}
			/>
			{(updateAndReset || addNewEntries) && (
				<TableAddSubmitControls
					resetAllHander={onResetAllHandler}
					displayAddRowHandler={displayAddRowHandler}
					cancelAddRowHandler={cancelAddRowHandler}
					newTableRow={newRowValues}
					saveNewRow={saveNewRowHandler}
					saveNewRowForm={saveNewRomFormHandler}
					onClickSubmitChanges={onClickSubmitChangesHandler}
					allowUpdateAndReset={updateAndReset}
					addNewRow={addNewEntries}
					FormToRender={options.addNewEntries == 'complex' ? options.FormToRender : undefined}
					dbUser={dbUser}
				/>
			)}
		</>
	);
};

export default DataTable;
