import InputText from '../../../InputText';
import Label from '../../../Label';
import { ColumnDefinitionText } from '../../types';
import styles from './index.module.css';

interface CascadeInputTextProps<T, K extends keyof T> {
	controlColumn: ColumnDefinitionText<T, K>;
	onChange: (value: string) => void;
}

const CascadeInputText = <T, K extends keyof T> ({controlColumn, onChange}: CascadeInputTextProps<T, K>) => {
	const onChangeHandler = (value: string) => {
		onChange(value);
	};
	return (
		<div className={styles.container}>
			<Label htmlFor={`input-${String(controlColumn.key)}`} strong>{controlColumn.name}</Label>
			<InputText
				id={`input-${String(controlColumn.key)}`}
				onChange={onChangeHandler}
				className={styles.input}
			/>
		</div>
	);
};

export default CascadeInputText;
