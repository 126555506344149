import styles from './index.module.css';

interface SpinnerProps {
	contained: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({contained = false}): JSX.Element => {
	if (contained) {
		return (
			<div className={styles.container}>
				<div className={styles.regular} role={'status'} />
			</div>
		);
	}
	return <div className={styles.regular} role={'status'} />;
};

export default Spinner;
