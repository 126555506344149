import { Fragment } from 'react';
import { TableColumnDefinition, TSortOrder } from '../types';
import { ColumnHeader } from './ColumnHeader';

type TableHeaderProps<T, K extends keyof T> = {
	columns: Array<TableColumnDefinition<T, K>>;
	sortControl: [K, TSortOrder] | undefined;
	indexable?: boolean;
	selectable?: boolean;
	selectAll?: () => void
	toggleSort?: (col: TableColumnDefinition<T,K>) => void
}; 

const TableHeader = <T, K extends keyof T>({columns, sortControl, indexable: isIndexable, selectable: isSelectable, selectAll, toggleSort}: TableHeaderProps<T, K>): JSX.Element => {
	return (
		<thead>
			<tr>
				{isSelectable && selectAll && <ColumnHeader key={'table-selection'} isSelectionColumn onClickSelectAll={selectAll}/>}
				{isIndexable && <ColumnHeader key= {'table-index'} isTableIndex />}
				{columns.map((column, i) => {
					if (column.isActionable) {
						return (
							<Fragment key={i}>
								<ColumnHeader 
									key={i} 
									column={column}
									toggleSort={toggleSort}
									sortOrder={sortControl?.[0] === column.key ? sortControl?.[1] : undefined} 
								/>
								<ColumnHeader isActionColumn key={`action-col-${i}`}/>
							</Fragment>
						);
					} return (
						<ColumnHeader 
							key={i} 
							column={column}
							toggleSort={toggleSort}
							sortOrder={sortControl?.[0] === column.key ? sortControl?.[1] : undefined} 
						/>
					);})}
				{isSelectable && (
					<>
						<ColumnHeader 
							key={'table-col-status'}
							columnName={'Status'}
						/>
						<ColumnHeader
							key={'table-edit-actions'}
							isActionColumn />
					</>
				)}   
			</tr>
		</thead>
	);
};

export default TableHeader;
