import React from 'react';
import notEmpty from '../../util/notEmpty';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import ReactExport from 'react-export-excel';
import notEmptyString from '../../util/notEmptyString';

const ExcelAllocationRequest = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Verzoeken om toewijzing'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Verzoeken om toewijzing'>
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Naam' value='naam' />
				<ExcelColumn label='Soort verwijzer' value='soort_label' />
				<ExcelColumn label='Verwijzer' value='verwijzer' />
				<ExcelColumn label='Zorgverlenercode' value='zorgverlenercode' />
				<ExcelColumn label='Categorie' value='categorie' />
				<ExcelColumn label='Product' value='product_code' />
				<ExcelColumn label='Begindatum' value={col => (notEmpty(col.begindatum) ? formatDate(col.begindatum, 2) : '')} />
				<ExcelColumn label='Einddatum' value={col => (notEmpty(col.einddatum) ? formatDate(col.einddatum, 2) : '')} />
				<ExcelColumn label='Volume' value='volume' />
				<ExcelColumn label='Eenheid' value='eenheid' />
				<ExcelColumn label='Frequentie' value='frequentie' />
				<ExcelColumn
					label='Status'
					value={col =>
						notEmpty(col.status)
							? col.status === 1
								? 'Aangemaakt'
								: col.status === 2
									? 'Ingediend'
									: col.status === 3
										? 'Goedgekeurd'
										: col.status === 4
											? 'Afgekeurd'
											: col.status === 5
												? 'Toegewezen'
												: col.status === 6
													? 'Afgewezen'
													: col.status === 7
														? 'In onderzoek'
														: 'Onbekend'
							: ''
					}
				/>
				<ExcelColumn
					label='Datum en tijd'
					value={col =>
						col.status === 1
							? notEmpty(col.aangemaakt)
								? formatDate(col.aangemaakt, 2) + ', ' + formatTime(col.aangemaakt, 2)
								: ''
							: col.status === 2
								? notEmpty(col.ingediend)
									? formatDate(col.ingediend, 2) + ', ' + formatTime(col.ingediend, 2)
									: ''
								: col.status === 3
									? notEmpty(col.goedgekeurd)
										? formatDate(col.goedgekeurd, 2) + ', ' + formatTime(col.goedgekeurd, 2)
										: ''
									: col.status === 4
										? notEmpty(col.afgekeurd)
											? formatDate(col.afgekeurd, 2) + ', ' + formatTime(col.afgekeurd, 2)
											: ''
										: col.status === 5
											? notEmpty(col.toegewezen)
												? formatDate(col.toegewezen, 2) + ', ' + formatTime(col.toegewezen, 2)
												: ''
											: col.status === 6
												? notEmpty(col.toegewezen)
													? formatDate(col.toegewezen, 2) + ', ' + formatTime(col.toegewezen, 2)
													: ''
												: col.status === 7
													? notEmpty(col.onderzoek)
														? formatDate(col.onderzoek, 2) + ', ' + formatTime(col.onderzoek, 2)
														: ''
													: col.status === 8
														? notEmpty(col.gearchiveerd)
															? formatDate(col.gearchiveerd, 2) + ', ' + formatTime(col.gearchiveerd, 2)
															: ''
														: ''
					}
				/>
				<ExcelColumn label='Openstaand' value={col => (notEmptyString(col.openstaand) ? (col.openstaand === null ? 'Ja' : 'Nee') : '')} />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelAllocationRequest;
