/**
 *  Short version of Object.prototype.hasOwnProperty.call()
 */
const hasProp = <T, K extends PropertyKey>(object: T, property: K): object is T & Record<K, unknown> => {
	try {
		return Object.prototype.hasOwnProperty.call(object, property);
	} catch (e) {
		return false;
	}
};

export default hasProp;
