import { Autorisatie } from '../types';

/*
 *  Searches for authorization string in token array
 */
const isAuthorized = (tokens: Autorisatie[], token: string) => {
	const autorisatie = tokens.findIndex((row) => {
		return row.autorisatie === token;
	});
	const uuid = tokens.findIndex((row) => {
		return row.uuid === token;
	});

	if (autorisatie === -1 && uuid === -1) {
		return false;
	}

	return true;
};

export default isAuthorized;
