import React from 'react';

export default class FormAllocationShow extends React.Component {
	constructor(props) {
		super(props);
	}

	handleCancel = async (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.handleForm({});
	};

	render = () => {
		const data = this.props.form.data;

		var style = {
			maxWidth: undefined,
		};

		return (
			<form style={style}>
				<div className='close' onClick={this.handleCancel} title='Annuleer'></div>
				<header>
					<h1>Toewijzing</h1>
				</header>
				<main>
					<label>
						<span>Gemeente</span>
						<input
							readOnly
							value={data.gemeente_naam}
							className='disabled'
						/>
					</label>
					<label className='col2'>
						<span>Categorie</span>
						<input
							readOnly
							value={data.categorie_label}
							className='disabled'
						/>
					</label>
					<label className='col2'>
						<span>Product</span>
						<input
							readOnly
							value={data.product_label}
							className='disabled'
						/>
					</label>
					{!data.aspecifiek && (
						<>
							<label className='col4'>
								<span>Volume</span>
								<input
									type='number'
									value={data.volume ? data.volume : ''}
									readOnly
									className='disabled'
								/>
							</label>
							<label className='col4'>
								<span>Eenheid</span>
								<input
									readOnly
									value={data.eenheid_label}
									className='disabled'
								/>
							</label>

							<label htmlFor='f11' className='col2'>
								<span>Frequentie</span>
								<input
									value={data.frequentie_label}
									readOnly
									className='disabled'
								/>
							</label>
						</>
					)}
					<label className='col2 mt2'>
						<span>Begindatum</span>
						<input
							type='date'
							value={data.begindatum}
							readOnly
							className='disabled'
						/>
					</label>
					<label className='col2 mt2'>
						<span>Einddatum</span>
						<input
							type='date'
							value={data.einddatum}
							readOnly
							className='disabled'
						/>
					</label>
					<label className='col2 mt2'>
						<span>Reden wijziging</span>
						<input
							type='text'
							value={(data.reden_wijziging_naam ? `${data.reden_wijziging_naam} (${data.reden_wijziging})` : data.reden_wijziging) ?? 'Geen data'}
							readOnly
							className='disabled'
						/>
					</label>
					<label>
						<span>Commentaar</span>
						<textarea
							value={data.commentaar ?? ''}
							readOnly
							className='disabled'
						/>
					</label>
				</main>

				<footer>
					<button
						type='button'
						className='secondary'
						onClick={this.handleCancel}
						autoFocus
					>
                        Terug
					</button>
				</footer>
			</form>
		);
	};
}
