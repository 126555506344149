import React from 'react';

const Start = () => {
	return (
		<section className='main-section'>
			<main className='main-content'>Selecteer een cliënt of een actie uit het menu.</main>
		</section>
	);
};

export default Start;
