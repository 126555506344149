import InputDate from '../InputDate';
import InputNumber from '../InputNumber';
import InputSelect from '../InputSelect';
import InputText from '../InputText';
import Label from '../Label';
import { FormEntryDefinition } from './types';
import styles from './index.module.css';
import { Dispatch, SetStateAction, useId } from 'react';

interface FormProps<T> {
	entries: FormEntryDefinition<T>[];
	title?: string;
	setValue: Dispatch<SetStateAction<T>>
}
/**@description Experimental Form builder */
function FormBuilder <T>({entries, title, setValue}: FormProps<T>) {
	const prefixId = useId();
	return (
		<form>
			{title && (
				<div className={styles.title}>
					<h3>{title}</h3>
				</div>
			)}
			<div className={styles.innerContainer}>
				{entries.map((entry, i) => {
					switch (entry.inputType) {
						case 'Currency':
							return (
								<div key={`${prefixId}-${i}`}>
									<Label strong htmlFor={`${prefixId}-${String(entry.key)}-input`} labelClassName={styles.label}>
										<span>{'€'}</span>{entry.label}
									</Label>
									<InputNumber
										id={`${prefixId}-${String(entry.key)}-input`}
										value={entry.value}
										step={entry.step}
										min={entry.validations && entry.validations.min ? entry.validations.min : undefined}
										max={entry.validations && entry.validations.max ? entry.validations.max : undefined}
										onChange={v => setValue((prevState) => {
											return {...prevState, [entry.key]: v};
										})}
									/>
								</div>
							);
						case 'Number':
							return (
								<div key={`${prefixId}-${i}`}>
									<Label strong htmlFor={`${prefixId}-${String(entry.key)}-input`} labelClassName={styles.label}>
										{entry.label}
									</Label>
									<InputNumber
										id={`${prefixId}-${String(entry.key)}-input`}
										value={entry.value}
										step={entry.step}
										min={entry.validations && entry.validations.min ? entry.validations.min : undefined}
										max={entry.validations && entry.validations.max ? entry.validations.max : undefined}
										onChange={v => setValue((prevState) => {
											return {...prevState, [entry.key]: v};
										})}
									/>
								</div>
							);

						case 'Date':
							return (
								<div key={`${prefixId}-${i}`}>
									<Label strong htmlFor={`${prefixId}-${String(entry.key)}-input`} labelClassName={styles.label}>
										{entry.label}
									</Label>
									<InputDate
										id={`${prefixId}-${String(entry.key)}-input`}
										min={entry.validations && entry.validations.min && 
                                        typeof entry.validations.min !== 'function' ? entry.validations.min : undefined}
										max={entry.validations && entry.validations.max && 
                                        typeof entry.validations.min !== 'function' ? entry.validations.max : undefined}
										value={entry.value}
										className=''
										onChange={v => setValue((prevState) => {
											return {...prevState, [entry.key]: v};
										})}
									/>
								</div>
							);
						case 'Text':
							return (
								<div key={`${prefixId}-${i}`}>
									<Label strong htmlFor={`${prefixId}-${String(entry.key)}-input`} labelClassName={styles.label}>
										{entry.label}
									</Label>
									<InputText
										id={`${prefixId}-${String(entry.key)}-input`}
										className='' 
										onChange={v => setValue((prevState) => {
											return {...prevState, [entry.key]: v};
										})}
									/>
								</div>
							);
						case 'List':
							return (
								<div key={`${prefixId}-${i}`}>
									<Label strong htmlFor={`${prefixId}-${String(entry.key)}-input`} labelClassName={styles.label}>
										{entry.label}
									</Label>
									<InputSelect
										id={`${prefixId}-${String(entry.key)}-input`}
										valueOptions={entry.valueOptions}
										className=''
										onChange={v => setValue((prevState) => {
											return {...prevState, [entry.key]: v};
										})}
									/>
								</div>
							);
					}
				})}
			</div>
		</form>
	);
}

export default FormBuilder;
