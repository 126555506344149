import React from 'react';
import isAuthorized from '../../util/isAuthorized';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import api from '../../util/apiRequest';
import Loading from '../element/miscellaneous/Loading';
import capitalize from '../../util/capitalize';
import upperCaseDefs from '../../util/upperCaseDefs';
import sortArray from '../../util/sortArray';
import {NotificationManager} from 'react-notifications';

class UserSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {loaded: false, userData: []};
	}

	componentDidUpdate = () => {
		this.getUser();
	};

	componentDidMount = () => {
		this.getUser();
	};

	getUser = async () => {
		const {loaded} = this.state;
		const {token} = this.props.dbUser;
		if (!loaded) {
			const getUser = await api.getUser(api.makeHeader(token));
			if (getUser.error && getUser.error === true) {
				this.props.handleForm(getUser);
				return null;
			}
			this.setState(() => ({
				loaded: true,
				userData: getUser.response,
			}));
		}
	};

	handleSubmit = async event => {
		event.preventDefault();
		event.stopPropagation();

		const {id, token} = this.props.dbUser;

		let data = {};
		const elements = event.target.elements;
		Object.keys(elements).forEach(key => {
			let element = elements[key];
			if (element.type !== 'submit' && element.name !== '') {
				data[element.name] = element.value;
			}
		});

		const updateUserData = await api.updateUserData(id, data, api.makeHeader(token));
		if (updateUserData.error && updateUserData.error === true) {
			NotificationManager.error('Uw instellingen zijn niet opgeslagen', 'Instellingen', 3000);
			this.props.handleForm(updateUserData);
			return null;
		}

		NotificationManager.success('Uw instellingen zijn opgeslagen', 'Instellingen', 3000);
		window.scrollTo(0, 0);
		data.result = updateUserData;
		this.props.handleUpdateUserData(data);
		this.updateTable(data);
	};

	updateTable = data => {
		this.setState(state => {
			let userData = state.userData;
			userData.voornaam = data.voornaam;
			userData.tussenvoegsel = data.tussenvoegsel;
			userData.achternaam = data.achternaam;
			state.userData = userData;
			return state;
		});
	};

	render = () => {
		const {userData, loaded} = this.state;
		const {dbUser, user, dbAanbiederInit} = this.props;
		const {autorisatie: auth} = userData;

		if (!loaded) {
			return <Loading message='' />;
		}

		const authlist = sortArray(dbUser.autorisatie, true, 'autorisatie').map(row => {
			return (
				<>
					<span title={row.omschrijving} key={row.autorisatie}>
						{row.autorisatie}
					</span>{' '}
				</>
			);
		});

		if (isAuthorized(auth, 'instellingen.tonen')) {
			return (
				<>
					<section className='client-data'>
						<section className='table-element'>
							<form onSubmit={event => this.handleSubmit(event)}>
								<header>
									<h1>Uw gegevens</h1>
								</header>

								<table className='client-table'>
									<tbody>
										{user && user.picture && (
											<tr>
												<td></td>
												<td>
													<img className='profile-img' src={user.picture} alt='Profile' />
												</td>
											</tr>
										)}

										<tr>
											<td>Volledige naam</td>
											<td>
												{userData.voornaam} {userData.tussenvoegsel} {userData.achternaam}
											</td>
										</tr>
										<tr>
											<td>Gebruikersnaam</td>
											<td>{userData.emailadres}</td>
										</tr>
										<tr>
											<td>Wachtwoord</td>
											<td>••••••••••••</td>
										</tr>
										<tr>
											<td valign='top'>Autorisaties</td>
											<td className='autorisaties'>{authlist}</td>
										</tr>
										<tr>
											<td>Laatst ingelogd op</td>
											<td>{formatDate(userData.ingelogd, 1) + ', om ' + formatTime(userData.ingelogd)}</td>
										</tr>
									</tbody>
								</table>

								<main className='exception'>
									<header>
										<h1>Gegevens wijzigen</h1>
									</header>
									<label>
										<span>Standaard aanbieder</span>
										<select name='aanbieder' placeholder='Aanbieder' defaultValue={dbAanbiederInit} disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}>
											{dbUser.aanbieders.map(aanbieder => (
												<option key={aanbieder.id} value={aanbieder.id}>
													{aanbieder.label} {aanbieder.naam}
												</option>
											))}
										</select>
									</label>
									<label className='col3'>
										<span>Voornaam</span>
										<input
											type='text'
											name='voornaam'
											placeholder='Voornaam'
											defaultValue={userData.voornaam ? userData.voornaam : ''}
											maxLength={127}
											disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}
										/>
									</label>
									<label className='col3'>
										<span>Tussenvoegsel</span>
										<input
											type='text'
											name='tussenvoegsel'
											placeholder='Tussenvoegsel'
											defaultValue={userData.tussenvoegsel ? userData.tussenvoegsel : ''}
											maxLength={15}
											disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}
										/>
									</label>
									<label className='col3'>
										<span>Achternaam</span>
										<input
											type='text'
											name='achternaam'
											placeholder='Achternaam'
											defaultValue={userData.achternaam ? userData.achternaam : ''}
											maxLength={127}
											disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}
										/>
									</label>

									{isAuthorized(auth, 'meldingen.tonen') && (
										<>
											<header>
												<h1>Meldingen</h1>
											</header>

											{auth
												.filter(authorisatie => {
													return authorisatie.tonen === 1;
												})
												.map(authorisatie => (
													<React.Fragment key={authorisatie.uuid}>
														<label className='col1'>
															<span>
																{authorisatie.autorisatie
																	.split('.')
																	.filter(word => word !== 'melding')
																	.map(word => {
																		return capitalize(upperCaseDefs(word));
																	})
																	.join(' ')}
															</span>
															<span>{authorisatie.omschrijving}</span>
														</label>

														<label htmlFor={authorisatie.uuid + 'O'} className='col4'>
															<span>Ontvangen</span>
															<select name={authorisatie.uuid + '|ontvangen'} defaultValue={authorisatie.ontvangen === 1 ? '1' : '0'}>
																<option key='Ja' value='1'>
                                                                    Ja
																</option>
																<option key='Nee' value='0'>
                                                                    Nee
																</option>
															</select>
														</label>

														<label htmlFor={authorisatie.uuid + 'B'} className='col4'>
															<span>In de browser</span>
															<select name={authorisatie.uuid + '|browser'} defaultValue={authorisatie.browser === 1 ? '1' : '0'}>
																<option key='Ja' value='1'>
                                                                    Ja
																</option>
																<option key='Nee' value='0'>
                                                                    Nee
																</option>
															</select>
														</label>

														<label htmlFor={authorisatie.uuid + 'E'} className='col4'>
															<span>Per e-mail</span>
															<select name={authorisatie.uuid + '|email'} defaultValue={authorisatie.email === 1 ? '1' : '0'}>
																<option key='Ja' value='1'>
                                                                    Ja
																</option>
																<option key='Nee' value='0'>
                                                                    Nee
																</option>
															</select>
														</label>
													</React.Fragment>
												))}
										</>
									)}
								</main>
								<footer>
									<button type='reset' className={isAuthorized(auth, 'instellingen.wijzigen') ? 'secondary' : 'disabled'} disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}>
                                        Herstel
									</button>
									<button type='submit' className={isAuthorized(auth, 'instellingen.wijzigen') ? 'default' : 'disabled'} disabled={isAuthorized(auth, 'instellingen.wijzigen') ? false : true}>
                                        Bewaar
									</button>
								</footer>
							</form>
						</section>
					</section>
				</>
			);
		} else {
			return <p>U heeft geen toegang tot gebruikersinformatie</p>;
		}
	};
}

export default UserSettings;
