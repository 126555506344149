import React from 'react';
import notEmpty from '../../util/notEmpty';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import ReactExport from 'react-export-excel';

const ExcelManageStatement = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Verantwoordingen'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Verantwoordingen'>
				<ExcelColumn label='Cliënt' value='naam' />
				<ExcelColumn label='Toewijzing' value='toewijzing_nummer' />
				<ExcelColumn label='Product' value='product_label' />
				<ExcelColumn label='Begindatum' value={col => (notEmpty(col.begindatum) ? formatDate(col.begindatum, 2) : '')} />
				<ExcelColumn label='Einddatum' value={col => (notEmpty(col.einddatum) ? formatDate(col.einddatum, 2) : '')} />
				<ExcelColumn label='Volume' value='volume' />
				<ExcelColumn label='Eenheid' value='eenheid' />
				<ExcelColumn
					label='Status'
					value={col =>
						notEmpty(col.status)
							? col.status === 1
								? 'Aangemaakt'
								: col.status === 2
									? 'Ingediend'
									: col.status === 3
										? 'Goedgekeurd'
										: col.status === 4
											? 'Afgekeurd'
											: col.status === 5
												? 'Toegewezen'
												: col.status === 6
													? 'Afgewezen'
													: col.status === 7
														? 'In onderzoek'
														: 'Onbekend'
							: ''
					}
				/>
				<ExcelColumn label='Datum en tijd' value={col => (notEmpty(col.status_ts) ? formatDate(col.status_ts, 2) + ', ' + formatTime(col.status_ts, 2) : '')} />
				<ExcelColumn label='Retourcode' value='retourcode' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelManageStatement;
