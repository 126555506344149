import assertNever from './assertNever';

/**
 *  Filters characters or digits only; used in form input sanitation
 */
const filterValue = (filter: 'A-Z' | '0-9', value: string) => {
	if (filter === 'A-Z') {
		return value.replace(new RegExp('[^A-Z]', 'g'), '');
	} else if (filter === '0-9') {
		return value.replace(new RegExp('[^0-9]', 'g'), '');
	} else {
		return assertNever(filter);
	}
};

export default filterValue;
