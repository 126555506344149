import React from 'react';
import Loading from '../element/miscellaneous/Loading';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import StatusCodeAllocationRequest from '../element/statuscode/StatusCodeAllocationRequest';
import notEmpty from '../../util/notEmpty';
import notEmptyString from '../../util/notEmptyString';
import isAuthorized from '../../util/isAuthorized';
import splitJoin from '../../util/splitJoin';
import {confirmAlert} from 'react-confirm-alert';

export default class ClientAllocationRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tables: {
				1: {
					name: 'Actie',
					figure: 'action-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				2: {
					name: 'In behandeling',
					figure: 'in-progress-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
				3: {
					name: 'Historie',
					figure: 'history-figure',
					column: 'status',
					label: 'Status',
					order: true,
					select: [],
					hint: false,
					hidden: true,
					left_hint: false,
				},
			},
			vots: this.props.data,
			client: this.props.client,
			count: 0,
			trigger: 0,
			loaded2: false,
			locked: false,
			sidebarTrigger: 0,
			loadingIndicators: {},
		};
	}

	handleRetourcode = retourcode => {
		const codes = retourcode.split(',');
		const retourcodes = this.props.retourcodes;
		const message = codes.map(row => {
			return retourcodes
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
								<strong>{row.code}</strong> {row.betekenis}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: codes.length > 1 ? 'Retourcodes' : 'Retourcode',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleReden = ({status_reden: reden, retour_gemeente, retour_gemeente_name}) => {
		const codes = reden.split(',');
		const redenen = this.props.redenen;
		const message = codes.map(row => {
			return redenen
				.filter(code => {
					return code.code === row;
				})
				.map(row => {
					return (
						<React.Fragment key={row.code}>
							<p className='retourcode'>
                                Reden voor afwijzing verzoek: <strong>{row.code}</strong> {row.betekenis} {retour_gemeente ? `: Verwachte gemeente: ${retour_gemeente}: ${retour_gemeente_name}` : ''}
							</p>
						</React.Fragment>
					);
				});
		});
		confirmAlert({
			title: 'Reden afwijzing verzoek',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	handleStatus = status => {
		const legend = [
			{
				status: 1,
				description: 'Verzoek om toewijzing is aangemaakt, maar nog niet door u ingediend.',
				label: 'Aangemaakt',
			},
			{
				status: 2,
				description: 'Verzoek om toewijzing is ingediend; bericht is verzonden naar de betreffende gemeente.',
				label: 'Ingediend',
			},
			{
				status: 3,
				description: 'Verzoek om toewijzing is goedgekeurd.',
				label: 'Goedgekeurd',
			},
			{
				status: 4,
				description: 'Verzoek om toewijzing is afgekeurd; zie retourcode voor de reden tot afkeur.',
				label: 'Afgekeurd',
			},
			{
				status: 5,
				description: 'Verzoek om toewijzing is toegewezen.',
				label: 'Toegewezen',
			},
			{
				status: 6,
				description: 'Verzoek om toewijzing is afgewezen; zie retourcode of reden voor afwijzing.',
				label: 'Afgewezen',
			},
			{
				status: 7,
				description: 'Verzoek om toewijzing is in onderzoek; zie reden voor onderzoek.',
				label: 'In onderzoek',
			},
			{
				status: 8,
				description: 'Verzoek om toewijzing is gearchiveerd; door u of door een automatisch proces binnen de applicatie.',
				label: 'Gearchiveerd',
			},
		];

		const message = legend.map(item => {
			return (
				<p key={item.status} className='legend'>
					{status === item.status ? (
						<div className='active'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					) : (
						<div className='inactive'>
							<strong>{item.label}</strong>
							<div>{item.description}</div>
						</div>
					)}
				</p>
			);
		});

		confirmAlert({
			title: 'Status',
			message: message,
			buttons: [{label: 'OK'}],
		});
	};

	componentDidMount = async () => {
		const {aanbieder, token} = this.props.dbUser;
		const {client} = this.props;

		const getVotInProgress = await api.getVotInProgress(aanbieder, this.props.client.id, api.makeHeader(token));
		if (getVotInProgress.error && getVotInProgress.error === true) {
			this.props.handleForm(getVotInProgress);
			return null;
		}
		const getVowInProgress = await api.getVowInProgress(aanbieder, client.id, api.makeHeader(token));
		if (getVowInProgress.error && getVowInProgress.error === true) {
			this.props.handleForm(getVowInProgress);
			return null;
		}
		this.setState(state => {
			state.loaded2 = true;
			state.locked = getVowInProgress.response;
			state.locked2 = getVotInProgress.response;
		});

		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = () => {
		const {update, trigger} = this.props.votTable;
		const {count, sidebarTrigger} = this.state;

		if (this.state.trigger !== this.props.trigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		if (sidebarTrigger !== this.props.sidebarTrigger) {
			setTimeout(() => {
				this.updateScroll();
			}, 500);
		}

		if (trigger !== count && update !== undefined) {
			if (update.formid === 'vot-toevoegen') {
				let index = this.state.vots.findIndex(row => {
					return row.id === Number(update.original_id);
				});

				this.setState(state => {
					state.count = trigger;
					state.vots.push(update.response);
					if (index >= 0) state.vots[index].status = 8;
					return state;
				});

				if (update.next === 'vot-toevoegen') {
					this.handleAdd(null, update.table, 1);
				} else if (update.next === 'vot-edit-next') {
					const nextRow = this.state.vots.find(row => row.status === 4 && row.verwijderd === 0 && row.id !== Number(update.original_id));
					if (nextRow) {
						this.handleAdd(null, update.table, 1, nextRow.id);
					}
				} else if (update.next === 'vot-verzenden') {
					this.handleSendVOT(update.aanbieder, update.client_id, update.table, update.response.id);
				}

				this.props.resetVotTable();
			} else if (update.formid === 'vot-wijzigen') {
				let index = this.state.vots.findIndex(row => {
					return row.id === update.id;
				});

				this.setState(state => {
					state.count = trigger;
					state.vots[index] = update.response;
					return state;
				});

				if (update.next === 'vot-toevoegen') {
					this.handleAdd(null, update.table, 0);
				} else if (update.next === 'vot-verzenden') {
					this.handleSendVOT(update.aanbieder, update.client_id, update.table);
				}

				this.props.resetVotTable();
			} else if (update.formid === 'vot-verwijderen') {
				const index = this.state.vots.findIndex(row => {
					return row.id === update.id;
				});
				const select = this.state.tables[1].select.filter(row => {
					return row !== update.id;
				});
				this.setState(state => {
					state.count = trigger;
					state.vots[index].verwijderd = 1;
					state.vots[index].status = 8;
					state.tables[1].select = select;
					return state;
				});
				this.props.resetVotTable();
			}
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	updateScroll = () => {
		const tables = [1, 2, 3];

		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			state.sidebarTrigger = this.props.sidebarTrigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);

		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				//
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSelect = (id, table) => {
		this.setState(state => {
			let {select} = state.tables[table];
			const index = select.findIndex(row => {
				return row === id;
			});
			if (index === -1) {
				state.tables[table].select.push(id);
				return state;
			}
			state.tables[table].select = select.filter(row => {
				return row !== id;
			});
			return state;
		});
	};

	handleCheckSelect = (id, table) => {
		const {select} = this.state.tables[table];
		return select.includes(id);
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleSelectAll = table => {
		this.setState(state => {
			const {vots} = this.state;
			const ids = vots
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => row.id);
			if (ids.length === state.tables[table].select.length) {
				state.tables[table].select = [];
			} else {
				state.tables[table].select = ids;
			}
			return state;
		});
	};

	handleSend = (event, table) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		const vot = this.state.vots.find(row => {
			return row.status === 1 && row.verwijderd === 0;
		});
		this.setState(state => ({
			loadingIndicators: {
				...state.loadingIndicators,
				main: true,
			},
		}));
		this.handleSendVOT(vot.aanbieder_id, vot.client_id, table).finally(() => this.setState(state => ({
			loadingIndicators: {
				...state.loadingIndicators,
				main: false,
			},
		})));
	};

	handleEnableSend = table => {
		const {tables} = this.state;
		return tables[table].select.length !== 0;
	};

	handleAdd = (event, table, add, id) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let votList = this.state.tables[table].select;
		let votCount = 0;

		if (votList.length === 0) {
			votList = this.state.vots
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => {
					return row.id;
				});
		}
		votCount = votList.length + 1 + add;
		const pendingRejectedCount = this.state.vots.filter(row => row.status === 4 && row.verwijderd === 0).length - 1;

		let data = {};
		if (id !== undefined) {
			data = this.handleGetRow(id);
		}

		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.votCount = votCount;
		data.votList = votList;
		data.votTable = table;
		data.pendingRejectedCount = pendingRejectedCount;
		data.locked = this.state.locked;

		this.props.handleForm({formid: 'vot-toevoegen', data: data});
	};

	handleGetRow = id => {
		return this.props.data.find(row => row.id === id);
	};

	handleEdit = (event, id, table) => {
		event.preventDefault();
		event.stopPropagation();

		let votList = this.state.tables[table].select;
		let votCount = 0;

		if (votList.length === 0) {
			votList = this.state.vots
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => {
					return row.id;
				});
			votCount = votList.length;
		} else {
			if (votList.findIndex(row => row === id) === -1) {
				votList.push(id);
			}
			votCount = votList.length;
		}

		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.votCount = votCount;
		data.votList = votList;
		data.votTable = table;
		data.locked = this.state.locked;

		this.props.handleForm({formid: 'vot-wijzigen', data: data});
	};

	handleShow = (event, id, table) => {
		event.preventDefault();
		event.stopPropagation();

		let votList = this.state.tables[table].select;
		let votCount = 0;

		if (votList.length === 0) {
			votList = this.state.vots
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => {
					return row.id;
				});
			votCount = votList.length;
		} else {
			if (votList.findIndex(row => row === id) === -1) {
				votList.push(id);
			}
			votCount = votList.length;
		}

		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '75vw';
		data.client = this.state.client;
		data.votCount = votCount;
		data.votList = votList;
		data.votTable = table;
		data.locked = this.state.locked;
		data.readOnly = true;

		this.props.handleForm({formid: 'vot-wijzigen', data: data});
	};

	handleRemove = (event, id) => {
		event.preventDefault();
		event.stopPropagation();
		let data = this.handleGetRow(id);
		data.lists = this.props.lists;
		data.contracts = this.props.contractData;
		data.dialogWidth = '40vw';
		data.client = this.state.client;
		data.votCount = 0;
		data.votList = [];
		data.votTable = 0;
		this.props.handleForm({formid: 'vot-verwijderen', data: data});
	};

	handleModal = () => {
		this.setState(state => {
			state.modal = !state.modal;
			return state;
		});
	};

	handleSendVOT = async (aanbieder, client_id, table, added_id = 0) => {
		const {token} = this.props.dbUser;
		let votList = this.state.tables[table].select;

		if (votList.length === 0) {
			votList = this.state.vots
				.filter(row => {
					return row.status === 1 && row.verwijderd === 0;
				})
				.map(row => {
					return row.id;
				});
		}

		if (added_id !== 0) {
			votList.push(added_id);
		}

		const sendVot = await api.sendVot(aanbieder, client_id, votList.join(','), api.makeHeader(token));
		if (sendVot.error && sendVot.error === true) {
			this.props.handleForm(sendVot);
			return null;
		}
		if (sendVot.status === 500) {
			alert('Er is een onbekende fout opgetreden, probeer het later opnieuw!');
			console.error(sendVot);
			return null;
		}
		this.setState(state => {
			state.vots = sendVot.response;
			state.tables[table].select = [];
			state.locked2 = true;
			return state;
		});
	};

	handleShowHideTable = table => {
		this.setState(state => {
			state.tables[table].hidden = !state.tables[table].hidden;
			return state;
		});
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	handleCellEdit(event, row, table) {
		if (row.status === 4 || row.status === 6) {
			this.handleAdd(event, table, 0, row.id);
		} else {
			this.handleEdit(event, row.id, table);
		}
	}

	render = () => {
		const {loaded} = this.props;
		const {vots, tables, loaded2, locked, locked2, loadingIndicators} = this.state;
		const {autorisatie} = this.props.dbUser;

		const lockedByMissingContracts = !this.props.contractData.find(c => c.gemeente_code === this.props.client.gemeente_code);

		if (!loaded || !loaded2) {
			return <Loading message='' />;
		}

		const table1 = vots.filter(row => {
			return (row.status === 1 || row.status === 4 || row.status === 6) && row.verwijderd === 0;
		});
		const data1 = sortArray(table1, tables[1].order, tables[1].column);

		let label1 = '';
		if (tables[1].select.length <= 1) {
			label1 = 'Verzoek om toewijzing indienen';
		} else {
			label1 = `${tables[1].select.length} Verzoeken om toewijzing indienen`;
		}

		const table2 = vots.filter(row => {
			return (row.status === 2 || row.status === 3 || row.status === 7) && row.verwijderd === 0;
		});
		const data2 = sortArray(table2, tables[2].order, tables[2].column);

		const table3 = vots.filter(row => {
			return row.status === 5 || row.verwijderd === 1 || row.status === 8;
		});
		const data3 = sortArray(table3, tables[3].order, tables[3].column);

		return (
			<>
				<section className='client-data'>
					<section className='table-element'>
						{locked === true && lockedByMissingContracts === false && (
							<section className='table-header error-color'>
                                U kunt op dit moment geen verzoek om toewijzing (VOT) indienen, omdat er een verzoek om wijziging (VOW) is ingediend.
							</section>
						)}
						{locked2 === true && lockedByMissingContracts === false && (
							<section className='table-header error-color'>
                                U kunt op dit moment geen verzoek om toewijzing (VOT) indienen, omdat er een verzoek om toewijzing (VOT) is ingediend.
							</section>
						)}
						{lockedByMissingContracts === true && (
							<section className='table-header error-color'>
                                U kunt op dit moment geen verzoek om toewijzing (VOT) indienen, omdat er voor deze gemeente geen geldige contracten bekend zijn.
							</section>
						)}
						<section className='table-header'>
							<figure className={tables[1].figure}></figure>
							<h2>{tables[1].name}</h2>
							<button className='add' disabled={locked || locked2 || lockedByMissingContracts} onClick={event => this.handleAdd(event, 1, 0)}></button>
						</section>
						{data1.length !== 0 ? (
							<>
								<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
									<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
									<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
									<table className='data-table'>
										<tbody>
											<tr>
												<td onClick={() => this.handleSelectAll(1, 1)} className='col-check'>
													<button className='col-check-btn'></button>
												</td>

												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcodes' label='Retourcodes' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_reden' label='Reden' />
												<td></td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='categorie_code' label='Categorie' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='product_code' label='Product' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='begindatum' label='Begindatum' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='einddatum' label='Einddatum' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='volume' label='Volume' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='eenheid_label' label='Eenheid' />
												<TableColumnHeader
													handleSort={this.handleSort}
													column={tables[1].column}
													order={tables[1].order}
													table='1'
													data='frequentie_label'
													label='Frequentie'
												/>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='soort' label='Soort' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='zorgverlenercode' label='Verwijzer' />
											</tr>

											{data1.map(row => (
												<tr
													key={row.id}
													onClick={() => (row.status === 1 ? this.handleSelect(row.id, 1, 1) : null)}
													className={this.handleCheckSelect(row.id, 1, 1) ? 'highlight' : ''}>
													<td>
														<button
															className={row.status === 1 ? (this.handleCheckSelect(row.id, 1, 1) ? 'button checkbox checked' : 'button checkbox') : 'button checkbox'}
															disabled={row.status === 6 || row.status === 4 ? true : false}></button>

														<button
															className='button edit'
															onClick={event => this.handleCellEdit(event, row, 1)}
															disabled={false}></button>

														<button
															className={row.status === 1 || row.status === 4 || row.status === 6 ? 'button remove' : 'button remove disabled'}
															onClick={event => this.handleRemove(event, row.id)}
															disabled={row.status === 1 || row.status === 4 || row.status === 6 ? false : true}></button>
													</td>

													<td>{<StatusCodeAllocationRequest status={row.status} />}</td>

													<td>
														<button
															className={notEmpty(row.status) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleStatus(row.status)}
															disabled={notEmpty(row.status) ? false : true}></button>
													</td>

													<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>

													<td>
														<button
															className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleRetourcode(row.retourcodes)}
															disabled={notEmpty(row.retourcodes) ? false : true}></button>
													</td>

													<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
													<td>
														<button
															className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
															onClick={() => this.handleReden(row)}
															disabled={notEmpty(row.status_reden) ? false : true}></button>
													</td>
													<td>{
														row.categorie_code === null ? 'Generiek' :
															notEmpty(row.categorie_code) ? row.categorie_code :
																<label>Geen data</label>
													}</td>
													<td>{
														row.categorie_code === null ? '' :
															row.product_code === null ? 'Aspecifiek' :
																notEmptyString(row.product_code) ? row.product_code :
																	<label>Geen data</label>
													}</td>
													<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum) : <label>Geen data</label>}</td>
													<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum) : <label>Geen data</label>}</td>
													<td>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
													<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : <label>Geen data</label>}</td>
													<td>{notEmpty(row.frequentie_label) ? row.frequentie_label : <label>Geen data</label>}</td>
													<td>{notEmpty(row.soort_label) ? row.soort_label : <label>Geen data</label>}</td>
													<td>{notEmpty(row.zorgverlenercode) ? row.zorgverlenercode : notEmpty(row.verwijzer) ? row.verwijzer : <label>Geen data</label>}</td>
												</tr>
											))}
										</tbody>
									</table>
								</section>
								{isAuthorized(autorisatie, 'functie.uitvoeren') && locked === false && !locked2 && lockedByMissingContracts === false ? (
									<section className='table-footer'>
										<button
											className={'send'}
											onClick={event => this.handleSend(event, 1)}
											disabled={(!this.handleEnableSend(1, 1) ? true : false) || loadingIndicators.main}
										>
											{label1}
										</button>
									</section>
								) : (
									<section className='table-footer'>
										<button className='send disabled' disabled={true}>
											{label1}
										</button>
									</section>
								)}
							</>
						) : (
							<section className='table-empty'>Er is nog geen verzoek om toewijzing aangemaakt</section>
						)}
					</section>
				</section>

				<section className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[2].figure}></figure>
							<h2>{tables[2].name}</h2>
						</section>
						{data2.length !== 0 ? (
							<section id='table2' className='table-scroll-x' onScroll={() => this.handleScroll(2)}>
								<aside className={tables[2].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(2)}></aside>
								<aside className={tables[2].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(2)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcodes' label='Retourcodes' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_reden' label='Reden' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='categorie_code' label='Categorie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='product_code' label='Product' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='begindatum' label='Begindatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='einddatum' label='Einddatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='volume' label='Volume' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='eenheid_label' label='Eenheid' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='frequentie_label' label='Frequentie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='soort' label='Soort' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[2].column} order={tables[2].order} table='2' data='zorgverlenercode' label='Verwijzer' />
										</tr>

										{data2.map(row => (
											<tr key={row.id}>
												<td>
													<button
														className={row.status === 1 || row.status === 4 ? 'button edit' : 'button read'}
														onClick={event =>
															row.status === 2 || row.status === 3 || row.status === 5 || row.status === 7
																? this.handleShow(event, row.id, 2)
																: this.handleEdit(event, row.id, 2)
														}></button>

													<button
														className={row.status === 1 || row.status === 4 ? 'button remove' : 'button remove disabled'}
														onClick={event => this.handleRemove(event, row.id)}
														disabled={row.status === 2 || row.status === 3 || row.status === 5 || row.status === 7 ? true : false}></button>
												</td>
												<td>{<StatusCodeAllocationRequest status={row.status} />}</td>

												<td>
													<button
														className={notEmpty(row.status) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleStatus(row.status)}
														disabled={notEmpty(row.status) ? false : true}></button>
												</td>

												<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>

												<td>
													<button
														className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleRetourcode(row.retourcodes)}
														disabled={notEmpty(row.retourcodes) ? false : true}></button>
												</td>

												<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
												<td>
													<button
														className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleReden(row.status_reden)}
														disabled={notEmpty(row.status_reden) ? false : true}></button>
												</td>

												<td>{notEmpty(row.categorie_code) ? row.categorie_code : <label>Geen data</label>}</td>
												<td>{notEmptyString(row.product_code) ? row.product_code === null ? 'Aspecifiek' : row.product_code : <label>Geen data</label>}</td>
												<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
												<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.frequentie_label) ? row.frequentie_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.soort_label) ? row.soort_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.zorgverlenercode) ? row.zorgverlenercode : notEmpty(row.verwijzer) ? row.verwijzer : <label>Geen data</label>}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						) : (
							<section className='table-empty pb2'>Er is nog geen verzoek om toewijzing in behandeling</section>
						)}
					</section>
				</section>
				<section className='client-data'>
					<button className={'btn-unhide'} onClick={() => this.handleShowHideTable(3)}>
						{tables[3].hidden ? 'Toon historie' : 'Verberg historie'}
					</button>
				</section>
				<section className={tables[3].hidden ? 'client-data table-hidden' : 'client-data'}>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[3].figure}></figure>
							<h2>{tables[3].name}</h2>
						</section>
						{data3.length !== 0 ? (
							<section id='table3' className='table-scroll-x' onScroll={() => this.handleScroll(3)}>
								<aside className={tables[3].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(3)}></aside>
								<aside className={tables[3].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(3)}></aside>
								<table className='data-table'>
									<tbody>
										<tr>
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status' label='Status' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='retourcodes' label='Retourcodes' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_reden' label='Reden' />
											<td></td>
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='categorie_code' label='Categorie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='product_code' label='Product' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='begindatum' label='Begindatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='einddatum' label='Einddatum' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='volume' label='Volume' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='eenheid_label' label='Eenheid' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='frequentie_label' label='Frequentie' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='soort' label='Soort' />
											<TableColumnHeader handleSort={this.handleSort} column={tables[3].column} order={tables[3].order} table='3' data='zorgverlenercode' label='Verwijzer' />
										</tr>

										{data3.map(row => (
											<tr key={row.id}>
												<td>
													<button
														className={row.status === 1 || row.status === 4 ? 'button edit' : 'button read'}
														onClick={event => (row.status === 1 || row.status === 4 ? this.handleEdit(event, row.id, 3) : this.handleShow(event, row.id, 3))}
														disabled={row.status === 1 || row.status === 4}></button>

													<button
														className={row.status === 1 || row.status === 4 ? 'button remove' : 'button remove disabled'}
														onClick={event => this.handleRemove(event, row.id, 3)}
														disabled={true}></button>
												</td>
												<td>{<StatusCodeAllocationRequest status={row.status} />}</td>

												<td>
													<button
														className={notEmpty(row.status) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleStatus(row.status)}
														disabled={notEmpty(row.status) ? false : true}></button>
												</td>

												<td>{notEmpty(row.retourcodes) ? splitJoin(row.retourcodes, ',', ', ') : <label>Geen data</label>}</td>

												<td>
													<button
														className={notEmpty(row.retourcodes) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleRetourcode(row.retourcodes)}
														disabled={notEmpty(row.retourcodes) ? false : true}></button>
												</td>

												<td>{notEmpty(row.status_reden) ? row.status_reden : <label>Geen data</label>}</td>
												<td>
													<button
														className={notEmpty(row.status_reden) ? 'button info' : 'button info disabled'}
														onClick={() => this.handleReden(row)}
														disabled={notEmpty(row.status_reden) ? false : true}></button>
												</td>
												<td>{
													row.categorie_code === null ? 'Generiek' :
														notEmpty(row.categorie_code) ? row.categorie_code :
															<label>Geen data</label>
												}</td>
												<td>{
													row.categorie_code === null ? '' :
														row.product_code === null ? 'Aspecifiek' :
															notEmptyString(row.product_code) ? row.product_code :
																<label>Geen data</label>
												}</td>
												<td>{notEmpty(row.begindatum) ? formatDate(row.begindatum) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.einddatum) ? formatDate(row.einddatum) : <label>Geen data</label>}</td>
												<td>{notEmpty(row.volume) ? row.volume : <label>Geen data</label>}</td>
												<td>{notEmpty(row.eenheid_label) ? row.eenheid_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.frequentie_label) ? row.frequentie_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.soort_label) ? row.soort_label : <label>Geen data</label>}</td>
												<td>{notEmpty(row.zorgverlenercode) ? row.zorgverlenercode : notEmpty(row.verwijzer) ? row.verwijzer : <label>Geen data</label>}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						) : (
							<section className='table-empty pb2'>Er is nog geen historie van verzoeken</section>
						)}
					</section>
				</section>
			</>
		);
	};
}
