import React, { useState } from 'react';
import Tooltip from '../../Tooltip';
import { ControlsValues, TableColumnDefinition } from '../types';
import styles from './index.module.css';
import CascadeInputNumber from './CascadeInputNumber';
import CascadeInputDate from './CascadeInputDate';
import CascadeInputSelect from './CascadeInputList';
import CascadeInputText from './CascadeInputText';

interface ITableUpdateControls <T, K extends keyof T> {
	controlColumns: Array<TableColumnDefinition<T,K>>,
	controlValues: ControlsValues<T>
	onChange: (v: number | string, col: TableColumnDefinition<T,K>) => void;
	onClickCascade: () => void;
	resetParametersHandler: () => void;
}

const TableUpdateControls = <T, K extends keyof T>({controlColumns, controlValues, onChange, onClickCascade, resetParametersHandler}: ITableUpdateControls<T,K>) => {
	const [tableControlsExpanded, setTableControlsExpanded] = useState<boolean>(false);
	const [cascadeButtonHovered, setCascadeButtonHovered] = useState<boolean>(false);
	const [resetParamButtonHovered, setResetParamButtonHovered] = useState<boolean>(false);
	const expandControlsHandler = () => {
		setTableControlsExpanded(!tableControlsExpanded);
	};

	const cascadeButtonOnMouseEnterHandler = () => {
		setCascadeButtonHovered(true);
	};
	const cascadeButtonOnMouseLeaveHandler = () => {
		setCascadeButtonHovered(false);
	};

	const resetButtonOnMouseEnterHandler = () => {
		setResetParamButtonHovered(true);
	};

	const resetButtonOnMouseLeaveHandler = () => {
		setResetParamButtonHovered(false);
	};

	const onChangeHandler = (v: number | string, col: TableColumnDefinition<T,K>) => {
		onChange(v, col);
	};

	return (
		<section className={styles.tableUpdateControlsContainer}>
			<div className={styles.tableUpdateControls}>
				<div className={styles.actionsContainer} onClick={expandControlsHandler} role={'button'}>
					<h2>{'Parameters Bijwerken'}</h2>
					<div className={tableControlsExpanded ? styles.expandChevronOpen : styles.expandChevronClosed}/>
				</div>
				{tableControlsExpanded && (
					<>
						<div className={styles.tableUpdateControlsParameters}>
							{controlColumns.map((col, i) => {
								switch (col.columnType) {
									case 'Text':
										return (
											<CascadeInputText
												controlColumn={col}
												onChange={(v) => onChangeHandler(v, col)}
												key={i}
											/>
										);
									case 'Currency': 
										return (
											<CascadeInputNumber 
												controlColumn={col}
												controlValues={controlValues}
												onChange={(v) => onChangeHandler(v, col)}
												key={i}
												isCurrency
											/>
										);
									case 'Number':
										return (
											<CascadeInputNumber
												controlColumn={col}
												controlValues={controlValues}
												onChange={(v) => onChangeHandler(v, col)}
												key={i}
											/>
										);
									case 'Date':
										return (
											<CascadeInputDate
												controlColumn={col}
												controlValues={controlValues}
												onChange={(v) => onChangeHandler(v, col)}
												key={i}
											/>
										);
									case 'List':
										return (
											<CascadeInputSelect
												controlColumn={col}
												onChange={(v) => onChangeHandler(v, col)}
												key={i}
											/>
										);
								}
							})}
						</div>
						<div className={styles.buttonsContainer}>
							<div style={{position: 'relative', width: '100%', height: 'auto', zIndex: 0}}>
								<button 
									onClick={onClickCascade} 
									className={styles.cascadeChangesButton} 
									aria-describedby={'cascade-description'}
									onMouseEnter={cascadeButtonOnMouseEnterHandler} 
									onMouseLeave={cascadeButtonOnMouseLeaveHandler}
								/>
								{cascadeButtonHovered && (
									<Tooltip id={'cascade-description'} text={'cascade waarden'} />
								)}
							</div>
							<div style={{position: 'relative', zIndex: 0}}>
								<button 
									onClick={resetParametersHandler} 
									className={styles.resetParametersButton} 
									aria-describedby={'reset-description'}
									onMouseEnter={resetButtonOnMouseEnterHandler}
									onMouseLeave={resetButtonOnMouseLeaveHandler}
								/>
								{resetParamButtonHovered && (
									<Tooltip id={'reset-description'} text={'reset parameters'} />
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default TableUpdateControls;
