import React from 'react';
import { IProductDetailsBySamenwerkings } from '../../../types';
import { Label } from '../../ui';
import styles from './index.module.css';

interface IContractFilters {
	title: string;
	samenwerkingFilterList?: IProductDetailsBySamenwerkings['samenwerkings_naam'][];
	productsFilterList?: IProductDetailsBySamenwerkings['product'][];
	wetFilterList?: IProductDetailsBySamenwerkings['wet'][];
	onWetSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onSamenwerkingSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onProductSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	onBegindatumSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**@todo: change query in the backend to remove count of items in the filter */
const ContractFilters: React.FC<IContractFilters> = ({
	title,
	samenwerkingFilterList,
	productsFilterList: productsFilteredList,
	wetFilterList,
	onWetSelection,
	onSamenwerkingSelection,
	onProductSelection,
	onBegindatumSelection,
}): JSX.Element => {
	return (
		<section className={styles.contractDataFiltersContainer}>
			<h2>{title}</h2>
			<div className={styles.contractDataFilters}>
				<div className={styles.contractDataFiltersElement}>
					<Label>Filters</Label>
				</div>
				<div className={styles.contractDataFiltersElement}>
					<Label strong htmlFor='wet-list'>Wet</Label>
					<select 
						id={'wet-list'} 
						name={'wetList'}
						onChange={onWetSelection}
					>
						<option value={undefined}>Toon Alle</option>
						{wetFilterList?.map((item, index) => (
							<option key={index}>{item}</option>
						))}
					</select>
				</div>
				<div className={styles.contractDataFiltersElement}>
					<Label strong htmlFor='f2'>Samenwerkingsverband</Label>
					<select 
						id='f2'
						name='samenwerkingsverband'
						onChange={onSamenwerkingSelection}
					>
						<option value={undefined}>Toon alle</option>
						{samenwerkingFilterList?.map((item, index) => (
							<option key={index} 
								value={item}>
								{`${item ? item : 'Geen Data'}`}
							</option>
						))}
					</select>
				</div>
				<div className={styles.contractDataFiltersElement}>
					<Label strong htmlFor={'products-list'}>Products</Label>
					<select 
						id={'products-list'} 
						name={'productslist'}
						onChange={onProductSelection}
					>
						<option value={undefined}>Toon alle</option>
						{productsFilteredList && productsFilteredList?.map((item, index) => (
							<option key={index} value={item}>
								{`${item}`}
							</option>
						))}
					</select>
				</div>
				<div className={styles.contractDataFiltersElement}>
					<Label strong htmlFor={'begindatum'}>Begindatum</Label>
					<input id={'begindatum'} type={'date'} onChange={onBegindatumSelection}/>
				</div>
			</div>
		</section>
	);
};

export default ContractFilters;
