import { FETCH_MIN_DELAY, FETCH_NOTIFY, FETCH_RETRIES, FETCH_TIMEOUT } from '../../configuration';
import unknownToError from '../../util/unknownToError';

type ExtraOptions = {
	retry?: boolean
};

type FetchFunction = (request: Request, extra: ExtraOptions) => Promise<Response>;
type WrapperForFetchFunction = (next: FetchFunction) => FetchFunction;

/**
 * Do a fetch call with options, nothing special at the time of writing the comment
 * (note: make request wrappers instead of adding logic to this function, request wrappers can easily be added/removed)
 */
const doFetch: FetchFunction = async (request): Promise<Response> => {
	return fetch(request);
};

/**
 * Wrapper that makes request timeout after some time
 * @param timeout The timeout
 */
const withTimeout = (timeout: number): WrapperForFetchFunction => next => async (request, extra) => {
	const controller = new AbortController();
	const abort = () => controller.abort();
	const id = setTimeout(abort, timeout);
	request.signal?.addEventListener('abort', abort);
	try {
		return await next(new Request(request, {
			signal: controller.signal,
		}), extra);
	} finally {
		clearTimeout(id);
		request.signal?.removeEventListener('abort', abort);
	}
};
/**
 * Wrapper that changes any 502 responses to an error
 */
const with502Fail = (): WrapperForFetchFunction => next => async (request, extra) => {
	const response = await next(request, extra);
	if (response.status === 502) {
		throw new Error('Got 502 error for ' + request.url + ', handling it as a general error');
	}
	return response;
};
/**
 * Wrapper that makes failed requests retry automatically
 */
const withRetry = (maxAttempts = FETCH_RETRIES, maxDelay = FETCH_MIN_DELAY): WrapperForFetchFunction => next => async (request, extra) => {
	let lastError: Error;
	let attempt = 0;
	let body: Blob | null = null;
	let isGoingToRetry;
	do {
		attempt++;
		isGoingToRetry = extra.retry !== false && attempt < maxAttempts;
		const delayPromise = new Promise((resolve) => window.setTimeout(resolve, Math.min(maxDelay * attempt, 1000)));
		try {
			if (isGoingToRetry && request.body && !request.bodyUsed) {
				body = await request.blob();
			}
			return await next(body ? new Request(request, { body }) : request, extra);
		} catch (error) {
			console.warn(`Caught failed request, attempt (${attempt}/${maxAttempts}): `, error);
			lastError = unknownToError(error);
		}
		await delayPromise;
	} while (isGoingToRetry);
	throw lastError;
};

/**
 * Wrapper that announces any pending requests to the window object
 */
const withAnnounce = (): WrapperForFetchFunction => next => async (request, extra) => {
	const detail = {
		requestId: request.headers.get('X-conflict-token') || `${Math.random()}`,
		endpoint: request.url,
		method: request.method,
	};

	let hasAnnouncedTimer: number | null = window.setTimeout(() => {
		hasAnnouncedTimer = null;
		window.dispatchEvent(new CustomEvent('aanbieder-url-loading', { detail }));
	}, FETCH_NOTIFY);

	try {
		return next(request, extra);
	} finally {
		if (hasAnnouncedTimer === null) {
			window.dispatchEvent(new CustomEvent('aanbieder-url-loading-done', { detail }));
		} else {
			window.clearTimeout(hasAnnouncedTimer);
		}
	}
};

/**
 * Wrapper that adds a token to the headers to every request, so retried requests do not execute double actions
 */
const withRequestId = (): WrapperForFetchFunction => next => (request, extra) => {
	let requestId = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		requestId += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	request.headers.set('X-conflict-token', requestId);
	return next(request, extra);
};

/**
 * Chain multiple request interceptors in a row. The request passes from the top to the bottom through the list
 */
const chain = (...pipeline: WrapperForFetchFunction[]) => (base: FetchFunction): FetchFunction => {
	let finalFunction = base;
	for (let i = pipeline.length - 1; i >= 0; i--) {
		finalFunction = pipeline[i](finalFunction);
	}
	return finalFunction;
};

/**
 * Sends a fetch request
 */
const doRequest = chain(
	withRequestId(),
	withAnnounce(),
	withRetry(),
	with502Fail(),
	withTimeout(FETCH_TIMEOUT),
)(doFetch);
export default doRequest;
