import {useAuth0} from './Auth0';
import LogoZorgVerkeerStart from '../element/logo/LogoZorgVerkeerStart';
import {ENVIRONMENT} from '../../configuration';
import AppErrorBoundary from './AppErrorBoundary';
import { GetTokenSilentlyOptions, User } from '@auth0/auth0-react';

interface Props {
	children: (props: {
		user: User | undefined;
		getTokenSilently: (payload?: GetTokenSilentlyOptions) => Promise<string>;
	}) => JSX.Element | null;
}

const AppAuthenticate = ({ children }: Props) => {
	const {isAuthenticated, loginWithRedirect, loading, user, getTokenSilently} = useAuth0();

	if (loading) return null;

	if (ENVIRONMENT === 'development') {
		return (
			<AppErrorBoundary>
				{children({ user, getTokenSilently: () => Promise.resolve('')})}
			</AppErrorBoundary>
		);
	}

	if (ENVIRONMENT === 'production' && isAuthenticated) {
		return (
			<AppErrorBoundary>
				{children({ user, getTokenSilently})}
			</AppErrorBoundary>
		);
	}

	return (
		<AppErrorBoundary>
			<div className='screen'>
				<div className='dialog'>
					<form>
						<header>
							<h1>Aanbieder</h1>
						</header>
						<p className='welcome-message'>
							<strong>Welkom bij de Aanbieder applicatie van Zorgverkeer.</strong>
							<br />
							<br />
                            Deze applicatie wordt u aangeboden door:
						</p>
						<main className='welcome-logos'>
							<LogoZorgVerkeerStart />
						</main>
						<p className='welcome-message'>Let op: deze applicatie bevat gevoelige informatie. Wij verzoeken u om hier vertrouwelijk mee om te gaan.</p>
						<footer>
							<button className='secondary' onClick={() => openInNewTab('https://zorgverkeer.nl/site/#zorgaanbieder')}>
                                Aanmelden voor een account
							</button>
							<button className='primary' onClick={() => loginWithRedirect({
								appState: {targetUrl: window.location.pathname + window.location.search + window.location.hash },
							})}>
                                Inloggen voor zorgaanbieders
							</button>
						</footer>
					</form>
				</div>
			</div>
		</AppErrorBoundary>
	);
};

export default AppAuthenticate;

const openInNewTab = (url: string) => {
	if (typeof url !== 'string' || url === '') return false;
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) newWindow.opener = null;
};
