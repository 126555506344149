import React, { useState, Fragment, ReactNode } from 'react';
import styles from './index.module.css';
import { AddEntryOptions, SelectableData, TableColumnDefinition } from '../types';
// import RatesEditTable from '../RatesEditTable';
import { checkIfSelectable, U } from  '../types';
import formatCurrency from '../../../../util/formatCurrency';
import formatDate from '../../../../util/formatDate';
import CheckBox from '../../CheckBox';
import { preventNegativePastInput } from '../../../../util/preventNegativePastInput';
import TableRow from '../TableRow';
import TableCell from '../TableCell';
import InputNumber from '../../InputNumber';
import InputDate from '../../InputDate';
import InputText from '../../InputText';
import InputSelect from '../../InputSelect';

type TableRowProps<T, K extends keyof T> = {
	data: Array<U<T>>,
	columns: Array<TableColumnDefinition<T, K>>,
	originalDataSet: Array<U<T>>;
	indexable?: boolean,
	selectable?: boolean,
	setDesiplayedData: React.Dispatch<React.SetStateAction<U<T>[] | undefined>>,
	entriesOnChange: (v: string | number, row: SelectableData<T>, col: TableColumnDefinition<T,K>) => void,
	addNewEntry?: AddEntryOptions,
	newTableRow: T | null,
	children?: React.ReactNode,
	newTableEntryOnChange: (v: string | number, col: TableColumnDefinition<T,K>) => void,
	saveModifiedRow: (row: SelectableData<T>) => void;
	revertRowChanges: (row: SelectableData<T>) => void;
	markRowForDelete: (row: SelectableData<T>) => void;
};

type TDisplayEdit = [boolean, number| undefined];

const TableRows = <T, K extends keyof T>({data, columns, originalDataSet, indexable, selectable: isSelectable, 
	setDesiplayedData, entriesOnChange, newTableRow, addNewEntry, newTableEntryOnChange, 
	saveModifiedRow, revertRowChanges, markRowForDelete}: TableRowProps<T, K>): JSX.Element => {
	const [displayEdit, setDisplayEdit] = useState<TDisplayEdit>([false, undefined]);
	
	const statusLabelStyleMap = {
		'Origineel': 'statusLabelOriginal',
		'Aangepast': 'statusLabelModified',
		'Nieuwe Invoer': 'statusLabelNewEntry',
		'Gearchiveerd': 'statusLabelArchived',
		'Bewerking': 'statusLabelEditing',
		'Fout': 'statusLabelError',
		'Verkeerde Waarde': 'statusLabelWrongValue',
	};

	/**
     * @description function that handles the disabling of checkbox based on the rowStatus case
     * @param row generic row with a Selectable Interface
     * @returns boolean
     */
	const handleCheckBoxEnable = (row: SelectableData<T>): boolean => {
		switch (row.rowStatus) {
			case 'Origineel': return false;
			case 'Fout': return false;
			case 'Verkeerde Waarde': return false;
			case 'Nieuwe Invoer': return true;
			case 'Bewerking': return true;
			case 'Aangepast': return true;
			case 'Gearchiveerd': return true;
		}
	};

	const entriesOnChangeHandler = (v: string | number, row: SelectableData<T>, col: TableColumnDefinition<T,K>) => {
		entriesOnChange(v, row, col);
	};

	const newTableEntryOnChangeHandler = (v: string | number, col: TableColumnDefinition<T,K>) => {
		newTableEntryOnChange(v, col);
	};

	return (
		<tbody className={styles.tableBody}>
			{data.map((row, rowIndex) => (
				<Fragment key={rowIndex}>
					<TableRow key={rowIndex} rowStyle={checkIfSelectable(row) && row.rowStatus == 'Fout'? 'fout': 'regular'}>
						{isSelectable && setDesiplayedData && checkIfSelectable(row) && (
							<TableCell cellStyle='check-box'>
								<CheckBox
									checked={row.selected}
									disabled={handleCheckBoxEnable(row)}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const checked = e.currentTarget.checked;
										setDesiplayedData(prevData => {
											if (prevData) {
												const newData = [...prevData];
												const modifiedEntry = {...data[rowIndex]};
												if (checkIfSelectable(modifiedEntry)) {
													const originalIndex = modifiedEntry.originalRowIndex;
													modifiedEntry.selected = checked;
													newData[originalIndex] = modifiedEntry;
													return newData;
												}
												return prevData;
											}
										});
									}}
								/>
							</TableCell>
						)}
						{indexable && (
							<TableCell cellStyle='index'>
								{checkIfSelectable(row) ? row.originalRowIndex + 1 :  rowIndex + 1}
							</TableCell>
						)}
						{columns.map((col, i) => {
							switch (col.columnType) {
								case 'Currency':
									if (col.isActionable) {
										return (
											<Fragment key={i}>
												<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
													{row[col.key] === null ? 'Geen Data' : formatCurrency(row[col.key] as unknown as number)}
												</TableCell>
												<TableCell cellStyle='regular'>
													<button 
														key = {`edit-button-${i}`} 
														onClick={() => setDisplayEdit((prevState) => {
															if (prevState[1] !== rowIndex) {
																return [true, rowIndex];
															}
															return [false, undefined];
														},
														)}
													/>
												</TableCell>
											</Fragment>
										);
									}
									if (col.isEditable) {
										if (checkIfSelectable(row) && row.selected && (row.rowStatus == 'Origineel' || row.rowStatus == 'Bewerking' || row.rowStatus == 'Fout')){
											return (
												<TableCell cellStyle='regular' key={i}>
													<span> {'€ '}
														<InputNumber 
															step={0.01}
															value={(row[col.key] as unknown as number)/100}
															min={col.validations ? col.validations.min : undefined}
															max={col.validations ? col.validations.max : undefined}
															// onChange={(e) => entriesChangeHandler(e, row, col)}
															onChange={v => entriesOnChangeHandler(v, row, col)}
															onPaste={preventNegativePastInput}
															className={styles.number}
														/>
													</span>
												</TableCell>
											);
										} 
									} 
									return (
										<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data': 'currency'}>
											{row[col.key] === null ? 'Geen Data' : formatCurrency(row[col.key] as unknown as number)}
										</TableCell>
									);

								case 'Number':
									if (col.isActionable) {
										return (
											<Fragment key={i}>
												<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
													{row[col.key] === null ? 'Geen Data' : row[col.key] as unknown as number}
												</TableCell>
												<TableCell cellStyle='regular'>
													<button 
														key = {`edit-button-${i}`} 
														onClick={() => setDisplayEdit((prevState) => {
															if (prevState[1] !== rowIndex) {
																return [true, rowIndex];
															}
															return [false, undefined];
														},
														)}
													/>
												</TableCell>
											</Fragment>
										);
									}
									if (col.isEditable) {
										if (checkIfSelectable(row) && row.selected && (row.rowStatus == 'Origineel' || row.rowStatus == 'Bewerking' || row.rowStatus == 'Fout')){
											return (
												<TableCell cellStyle='regular' key={i}>
													<InputNumber
														step={1}
														value={row[col.key] as unknown as number}
														onChange={v => entriesOnChangeHandler(v, row, col)}
														min={col.validations ? col.validations.min : undefined}
														max={col.validations ? col.validations.max : undefined}
														onPaste={preventNegativePastInput}
														className={styles.number}
													/>
												</TableCell>
											);
										}
									}
									return (
										<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
											{row[col.key] === null ? 'Geen Data' : row[col.key] as unknown as number}
										</TableCell>
									);
								case 'Date':
									if (col.isActionable) {
										return (
											<Fragment key={i}>
												<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
													{row[col.key] === null ? 'Geen Data' : formatDate(row[col.key] as unknown as Date)}
												</TableCell>
												<TableCell cellStyle='regular'>
													<button key = {`edit-button-${i}`}/>
												</TableCell>
											</Fragment>
										);
									}
									if (col.isEditable) {
										{
											if (checkIfSelectable(row) && row.selected && 
                                            (row.rowStatus == 'Origineel' || row.rowStatus == 'Bewerking' || row.rowStatus == 'Fout')) {
												// const oldDate = formatDate(originalDataSet[rowIndex][col.key] as unknown as string, 1);
												const { originalRowIndex }= row;
												const originalRow = originalDataSet[originalRowIndex];
												return (
													<Fragment key={i}>
														<TableCell cellStyle='regular' key={i}>
															<InputDate
																className={styles.input}
																onChange={v => entriesOnChangeHandler(v, row, col)}
																value={formatDate(row[col.key] as unknown as string, 1)}
																min={col.validations?.min instanceof Function ? col.validations.min(originalRow) : col.validations?.min}
																max={col.validations?.max}
															/>
														</TableCell>
													</Fragment>
												);
											}
										}
									}
									return (
										<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
											{row[col.key] === null ? 'Geen Data' : formatDate(row[col.key] as unknown as Date)}
										</TableCell>
									);
								case 'Text':
									if (col.isActionable) {
										return (
											<Fragment key={i}>
												<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
													{row[col.key] === null ? 'Geen Data' : row[col.key] as ReactNode}
												</TableCell>
												<TableCell cellStyle='regular'>
													<button key = {`edit-button-${i}`}/>
												</TableCell>
											</Fragment>
										);
									}
									if (col.isEditable) {
										if (checkIfSelectable(row) &&
                                        (row.rowStatus == 'Origineel' || row.rowStatus == 'Bewerking' || row.rowStatus == 'Fout') 
                                        && row.selected) { 
											return (
												<Fragment key={i}>
													<TableCell cellStyle='regular' key={i}>
														<InputText
															value={row[col.key] as unknown as string}
															onChange={v => entriesOnChangeHandler(v, row, col)}
															className={styles.input}
														/>
													</TableCell>
												</Fragment>
											);}
									}
									return (
										<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
											{row[col.key] === null ? 'Geen Data' : row[col.key] as unknown as string}
										</TableCell>
									);
								case 'List': 
									if (col.isActionable) {
										return (
											<Fragment key={i}>
												<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
													{row[col.key] === null ? 'Geen Data' : row[col.key] as unknown as string}
												</TableCell>
												<TableCell cellStyle='regular'>
													<button key = {`edit-button-${i}`}/>
												</TableCell>
											</Fragment>
										);
									}
									if (col.isEditable) {
										if (checkIfSelectable(row) && row.selected && col.columnListValues &&
                                            (row.rowStatus == 'Origineel' || row.rowStatus == 'Bewerking' || row.rowStatus == 'Fout')) {
											return (
												<Fragment key={i}>
													<TableCell key={i} cellStyle='regular'>
														<InputSelect
															onChange={v => entriesOnChangeHandler(v, row, col)}
															className={styles.input}
															valueOptions={col.columnListValues} 
														/>
													</TableCell>
												</Fragment>
											);
										}
									}
									return (
										<TableCell key={i} cellStyle={row[col.key] === null ? 'geen-data' : 'regular'}>
											{row[col.key] === null ? 'Geen Data' : row[col.key] as unknown as string}
										</TableCell>
									);  
							}
						})}
						{isSelectable && checkIfSelectable(row) && (
							<Fragment>
								<TableCell cellStyle={row.rowStatus === 'Fout' ? 'fout' : 'regular'}>
									<div className={styles[statusLabelStyleMap[row.rowStatus]]} aria-describedby={`row-status-${rowIndex}`} title={row.updateStatus}>
										<p>{row.rowStatus}</p>
									</div>
								</TableCell>
								<TableCell cellStyle='regular'>
									<button 
										className={styles.saveButton}
										disabled={!row.selected || row.rowStatus !== 'Bewerking'}
										onClick={(e) => {
											const element = e.target as HTMLElement;
											const trParent = element.closest('tr');
											const allInputs = trParent?.querySelectorAll('input');
											if (allInputs) {
												for (const element of allInputs) {
													const isValid = element.checkValidity();
													if (!isValid) {
														element.focus();
														return;
													}}
											}
											saveModifiedRow(row);
										}
										}
									/>
									<button
										className={styles.revertChangesButton}
										disabled={!row.selected || row.rowStatus === 'Nieuwe Invoer'}
										onClick={() => revertRowChanges(row)} 
									/>
									<button 
										className={styles.deleteButton}
										disabled={!row.selected || row.rowStatus == 'Gearchiveerd'}
										onClick={() => markRowForDelete(row)}
									/>
								</TableCell>
							</Fragment>

						)}
					</TableRow>
					{displayEdit[0] && 
                    displayEdit[1] == rowIndex && (
					// <RatesEditTable 
					//     title={'Tarieven'}
					//     dbUser={dbUser && dbUser}
					//     contractDetails = {row as unknown as IContractDetailsSamenwerkings}
					// />
						<Fragment />
					)}
				</Fragment>
			))}
			{/* {newTableRow && (
				<NewTableRow 
					columns={columns}
					indexable={indexable}
					indexNumber={data.length + 1}
					onChange={newTableEntryOnChangeHandler}
				/>
			)} */}
			{newTableRow && (
				<TableRow rowStyle='regular'>
					<TableCell cellStyle='regular'>
						<CheckBox 
							checked 
							disabled
						/>
					</TableCell>
					{indexable && (
						<TableCell cellStyle='index'>
							{data.length + 1}
						</TableCell>
					)}
					{columns.map((col, i) => {
						switch (col.columnType) {
							case ('Text'):
								return (
									<TableCell key={i} cellStyle='regular'>
										<InputText
											key={i}
											onChange={(v) => newTableEntryOnChangeHandler(v, col)}
											className={styles.input}
										/>
									</TableCell>
								);
							case ('Currency'):
								return (
									<TableCell key={i} cellStyle='currency'>
										<span>{'€ '}</span>
										<InputNumber
											step={0.01}
											key={i}
											min={col.validations ? col.validations.min : undefined}
											max={col.validations ? col.validations.max : undefined}
											onChange={(v) => newTableEntryOnChangeHandler(v, col)}
											onPaste={preventNegativePastInput}
											className={styles.number}
											value={newTableRow[col.key] as unknown as number} // Needs checking
										/>
									</TableCell>
								);
							case 'Number':
								return (
									<TableCell key={i} cellStyle='regular'>
										<InputNumber
											step={1}
											key={i}
											min={col.validations ? col.validations.min : undefined}
											max={col.validations ? col.validations.max : undefined}
											onChange={(v) => newTableEntryOnChangeHandler(v, col)}
											onPaste={preventNegativePastInput}
											className={styles.number}
											value={newTableRow[col.key] as unknown as number}
										/>
									</TableCell>
								);
							case ('Date'):
								return (
									<TableCell key={i} cellStyle='regular'>
										<InputDate
											onChange={(v) => newTableEntryOnChangeHandler(v, col)}
											className={styles.input}
										/>
									</TableCell>
								);
							case 'List':
								return (
									<TableCell key={i} cellStyle='regular'>
										<InputSelect
											className={styles.input}
											onChange={(v) => newTableEntryOnChangeHandler(v, col)}
											valueOptions={col.columnListValues}
										/>
									</TableCell>
								);
						}
					})}
				</TableRow>
			)}
		</tbody>
	);
};

export default TableRows;
