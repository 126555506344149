import React from 'react';
import notEmpty from '../../util/notEmpty';
import formatDate from '../../util/formatDate';
import ReactExport from 'react-export-excel';

const ExcelListInvoiceRule = props => {
	const {dbUser, data} = props;

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	const prefix = dbUser.aanbieders.find(row => {
		return row.id === dbUser.aanbieder;
	});

	return (
		<ExcelFile
			filename={prefix.label + ' ' + prefix.naam + ' - Declaratieregels'}
			element={
				<button className='button download'>
					<strong>Excel</strong>
				</button>
			}>
			<ExcelSheet data={data} name='Declaratieregels'>
				<ExcelColumn label='Gemeente' value='gemeente_naam' />
				<ExcelColumn label='Cliënt' value='naam' />
				<ExcelColumn label='Toewijzing' value='toewijzing_nummer' />
				<ExcelColumn label='Product' value='product' />
				<ExcelColumn label='Volume' value='volume' />
				<ExcelColumn label='Eenheid' value='eenheid' />
				<ExcelColumn label='Tarief' value={col => col.tarief / 100} />
				<ExcelColumn label='Bedrag' value={col => col.totaal / 100} />
				<ExcelColumn label='Debet/Credit' value={col => col.totaal < 0 ? 'Credit' : 'Debet'} />
				<ExcelColumn label='Periode' value='periode' />
				<ExcelColumn label='Status' value={col => (col.status === 1 ? 'Aangemaakt' : col.status === 2 ? 'Ingediend' : col.status === 3 ? 'Goedgekeurd' : col.status === 4 ? 'Afgekeurd' : col.status === 5 ? 'Gecrediteerd' : '')} />
				<ExcelColumn label='Datum en tijd' value={col => (notEmpty(col.status_ts) ? formatDate(col.status_ts, 2) : '')} />
				<ExcelColumn label='Declaratienummer' value='factuurnummer' />
				<ExcelColumn label='Retourcode' value='retourcode' />
				<ExcelColumn label='Reden afwijzing' value='reden_afwijzing' />
				<ExcelColumn label='Bsn' value='bsn' />
			</ExcelSheet>
		</ExcelFile>
	);
};

export default ExcelListInvoiceRule;
