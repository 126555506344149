import React, { FormEventHandler } from 'react';
import styles from './index.module.css';
interface FormProps {
	children: React.ReactNode;
	title?: string;
	onSubmit?: FormEventHandler<HTMLFormElement>;
}
const Form: React.FC<FormProps> = ({children, title, onSubmit}) => {
	return (
		<form className={styles.container} onSubmit={onSubmit}>	
			{title && (
				<div className={styles.titleWrapper}>
					<h2>{title}</h2>
				</div>
			)}
			<div className={styles.inputContainerGrid}>
				{children}
			</div>

		</form>
	);
};

export default Form;
