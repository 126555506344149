import React from 'react';
import TableColumnHeader from '../element/table/TableColumnHeader';
import formatDate from '../../util/formatDate';
import formatTime from '../../util/formatTime';
import sortArray from '../../util/sortArray';
import api from '../../util/apiRequest';
import {confirmAlert} from 'react-confirm-alert';
import {matchPath, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import history from '../../util/history';
import isAuthorized from '../../util/isAuthorized';
import Attachment from '../element/miscellaneous/Attachment';

const NOTIFICATION_SUFFIX = ' | Aanbieder';

class UserNotification extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tables: {
				1: {
					name: 'Meldingen',
					figure: 'notification-figure',
					column: 'prioriteit',
					label: 'Prioriteit',
					order: true,
					select: [],
					hint: false,
					hidden: false,
					left_hint: false,
				},
			},
			notifications: [],
			checksum: null,
			init: false,
		};
	}

	handleMatch = () => {
		const match = matchPath(window.location.pathname, {
			path: '/meldingen/:id',
			exact: true,
			strict: false,
		});
		return Number(match.params.id);
	};

	componentDidMount = () => {
		this.handleNotifications();
		window.addEventListener('resize', this.updateScroll);
		setTimeout(() => {
			this.updateScroll();
		}, 500);
	};

	componentDidUpdate = () => {
		const {checksum} = this.state;
		if (this.props.checksum !== checksum) this.handleNotifications();
	};

	handleNotifications = () => {
		this.setState(state => {
			state.checksum = this.props.checksum;
			state.notifications = this.props.notifications;
			state.init = true;
			return state;
		});
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateScroll);
	};

	updateScroll = () => {
		const tables = [1];
		tables.forEach(id => {
			this.handleScroll(id);
		});
		this.setState(state => {
			state.update++;
			state.trigger = this.props.trigger;
			return state;
		});
	};

	handleScroll = id => {
		const table = document.querySelector(`#table${id}`);
		this.setState(state => {
			try {
				if (table.scrollWidth > table.clientWidth && table.scrollWidth - table.clientWidth !== table.scrollLeft) {
					state.tables[id].hint = true;
				} else {
					state.tables[id].hint = false;
				}

				if (table.scrollWidth > table.clientWidth && table.scrollLeft !== 0) {
					state.tables[id].left_hint = true;
				} else {
					state.tables[id].left_hint = false;
				}
			} catch (e) {
				// e
			}
			return state;
		});
	};

	handleSrollToEnd = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = table.scrollWidth - table.clientWidth;
	};

	handleSrollToStart = id => {
		const table = document.querySelector(`#table${id}`);
		if (table) table.scrollLeft = 0;
	};

	handleSort = (col, label, table) => {
		this.setState(state => {
			if (state.tables[table].column === col) {
				state.tables[table].order = !state.tables[table].order;
			} else {
				state.tables[table].order = true;
			}
			state.tables[table].column = col;
			state.tables[table].label = label;
			return state;
		});
	};

	handleRead = async (event, user, notification, status) => {
		event.preventDefault();
		event.stopPropagation();
		const {token} = this.props.dbUser;
		const updateNotification = await api.updateNotification(user, notification, status, api.makeHeader(token));
		if (updateNotification.error && updateNotification.error === true) {
			this.props.handleForm(updateNotification);
			return null;
		}
		if (updateNotification.status === 200) this.props.handleNotificationStatus(notification, status);
	};

	confirmArchive = (event, user, notification) => {
		event.preventDefault();
		event.stopPropagation();
		const message = notification === 0 ? 'Weet u zeker dat u alle meldingen wilt archiveren?' : 'Weet u zeker dat u deze melding wilt archiveren?';
		confirmAlert({
			title: 'Melding archiveren',
			message: message,
			buttons: [{label: 'Annuleer'}, {label: 'OK', onClick: () => this.handleArchive(user, notification, 1)}],
		});
	};

	handleArchive = async (user, notification, status) => {
		const {token} = this.props.dbUser;
		const archiveNotification = await api.archiveNotification(user, notification, api.makeHeader(token));
		if (archiveNotification.error && archiveNotification.error === true) {
			this.props.handleForm(archiveNotification);
			return null;
		}
		if (archiveNotification.status === 200) this.props.handleNotificationRemove(notification, status);
	};

	handleClick = (event, user, notification) => {
		event.preventDefault();
		event.stopPropagation();
		history.push('/meldingen/' + notification);
		this.handleRead(event, user, notification, 1);
	};

	render = () => {
		const {notifications, tables} = this.state;
		const {dbUser} = this.props;
		const {autorisatie} = dbUser;

		if (isAuthorized(autorisatie, 'meldingen.tonen')) {
			const id = this.handleMatch();

			if (id !== 0) {
				const index = notifications.findIndex(row => {
					return row.id === id;
				});
				if (index === -1) {
					return null;
				}
				const data = notifications[index];
				const message = data.bericht.split('\n');
				const bijlagen = JSON.parse(data.bijlagen);

				return (
					<section className='client-data'>
						<section className='table-element'>
							<form>
								<header>
									<h1>{data.onderwerp}</h1>
								</header>
								<table className='client-table'>
									<tbody>
										<tr>
											<td>Prioriteit</td>
											<td>
												<span className={data.prioriteit === 1 ? 'info-color' : data.prioriteit === 2 ? 'warning-color' : data.prioriteit === 3 ? 'error-color' : ''}>
													{data.prioriteit === 1 ? 'Informatief' : data.prioriteit === 2 ? 'Waarschuwing' : data.prioriteit === 3 ? 'Foutmelding' : 'Onbekend'}
												</span>
											</td>
										</tr>
										<tr>
											<td>Datum</td>
											<td>
												{formatDate(data.status_ts, 2)} om {formatTime(data.status_ts)}
											</td>
										</tr>
										<tr>
											<td>Van</td>
											<td>
												{data.van_email ? (
													<a href={`mailto:${encodeURI(data.van_naam + ' <' + data.van_email + '>')}`}>
														{data.van_naam} &lt;{data.van_email}&gt;
													</a>
												) : (
													<em>Aanbieder module</em>
												)}
											</td>
										</tr>
										<tr>
											<td>Aan</td>
											<td>
												<a href={`mailto:${encodeURI(data.aan_naam + ' <' + data.aan_email + '>')}`}>
													{data.aan_naam} &lt;{data.aan_email}&gt;
												</a>
											</td>
										</tr>
										<tr>
											<td>Onderwerp</td>
											<td>{data.onderwerp.endsWith(NOTIFICATION_SUFFIX) ? data.onderwerp.substring(0, data.onderwerp.length - NOTIFICATION_SUFFIX.length) : data.onderwerp}</td>
										</tr>
										<tr>
											<td valign='top'>Bericht</td>
											<td>
												{message.map((row, index) => {
													return <p key={index}>{row}</p>;
												})}
											</td>
										</tr>

										{data.aanbieder_id && (
											<tr>
												<td>Aanbieder</td>
												<td>{this.props.aanbieder !== data.aanbieder_id ? <strong onClick={() => this.props.handleChooseAanbieder(null, data.aanbieder_id, 0)}>data.aanbieder</strong> : data.aanbieder}</td>
											</tr>
										)}
										{data.bericht_id && (
											<tr>
												<td>Bericht</td>
												<td>{data.bericht_id}</td>
											</tr>
										)}
										{data.client_id && (
											<tr>
												<td>Cliënt</td>
												<td>
													<Link
														to={{pathname: `/client/${data.client_id}`}}
														onClick={() => this.props.aanbieder !== data.aanbieder_id && this.props.handleChooseAanbieder(null, data.aanbieder_id, 0)}
													>
														{data.client}
													</Link>
												</td>
											</tr>
										)}
										{data.gebruiker_id && (
											<tr>
												<td>Client</td>
												<td>

													<Link
														to={{pathname: `/client/${data.gebruiker_id}`}}
														onClick={() => this.props.aanbieder !== data.aanbieder_id && this.props.handleChooseAanbieder(null, data.aanbieder_id, 0)}
													>
														{data.gebruiker ?? this.props.clienten.find(c => c.id === data.gebruiker_id)?.naam ?? data.gebruiker_id}
													</Link>
												</td>
											</tr>
										)}
										{data.gemeente_id && (
											<tr>
												<td>Gemeente</td>
												<td>{data.gemeente}</td>
											</tr>
										)}
										{data.start_stop_id && (
											<tr>
												<td>Start/Stop ID</td>
												<td>{data.start_stop_id}</td>
											</tr>
										)}
										{data.toewijzing_id && (
											<tr>
												<td>Toewijzing ID</td>
												<td>{data.toewijzing_id}</td>
											</tr>
										)}
										{data.vot_id && (
											<tr>
												<td>Veroek om toewijzing ID</td>
												<td>{data.vot_id}</td>
											</tr>
										)}
										{data.vow_id && (
											<tr>
												<td>Veroek om wijziging ID</td>
												<td>{data.vow_id}</td>
											</tr>
										)}
										{data.bijlagen && (
											<tr>
												<td valign='top'>Bijlagen</td>
												<td>
													{bijlagen.map((row, index) => {
														return (
															<p key={index}>
																<Attachment naam={row.naam} inhoud={row.inhoud} />
															</p>
														);
													})}
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<footer>
									<Link to='/meldingen/0' className='button'>
										<button>Terug</button>
									</Link>
								</footer>
							</form>
						</section>
					</section>
				);
			}

			let table1 = notifications.filter(row => {
				return row.verwijderd === 0;
			});

			let data1 = sortArray(table1, tables[1].order, tables[1].column);
			data1.map(row => {
				row.bijlagenbool = row.bijlagen ? 'Ja' : 'Nee';
				row.bijlagencount = row.bijlagen ? JSON.parse(row.bijlagen).length : 0;
				return row;
			});

			return (
				<section className='client-data'>
					<section className='table-element'>
						<section className='table-header'>
							<figure className={tables[1].figure}></figure>
							<h2>{tables[1].name}</h2>
						</section>
						{data1.length !== 0 ? (
							<>
								<section id='table1' className='table-scroll-x' onScroll={() => this.handleScroll(1)}>
									<aside className={tables[1].hint === true ? 'hint visible' : 'hint'} onClick={() => this.handleSrollToEnd(1)}></aside>
									<aside className={tables[1].left_hint === true ? 'left_hint visible' : 'left_hint'} onClick={() => this.handleSrollToStart(1)}></aside>
									<table className='data-table'>
										<tbody>
											<tr>
												<td>
													<button className='button read' onClick={event => this.handleRead(event, dbUser.id, 0, data1[0].is_gelezen === 1 ? 0 : 1)} disabled={false}></button>
													<button className='button delete' onClick={event => this.confirmArchive(event, dbUser.id, 0)} disabled={false}></button>
												</td>
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='prioriteit' label='Prioriteit' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='status_ts' label='Datum' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='van_naam' label='Van' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='aan_naam' label='Aan' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='onderwerp' label='Onderwerp' />
												<TableColumnHeader handleSort={this.handleSort} column={tables[1].column} order={tables[1].order} table='1' data='bijlagenbool' label='Bijlagen' />
											</tr>

											{data1.map(row => (
												<tr
													key={row.id}
													className={
														row.prioriteit === 1
															? row.is_gelezen === 0
																? 'unread'
																: undefined
															: row.prioriteit === 2
																? row.is_gelezen === 0
																	? 'unread'
																	: undefined
																: row.prioriteit === 3
																	? row.is_gelezen === 0
																		? 'unread'
																		: undefined
																	: undefined
													}
													onClick={event => this.handleClick(event, dbUser.id, row.id)}>
													<td>
														<button className='button read' onClick={event => this.handleRead(event, dbUser.id, row.id, row.is_gelezen === 1 ? 0 : 1)} disabled={false}></button>

														<button className='button delete' onClick={event => this.confirmArchive(event, dbUser.id, row.id)} disabled={false}></button>
													</td>
													<td>
														<span
															className={
																row.prioriteit === 1
																	? row.is_gelezen === 0
																		? 'unread info-color'
																		: 'info-color'
																	: row.prioriteit === 2
																		? row.is_gelezen === 0
																			? 'unread warning-color'
																			: 'warning-color'
																		: row.prioriteit === 3
																			? row.is_gelezen === 0
																				? 'unread error-color'
																				: 'error-color'
																			: ''
															}>
															{row.prioriteit === 1 ? 'Informatief' : row.prioriteit === 2 ? 'Waarschuwing' : row.prioriteit === 3 ? 'Foutmelding' : 'Onbekend'}
														</span>
													</td>
													<td>
														{formatDate(row.status_ts, 2)} om {formatTime(row.status_ts)}
													</td>
													<td>{row.van_email ? row.van_naam : 'Systeem'}</td>
													<td>{row.aan_naam}</td>
													<td>{row.onderwerp.endsWith(NOTIFICATION_SUFFIX) ? row.onderwerp.substring(0, row.onderwerp.length - NOTIFICATION_SUFFIX.length) : row.onderwerp}</td>
													<td>
														{row.bijlagenbool}
														{row.bijlagencount !== 0 && ' (' + row.bijlagencount + ')'}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</section>
								<section className='table-footer'></section>
							</>
						) : (
							<section className='table-empty'>Er zijn geen nieuwe meldingen.</section>
						)}
					</section>
				</section>
			);
		} else {
			return '';
		}
	};
}

export default withRouter(UserNotification);
